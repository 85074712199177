import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from 'reactstrap';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import Global, {DEFAULT_LOCATION, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE} from '../utils/Global';
import {allIsEmpty, handleTimeAgo, imageSelector, isEmpty} from "na-utilities/src/utils/Utilities";
import queryString from 'query-string';
// import InventoryQuantityModal from '../components/modal/InventoryQuantityModal';
import {getData} from '../utils/StorageUtil';
import ImageCropper from '../components/modal/ImageCropper';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import WarningLabel from '../components/Widget/WarningLabel';
import label from 'na-utilities/src/label/Label'
import SpotLocationSelectionModal from "../components/modal/SpotLocationSelectionModal";
import UpdateSmartDeviceStatusModal from "../components/modal/UpdateSmartDeviceStatusModal";
import {MdEdit, MdStorage} from "react-icons/all";
import SmartDeviceConfigurationModal from "../components/modal/SmartDeviceConfigurationModal";
import SockJsClient from "react-stomp";
import Endpoint from "na-utilities/src/api/Endpoint";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import Pagination from "../components/Pagination";
import SmartDeviceUpdateModal from "../components/modal/SmartDeviceUpdateModal";


class SmartDeviceDetailPage extends BasePage{

    constructor(props) {
        super(props);
        let state = this.state
        state.smartDevice = {}
        state.company = {}
        state.spotLocation = {}
        state.smartDeviceStatus = {}
        state.categories = []
        state.image  = null
        state.provinces = []
        state.cities = []
        state.districts = []
        state.villages = []
        state.province = {}
        state.city= {}
        state.district = {}
        state.village = {}
        state.smartDeviceIdQuery = queryString.parse(this.props.query).id
        state.category = {}
        state.modalAddConfirm = false
        state.modalDeleteConfirm = false
        state.ascending = true
        state.sortir = 'created'
        state.quantityModal = false
        state.page = 1
        state.totalPage = 0
        state.totalElement = 0
        state.pageElement = 0
        state.smartDeviceTypes = []
        state.smartDeviceType = {}
        state.companyModal = false ;
        state.spotLocationModal = false ;
        state.company = {}
        state.smartDeviceStatusModal = false
        state.smartDeviceConfigurationModal = false;
        state.smartDeviceState = {}
        state.validation = {}
        state.smartDeviceActivities = []
        state.smartDeviceUpdateModal = false ;
        this.setState(state)

    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchSmartDeviceDetail(this.state.smartDeviceIdQuery)
        this.fetchSmartDeviceTypes((smartDeviceTypes)=>{
            this.setState({smartDeviceTypes:smartDeviceTypes})
        })
    }




    fetchSmartDeviceDetail = (id) => {
        if(id!=null){
            this.get(Endpoint.API.SMART_DEVICE, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let smartDevice = response.data
                    // let smartDevice = smartDevice.smartDevice
                    if(!smartDevice.latitude || !smartDevice.longitude){
                        smartDevice.latitude = DEFAULT_LOCATION.latitude
                        smartDevice.longitude = DEFAULT_LOCATION.longitude
                    }
                    let productSubscribe = smartDevice.productSubscribe?smartDevice.productSubscribe:{}
                    let company = productSubscribe.company?productSubscribe.company:{}
                    let spotLocation = smartDevice.spotLocation?smartDevice.spotLocation:{}
                    let smartDeviceType = smartDevice.smartDeviceType?smartDevice.smartDeviceType:{}
                    let smartDeviceStatus = smartDevice.smartDeviceStatus?smartDevice.smartDeviceStatus:{}
                    let smartDeviceState = smartDevice.smartDeviceState?smartDevice.smartDeviceState:{}

                    let village = smartDevice&&smartDevice.village?smartDevice.village:null
                    let district = smartDevice&&smartDevice.village?smartDevice.village.district:null
                    let city = smartDevice&& smartDevice.village?smartDevice.village.district.city:null
                    let province = smartDevice&&smartDevice.village?smartDevice.village.district.city.province:null

                    this.setState({
                        smartDevice:smartDevice,
                        company:company,
                        spotLocation:spotLocation,
                        smartDeviceType:smartDeviceType,
                        smartDeviceStatus:smartDeviceStatus,
                        smartDeviceState:smartDeviceState,
                        taskCategory:response.data.taskCategory,
                        image1:smartDevice.imageLink1,
                        image2:smartDevice.imageLink2,
                        image3:smartDevice.imageLink3,
                        image4:smartDevice.imageLink4,
                        village:village,
                        district:district,
                        city:city,
                        province:province,
                    }, () => {
                        this.fetchSmartDeviceActivities()
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }



    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }

    fetchSmartDeviceActivities = () => {
        let {smartDeviceActivities, smartDevice} = this.state
        this.get(Endpoint.API.SMART_DEVICE_ACTIVITIES, {
            params :{
                ascending: false,
                sortir: "ca.created",
                page: this.state.page - 1,
                deviceId:smartDevice.id
            }
        }, null, response=>{
            if(response.code===200){
                let smartDeviceActivities = response.data
                let totalPage = response.totalPage
                let totalElement = response.totalElement
                let pageElement = response.pageElement
                this.setState({smartDeviceActivities:smartDeviceActivities,totalPage, totalElement, pageElement})
            }else{
                this.props.history.goBack();
            }
        }, false, true)
    }


    render() {
        var ids = [];
        var labels = [];

        let configuration = getData(Global.CONFIGURATION)
        let aspect = 4/4
        let smartDeviceTypes = this.state.smartDeviceTypes?this.state.smartDeviceTypes:[]
        let smartDeviceType = this.state.smartDeviceType?this.state.smartDeviceType:{}
        let {smartDeviceUpdateModal, spotLocationModal, smartDevice, company, spotLocation, smartDeviceStatus, smartDeviceStatusModal,
            smartDeviceConfigurationModal, smartDeviceState, validation, smartDeviceActivities} = this.state
        // let smartDevice = this.state.smartDevice?this.state.smartDevice:{}

        let rackIcon = ApplicationIcons.Icons.rack

        return (
            <Page
                title="Smart Device Cabinet"
                breadcrumbs={[{ name: 'smart device form', active: true }]}>
                {super.render()}
                <SpotLocationSelectionModal
                    showing={spotLocationModal}
                    spotLocation={spotLocation}
                    company={company}
                    onClose={()=>{
                        spotLocation = {}
                        smartDevice.spotLocation = spotLocation
                        this.setState({spotLocationModal:false, spotLocation, smartDevice})
                    }}
                    onSelect={(spotLocation)=>{
                        spotLocation = spotLocation
                        smartDevice.spotLocation = spotLocation
                        validation.spotLocation = undefined
                        this.setState({spotLocationModal:false, spotLocation, smartDevice, validation})
                    }}
                />
                <UpdateSmartDeviceStatusModal
                    modal={smartDeviceStatusModal}
                    smartDevice={smartDevice}
                    okCallback={(smartDeviceStatus)=>{
                        smartDevice.smartDeviceStatus = smartDeviceStatus
                        this.setState({smartDeviceStatus:smartDeviceStatus, smartDevice:smartDevice, smartDeviceStatusModal:false})
                    }} closeCallback={()=>{
                        this.setState({smartDeviceStatusModal:false})
                    }}/>
                <SmartDeviceConfigurationModal smartDevice={smartDevice} showing={smartDeviceConfigurationModal} onClose={()=>{
                    this.setState({
                        smartDeviceConfigurationModal:false
                    })
                }}/>
                <SmartDeviceUpdateModal
                    show={smartDeviceUpdateModal}
                    onClose={(value) => {
                        if(value){
                            smartDevice = value
                        }
                        this.setState({smartDeviceUpdateModal:false, smartDevice})
                    }}
                    smartDevice={smartDevice}/>

                <Row key={1}>
                    <Col>
                        <Card className="mb-6">
                            <CardHeader>{label.SmartDevice}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image1!=null?this.state.image1:rackIcon} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image1:file,
                                                        cropperModal1:true
                                                    })
                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                            onError={(elm)=>this.defaultImage(elm, rackIcon)}/>
                                            <CardBody>
                                                <CardTitle>Smart Device Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+SMART_DEVICE_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    smartDevice:null,
                                                                    image1:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image1:file,
                                                                        cropperModal1:true
                                                                    })
                                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image2!=null?this.state.image2:rackIcon} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image2:file,
                                                        cropperModal2:true
                                                    })
                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                            onError={(elm)=>this.defaultImage(elm, rackIcon)}/>
                                            <CardBody>
                                                <CardTitle>Smart Device Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+SMART_DEVICE_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    smartDevice:null,
                                                                    image2:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image2:file,
                                                                        cropperModal2:true
                                                                    })
                                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image3!=null?this.state.image3:rackIcon} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image3:file,
                                                        cropperModal3:true
                                                    })
                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                                     onError={(elm)=>this.defaultImage(elm, rackIcon)}/>
                                            <CardBody>
                                                <CardTitle>Smart Device Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+SMART_DEVICE_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    smartDevice:null,
                                                                    image3:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image3:file,
                                                                        cropperModal3:true
                                                                    })
                                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={3}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image4!=null?this.state.image4:rackIcon} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image4:file,
                                                        cropperModal4:true
                                                    })
                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                                     onError={(elm)=>this.defaultImage(elm, rackIcon)}/>
                                            <CardBody>
                                                <CardTitle>Smart Device Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+SMART_DEVICE_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    smartDevice:null,
                                                                    image4:null
                                                                })
                                                            )}>{label.Remove}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image4:file,
                                                                        cropperModal4:true
                                                                    })
                                                                }, MIME_PNG_JPEG, SMART_DEVICE_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col>
                                                        {label.Information}
                                                    </Col>
                                                    <Col className="text-right">
                                                        <Button
                                                            onClick={() => {
                                                                this.setState({smartDeviceUpdateModal:true})
                                                            }}>
                                                            <MdEdit/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="name">{label.Name}</Label>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={(smartDevice)?smartDevice.name:""}
                                                                readOnly={true}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    smartDevice.name = e.target.value
                                                                    validation.name = undefined
                                                                    this.setState({
                                                                        smartDevice, validation
                                                                    })

                                                                }}
                                                                placeholder={label.EnterName}
                                                            />
                                                            <WarningLabel show={validation.name} message={validation.name}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="name">{label.Label}</Label>
                                                            <Input
                                                                type="text"
                                                                name="label"
                                                                value={(smartDevice)?smartDevice.label:""}
                                                                readOnly={true}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    smartDevice.label = e.target.value
                                                                    validation.label = undefined
                                                                    this.setState({
                                                                        smartDevice,validation
                                                                    })

                                                                }}
                                                                placeholder={label.EnterLabel}
                                                            />
                                                            <WarningLabel show={validation.label} message={validation.label}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="code">{label.ShortCode}</Label>
                                                            <Input
                                                                type="text"
                                                                name="code"
                                                                readOnly={true}
                                                                value={smartDevice.shortCode}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="code">{label.DeviceId}</Label>
                                                            <Input
                                                                type="text"
                                                                name="code"
                                                                readOnly={true}
                                                                value={smartDevice.deviceId}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectSmartDeviceType}
                                                                objects={smartDeviceTypes}
                                                                fieldForLabel={"type"}
                                                                default={!allIsEmpty(smartDeviceType)?smartDeviceType.id:null}
                                                                disable={true}
                                                                callback={(smartDeviceType)=>{
                                                                    if(smartDeviceType){
                                                                        validation.smartDeviceType = undefined
                                                                        smartDevice.smartDeviceType = smartDeviceType
                                                                        this.setState({
                                                                            smartDevice,smartDeviceType,validation
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            smartDeviceType:undefined,
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                            <WarningLabel show={validation.smartDeviceType} message={validation.smartDeviceType}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Company}</Label>
                                                            <Input
                                                                style={{cursor:'pointer'}}
                                                                type="text"
                                                                name="company"
                                                                value={(company)?company.fullName:""}
                                                                readOnly={true}
                                                                placeholder={label.SelectCompany}
                                                            />
                                                            <WarningLabel show={validation.company} message={validation.company}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="spotLocation">{label.Location}</Label>
                                                            <Input
                                                                style={{cursor:'pointer'}}
                                                                type="text"
                                                                name="spotLocation"
                                                                value={(spotLocation)?spotLocation.name:""}
                                                                onClick={()=>{
                                                                    this.setState({spotLocationModal:true})
                                                                }}
                                                                readOnly={true}
                                                                placeholder={label.SelectStation}
                                                            />
                                                            <WarningLabel show={validation.spotLocation} message={validation.spotLocation}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Description}</Label>
                                                            <Input
                                                                type="textarea"
                                                                rows={4}
                                                                name="description"
                                                                value={(smartDevice)?smartDevice.description:""}
                                                                readOnly={true}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    if(smartDevice!=null){
                                                                        smartDevice.description = e.target.value
                                                                        this.setState({
                                                                            smartDevice:smartDevice
                                                                        })
                                                                    }
                                                                }}
                                                                placeholder={label.EnterDescription}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                {label.Activity}
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={12}>
                                                        <Table responsive hover>
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th scope="col">{label.User}</th>
                                                                <th scope="col">{label.Title}</th>
                                                                <th scope="col">{label.Description}</th>
                                                                <th scope="col">{label.TimeActivity}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                smartDeviceActivities.map((item, index)=>(
                                                                    <tr>
                                                                        <td>{((this.state.page - 1) * this.state.pageElement) + (++index)}</td>
                                                                        <td>{item.user.fullname}</td>
                                                                        <td>{item.title}</td>
                                                                        <td className="text-wrap">{item.description}</td>
                                                                        <td>{handleTimeAgo(item.created)}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            <Pagination 
                                                {...this.props}
                                                currentPage={parseInt(this.state.page)}
                                                pageCount={this.state.totalPage}
                                                callback={(page) => {
                                                    this.setState({page:page}, () => {
                                                        this.fetchSmartDeviceActivities()
                                                    })
                                                }}
                                            />
                                        </Card>
                                    </Col>
                                    <Col md={4}>
                                        <Card>
                                            <CardHeader>{label.Condition}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={10}>
                                                        <FormGroup>
                                                            <Label>&nbsp;</Label>
                                                            <Button
                                                                color={"secondary"}
                                                                disabled={smartDeviceState.id!=Global.SMART_DEVICE_TURNED_ON_ID}
                                                                size={smartDeviceState.id!=Global.SMART_DEVICE_TURNED_ON_ID?'md':'lg'}>{label.On}</Button>
                                                            <Button
                                                                color={"danger"}
                                                                disabled={smartDeviceState.id!=Global.SMART_DEVICE_TURNED_OFF_ID}
                                                                size={smartDeviceState.id!=Global.SMART_DEVICE_TURNED_OFF_ID?'md':'lg'}>{label.Off}</Button>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col  md={2}>
                                                        <Button color={"green"} size={"lg"} onClick={()=>{
                                                            this.setState({smartDeviceConfigurationModal:true})
                                                        }}><MdStorage/></Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="smartDeviceStatus">{label.Status}</Label>
                                                            <Input
                                                                style={{cursor:'pointer'}}
                                                                type="text"
                                                                name="smartDeviceStatus"
                                                                value={(smartDeviceStatus)?smartDeviceStatus.label:""}
                                                                onClick={()=>{
                                                                    if(smartDevice.id){
                                                                        this.setState({smartDeviceStatusModal:!smartDeviceStatusModal})
                                                                    }
                                                                }}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Endpoint.API.LISTEN_UPDATE_SMART_DEVICE_STATE+"/"+smartDevice.code]}
                    autoReconnect={true}
                    debug={Global.DEBUG}
                    heartbeat={5000}
                    onMessage={(smartDeviceState) => {
                        this.setState({smartDeviceState})
                    }}
                    onConnectFailure={(object)=>{
                        console.error(object)
                    }}
                    ref={ (client) => { this.clientRef = client }} />

                <ImageCropper src={this.state.image1} aspect={aspect} show={this.state.cropperModal1} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image1:file,
                            cropperModal1:false,
                            imageBlob1:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal1:false,
                        image1:smartDevice.imageLink1,
                        imageBlob1:null,
                    })
                }}/>
                <ImageCropper src={this.state.image2} aspect={aspect} show={this.state.cropperModal2} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image2:file,
                            cropperModal2:false,
                            imageBlob2:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal2:false,
                        image2:smartDevice.imageLink2,
                        imageBlob2:null,
                    })
                }}/>
                <ImageCropper src={this.state.image3} aspect={aspect} show={this.state.cropperModal3} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image3:file,
                            cropperModal3:false,
                            imageBlob3:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal3:false,
                        image3:smartDevice.imageLink3,
                        imageBlob3:null,
                    })
                }}/>
                <ImageCropper src={this.state.image4} aspect={aspect} show={this.state.cropperModal4} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image4:file,
                            cropperModal4:false,
                            imageBlob4:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal4:false,
                        image4:smartDevice.imageLink4,
                        imageBlob4:null,
                    })
                }}/>

            </Page>
        );
    }


}
export default Object.assign(SmartDeviceDetailPage, {ENDPOINT : "/smartDeviceDetail"})