import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import SearchInput from '../components/SearchInput';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import {deleteParam, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import ActiveLabel from '../components/Widget/ActiveLabel';
import label from 'na-utilities/src/label/Label'
import CompanyDetailPage from "./CompanyDetailPage";
import StationDetailPage from "./SpotLocationDetailPage";
import SmartDeviceDetailPage from "./SmartDeviceDetailPage";
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class SmartDevicePage extends BasePage{


  constructor(props) {
    super(props);
    this.state ={
      smartDevices : [],
      smartDevice:{},
      modalDeleteConfirm:false,
      ascending:false,
      sortir:'jt.created',
      search:"",
      page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
      totalPage:0,
      totalElement:0,
      pageElement:0,
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
  }
    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
            })
        }
    }

    fetchAll = (ascending, sortir, search, page, progressing) => {
        this.get(Endpoint.API.SMART_DEVICES, {
            params:{
                ascending:ascending,
                sortir:sortir,
                search:search,
                page: page-1
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    smartDevices : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        },progressing, true);
    }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Endpoint.API.SMART_DEVICE_DELETE+"/"+this.state.smartDevice.id, null, null, res=>{
          if(res.code===200){
              this.successToast(res.message)
              this.setState({
                  category:null
              }, () => {
                  this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
              })
          }
      }, true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  refreshSmartDevice = (ascending, sortir, progressing) => (
      this.setState({
          ascending:ascending,
          sortir:sortir
      }, () => {
          this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, progressing)
      })
  )

    render() {
        var i = 0 ;

    return (
        <Page
            title={label.SmartDevices}
            breadcrumbs={[{ name: 'smart device', active: true }]}
            className="TablePage"
        >
            {super.render()}
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message="Do you want to delete this smart device item from your list ?"
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card className="mb-6">
                <CardHeader>{label.SmartDevices}</CardHeader>
                <CardBody>
                  <Row>
                        <Col md={3}>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        sortirMap(this.state.sortir)
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshSmartDevice(this.state.ascending, "jt.name", true))}>{label.Name}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshSmartDevice(this.state.ascending, "jt.deviceId", true))}>{label.DeviceId}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshSmartDevice(this.state.ascending, "jt.created", true))}>{label.Created}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshSmartDevice(this.state.ascending, "jt.updated", true))}>{label.Updated}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={3}>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        this.state.ascending?label.Ascending:label.Descending
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshSmartDevice(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshSmartDevice(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={4}>
                            <SearchInput
                                placeholder={label.TypeAndEnterToSearch}
                                value={this.state.search}
                                onChange={e=>{
                                    this.setState({
                                        search:e.target.value
                                    }, () => {
                                        if(this.state.search===''){
                                            deleteParam(this.props, 'page')
                                        }
                                    })
                                }}
                                onEnter={e=>{
                                    if(this.state.search!==''){
                                        deleteParam(this.props, 'page')
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                        </Col>
                    </Row>
                  <Row>
                      <Table responsive hover>
                          <thead>
                          <tr>
                              <th>#</th>
                              <th>{label.Name}</th>
                              <th>{label.DeviceId}</th>
                              <th>{label.ShortCode}</th>
                              <th>{label.Label}</th>
                              <th>{label.Type}</th>
                              <th>{label.Company}</th>
                              <th>{label.Location}</th>
                              <th>{label.Status}</th>
                              <th>{label.Created}</th>
                              <th>{label.Updated}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                              this.state.smartDevices.map((item, index)=>(
                                  <tr key={index}>
                                      <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                      <td><a href={SmartDeviceDetailPage.ENDPOINT+"?id="+item.id}>{item.name}</a></td>
                                      <td>{item.deviceId}</td>
                                      <td>{item.shortCode}</td>
                                      <td>{item.label}</td>
                                      <td>{item.smartDeviceType.type}</td>
                                      <td><a href={CompanyDetailPage.ENDPOINT+"?id="+(item.productSubscribe.company?item.productSubscribe.company.id:undefined)}>{item.productSubscribe.company.fullName}</a></td>
                                      <td><a href={StationDetailPage.ENDPOINT+"?id="+(item.spotLocation?item.spotLocation.id:undefined)}>{item.spotLocation?item.spotLocation.name:""}</a></td>
                                      <td><ActiveLabel active={item.active}/></td>
                                      <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>

                                  </tr>
                              ))
                          }
                          </tbody>
                      </Table>
                  </Row>
                </CardBody>
                  <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }

};


export default Object.assign(SmartDevicePage, {ENDPOINT : "/smartDevices"})
