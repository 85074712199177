
const label =  {
    Username:"Username",
    Submit:"Kirim",
    Cancel:"Batalkan",
    Back:"Kembali",
    Or:"Atau",
    And:"Dan",
    Ok:"Oke",
    Edit:"Edit",
    Remove:"Hapus",
    Save:"Simpan",
    Close:"Tutup",
    Email:"Email",
    Page:"Halaman",
    Detail:"Detil",
    AddProduct:"Tambah Produk",
    AddInventory:"Tambah Inventori",
    EnterYourMail:"Masukkan Email Anda",
    YourEmail:"your@email.com",
    Password:"Password",
    ConfirmPassword:"Konfirmasi Password",
    EnterPassword:"Masukkan password",
    EnterConfirmPassword:"Masukkan konfirmasi password",
    EnterOldPassword:"Masukkan password lama",
    EnterNewPassword:"Masukkan password baru",
    ForgetPassword:"Lupa Password",
    NotRegisteredYet:"Belum Terdaftar",
    InvalidEmailFormat:"Format email tidak sesuai",
    PleaseEnterYourEmailWeWillSendYouInstructionToResetYourPassword:"Mohon masukkan email anda, kami akan mengirim instruksi lupa password melalui email",


    SelectProduct:"Pilih Produk",
    SelectCabinet:"Pilih Kabinet",
    SelectSize:"Pilih Ukuran",
    Sortir:"Pengurutan",
    SortBy:"Urutan",
    Ascending:"Kecil Ke Besar",
    Descending:"Besar Ke Kecil",
    Confirmation:"Konfirmasi",
    UpdateConfirmationMessage:"Apakah anda yakin untuk memperbarui data ini",
    DeleteConfirmationTitle:"Konfirmasi Penghapusan",
    DeleteConfirmationMessage:"Apakah anda yakin akan menghapus konten ini",

    _Maked:"Dibuat",
    Created:"Tanggal Buat",
    Name:"Nama",
    BrandName:"Merk",
    Heading:"Menu",
    Category:"Sub Menu",
    Updated:"Tanggal Ubah",
    Create:"Buat",
    Update:"Ubah",
    Add:"Tambah",
    SelectStatus:"Pilih Status",
    Active:"Aktif",
    Inactive:"Inaktif",
    Expired:"Expired",
    All:"Semua",
    AllInventory:"Semua Inventori",
    Type:"Tipe",
    Quantity:"Kuantitas",
    Weight:"Berat",
    Price:"Harga",
    Fullname:"Nama Lengkap",
    Phone:"No Telpon",

    Search:"Cari",
    SearchCreator:"Cari Pembuat",
    TypeAndEnterToSearch:"Masukkan pencarian lalu tekan enter",
    SearchByName:"Cari Berdasarkan Nama",
    HashTag:"Hashtag",
    Image:"Gambar",
    Slug:"Slug",
    Status:"Status",
    ChannelSize:"Jumlah Channel",
    LastStatus:"Status Terakhir",
    PreviousStatus:"Status Sebelumnya",
    Warning:"Peringatan",
    PleaseCompleteTheFields:"Mohon lengkapi form input",
    ProductForm:"Form Produk",
    UseImageTypeInstruction:(type, size)=>"Gunakan gambar bertipe "+type+" Dengan ukuran maksimal adalah "+size+" Kb",
    Upload:"Upload",
    EnterProductName:"Masukkan Nama Produk",
    EnterQuantity:"Masukkan Kuantitas",
    EnterSlug:"Masukkan Slug",
    EnterWeight:"Masukkan Berat",
    EnterPrice:"Masukkan Harga",
    EnterPricePerKW:"Masukkan Harga Per KW",
    HoursExpiredAfterCreated:"Jam masa berlaku setelah dibuat",
    HoursExpiredAfterPaid:"Jam masa berlaku setelah dibayar",
    HoursExpiredOnWaitingForPayment:"Jam masa berlaku saat menunggu pembayaran",
    EnterHoursExpiredAfterCreated:"Masukkan jam masa berlaku setelah dibuat",
    EnterHoursExpiredAfterPaid:"Masukkan jam masa berlaku setelah dibayar",
    EnterHoursExpiredOnWaitingForPayment:"Masukkan jam masa berlaku saat menunggu pembayaran",
    AutoSlug:"Slug Otomatis",
    SelectBrand:"Pilih Merk",
    SelectHeading:"Pilih Menu",
    SelectCategory:"Pilih Kategori",
    SelectFormType:"Pilih Jenis Form",
    RelatedHashTag:"Hashtag terkait",
    Description:"Deskripsi",
    EnterDescription:"Masukkan Deskripsi",
    Size:"Ukuran",
    EnterSize:"Masukkan Ukuran",
    EnterName:"Masukkan nama",
    EnterBrandName:"Masukkan nama merk",
    CategoryDescription:"Deskripsi Kategori",
    CategoryName:"Nama Kategori",
    EnterCategoryName:"Masukkan nama kategori",
    EnterCategoryDescription:"Masukkan deskripsi kategori",
    EnterProvinceName:"Masukkan nama propinsi",
    SelectProvince:"Pilih propinsi",
    SelectSmartDeviceType:"Pilih tipe smart device",
    EnterCityName:"Masukkan nama kota",
    SelectCity:"Pilih kota",
    EnterDistrictName:"Masukkan nama kecamatan",
    SelectDistrict:"Pilih kecamatan",
    EnterVillageName:"Masukkan nama kelurahan",
    SelectVillage:"Pilih kelurahan",
    PostalCode:"Kode Pos",
    Creator:"Pembuat",
    ContentOrStockLabel:"Konten / Stok",
    SoldStock:"Stok Terjual",
    Stock:"Stok",
    StockLog:"Riwayat Stok",
    SelectSortir:"Pilih Pengurutan",
    SelectDirection:"Pilih Arah Urutan",
    SelectInOurOut:"Pilih Tambah / Kurang",
    SelectStockAuditType:"Pilih Jenis Audit",
    StartDate:"Tanggal Mulai",
    EndDate:"Tanggal Akhir",
    CreatedStartDate:"Dibuat Mulai Tanggal",
    CreatedEndDate:"Dibuat Hingga Tanggal",
    UpdatedStartDate:"Diubah Mulai Tanggal",
    UpdatedEndDate:"Diubah Hingga Tanggal",
    EnterStartDate:"Masukkan Tanggal Awal",
    EnterEndDate:"Masukkan Tanggal Akhir",
    Note:"Catatan",
    View:"Lihat",
    IconClass:"Klasifikasi Icon",
    EnterIconClass:"Masukkan klasifikasi Icon",
    InventoryForm:"Form Inventori",
    AdminForm:"Form Admin",
    CustomerForm:"Form Customer",
    PointForm:"Form Poin",
    VoucherForm:"Form Voucher",
    PopularProduct:"Produk Populer",
    LatestProduct:"Produk Terbaru",
    Latest:"Terbaru",
    OrderQuantity:"Jumlah Pesanan",
    SoldOut:"Terjual",
    Added:"Ditambahkan",
    EnterBankLabel:"Masukkan label bank",
    EnterBankName:"Masukkan nama bank",
    EnterBankCode:"Masukkan kode bank",
    EnterAccountBankNumber:"Masukkan no rekening",
    EnterAccountBankName:"Masukkan nama pemilik rekening",
    SMSBankingSenderId:"Sender ID SMS",
    SMSBankingTemplate:"Template SMS Banking",
    EnterSmsBankingSenderId:"Masukkan sender SMS",
    SMSPrefixIndicator:"Prefix SMS Sebagai Indikator",
    EnterSMSPrefixIndicator:"Masukkan indikator prefix SMS",
    CurrencyFormat:"Format Mata Uang",
    CurrencyFormatExample:"Contoh : ##.###,##",
    EnterMobilePhone:"Masukkan no handphone",
    EnterCustomerReceiverEmail:"Masukkan email konsumen penerima",
    EnterSubscriberReceiverEmail:"Masukkan email subscriber penerima",
    EnterSortir:"Masukkan pengurutan",
    EnterPageLink:"Masukkan pagelink",
    ProductOrderCount:"Jumlah Pesanan",
    ProductOrderDate:"Tanggal Pesanan",
    FavoriteCount:"Jumlah Favorite",
    CartCount:"Jumlah Keranjang",
    OriginalPrice:"Harga Asli",
    FinalPrice:"Harga Final",
    Select:"Pilih",
    Check:"Cek",
    CompanyDescription:"Deskripsi Perusahaan",
    EnterCompanyDescription:"Masukkan Deskripsi Perusahaan",
    VoucherCode:"Kode Voucher",
    Owner:"Pemilik",
    Theme:"Tema",
    Period:"Periode",
    Benefit:"Benefit",
    Aggregate:"Aggregate",
    StartPeriod:"Periode Awal",
    EndPeriod:"Periode Akhir",
    SelectPeriod:"Pilih Periode",
    SelectVoucherBenefit:"Pilih Keuntungan Voucher",
    SelectPointBenefit:"Pilih Keuntungan Poin",
    SelectVoucherOwnerType:"Klasifikasi Pemilik Voucher",
    SelectVoucherDiscountType:"Klasifikasi Diskon",
    SelectAggregateType:"Pilih Tipe Aggregate",
    MaxUsage:"Maksimum Penggunaan",
    CurrentUsage:"Penggunaan Saat Ini",
    Publish:"Publikasi",
    Unpublish:"Stop Publikasi",
    ValidDay:"Masa Berlaku Dalam Hari",
    PurchaseDiscount: "Diskon Pembelian",
    ShippingDiscount:"Diskon Pengiriman",
    FreeShipping:"Gratis Pengiriman",
    PercentageDiscount : "Diskon Persentasi Harga",
    PriceDiscount : "Diskon Potongan Harga",
    AllTime:"Sepanjang Waktu",
    Dynamic:"Dinamis",
    Static:"Statis",
    SpecificCustomer:"Customer Tertentu",
    AnyCustomer:"Siapa Saja",
    SingleItem:"Satu Item Saja",
    JoinItem:"Penggabungan Item",
    AnyItem:"Item Apapun",
    PointExchange:"Penukaran Poin",
    EnterVoucherCode:"Masukkan Kode Voucher",
    EnterVoucherName:"Masukkan Nama Voucher",
    ThemeColor:"Warna Tema",
    AvailableItemForThisVoucher:"Item Tersedia Untuk Voucher Ini",
    MinimumPurchaseQuantity:"Minimum jumlah belanja",
    AvailableStock:"Stok Tersedia",
    CustomerWhoHasThisVoucher:"Customer Memiliki Voucher Ini",
    SelectPointType:"Pilih Jenis Poin",
    PurchaseItemPoin:"Poin Pembelian Untuk Item",
    PurchasePricePoin:"Poin Pembelian Untuk Harga",
    PurchasePercentagePoint:"Poin Persentase Pembelian",
    FixedPoint:"Poin Tetap",
    PointBonus:"Bonus Poin",
    PurchaseMinimum:"Minimal Pembelian",
    ProductItemBelongToThisPoin:"Item Produk Dengan Poin Ini",
    RegisteredForThisPoin:"Diberikan Poin Ini",
    RegisteredForAnotherPoin:"Diberikan Poin Lain",
    NotRegisteredForAnyPoin:"Belum Diberikan Poin",
    PublishConfirmation:"Konfirmasi Publikasi",
    UnpublishConfirmation:"Konfirmasi Stop Publikasi",
    PublishConfirmationMessage:"Apakah anda setuju dengan publikasi poin ini ?",
    UnpublishConfirmationMessage:"Apakah anda setuju dengan stop publikasi poin ini ?",
    FlashSaleContent:"Konten Flash Sale",
    Inactivate:"Non Aktifkan",
    Activate:"Aktifkan",
    InactivateConfirmation:"Konfirmasi Non Aktifkan",
    ActivateConfirmation:"Konfirmasi Aktifkan",
    InactivateFlashSaleConfirmationMessage:"Apakah anda yakin untuk menonaktifkan Flash Sale ini ?",
    ActivateFlashSaleConfirmationMessage:"Apakah anda yakin untuk mengaktifkan Flash Sale ini ?",
    SearchProduct:"Cari Produk",
    FlashSaleStock:"Stok Flash Sale",
    FilterCustomerSearch:"Filter Pencarian Customer",
    RegistrationStartDate:"Tanggal Registrasi Mulai",
    RegistrationEndDate:"Tanggal Registrasi Hingga",
    Gender:"Jenis Kelamin",
    FilterCustomerByProductOrder:"Filter Pencarian Customer Berdasarkan Pesanan",
    ProductOrderStartDate:"Tanggal Pesanan Mulai",
    ProductOrderEndDate:"Tanggal Pesanan Hingga",
    MobilePhone:"Handphone",
    ProductOrderDuring:"Pesanan Antara",
    ProductOrderFavoriteCartColumnLabel:"Trx/Fav/Keranjang",
    Address:"Alamat",
    CustomerAddress:"Alamat Customer",
    ListOfDestinationAddress:"Daftar Alamat Pengiriman",
    EnterAddress:"Masukkan Alamat",
    Firstname:"Nama Depan",
    EnterFirstname:"Masukkan Nama Depan",
    Lastname:"Nama Belakang",
    EnterLastname:"Masukkan Nama Belakang",
    EnterEmail:"Masukkan Email",
    EnterPhoneNumber:"Masukkan No Telp",
    BirthDate:"Tanggal Lahir",
    EnterBirthDate:"Masukkan Tanggal Lahir",
    SelectGender:"Pilih Jenis Kelamin",
    ProductOrderItemQuantity:"Jumlah Item Pesanan",
    TotalPay:"Jumlah Bayar",

    /**/
    // ProductOrder Status
    /**/
    Paid:"Terbayar",
    Packaging:"Pengepakan",
    Delivery:"Pengiriman",
    Done:"Selesai",
    Rejected:"Ditolak",
    OnReturn:"Pengembalian",
    AcceptedReturn:"Pengembalian Diterima",
    Order:"Pemesanan",
    /**/
    // End ProductOrder Status
    /**/

    Payment:"Pembayaran",
    PaymentFee:"Fee Pembayaran",
    ResiAndTracking:"Resi & Tracking",
    Print:"Cetak",
    SearchByReferenceCode:"Cari Berdasarkan Kode Referensi",
    YourCustomerCart:"Keranjang Belanja Customer Anda",
    RemoveAllCustomerCartConfirmationMessage:"Apakah anda yakin untuk menghapus semua keranjang belanja konsumen anda ?",
    RemoveCustomerCartConfirmationMessage:"Apakah anda yakin untuk menghapus keranjang belanja milik konsumen tersebut ?",
    RemoveAllCustomerCart:"Hapus Semua Keranjang Customer",
    RemoveCartRespectively:"Hapus Keranjang Per Customer",
    PerCustomer:"Per Customer",
    RemoveHisCart:"Hapus Keranjang Miliknya",
    SelectType:"Pilih Tipe",
    SearchCustomer:"Cari Customer",
    CustomerName:"Nama Customer",
    SearchAdmin:"Cari Administrator",
    Attribute:"Atribut",
    AttributeForm:"Form Atribut",
    Open:"Buka",
    OpenPageLink:"Buka Pagelink",
    PageLink:"Pagelink",
    EnterColorName:"Masukkan nama warna",
    Hexa:"Hexa",
    EnterLabel:"Enter Label",
    Label:"Label",
    SendEmail:"Kirim Email",
    Subject:"Subyek",
    SendAt:"Kirim Pada Waktu",
    SendAnnouncement:"Kirim Pengumuman",

    PublishConfigurationConfirmationMessage:"Apakah anda setuju dengan publikasi konfigurasi ini ? Konfigurasi akan ditetapkan secara langsung terhadap setting website anda",
    ConfigurationDetail:"Detil Konfigurasi",
    PageRow:"Baris Per Halaman",
    FavoriteRow:"Baris Halaman Favorit",
    LatestRow:"Baris Halaman Terbaru",
    DiscountRow:"Baris Halaman Diskon",
    EnterDiscountRow:"Masukkan Baris Halaman Diskon",
    FlashSaleRow:"Baris Halaman Flash Sale",
    EnterFlashSaleRow:"Masukkan Baris Halaman Flash Sale Yang Diinginkan",
    SelebgramRow:"Selebgram Yang Hendak Ditampilkan",
    EnterSelebgramRow:"Masukkan Jumlah Selebgram Yang Hendak Ditampilkan",
    ImageSize:"Ukuran Gambar",
    MainProductMaximumRow:"Jumlah Maksimal Product Utama",
    EnterMainProductMaximumRow:"Masukkan Jumlah Maksimum Product Utama",
    EnterCourierApiName:"Masukkan Nama Penyedia Jasa API Courier",

    FreePaymentFee:"Gratis Biaya Pembayaran",
    BubbleWrapFee:"Biaya Kirim Dengan Bubble Wrap",
    CompanyName:"Nama Perusahaan",
    EnterCompanyName:"Masukkan Nama Perusahaan",
    CompanyPhoneNumber:"No Telp Perusahaan",
    EnterCompanyPhoneNumber:"Masukkan No Telp Perusahaan",
    ContactPersonPhoneNumber:"No Telp Kontak Person",
    EnterContactPersonPhoneNumber:"Masukkan No Telp Kontak Person",
    ProductRelatedSize:"Jumlah Produk Terkait Yang Ditampilkan",
    EnterProductRelatedSize:"Masukkan Jumlah Produk Terkait Yang Hendak Ditampilkan",
    EnterFavoriteRow:"Masukkan Jumlah Produk Favorite Yang Ditampilkan",
    EnterLatestRow:"Masukkan Jumlah Produk Terbaru Yang Hendak Ditampilkan",
    InventoryImageSize:"Jumlah Maksimal Gambar Inventori",
    EnterInventoryImageSize:"Masukkan Ukuran Gambar Inventori",
    VoucherCodeLength:"Panjang Kode Voucher",
    EnterVoucherCodeLength:"Panjang Kode Voucher Yang Diinginkan",
    BankImageDimension:"Dimensi Gambar Bank",
    Width:"Lebar",
    Height:"Tinggi",
    BrandImage:"Gambar Merk",
    MidtransMediatorImageDimension:"Dimensi Gambar Channel Midtrans",
    CourierImageDimension:"Dimensi Gambar Logo Kurir",
    ProductImageDimension:"Dimensi Gambar Produk",
    ProductOrderConfiguration:"Konfigurasi Pesanan",
    CourierServiceBaseURL:"Address Service Kurir",
    EnterCourierServiceBaseURL:"Masukkan Address Service Kurir",
    CourierServiceApiKey:"Kunci Akses API Courier",
    EnterCourierServiceApiKey:"Masukkan Kunci Akses API Courier",
    ShippingOriginAddress:"Alamat Asal Pengiriman",
    EnterShippingOriginAddress:"Masukkan Alamat Asal Pengiriman",
    SocialMedia:"Media Sosial",
    EnterHashTag:"Masukkan #hashtag",
    InstagramLink:"Link Instagram",
    EnterInstagramLink:"Masukkan Link Instagram",
    FacebookLink:"Link Facebook",
    EnterFacebookLink:"Masukkan Link Facebook",
    TwitterLink:"Link Twitter",
    EnterTwitterLink:"Masukkan Link Twitter",
    FacebookApplicationId:"ID Aplikasi Facebook",
    EnterFacebookApplicationId:"Masukkan ID Aplikasi Facebook",
    GoogleApplicationId:"ID Aplikasi Google",
    EnterGoogleApplicationId:"Masukkan ID Aplikasi Google",
    TawkToReference:"Referensi Aplikasi Tawk To",
    EnterTawkToReference:"Masukkan Referensi Aplikasi Tawk To",
    SelectRole:"Pilih Hak Akses",
    ResendPassword:"Kirim Ulang Password",
    AccessMenu:"Akses Menu",
    Menu:"Menu",
    Domain:"Domain",
    ClientKey:"Kunci Klien",
    ServerKey:"Kunci Server",
    Environment:"Environment",
    PaymentLimitExpiration:"Masa Berlaku Pembayaran",
    BankCode:"Kode Bank",
    BankAccountNumber:"No Rekening",
    BankAccountName:"Nama Pemilik Rekening",
    SelectBank:"Pilih Bank",
    BalanceInformation:"Informasi Saldo",
    History:"Riwayat",
    BalanceMutation:"Mutasi Saldo",
    Mutation:"Mutasi",
    WithdrawalHistory:"Riwayat Penarikan",
    ReferenceCode:"Kode Referensi",
    Reference:"Referensi",
    Credit:"Kredit",
    Debit:"Debit",
    SelectDebitOrCredit:"Pilih Debit / Kredit",
    Amount:"Nominal",
    RemainBalance:"Sisa Saldo",
    WithdrawalConfirmationTitle:"Konfirmasi Penarikan Dana",
    RequestWithdrawConfirmationMessage:(amount)=>"Anda akan melakukan penarikan sejumlah "+amount+", " +
        "\nMohon pastikan no rekening & bank anda sudah terisi dengan benar. " +
        "Kami akan memprosesnya dalam waktu lebih kurang 2x24 jam. " +
        "Mohon menunggu atau anda dapat memberikan konfirmasi kepada admin / support kami, Terimakasih",
    YesIamSure:"Ya saya yakin",
    Sure:"Yakin",
    ReCheck:"Cek Ulang",
    WarningWithdrawExist:"Anda memiliki permintaan penarikan sebelumnya yang belum diproses, mohon menunggu, Staff kami akan segera memprosesnya",
    Waiting:"Menunggu",
    Processing:"Sedang Diproses",
    Issued:"Telah Dibayarkan",
    Postponed:"Ditunda",
    StatusHistory:"Riwayat Status",
    WithdrawalStatusHistory:"Riwayat Status Penarikan",
    WaitingForPayment:"Menunggu Pembayaran",
    WaitingForSubscriptionPayment:"Menunggu Pembayaran Perpanjangan Subscription",
    WaitingForUpgradePayment:"Menunggu Pembayaran Permintaan Upgrade",
    ExpiredOn:"Expired Pada",
    Channel:"Channel",
    Account:"Akun",
    Total:"Total",
    Tax:"Tax",
    AccountInformation:"Informasi Akun",
    RegisteredOn:"Terdaftar Pada",
    ActivePeriod:"Periode Aktif",
    YourProductService:"Layanan Anda",
    ExtendPeriod:"Perpanjang Periode",
    ExtendSubscriptionPeriod:"Perpanjang Periode Berlangganan",
    PayNow:"Bayar Sekarang",
    PAY_NOW:"BAYAR SEKARANG",
    RequestUpgrade:"Request Upgrade",
    REQUEST_UPGRADE:"REQUEST UPGRADE",
    MonthlyPeriod:"Periode Bulanan",
    Month:"Bulan",
    Months:"Bulan",
    ProductOption:"Pilihan Produk",
    AdditionalPayment:"Penambahan Biaya",
    AdditionalDiscount:"Potongan Harga",
    PaymentMethod:"Metode Pembayaran",
    ProductInformation:"Informasi Produk",
    ProductName:"Nama Produk",
    ValidityInformation:"Informasi Keberlakuan",
    ValidFrom:"Berlaku Mulai",
    ValidUntil:"Berlaku Hingga",
    CreateProductSubscribe:"Tambah Product Subscription",
    Item:"Item",
    PleaseEnterReceiverBankAccountOnYourAccountPage:"Mohon lengkapi terlebih dahulu informasi data akun bank penerima, yang dapat dilengkapi pada halaman akun oleh pemilik !",
    Refresh:"Perbarui Data",
    TotalCustomer:"Jumlah Customer",
    TotalCustomerProductOrder:"Jumlah Customer Bertransaksi",
    PreviousMonth:"Bulan Lalu",
    ThisMonth:"Bulan Ini",
    PreviousYear:"Tahun Lalu",
    ThisYear:"Tahun Ini",
    TotalProductOrder:"Jumlah Pesanan",
    TotalProductOrderAmount:"Nominal Pesanan",
    OnGoingProductOrder:"Pelayanan Pesanan Saat Ini",
    Date:"Waktu",
    SelectCriteria:"Pilih Kriteria",
    TotalAmount:"Total Nominal",
    BubbleWrapAmount:"Nominal Bubble Wrap",
    CourierAmount:"Nominal Ongkos Kirim",
    TotalQuantity:"Total Kuantitas",
    PurchaseItemQuantity:"Kuantitas Item Pembelian",
    EnterYourTicketMessageHereInstruction:"Masukkan pesan anda di sini... \nDan anda juga dapat menambahkan screenshot / gambar sebagai pendukung kejelasan atas pesan yang anda maksud..",
    Attachment:"Lampiran",
    PhysicalServiceAddress:"Alamat Layanan Fisik",
    PublicDomain:"Alamat Domain Publik",
    ProductSubscribe:"Produk Berlangganan",
    SubscribePeriod:"Periode Berlangganan",
    CloudVPS:"Cloud VPS",
    VPSInformation:"Informasi VPS",
    Yes:"Ya",
    TotalRecord:"Total Record",
    TotalPage:"Total Halaman",
    YourAccount:"Akun Anda",
    TakeAPayment:"Lakukan Pembayaran",
    ExtendNow:"Perpanjang Sekarang",
    DoYouWantToLogoutFormThiApplication:"Apakah anda ingin keluar dari aplikasi ini ?",
    RegistrationDate:"Tanggal Daftar",
    Until:"s/d",

    Branch:"Cabang",
    Title:"Judul",
    EnterTitle:"Masukkan judul",
    FlashSaleName:"Judul Flash Sale",
    EnterFlashSaleName:"Masukkan judul flash sale",
    EnterSenderEmail:"Masukkan email pengirim",
    WhenItCouldBeSent:"Kapan ini akan dikirim ?",
    EnterNoteRecommended:"Masukkan catatan (Rekomendasi)",
    EnterResiCode:"Masukkan Kode Resi",
    EnterDailyOperationInAPlainWords:"Masukkan hari operasional dengan kalimat umum",
    EnterSettingName:"Masukkan nama konfigurasi",
    EnterStock:"Masukkan kuantitas",
    EnterPercentOfDiscount:"Masukkan persentasi diskon",
    ChangePassword:"Ubah Password",
    OldPassword:"Password Lama",
    NewPassword:"Password Baru",
    Receiver:"Penerima",
    Origin:"Asal",
    Destination:"Tujuan",
    DailyOperation:"Hari Operasional",
    UpdateResiCode:"Perbarui Kode Resi",
    SubjectEmail:"Email Subyek",
    Base:"Base",
    Api:"Api",
    Simulator:"Simulator",
    EditFlashSale:"Ubah Flash Sale",
    CreateFlashSale:"Buat Flash Sale",
    Scheduled:"Dijadwalkan",
    MessageForm:"Pesan Dari",
    ReplyFrom:"Balasan Dari",
    SeeTicket:"Lihat Tiket",
    CreateTicket:"Buat Tiket",
    InvalidInputOfWaybill:"Kode Resi Tidak Valid",
    No:"Tidak",
    VoucherPublishMessage:"Apakah anda ingin mempublikasikan voucher ini kepada konsumen ?",
    VoucherUnpublishMessage:"Apakah anda ingin membatalkan publikasi voucher ini terhadp konsumen ?",
    OriginAddress:"Alamat Pengirim",
    StockAllocation:"Alokasi Stok",
    Allocation:"Alokasi",
    SpecialCloudVPS:"Cloud VPS Spesial",
    Filter:"Penyaringan",
    DeviceFilter:"Filter Mesin",
    UserFilter:"Filter User",
    CategoryFilter:"Filter Kategori",
    AdvanceFilter:"Penyaringan Data",
    ClickToExpandFilterContent:"Klik untuk membuka form penyaringan",
    productOrderStartDate:"Pesanan mulai tanggal",
    productOrderEndDate:"Pesanan hingga tanggal",
    DestinationProvince:"Propinsi tujuan kirim",
    DestinationCity:"Kota tujuan kirim",
    DestinationDistrict:"Kecamatan tujuan kirim",
    DestinationVillage:"Kelurahan tujuan kirim",
    WithVoucher:"Dengan Voucher",
    EnterNameOrEmailOrPhoneNumberOrRefCodeOrResiCode:"Masukkan nama, email, no telp, referensi, atau kode resi",
    ApplyFilter:"Terapkan Penyaringan",
    ResetFilter:"Reset Penyaringan",
    DownloadExcel:"Unduh File Excel",
    DownloadFile:"Unduh File",
    Download:"Unduh",
    DeliveryDuration:"Durasi Pengiriman",
    Main:"Utama",
    Profile:"Profil",
    Help:"Tolong",
    Guide:"Panduan",
    Signout:"Keluar",
    Message:"Pesan",
    Ticket:"Tiket",
    ListOfImage:"Daftar Gambar",
    EnterHeadingName:"Masukkan nama menu",
    SearchFlashSaleName:"Cari nama flash sale...",
    Information:"Informasi",
    EnterInformation:"Masukkan Informasi",
    EmailSubjectCannotBeingEmpty:"Subyek email tidak boleh kosong",
    IfYouChooseScheduledModeThenSendAtCannotBeingEmpty:"Jika anda memilih pengiriman terjadwal maka input jadwal kirim tidak boleh dikosongkan",
    IfYouChooseNonRegulerThenSenderFieldCannotBeingEmpty:"Jika anda memilih pengirim non reguler maka input pengirim tidak boleh dikosongkan",
    PleaseEnterAMinimumSingleReceiver:"Mohon masukkan setidaknya 1 penerima email",
    PleaseEnterTheContentOfThisAnnouncement:"Mohon masukkan konten daripada pengumuman ini",
    DoYouWantToSendThisAnnouncementRightNowPleaseItCannotBePostponed:"Apakah anda ingin mengirim pengumuman saat ini juga, mohon diketahui ini tidak dapat dibatalkan",
    DoYouWantScheduleThisAnnouncementAtTheMomentYouFillInPleaseItCannotBePostponed:"Apakah anda ingin menjadwalkan pengumuman ini pada waktu yang telah anda tentukan, mohon diketahui ini tidak dapat dibatalkan",
    RegularSender:"Pengirim Reguler",
    RightNow:"Saat Ini Juga",
    AllCustomer:"Semua Customer",
    AllSubscriber:"Semua Subscriber",
    AnnouncementContentOptions:"Pilihan Konten Pengumuman",
    ManualTyping:"Pengetikan manual",
    UploadHtmlFile:"Unggah berkas berformat HTML",
    CustomerReceiver:"Customer Penerima",
    Sender:"Pengirim",
    PreviewHtmlContent:"Preview Konten HTML",
    PreviewContent:"Preview Konten",
    WriteYourCSSInline:"Masukkan konten css anda dalam file html secara inline atau menjadi 1 file saja",
    UseImageWichisAlreadyStoredOnServer:"Gunakan image yang dapat diakses secara publik / internet dan masukkan image src secara inline seperti https://domain.com/image.jpg, " +
        "atau gunakan format base 64",
    ThirdPartyKeys:"Kunci Pihak Ke Tiga",
    ListMenu:"Daftar Menu",
    AppName:"Smart Supplies",
    ActivateDate:"Tanggal Pengaktifan",
    EnterActivateDate:"Masukkan tanggal jadwal pengaktifan",
    LOGIN:"MASUK",
    SIGNUP:"DAFTAR",
    UpdateConfirmation:"Konfirmasi Update",
    Logout:"Keluar",
    TotalUser:"Total Pengguna",
    TotalInspection:"Inspeksi Total",
    TotalInspectionDone:"Total Inspeksi Selesai",
    TotalTransfer:"Total Transfer Komisi",
    OnGoingInspection:"Inspeksi Berlangsung",
    Pass:"Lulus",
    Taken:"Dikerjakan",
    NotPass:"Tidak Lulus",
    Closed:"Closed",
    Suspended:"Suspended",
    Canceled:"Canceled",
    Opened:"Terbuka",
    OnWorking:"Dikerjakan",
    UserCode:"Kode User",
    ProductCategory:"Kategori Produk",
    BalanceOfAllUser:"Total Saldo Semua Pengguna",
    PaidAmountOfAllUser:"Terbayar Kepada Para Pengguna",
    Success:"Sukses",
    ProductCategoryDetail:"Detil Kategori Produk",
    MONTHS_LABEL:["Januari", "Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"],
    Failed:"Gagal",
    ModelCount:"Jumlah Model",
    EnterFullname:"Masukkan Nama Lengkap",
    EnterMessage:"Masukkan Pesan",
    GenerateAutomatically:"Generate otomatis...",
    EnterProductPackageCode:"Masukkan kode paket produk",
    EnterProductPackageName:"Masukkan nama paket produk",
    AddModel:"Tambah Model",
    AddBrand:"Tambah Merk",
    Fee:"Komisi",
    Limitation:"Batasan",
    InspectionLimit:"Batasan Inspeksi",
    User:"Pengguna",
    InspectionDone:"Inspeksi Selesai",
    Website:"Website",
    Suggestion:"Prioritas Tampilan",
    Publication:"Publikasi",
    Published:"Terpublikasi",
    Unpublished:"Tak Terpublikasi",
    ModelList:"Daftar Model",
    Cabinet:"Kelompok Cabinet",
    CabinetDetail:"Detil Pengelompokan Cabinet",
    CabinetList:"Daftar Pengelompokan Cabinet",
    AddCabinet:"Tambah Cabinet",
    FormBuilder:"Pembuatan Cabinet",
    FormName:"Nama Cabinet",
    EnterFormName:"Masukkan Nama Cabinet",
    CabinetRack:"Rack Cabinet",
    AddCabinetRow:"Tambah Baris Cabinet",
    AddCabinetRack:"Tambah Rak Kabinet",
    EnterFormPage:"Masukkan halaman form",
    FormCard:"Cabinet Input",
    AddFormCard:"Tambah Cabinet Input",
    Tag:"Penanda",
    EnterTag:"Masukkan penanda",
    Mandatory:"Wajib Diisi",
    True:"Ya",
    False:"Tidak",
    UnallowedCharacters:"Karakter Tak Diperbolehkan",
    EnterUnallowedCharacters:"Masukkan karakter tak diperbolehkan",
    EnterFormType:"Masukkan tipe form",
    EnterFormTitle:"Masukkan judul form",
    Placeholder:"Placeholder",
    EnterPlaceholder:"Masukkan placeholder",
    MaxLength:"Jumlah Karakter Maksimum",
    EnterMaxLength:"Masukkan jumlah karakter maksimum",
    LineCount:"Jumlah Baris",
    EnterLineCount:"Masukkan jumlah baris",
    ImageDescription:"Deskripsi Gambar",
    ImageDirectory:"Direktori Gambar",
    ExampleReplacer:"Contoh format direktori",
    ExampleDirectoryFormat:"Contoh format direktori",

    PleaseEnterFormTag:"Mohon masukkan penanda form",
    PleaseEnterAFormTitle:"Mohon masukkan judul form",
    SaveCabinet:"Simpan Cabinet",
    UserList:"Daftar User",
    Installation:"Instalasi",
    Installed:"Terpasang",
    Uninstalled:"Tidak Terpasang",
    SoftwareInstallation:"Pemasangan Aplikasi",
    LastOpen:"Terakhir Membuka",
    EnterReceiverEmail:"Masukkan email-email penerima",
    SlugAlreadyBeenUsed:"Slug sudah digunakan",
    CodeAlreadyBeenUsed:"Kode sudah digunakan",
    Subtitle:"Sub judul",
    EnterSubtitle:"Masukkan sub judul",
    PleaseEnterFormName:"Mohon masukkan nama form",
    PleaseSelectLockerType:"Mohon pilih jenis device locker",
    PleaseSelectRFIDReaderType:"Mohon pilih jenis device RFID",
    PleaseSelectParallelPosition:"Mohon pilih posisi parallel untuk device",
    PleaseSelectLockerPosition:"Mohon pilih posisi pengunci",
    PleaseSelectRfidChannelPosition:"Mohon pilih channel pembaca RFID",
    EnterRoleName:"Masukkan nama role",
    EnterMidtransMediatorLabel:"Masukkan label untuannel media midtrans",
    EnterMidtransMediatorName:"Masukkan nama channel media midtrans",

    EnterGroupMenu:"Masukkan nama grup menu",
    EnterQueryKey:"Masukkan kunci query",
    EnterReference:"Masukkan referensi",
    EnterRecommendedInformation:"Masukkan tambahan informasi (Disarankan)",
    EnterRecommendedReceiptCode:"Masukkan kode receipt (Disarankan)",
    EnterFAQTitle:"Masukkan judul FAQ",
    EnterFAQSubTitle:"Masukkan sub judul FAQ",
    EnterWebsite:"Masukkan nama website",
    EnterBusinessField:"Masukkan bidang usaha",
    EnterBuildDate:"Masukkan tanggal berdiri",
    EnterCompanyAddress:"Masukkan alamat perusahaan",
    EnterStartPeriod:"Masukkan Periode Awal",
    EnterEndPeriod:"Masukkan Periode Berakhir",
    EnterIdCardNumber:"Masukkan no id card / KTP",
    ClickButtonToGenerate:"Klik tombol untuk menggenerate otomatis",
    Organization:"Organisasi",
    EnterOrganization:"Masukkan Organisasi",
    EnterAccountName:"Masukan nama akun",
    AccountName:"Nama Akun",
    EnterAccountNumber:"Masukkan nomor/kode akun",
    AccountNumber:"Nomor / Kode Akun",
    EnterForSpecificEmailSender:"Masukkan email sebagai email pengirim",
    ModelDetail:"Detil Model",
    TimeValidating:"Waktu Proses Validasi",
    ModelIconRatio:"Icon Model, Dimensi = 1 (lebar) * 1 (tinggi)",
    InspectionDetail:"Detil Inspeksi",
    ModelAddressLink:"Link Alamat URL Model",
    Platform:"Platform",
    DeviceId:"Device ID",
    FCMToken:"Token Firebase Cloud Messaging",
    Delete:"Hapus",
    SearchDeviceId:"Cari berdasarkan device id...",
    PleaseAddAMinimumSingleContainer:"Mohon tambahkan setidaknya satu container",
    PleaseEnterCabinetName:"Mohon masukkan nama group form",
    ManufacturingSite:"Manufacturing Site",
    ManufacturingSiteDetail:"Detil Manufacturing Site",
    EnterManufacturingSiteName:"Mohon masukkan nama manufacturing site",
    PleaseSelectManufacturingSite:"Mohon pilih manufacturing site",
    PriceConfiguration:"Konfigurasi Harga",
    ProductOrderPerStation:"Pesanan Per Stasiun",
    SoC:"SoC",
    SoCAvailable:"Ketersediaan SoC",
    SetSoCAvailability:"Set Ketersediaan SoC",
    SuspendedEVToFinishInSecond:"Suspended EV Untuk Finish Dalam Satuan Detik",
    EnterSuspendedEVToFinishInSecond:"Masukkan Suspended EV Untuk Finish Dalam Satuan Detik",
    UnchangingMeterToFinishInSecond:"Penanda Meter Terhenti Untuk Finish Dalam Satuan Detik",
    UnchangingMeterToFinishInFrequent:"Penanda Meter Terhenti Untuk Finish Dalam Komunikasi ",
    EnterUnchangingMeterToFinishInSecond:"Masukkan Penanda Terhenti Untuk Finish Dalam Satuan Detik",
    EnterUnchangingMeterToFinishInFrequent:"Masukkan Penanda Meter Terhenti Untuk Finish Dalam Notifikasi",
    Module:"Modul",
    GroupName:"Nama Grup",
    CompleteYourRegistrationInformation:"Lengkapi Informasi Pendaftaranmu",
    CompanyInformation:"Informasi Perusahaan",
    Industry:"Industri",
    Verified:"Terverifikasi",
    ProductPackage:"Paket Produk",
    ProductPackageDetail:"Product Package Detail",
    MonthPeriod:"Periode Bulan",
    EnterMonthPeriod:"Masukkan periode bulan",
    EnterSuggestion:"Masukkan urutan prioritas",
    FollowUp:"Follow Up",
    FollowUpList:"Follow Up List",
    CompanyType:"Tipe Perusahaan",
    SelectIndustry:"Pilih Industri",
    AgreeWithTermAndCondition:"Setuju dengan syarat & ketentuan yang berlaku",
    AgreeWith:"Setuju dengan",
    TermAndCondition:"Syarat & ketentuan yang berlaku",
    SignUp:"Mendaftar",
    VerifyYourMobilePhone:"Proses verifikasi no handphone mu",
    CheckYourMobilePhoneAt : (mobilePhone) => "Periksa handphonemu dengan no "+mobilePhone,
    OTPCodeSentToYourMobilePhone : (mobilePhone) => "Kode OTP telah dikirim ke no handphone mu : "+mobilePhone,
    WeAreDoingVerifyYourMobilePhone_PleaseFillThisFormWithTheOTPCodeThatWeSendToYourMobilePhone:"Kami sedang melakukan proses verifikasi no hanphone mu, mohon masukkan kode OTP yang telah kami kirim ke no handphone mu pada form input di atas.",
    RegistrationConfirmation:"Konfirmasi Registrasi",
    RegistrationSucceed:"Registrasi Berhasil",
    RegistrationSucceedNote:"Terima kasih telah mengirim data, datamu telah terproses dengan berhasil, silahkan kunjungi halaman Sign In dan login menggunakan autentikasi akunmu.",
    PleaseMakeSureYouHaveFulfillTheFormWithCorrectValueWeWillSendToYouOTPCodeToVerifyYourPhoneNumber_PleaseHoldYourPhoneCloseToYouAndVerifyYourPhoneNumberAtTheNextStep:
        "Mohon pastikan kamu sudah mengisi form dengan isian yang benar dan sesuai dengan informasi dan identitas mu, kami akan mengirim kode OTP ke no handphone yang kamu daftarkan untuk memverifikasinya." +
        "\nMohon pastikan kamu berada di sekitar no handphone mu, dan lakukan verifikasi no handphone pada step selanjutnya",
    Continue:"Selanjutnya",
    PhoneNumberHasAlreadyBeenUsed:"No Telp telah digunakan sebelumnya",
    UsernameHasAlreadyBeenUsed:"Username telah digunakan sebelumnya",
    CompanyNameHasAlreadyBeenUsed:"Nama Perusahaan telah digunakan sebelumnya",
    WebsiteHasAlreadyBeenUsed:"Website telah digunakan sebelumnya",
    SomethingUnexpected:"Terjadi masalah system",
    YouWillBeRedirectToLoginPageForMoment:"Kamu akan dialihkan ke halaman login sesaat lagi",




}
const baru = {
    SearchByCustomerNameOrModelNameOrRefCode:"Cari berdasarkan pengguna, model atau kode referensi...",
    LimitType:"Jenis Pembatasan",
    Limit:"Batasan",
    VerificationType:"Jenis Verifikasi",
    WorkerVerificationType:"Status User Terverifikasi",
    InspectionCount:"Jumlah Inspeksi",
    TotalFee:"Total Komisi",
    InspectionReferenceCode:"Kode Referensi Inspeksi",
    BrandReferenceCode:"Kode Referensi Merk",
    StatusHistory:"Riwayat Status",
    BrandStatus:"Status Merk",
    ModelApplicationInfo:"Informasi Pengajuan Model",
    SelectVerificationStatus:"Pilih Status Verifikasi",
    SelectUserStatus:"Pilih Status User",
    ReferralAgent:"Pengundang",
    EverLoggedIn:"Pernah Login",
    Never:"Tidak Pernah",
    TotalIncome:"Total Pemasukan",
    CurrentBalance:"Saldo Saat Ini",
    TotalWithdrawal:"Total Penarikan",
    CompletedInspection:"Inspeksi Selesai",
    PostponedInspection:"Inspeksi Ditunda",
    IDCard:"Kode / ID KTP",
    InvalidEmailAddress:"Email tidak valid",
    Regenerate:"Regenerate",
    Generate:"Generate",
    UserDetail:"Detil User",
    AddUser:"Tambah User",
    Location:"Lokasi",
    LocationOnMap:"Lokasi Di Peta",
    OpenInMap:"Buka Di Peta",
    EnterTitleSubtitleSlugBrandOrCategoryToSearch:"Masukkan judul, subjudul, slug, merk, category untuk mencari..",
    SearchNameEmailPhoneUserCodeOrOrganization:"Cari nama, email, telp, kode user atau organisasi..",
    LastOpenStart:"Terakhir Membuka Mulai",
    LastOpenEnd:"Terakhir Membuka Hingga",
    Male:"Pria",
    Female:"Wanita",
    UnrecognizedDueNotAuthenticated:"Tidak dikenali karena tidak melakukan login",
    EnterDeviceIdUserCodeOrNameToSearch:"Masukkan Device Id, nama user atau kode user untuk mencari..",
    AuthenticatedAsUser:"LoginPage Sebagai User",
    NotAuthenticatedAsUser:"Tidak LoginPage Sebagai User",
    AuthenticationOption:"Pilihan Otentikasi",
    ReceiverOption:"Pilihan Penerima",
    NotificationDetail:"Detil Notifikasi",
    SendingConfirmationTitle:"Sending Confirmation",
    DoYouWantToSendThisNotificationWithTheSelectedTopic:"Do you want to send this notification with the selected topic ?",
    InspectionMustBeingOrderedSequentially:"Inspeksi harus dilakukan secara berurutan satu per satu",
    InspectionCanBeAppliedMoreThanOneSimultaneously:"Inspeksi dapat dilakukan lebih dari satu secara bersamaan",
    InspectionSubmissionOrder:"Urutan Inspeksi",
    MaxUsagePerUser:"Batas maksimal inspeksi per user",
    MaxInspectionPerUserDefaultUnlimitedPlaceholder:"Maksimal Inspeksi @User (0 = tak terbatas)",
    MaximumInspectionCanBeApplied:"Jumlah Maksimal Inspeksi Yang Dapat Diajukan",
    MaximumApplicationUsage:"Jumlah Maksimal Inspeksi",
    CurrentApplicationUsage:"Jumlah Inspeksi Telah Terjadi Saat Ini",
    MaxUsagePerUserCannotBeLargerThanMaxUsageGeneral:"Maximum inspeksi per user tidak boleh lebih besar daripada jumlah maksimal pengajuan",
    MaxUsageCannotBeSmallerThanCurrentUsage:"Maximum inspeksi tidak boleh lebih kecil daripada jumlah pengajuan saat ini",
    PleaseEnterModelAddressLink:"Mohon lengkapi link alamat url model",
    PleaseEnterCallbackId:"Mohon lengkapi callback Id",
    PleaseEnterCabinet:"Mohon lengkapi isian group form",
    PleaseEnterModelTimeValidationByAdmin:"Mohon lengkapi isian lama waktu validasi oleh admin",
    ModelFee:"Komisi Model",
    PleaseEnterModelFee:"Mohon lengkapi komisi model",
    CanBeAppliedByUserVerificationAt:"Dapat dilakukan oleh user dengan status verifikasi",
    CanOnlyBeAppliedByVerifiedUser:"Hanya dapat dilakukan oleh user terverifikasi",
    CanBeAppliedByUnverifiedUser:"Dapat dilakukan oleh user yang tidak terverifikasi",
    TimeApplicationValidationByAdminInHour:"Lama Proses Memvalidasi Oleh Admin (Dalam Hitungan Jam)",
    PleaseEnterDescription:"Mohon masukkan deskripsi",
    PleaseEnterReferenceDocument:"Mohon masukkan dokumen referensi",
    ReferenceDocument:"Dokumen Referensi",
    MainModelDetail:"Detil Model Utama",
    EnterMainModelLabel:"Masukkan label model utama",
    SelectModel:"Pilih Model",
    MainModelIcon:"Ikon model utama",
    MultiFinanceDetail:"Detil Multi Finance",
    Label:"Label",
    EnterMultiFinanceLabel:"Masukkan label multi finance",
    EnterMultiFinanceName:"Masukkan nama multi finance",
    EnterLabelOfInstallmentPeriod:"Enter label of installment period",
    EnterPeriodOfInstallmentPeriod:"Enter tenor of installment period",
    InstallmentPeriod:"Tenor",
    InstallmentPeriodDetail:"Detil Tenor",
    EnterDiscussionCategoryName:"Masukkan nama kategori diskusi",
    DiscussionCategoryDetail:"Detil Kategori Diskusi",
    AgentFee:"Komisi User",
    AgentFeeDetail:"Detil Komisi User",
    SelectProductAgent:"Pilih Produk",
    SelectMultiFinance:"Pilih MultiFinance",
    AmountFee:"Fee dalam rupiah",
    PercentFee:"Fee persentasi beli (%)",
    SelectInstallmentPeriod:"Pilih Periode Tenor",
    PreviewCount:"Jumlah Preview",
    ShareCount:"Jumlah Share",
    CommentCount:"Jumlah Komentar",
    ReplyCount:"Jumlah Balasan",
    Comment:"Komentar",
    CommentReply:"Balasan Komentar",
    PartName:"Part Name",
    PN:"P/M",
    ACInput:"AC Input",
    Frequency:"Frequency",
    ACInputCurrent:"Ac Input Current",
    DCOutput:"DC Output",
    Polarity:"Polarity",
    CORef:"CO Ref",

    EnterPartName:"Masukkan Part Name",
    EnterPn:"Masukkan PN",
    EnterACInput:"Enter AC Input",
    EnterFrequency:"Masukkan Frequency",
    EnterACInputCurrent:"Masukkan Ac Input Current",
    EnterDCOutput:"Masukkan DC Output",
    EnterPolarity:"Masukkan Polarity",
    EnterCORef:"Masukkan CO Ref",
    Companies:"Daftar Perusahaan",
    Company:"Perusahaan",
    SelectCompany:"Pilih Perusahaan",
    Stations:"Daftar Stasiun",
    Station:"Stasiun",
    SelectStation:"Pilih Stasiun",
    Condition:"Kondisi",
    Container:"Container",
    Containers:"Daftar Container",
    Enabled:"Enabled",
    SmartDevices:"Daftar Smart Device",
    SmartDevice:"Smart Device",
    AddContainer:"Tambah Container",
    AddSmartDevice:"Tambah Smart Device",
    ContainerType:"Tipe Container",
    EnterContainerType:"Masukkan tipe Container",
    QRCode:"QR Code",
    ProductOrderList:"Daftar Pesanan",
    SelectProduct:"Pilih Produk",
    SelectSmartDevice:"Pilih Smart Device",
    PaymentReference:"Referensi Pembayaran",
    PaymentMethod:"Metode Pembayaran",
    ProductOrderAmount:"Nominal Pesanan",
    ProductOrderDetail: "Detil Pesanan",
    AmountInformation: "Informasi Nominal",
    Purchase:"Pembelian",
    Usage:"Penggunaan",
    Remain:"Sisa",
    Refund:"Pengembalian",
    OnCharging:"On Charging",
    PricePerKW:"Harga per KW",
    BuyAmount:"Nominal Pembelian",
    AdminFee:"Biaya Admin",
    ParkingHour:"Lama Parkir",
    ParkingFeeHour:"Biaya parkir per jam",
    TotalParkingFee:"Total Parking Fee",
    TotalProductOrderAmount:"Total Nominal Pesanan",
    OtherInformation:"Informasi Lainnya",
    SubPaymentMedia:"Sub Media Pembayaran",
    SubPaymentMediaDetail:"Detil Sub Media Pembayaran",
    SmartDeviceConfiguration:"Konfigurasi Smart Device",
    Key:"Kunci",
    ReadOnly:"Read Only",
    Original:"Original",
    ResetAll:"Reset All",
    Position:"Posisi",
    EnterPosition:"Masukkan Position",
    On:"Menyala",
    Off:"Mati",
    Used:"Digunakan",
    Products:"Daftar Produk",
    SmartDeviceType:"Tipe Smart Device",
    LockerDevice:"Perangkat Pengunci",
    RegisteredLockerDevice:"Locker Device Terdaftar",
    EenterSmartDeviceType:"Masukkan Tipe Smart Device",
    _24Hours:"24 Jam",
    OpenOperational:"Buka",
    CloseOperational:"Tutup",
    EnterOpenTime:"Masukkan jam buka",
    EnterCloseTime:"Masukkan jam tutup",
    GenerateCode:"Generate Code",
    AddLocation:"Tambah Lokasi",
    AddCompany:"Tambah Perusahaan",
    CompanyForm:"Form Daftar Perusahaan",
    SuccessCallbackUrl:"Success Callback Url",
    FailedCallbackUrl:"Failed Callback Url",
    EnterSuccessCallbackUrl:"Masukkan Success Callback Url",
    EnterFailedCallbackUrl:"Masukkan Failed Callback Url",
    HaveUserAccess:"Memiliki Akses User",
    HaveAdminAccess:"Memiliki Akses Admin",
    Current:"Current",
    ApproveAndCreateOrder:"Approve dan Buat Pesanan",
    CreateOrder:"Buat Pesanan",
    CreateOrderByAdminConfirmation:"Apakah kamu yakin akan membuat daftar order untuk customer tersebut",
    PleaseEnterCompanyNameCorrectly:"Mohon masukkan nama perusahaan dengan benar",
    PleaseEnterWebsiteCorrectly:"Mohon masukkan website dengan benar",
    PleaseSelectIndustry:"Mohon pilih industri perusahaan mu",
    PleaseSelectCompanyType:"Mohon pilih jenis perusahaan mu",
    PleaseEnterAddressCorrectly:"Mohon masukkan alamat dengan benar",
    PleaseSelectProvince:"Mohon pilih propinsi",
    PleaseSelectCity:"Mohon pilih kota",
    PleaseSelectDistrict:"Mohon pilih kecamatan",
    PleaseSelectVillage:"Mohon pilih kelurahan",
    SignIn:"Masuk",
    SignInNow:"Masuk Sekarang",
    RegisterNow:"Daftar Sekarang",
    PageNotFound:"Halaman Tidak Diketemukan",
    PageNotFoundDescription:"Halaman yang kamu kunjungi tidak tersedia, kamu mungkin mengunjungi halaman yang salah atau sesuatu terjadi pada sistem, jika kamu merasa yakin dengan halaman yang ingin kamu kunjungi silahkan hubungi kami",
    InvalidLink:"Link Tidak Valid",
    InvalidLinkDescription:"Halaman yang kamu kunjungi tidak dibenarkan, kamu mungkin mengunjungi halaman yang salah, jika kamu merasa yakin dengan halaman yang ingin kamu kunjungi silahkan hubungi kami",
    UsernameOrEmail:"Username atau Email",
    EnterYourUsernameOrEmail:"Masukkan username atau emailmu",
    KeepLoggedIn:"Jangan keluarkan saya otomatis",
    SignInToApplication:"Masuk ke dalam Aplikasi",
    DoesntHaveAnAccount:"Belum punya akun ?",
    HomePage:"Halaman Utama",
    SignInWithRFIDOrFingerPrint:"Masuk dengan RFID atau Fingerprint",
    SignInWithScanner:"Masuk dengan Scanner",
    SignInWithUsernamePassword:"Masuk dengan Username",

}

const menu = {
    Dashboard:"Dasbor",
    Account:"Akun",
    Product:"Produk",
    Brand:"Merk",
    Heading:"Menu",
    Category:"Kategori",
    ItemProduct:"Produk",
    Inventory:"Inventori",
    Popular:"Populer",
    Latest:"Terbaru",
    MainProduct:"Produk Utama",
    StockAudit:"Kontrol Stok",
    Program:"Program",
    Discount:"Diskon",
    Voucher:"Voucher",
    Point:"Poin",
    PointName:"Nama Poin",
    FlashSale:"Flash Sale",
    Shopper:"Perbelanjaan",
    Customer:"Customer",
    ProductOrder:"Pesanan",
    Cart:"Keranjang",
    Activity:"Aktifitas",
    CustomerActivity:"Aktifitas Customer",
    AdminActivity:"Aktifitas Admin",
    Miscellaneous:"Aksesoris",
    Banner:"Banner",
    Selebgram:"Selebgram",
    Color:"Warna",
    Size:"Ukuran",
    OnlineShop:"Toko Online",
    Branches:"Cabang",
    CustomerGuide:"Panduan Customer",
    Announcement:"Pengumuman",
    Broadcast:"Distribusi Pesan",
    Subscriber:"Subscriber",
    Administrator:"Administrator",
    Configuration:"Konfigurasi",
    Admin:"Admin",
    Role:"Hak Akses",
    UserRole:"Hak Akses User",
    PaymentMedia:"Media Pembayaran",
    Bank:"Bank",
    BankAccount:"Rekening",
    MidtransMediator:"Media Midtrans",
    Courier:"Kurir",
    Region:"Wilayah",
    Province:"Propinsi",
    City:"Kota",
    District:"Kecamatan",
    Village:"Kelurahan",
    PaymentTypeCode:"Kode Tipe Pembayaran",
    EnterPaymentTypeCode:"Masukkan Kode Tipe Pembayaran",
    EnterAmountFee:"Masukkan Fee Nominal",
    EnterPercentFee:"Masukkan Fee Persentasi",
    EnterCourierLabel:"Masukkan Label Kurir",
    CourierCode:"Kode Kurir",
    Code:"Kode",
    RequestWithdrawal:"Request Penarikan Dana",
    ProductOrderDetail:"Detil Pesanan",
    ProductOrderInfo:"Informasi Pesanan",
    ResiCode:"Kode Resi",
    CourierCost:"Biaya Ongkir",
    Service:"Layanan",
    ProductOrderStatus:"Status Pesanan",
    ItemPrice:"Harga Item",
    UniquePrice:"Harga Kode Unik",
    BubbleWrap:"Bubble Wrap",
    ShippingAddress:"Alamat Pengiriman",
    ProductOrderItem:"Item Pesanan",
    TotalPrice:"Harga Total",
    Source:"Sumber",
    TotalWeight:"Jumlah Berat",
    UpdateStatus:"Perbarui Status",
    ChangeStatus:"Ganti Status",
    CurrentStatus:"Status Saat Ini",

    Business:"Bisnis",
    Inspection:"Inspeksi",
    InspectionScheme:"Skema Inspeksi",
    Entities:"Entitas",
    InspectionEvent:"Event Inspeksi",
    SubMenu:"Sub Menu",
    Model:"Model",
    FormBuilder:"Pembuatan Cabinet",
    Worker:"Data Pekerja",
    QCManagement:"Manajemen QC",
    Devices:"Perangkat Device",
    UserDevices:"Device User",
    AdminDevices:"Device Admin",
    ConfigurationAdmin:"Konfigurasi Admin",
    ConfigurationEmail:"Konfigurasi Email",
    User:"User",
    Verification:"Verifikasi",
    RegHistory:"Riwayat Pendaftaran",
    Withdraw:"Penarikan Dana",
    WithdrawalRequest:"Permintaan Penarikan",
    Mutation:"Mutasi Saldo",
    UserActivity:"Aktifitas Pengguna",
    Notification:"Notifikasi",
    ApplicationVersion:"Versi Aplikasi",
    GoodsCategory:"Kategori Barang",
    MainModel:"Model Utama",
    Reward:"Reward",
    Fee:"Komisi",
    MultiFinance:"Multi Finance",
    InstallmentPeriod:"Tenor",
    Discussion:"Diskusi",
    DiscussionCategory:"Kategori Diskusi",
    CategoryImage:"Gambar Kategori",
    UserPhoto:"Foto User",
    UserAgent:"User Agent",
    SystemType:"System Type",
    ModelName:"Nama Model",
    Form:"Form",
    EnterSystemType:"Masukkan nama system type / model",
    EnterModelName:"Masukkan nama model",
    Scope:"Scope",
    TestingEquipmentAndAccessories:"Testing Equipment And Accessories",
    SystemSpecification:"Spesifikasi Sistem",
    SerialComponent:"Serial Component",
    SerialComponentList:"Daftar Serial Component",
    Value:"Value",
    PassInspection:"Inspeksi Lulus",
    NotPassInspection:"Inspeksi Belum Lulus",
    PostponedInspection:"Inspeksi Ditunda",
    SerialCode:"Serial Number",
    InspectionList:"Daftar Inspeksi",
    BrandCategory:"Kategori",
    SelectBrandCategory:"Pilih Kategori",
    Searching:"Pencarian",
    SearchReferenceSerialModelUser:"Cari kode referensi, referensi pembayaran, metode pembayaran atau nama customer.. ",
    Copy:"Copy",
    CopyToTheNewFormBuilder : (formName) => "Salin ("+formName+") menjadi sebuah form builder baru",
    CopyToTheNewForm : (formName) => "Salin ("+formName+") menjadi sebuah form input baru",
    NewFormBuilderName:"Nama Cabinet Baru",
    EnterNewFormBuilderName:"Masukkan nama form baru",
    CreateNewCabinet:"Buat Cabinet Baru",
    CreateCabinetRow:"Pembuatan Baris Cabinet",
    CabinetRowTitle:"Label Baris Kabinet",
    EnterCabinetRowTitle:"Masukkan label untuk baris kabinet",
    PleaseEnterCabinetRowTitle:"Mohon masukkan label untuk baris kabinet",
    ApiDescription:"Deskripsi API",
    PositiveLabel:"Label untuk tipe positif",
    EnterPositiveLabel:"Masukkan label untuk tipe positif",
    NegativeLabel:"Label untuk tipe negative",
    EnterNegativeLabel:"Masukkan label untuk tipe negative",
    HidePrintout:"Sembunyikan Cetakan",
    ContainerInputConfiguration:"Konfigurasi Container",
    SizeOfRectifierControllerSerialNumber:"Jumlah Controller/Rectifier SN",
    SubInput:"Sub Input",
    Type:"Tipe",
    DoYouWantToRemoveThisContainer:"Apakah anda ingin membuang contaier ini ?",
    DoYouWantToRemoveThisRack:"Apakah anda ingin membuang rak ini ?",
    QualityController:"Quality Controller",
    YourInspectionHistory:"Riwayat Inspeksi Mu",
    ThereIsNoHistory:"Belum ada riwayat",
    NewInspection:"Inspeksi Baru",
    SerialCodeOrReferenceCode:"SN or reference code",
    SearchInspection:"Cari Inspeksi",
    ContinueInspection:"Lanjutkan Inspeksi",
    InspectionDetailInfo:"Informasi Detil Inspeksi",
    CreateNewInspection:"Buat Inspeksi Baru",
    ModelOrSystemType:"Model / System Type",
    ItemInspectionIsNotAvailable:"Inspeksi Tidak Ditemukan",
    DoYouWantToCreateNewInspection:"Apakah anda ingin membuat data inspeksi baru ?",
    DoYouWantToContinueInspection:"Apakah anda ingin melanjutkan inspeksi item ini ?",
    InspectionItem:"Inspeksi Item",
    TakingInspectionItem:"Melakukan Inspeksi Item",
    ModelInformation:"Informasi Model",
    CurrentInspectionStatus : "Status Saat Ini",
    PerformedBy : "Dilakukan Oleh",
    PreviousInspectionPerformedBy:"User Inspeksi Sebelumnya",
    PreviousInspectionStatus:"Status Inspeksi Sebelumnya",
    PreviousInspectionDate:"Waktu Inspeksi Sebelumnya",
    LastInspectionPerformedBy:"User Inspeksi Terakhir",
    LastInspectionDate:"Waktu Inspeksi Terakhir",
    LastInspectionStatus:"Status Inspeksi Terakhir",
    InspectionStatus:"Status Inspeksi",
    Next:"Selanjutnya",
    Previous:"Sebelumnya",
    PageNext:"Selanjutnya >>",
    PagePrevious:"<< Sebelumnya",
    InspectionInstruction:"Instruksi Inspeksi",
    TakePicture:"Ambil Gambar",
    SetPicture:"Tetapkan Gambar",
    OkIUnderstand:"Baik Saya Mengerti",
    RectifierControllerSerialNumber:"Controller/Rectifier Serial Number",
    ConfirmSubmission:"Konfirmasi Pengiriman Data",
    ScanTheSerialNumber:"Scan Serial Number",
    OrEnterItManually:"Atau masukkan secara manual",
    YouWillSubmitInspectionWithReferenceVariable1AndSerialCodeVariable2WithStatusVariable3_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan mengirim data inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong>dan status inspeksi menjadi<strong><h3>"+status+"</h3></strong><p>Apakah anda sudah yakin dengan informasi tersebut?</p>",
    YesImSure:"Ya, Saya yakin",
    YouWillCloseInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan mengubah data inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong>dan status inspeksi menjadi<strong><h3>"+status+"</h3></strong><p>Apakah anda sudah yakin dengan informasi tersebut?</p>",
    YouWillPostponeInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan membatalkan inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>Tindakan ini akan membuat inspeksi dapat dilakukan oleh user lain, apakah anda yakin ?</p>",
    YouWillSuspendInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "Kamu akan mensuspend inspeksi dengan kode referensi<br/><strong><h3>"+reference+"</h3></strong>" +
        "dan serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>Tindakan ini akan membuat inspeksi tidak dapat dilakukan siapapun, apakah anda yakin dengan hal tersebut ?</p>",
    PleaseEnterAFieldValueVariable : (variableName) => "Mohon lengkapi "+variableName,
    EnterMessageIfYouNeedTo:"Tambahkan pesan jika kamu membutuhkannya",
    EnterNoteIfYouNeedTo:"Tambahkan catatan jika kamu membutuhkannya",
    InspectionMessage:"Pesan Inspeksi",
    InspectionNote:"Catatan Inspeksi",
    Preview:"Preview",
    PreviewInvoice:"Preview Invoice",
    FormInspectionDetail:"Detil Form Inspeksi",
    CloseStatusAndPrint:"Close Status & Cetak",
    ClickToExpandMoreInformation:"Klik untuk membuka informasi lebih",
    CLoseStatus:"Close",
    SuccessUpdatingStatus:"Berhasil Memperbarui Status",
    FailedUpdatingStatus:"Gagal Memperbarui Status",
    Reprint:"Reprint",
    Checking:"Pengecekan",
    CheckingList:"Daftar Pengecekan",
    UserStatus:"Status Pengguna",
    AdminStatus:"Status Admin",
    CheckingHistory:"Riwayat Pengecekan",
    CheckingActivity:"Aktifitas Pengecekan",
    PostponeInspection:"Tunda Inspeksi",
    InspectionOrderedSequentially:"Inspeksi Dikerjakan Berurutan",
    SelectSystemType:"Pilih System Type",
    OpenedInspection:"Inspeksi Terbuka",
    NA:"NA",
    SuspendInspection:"Suspend Inspeksi",
    Reopen:"Buka Kembali",
    DoYouWantToReopenThisInspection_ThisActionWillCauseTheInspectionCouldBeTakenByAnyUser:
        "Apakah anda ingin membuka kembali inspeksi ini ?\nAksi ini akan menyebabkan inspeksi ini dapat diambil oleh user siapapun, apakah anda sudah yakin ?",
    ContinueReOpenInspection:"Buka Status Inspeksi",
    PleaseEnterFirstName:"Mohon lengkapi nama depan dengan benar",
    PleaseEnterSmartDeviceLabel:"Mohon masukkan label perangkat device",
    PleaseEnterSmartDeviceName:"Mohon masukkan nama perangkat device",
    PleaseEnterSmartDeviceNameCorrectly:"Mohon masukkan nama perangkat device dengan benar",
    PleaseEnterSmartDeviceLabelCorrectly:"Mohon masukkan label perangkat device dengan benar",
    PleaseEnterFullName:"Mohon masukkan nama lengkap dengan benar",
    PleaseGenerateARefCodeForThisUser:"Mohon generate kode referensi untuk user ini",
    PleaseEnterEmail:"Mohon lengkapi email dengan benar",
    PleaseSelectRoleForThisUser:"Mohon pilih hak akses untuk user ini",
    PleaseSelectRoleForThisAdmin:"Mohon pilih hak akses untuk admin ini",
    PleaseEnterARightMobilePhone:"Mohon masukkan no handphone dengan benar",
    PleaseEnterPhoneNumberCorrectly:"Mohon masukkan no telpon dengan benar (dengan kode wilayah)",
    DoYouWantToCreateANewUser:"Apakah anda ingin membuat user baru ?",
    DoYouWantToCreateANewAdmin:"Apakah anda ingin membuat admin baru ?",
    DoYouWantToUpdateInformationOfThisUser:"Apakah anda ingin memperbarui informasi mengenai user ini ?",
    DoYouWantToUpdateInformationOfThisAdmin:"Apakah anda ingin memperbarui informasi mengenai admin ini ?",
    SuccessUpdatingData:"Berhasil memperbarui data",
    SuccessDeletingData:"Berhasil menghapus data",
    DoYouWantToResendAPasswordForThisUser:"Apakah kamu ingin mengirim ulang password kepada user ini ?",
    DoYouWantToResendAPasswordForThisAdmin:"Apakah kamu ingin mengirim ulang password kepada admin ini ?",
    SuccessSendingThePassword:"Berhasil mengirim password",
    DoYouWantToUpdateThisUserStatusFrom : (previousStatus, nextStatus) => "Apakah kamu hendak mengubah status user ini dari "+previousStatus+" menjadi "+nextStatus,
    DoYouWantToUpdateThisAdminStatusFrom : (previousStatus, nextStatus) => "Apakah kamu hendak mengubah status admin ini dari "+previousStatus+" menjadi "+nextStatus,
    Reason:"Alasan",
    StatusHistory:"Riwayar Status",
    BanThisDevice_TheUserWillBeBannedAfterThisPerform:"Keluarkan perangkat ini dari aplikasi, User akan otomatis keluar dari aplikasi setelah perform ini dilakukan, apakah kamu yakin ?",
    BanThisDevice_TheAdminWillBeBannedAfterThisPerform:"Keluarkan perangkat ini dari aplikasi, Admin akan otomatis keluar dari aplikasi setelah perform ini dilakukan, apakah kamu yakin ?",
    ApplicationSetting:"Setting Aplikasi",
    Sequence:"Urutan",
    TimeActivity:"Waktu Aktifitas",
    TimePerform:"Waktu Tindakan",
    AdminDetailInformation:"Detil Informasi Admin",
    CreateNewAdmin:"Buat Admin Baru",
    AdminCode:"Kode Admin",
    NeverEnd:"Selamanya",
    Periodic:"Periodik",
    NoLimit:"Tidak Terbatas",
    Limited:"Terbatas",
    PleaseEnterPositiveLabel:"Mohon masukkan label positif",
    PleaseEnterNegativeLabel:"Mohon masukkan label negatif",
    PleaseEnterPlaceHolder:"Mohon masukkan placeholder",
    NEVER_END:"Selamanya",
    PERIODIC:"Periodik",
    NO_LIMIT:"Tidak Terbatas",
    LIMITED:"Terbatas",
    CustomerOrderNo:"Customer Order No",
    Client:"Client",
    ProductSKU:"Product SKU",
    ProductName:"Nama Produk",
    Controller:"Controller",
    FWVersion:"FW Version",
    ControllerInfo:"Informasi Controller",
    CustomerInfo:"Informasi Customer",
    OrderNo:"Order No",
    DoYouWantToChangeYourPassword:"Apakah kamu ingin mengubah password mu ?",
    OldPasswordMustBeEntered:"Password lama harus diisi",
    NewPasswordMustBeEntered:"Password baru harus diisi",
    ConfirmPasswordMustBeEntered:"Konfirmasi password harus diisi",
    PasswordMustAtLeastContain6Characters:"Password setidaknya minimal terdiri dari 6 karakter",
    OldPasswordAndNewPasswordCannotBeSame:"Password lama dan password baru tidak boleh sama",
    ConfirmPasswordAndNewPasswordIsNotSame:"Konfirmasi password dan password baru tidak sesuai",
    Performer:"Performer",
    LastPerformer:"Performer Terkahir",
    PreviousPerformer:"Performer Sebelumnya",
    TimeAgo_future: "dalam %s",
    TimeAgo_past: "%s lalu",
    TimeAgo_s: 'beberapa detik yang lalu',
    TimeAgo_m: "semenit yang lalu",
    TimeAgo_mm: "%d menit lalu",
    TimeAgo_h: "sejam yang lalu",
    TimeAgo_hh: "%d jam lalu",
    TimeAgo_d: "sehari yang lalu",
    TimeAgo_dd: "%d hari lalu",
    TimeAgo_M: "sebulan yang lalu",
    TimeAgo_MM: "%d bulan lalu",
    TimeAgo_y: "setahun yang lalu",
    TimeAgo_yy: "%d tahun lalu",
    Time_future: "dalam %s",
    TimeFuture_next: "%s lagi",
    TimeFuture_s: 'beberapa detik lagi',
    TimeFuture_m: "semenit lagi",
    TimeFuture_mm: "%d menit lagi",
    TimeFuture_h: "sejam lagi",
    TimeFuture_hh: "%d jam lagi",
    TimeFuture_d: "sehari lagi",
    TimeFuture_dd: "%d hari lagi",
    TimeFuture_M: "sebulan lagi",
    TimeFuture_MM: "%d bulan lagi",
    TimeFuture_y: "setahun lagi",
    TimeFuture_yy: "%d tahun lagi",
    LastModification:"Perubahan Terakhir",
    DocumentNumber:"Nomor Dokumen",
    EnterDocumentNumber:"Enter Document Number",
    PleaseEnterDocumentNumber:"Mohon masukkan nomor dokumen",
    NewDocumentNumber:"Nomor Dokumen Baru",
    EnterNewDocumentNumber:"Masukkan Nomor Dokumen Baru",
    PleaseEnterNewDocumentNumber:"Mohon masukkan nomor dokumen baru",
    InternalPrint:"Cetak Internal",
    ExternalPrint:"Cetak Eksternal",
    SuccessSavingInspection:"Berhasil Menyimpan Inspeksi",
    InspectionEventDetail:"Detil Event Pengecekan",

    // start configuration admin
    EnterHostMail:"Masukan nama host email",
    PleaseEnterHostMail:"Mohon masukan nama host email",

    EnterPortMail:"Masukan port email",
    PleaseEnterPortMail:"Mohon masukan port email",

    EnterUsernameMail:"Masukan username email",
    EnterUsername:"Masukkan username",
    PleaseEnterUsernameMail:"Mohon masukan username email",

    EnterPasswordMail:"Masukan password email",
    PleaseEnterPasswordMail:"Mohon masukan password email",

    EnterReplyToMail:"Masukan email balasan",
    PleaseEnterReplyToMail:"Mohon masukan email balasan",

    EnterFromMail:"Masukan email pengirim",
    PleaseEnterFromMail:"Mohon masukan email pengirim",

    DoYouWantToUpdateInformationOfConfigurationAdmin:"Apakah anda ingin memperbarui informasi mengenai konfigurasi ini ?",
    TestSendMail:"Tes Kirim Email",
    NotificationEmailSent:"Notifikasi Email Terkirim",
    Resend:"Kirim Lagi",
    CompanyType:"Tipe Perusahaan",
    AllocatedDeviceId:"Alokasi ID Device",
    PaymentProof:"Bukti Pembayaran",
    ControlStatus:"Kontrol Status",

    SeeAll:"Lihat Semua",
    SubscribedService:"Langganan Layanan",
    Trying:"Mencoba",
    NewOrder:"Pesanan Baru",
    InventoryType:"Tipe Inventori",
    AllRegistered:"Terdaftar",
    SinceLastMonth:"Sejak akhir bulan",
    TodayActivity:"Penggunaan hari ini",
    WeeklyActivity:"Aktifitas Mingguan",
    Top5UsageRank:"Top 5 Usage Rank",
    CurrentProductOrderList:"Daftar Pesanan Sedang Berlangsung",
    SeeOrderDetail:"Lihat Detil Pesanan",
    SummaryOfOrder:"Ringkasan Pesanan",
    RequestProcess:"Mengajukan Pemrosesan",
    UpdatedAt:"Diperbarui Pada",
    SeeInvoice:"Lihat Invoice",
    SelectImage:"Pilih Gambar",
    Apply:"Terapkan",
    CropImage:"Crop Gambar",
    PaymentInformation:"Informasi Pembayaran",
    UploadPaymentProof:"Unggah Bukti Bayar",
    SelectImageToUpload:"Pilih gambar untuk diunggah",
    CannotDeletePaymentProof:"Tidak dapat menghapus bukti pembayaran ini",
    PaymentGuideline:"Petunjuk Cara Pembayaran",
    TransferAsPaymentMethod:"Cara Pembayaran Melalui Transfer",
    PaymentGuidelineDescription:"Lakukan pembayaran melalui no rekening di atas,\n" +
        "isi berita dengan menggunakan kode referensi, lalu simpan bukti pembayaran. Hubungi kami untuk melakukan konfirmasi pembayaran.",
    RequestToProcessTitle:"Apakah kamu yakin dengan permintaan pemrosesan ini",
    RequestToProcessDescription:"Kamu akan meminta pemrosesan yang akan dilakukan oleh kami, mohon pastikan kamu telah melakukan pembayaran atau hubungi kami untuk mengetahui tindakan lebih lanjut yang perlu kamu lakukan.",
    ProductOrderStatusHistory:"Riwayat Status Pesanan",
    HistoryOfYourProductOrder:"Riwayat dari pesanan mu",
    Service:"Layanan",
    SessionAndActivity:"Sesi & Aktifitas",
    Longest:"Terlama",
    Latest:"Terbaru",
    TheMost:"Terbesar",
    Smallest:"Terkecil",
    State:"Keadaan",
    Action:"Tindakan",
    DownloadSoftware:"Unduh Software",
    Restart:"Restart",
    DeviceInformation:"Informasi Device",
    UUID:"UUID",
    IPAddress:"IP Address",
    PublicIpAddress:"Public IP Address",
    LocalIpAddress:"Local IP Address",
    ShortCode:"Short Code",
    ContainerAvailability:"Ketersediaan Kontainer",
    NoInformation:"Tidak ada informasi",
    PleaseFillUpDeviceLocated:"Mohon isi lokasi penempatan device",
    Management:"Manajemen",
    Showing:"Menampilkan",
    Entries:"Data",
    Entry:"Data",
    OutOf:"Dari",
    Regional:"Wilayah",
    CreateLocation:"Create Location",
    LocationInformation:"Informasi Lokasi",
    BuildDate:"Tanggal Berdiri",
    BuildingInformation:"Informasi Gedung",
    PhoneNumber:"Nomor Telpon",
    SelectLocation:"Pilih Lokasi",
    Reset:"Reset",
    UpdateConfirmationDetailMessage:"Mohon pastikan informasi yang kamu masukkan sudah sesuai dengan kebutuhan yang hendak kamu capai, apakah kamu ingin melanjutkan pembaruan ini ?",
    SelectCalendar:"Pilih Kalender",
    PleaseSelectYourPlaceGeoLocation:"Mohon pilih geo lokasi tempat mu",
    LocationList:"Daftar Lokasi",
    CompanyLocationList:"Daftar Lokasi Perusahaan",
    ClientLocationList:"Daftar Lokasi Klien",
    LastAccess:"Akses Terakhir",
    UpdateInformation:"Perbarui Informasi",
    GeneralInformation:"Informasi Umum",
    ChangePhoto:"Ubah Foto",
    UserSession:"Sesi User",
    TotalAuthenticatedDevice:"Total Perangkat Terotentikasi",
    Last5UserActivity:"5 Aktifitas Terakhir",
    PleaseEnterCorrectPhoneNumber:"Mohon masukkan no telp dengan benar",
    PleaseEnterCorrectEmail:"Mohon masukkan email dengan benar",
    PleaseEnterAddress:"Mohon masukkan alamat",
    YourProfilePage:"Informasi Profil Mu",
    CreateUser:"Buat User",
    CreateUserAccount:"Buat Akun User",
    UserCreationPasswordNote:"Pengguna akan mendapat password sementara melalui email, dan dapat digunakan untuk masuk ke aplikasi. Mereka akan diminta untuk mengubah password setelah berhasil masuk.",
    NeverAccessing:"Belum Mengakses",
    NoActivitiesFound:"Belum ada aktifitas",
    SelectAtLeastOneRole:"Minimal 1 tugas perlu didaftarkan",
    Latest5SmartDeviceHavingActivity : (count) => "Aktifitas terbaru ",
    Latest10UserActivity : "10 Aktifitas Terbaru ",
    LogoutConfirmation:"Konfirmasi Keluar",
    BackToSignIn:"Kembali ke halaman sign in",
    WeWillSendYourPasswordOnForgetPasswordMessage:"Kami akan mengirimkan kamu instruksi melalui email kamu yang terdaftar pada sistem kami",
    PleaseRememberYourPasswordAsItIsImportantToYourAccessToApplicationAndDoNotShareWithAnyOtherPerson:"Mohon ingat password barumu untuk masuk ke dalam aplikasi, dan jangan bagikan password dan informasi akunmu kepada orang lain",
    RequestToChangePasswordRequiredIsExistsToEnterApplication:"Permintaan untuk mengganti password diperlukan untuk dapat masuk ke dalam aplikasi",
    ResetPasswordSucceed:"Reset Password Berhasil",
    ResetPasswordSucceedNote:"Terima kasih telah melakukan perubahan password, silahkan kunjungi halaman Sign In dan login menggunakan autentikasi akunmu.",
    SuccessUpdatingYourPassword:"Sukses memperbarui password mu",
    RegisteredSince:"Terdaftar Mulai",
    RegisteredUntil:"Terdaftar Hingga",
    PleaseSelectCompany:"Mohon pilih perusahaan",
    PleaseSelectProduct:"Mohon pilih produk",
    PleaseSelectPackage:"Mohon pilih paket",
    PleaseAddAtLeastOneOrderItem:"Mohon tambahkan minimal 1 pesanan item",
    Welcome:"Selamat Datang",
    UseUsernameOrEmailToSignIn:"Gunakan username atau password untuk masuk ke dalam aplikasi",
    UseRFIDOrFingerprintToSignIn:"Gunakan RFID Tag atau fingerprint untuk masuk ke dalam aplikasi",
    ScanYourRFIDOrFingerprint:"Scan RFID atau Fingerprint mu",
    WhatWouldYouDo:"Apa yang hendak kamu lakukan",
    Home:"Home",
    Starting:"Mulai",
    LocationName:"Nama Lokasi",
    GoodsCategoryDetail:"Detil Kategori Barang",
    Goods:"Barang",
    GoodsList:"Daftar Barang",
    RegisteredItem:"Item Terdaftar",
    RegisterItem:"Daftarkan Item",
    CreateGoods:"Buat Barang",
    CreateGoodsForm:"Form Pembuatan Barang",
    GoodsName:"Nama Barang",
    EnterGoodsName:"Masukkan Nama Barang",
    GoodsDescription:"Deskripsi Barang",
    EnterGoodsDescription:"Masukkan Deskripsi Barang",
    More:"Lebih Banyak",
    SeeAllItem:"Lihat Semua Item",
    Register:"Daftarkan",
    NewItem:"Item Baru",
    ScanTag:"Scan Tag",
    StartScanning:"Mulai Scan",
    StopScanning:"Hentikan Scanning",
    NotOnScanningMode:"Not on scanning mode",
    ScanningRFIDOnAReader:"Scanning RFID pada mesin reader",
    RegisterTagForGoodsItem:"Pendaftaran Tag Untuk Item Barang",
    RFIDTag:"RFID Tag",
    RFIDTag_OnTheGo:"RFID Tag (On The Go)",
    RFIDTag_EnterManually_PressScanButtonToRetrieveItFromReaderMachine:"RFID Tag (Masukkan Manual) / Klik tombol scan untuk mengambil dari mesin pembaca",
    LengthDoesntFollowTheRuleOfTag:"Jumlah karakter tidak memenuhi syarat aturan pemberian tag",
    AvailableMedia:"Media Tersedia",
    Storage:"Storage",
    MediaStorage:"Media Penyimpanan",
    TakingPictureMedia:"Media Pengambilan Gambar",
    RegisterImageForGoodsItem:"Pendaftaran Gambar Untuk Item Barang",
    RegisterImage:"Pendaftaran Gambar",
    GoodsItemList:"Daftar Item Barang",
    GoodsInformation:"Informasi Barang",
    EnterGoodsCategory:"Masukkan Kategori Barang",
    GoodsInfoForm:"Form Informasi Barang",
    GoodsCategoryCreation:"Pembuatan Kategori Barang",
    Rows:"Baris",
    Pages:"Halaman",
    SelectGoodsCategory:"Pilih Kategori Barang",
    Exclusive:"Eksklusif",
    CreateCategory:"Buat Kategori",
    NotYetAllocated:"Belum Teralokasikan",
    UpdateRfidTag:"Perbarui Tag RFID",
    Executor:"Pelaku",
    DateTime:"Waktu",
    GoodsInfo:"Informasi Barang",
    GoodsItem:"Item Barang",
    Goods:"Barang",
    Registered:"Terdaftar",
    Borrowed:"Dipinjam",
    Placed:"Pada Mesin",
    Returned:"Dikembalikan",
    RemovedFromMachine:"Keluar Dari Mesin",
    ToolBox:"ToolBox",
    SelectUser:"Pilih User",
    Selected_Count : (count) => "Terpilih ("+count+")",
    LockerDeviceParallelPosition:"Urutan Device Pengunci",
    LockerPosition:"Urutan Knob Pengunci",
    MaximumContainerSizeReached:"Maksimum jumlah pembuatan container telah tercapai",
    Locker:"Locker",
    RFIDReaderDevice:"Perangkat Baca RFID",
    RFIDReaderParallelPosition:"Urutan device pembaca RFID",
    RFIDChannelPosition:"Urutan channel pembaca RFID",
    Placement:"Penempatan",
    UploadPaymentReceipt:"Unggah Bukti Pembayaran",
    PaymentReceiptNeededToRequestProcessing:"Bukti pembayaran diperlukan untuk melanjutkan proses",
    MaxBorrowingDuration:"Durasi Pinjam Maks (jam)",
    NotLimited:"Tidak Terbatas",
    Limited:"Terbatas",
    VendingMachine:"Vending Machine",
    Borrowing:"Peminjaman",
    Missing:"Hilang",
    BorrowingList:"Daftar Peminjaman",
    SupportTicket:"Support Ticket",
    TakenFrom:"Diambil dari",
    ReturnedOn:"Kembali di",
    Borrower:"Peminjam",
    Returner:"Pengembali",
    Time:"Waktu",
    BorrowingInformation:"Informasi Peminjaman",
    UserInformation:"Informasi Pengguna",
    BorrowingTime:"Waktu Peminjaman",
    ReturningTime:"Waktu Pengembalian",
    BorrowingSmartDevice:"Device Pengambilan",
    ReturningSmartDevice:"Device Pengenbalian",
    BorrowingPhysicalContainer:"Laci Pengambilan",
    ReturningPhysicalContainer:"Laci Pengembalian",
    ShouldReturnedAt:"Harus Kembali Pada",
    Returning:"Pengembalian",
    LastLocation:"Lokasi Terakhir",
    RegistrationList:"Daftar Registrasi",
    Registration:"Registrasi",
    NewRegistration:"Registrasi Baru",
    SucceedCreatingNewRegistration:"Berhasil menambahkan pendaftaran baru",
    HelpVerification:"Bantu Verifikasi",
    CompleteData:'Lengkapi Data',
    SearchLocation:"Cari Lokasi",
    CompanySubscription:"Perusahaan Berlangganan",
    SubscriptionList:"Daftar Berlangganan",
    CreateNewOrder:"Buat Pesanan Baru",
    LastUpdate:"Perubahan Terakhir",
    SomeOfSubscriptionGonnaExpiredAlert:"Salah satu produk langgananmu sudah memasuki masa tenggang, segera perpanjang sebelum expired",
    SomeOfSubscriptionIsExpiredAlert:"Salah satu produk langgananmu sudah expired, kamu masih bisa perpanjang jika sistem belum menonaktifkan langgananmu",
    ActivePeriod:"Masa berlaku",
    OnBorrow:"Dipinjam",
    BorrowingOfItemList:"Daftar Peminjaman",
    FormCreation:"Form Pembuatan",
    UploadFile:"Upload File",
    APKFile:"File APK",
    MiscellaneousFile:"Miscellaneous File",
    Filename:"Filename",
    Whatsapp:"Whatsapp",
    BusinessContact:"Kontak Bisnis",
    PleaseSelectLocation:"Mohon pilih lokasi",
    UpdateSmartDevice:"Update Smart Device",

}
module.exports = Object.freeze(Object.assign(label,menu, baru))

