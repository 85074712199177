import Global from './Global';

// import { lang } from "moment";

export function removeStorage(){
    localStorage.clear();
    sessionStorage.clear();
}

export function setLanguage(language){
    localStorage.setItem(Global.LANGUAGE, language) //set Language Catcha
}

export function getLanguage(){
    if (localStorage.getItem(Global.LANGUAGE)) {
        return localStorage.getItem(Global.LANGUAGE)
    }
    return null
}

export function removeData(key){
    localStorage.removeItem(key)
    sessionStorage.removeItem(key)
}

export function storeData(key, value){
    if(localStorage.getItem(key)){
        localStorage.removeItem(key)
        sessionStorage.removeItem(key)
    }
    localStorage.setItem(key, JSON.stringify(value))
    sessionStorage.setItem(key, JSON.stringify(value))
}

export function getData(key){
    let value = localStorage.getItem(key)
    if(value!=null){
        return JSON.parse(value)
    }else{
        value = sessionStorage.getItem(key)
        if(value!=null){
            return JSON.parse(value)
        }
    }
    return null
}
