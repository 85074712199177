import BasePage from './BasePage';
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import {
    changeParam2,
    changeParam3,
    currencyFormat,
    isEmpty,
    navigatePage,
    parseDate,
    sortirMap
} from "na-utilities/src/utils/Utilities";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import {IoMdSettings, IoMdAdd} from 'react-icons/io';
import SearchInput from '../components/SearchInput';
import Global, {CURRENCY_SYMBOL, DD_MM_YYYY_HH_MM_SS, PRODUCT_ORDER_STATUS_COLOR} from '../utils/Global';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import SockJsClient from 'react-stomp';
import label from 'na-utilities/src/label/Label'
import {FiArrowDownCircle, FiArrowUpCircle} from "react-icons/fi";
import DateInput from "../components/Widget/DateInput";
import ItemOption from "../components/Widget/ItemOption";
import {MdRestore} from "react-icons/md";
import {FaFileExcel} from "react-icons/fa";
import Page from "../components/Page";
import ProductModal from "../components/modal/ProductModal";
import CompanyDetailPage from "./CompanyDetailPage";
import UserDetailPage from "./UserDetailPage";
import ProductOrderDetailPage from "./ProductOrderDetailPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import CompanySelectionModal from "../components/modal/CompanySelectionModal";
import NewProductOrderInquiryModal from "../components/modal/NewProductOrderInquiryModal";


class ProductOrderPage extends BasePage {

    constructor(props) {
        super(props);
        this.state = {
            ascending: false,
            sortir: 'ja.updated',
            page: queryString.parse(this.props.query).page ? queryString.parse(this.props.query).page : 1,
            key: queryString.parse(this.props.query).tab ? queryString.parse(this.props.query).tab : label.All,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            createdStartDate: null,
            createdEndDate: null,
            updatedStartDate: null,
            updatedEndDate: null,
            productOrderStatuses: [],
            productOrders: [],
            products: [],
            product: {},
            modelModal: false,
            productOrderStatus: null,
            productOrderStatusId: undefined,
            spotLocations:[],
            smartDevices:[],
            spotLocation:{},
            smartDevice:{},
            inputSearch: queryString.parse(this.props.query).search ? queryString.parse(this.props.query).search : "",
            companySelectionModal:false,
            newProductOrderInquiryModal:false,
            selectedCompany:{}
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAllStatus()
        this.fetchModels()
        this.fetchSpotLocations()
        if(this.state.spotLocation&&this.state.spotLocation.id){
            this.fetchSmartDevices(this.state.spotLocation)
        }

    }

    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
            let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
            let inputSearch = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
            let productOrderStatuses = this.state.productOrderStatuses?this.state.productOrderStatuses:[]
            let productOrderStatusId = null
            if (key === label.All) {
                productOrderStatusId = null
            } else {
                productOrderStatuses.map((productOrderStatus)=>{
                    if (key == productOrderStatus.sortir) {
                        productOrderStatusId = productOrderStatus.id
                    }
                })
            }
            this.setState({
                page: page,
                key: key,
                inputSearch: inputSearch,
                productOrderStatusId: productOrderStatusId
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
            })
        }
    }


    fetchSpotLocations = () =>{
        this.get(Endpoint.API.SPOT_LOCATIONS, {
            params:{
                ascending:true,
                sortir:"jc.name"
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    spotLocations : res.data,
                })
            }
        },false, true);

    }
    fetchSmartDevices = (spotLocation) =>{
        this.get(Endpoint.API.SMART_DEVICES, {
            params:{
                ascending:true,
                sortir:"jt.created",
                spotLocationId:spotLocation.id
            }
        }, null, response=>{
            if(response.code===200){
                let smartDevices = response.data
                this.setState({
                    smartDevices : smartDevices
                })
            }
        }, false, true)
    }

    fetchAllStatus = () => {
        let {key} = this.state
        this.get(Endpoint.API.PRODUCT_ORDER_STATUSES, null, null, response => {
            if (response.code === 200) {
                let productOrderStatuses = response.data
                this.fetchBadgeCount(undefined)
                productOrderStatuses.forEach((item, index) => {
                    this.fetchBadgeCount(item.id)
                })
                let productOrderStatusId = null
                productOrderStatuses.map((productOrderStatus)=>{
                    if (key == productOrderStatus.sortir) {
                        productOrderStatusId = productOrderStatus.id
                    }
                })
                this.setState({
                    productOrderStatusId:productOrderStatusId,
                    productOrderStatuses: productOrderStatuses
                }, () => {
                    this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                })
            }
        }, true, true)
    }

    fetchModels = () => {
        this.get(Endpoint.API.PRODUCTS, null, null, response => {
            if (response.code === 200) {
                this.setState({
                    products: response.data
                })
            }
        }, true, true)
    }

    fetchAll = (ascending, sortir, search, progressing, excelMode) => {
        let {productOrderStatusId, spotLocation, smartDevice} = this.state
        let params = {
            productOrderStatusId: productOrderStatusId,
            ascending: ascending,
            sortir: sortir,
            search: search,
            spotLocationId : spotLocation.id,
            smartDeviceId : smartDevice.id,
            page: this.state.page - 1,
            createdStartDate: this.state.createdStartDate,
            createdEndDate: this.state.createdEndDate,
            updatedStartDate: this.state.updatedStartDate,
            updatedEndDate: this.state.updatedEndDate,
            productId: this.state.product.id,
            excelMode: excelMode
        }
        if (excelMode) {
            this.asyncDownloadGet(Endpoint.API.PRODUCT_ORDERS, {params: params}, null, true);
            return
        }
        this.get(Endpoint.API.PRODUCT_ORDERS, {params: params}, null, res => {
            if (res.code === 200) {
                this.setState({
                    productOrders: res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    ['size-' + productOrderStatusId ? productOrderStatusId : "all"]: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, progressing, true);
    }

    refreshProductOrders = (ascending, sortir, progressing) => (
        this.setState({
            ascending: ascending,
            sortir: sortir,
        }, () => {
            this.fetchAll(ascending, sortir, this.state.inputSearch, progressing)
        })
    )

    resetFilter() {
        this.setState({
            page: 1,
            totalPage: 0,
            totalElement: 0,
            pageElement: 0,
            createdStartDate: null,
            createdEndDate: null,
            updatedStartDate: null,
            updatedEndDate: null,
            productOrders: [],
            productOrderStatus: null,
            productOrderStatusId: null,
            product: {},
            spotLocation: {},
            smartDevice: {},
            inputSearch: "",
        }, () => {
            // navigatePage(this.props, 1)
            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
        })
    }


    render() {
        let totalElement = this.state.totalElement
        let totalPage = this.state.totalPage
        let {spotLocations, spotLocation, smartDevices, smartDevice, products, product, modelModal} = this.state

        return (
            <Page
                title={label.ProductOrderList}
                breadcrumbs={[{name: label.ProductOrderList, active: true}]}
                className="TablePage"
            >
                {super.render()}
                <ProductModal
                    show={modelModal}
                    product={product}
                    onClickOk={() => {
                        this.setState({modelModal: false})
                    }}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader style={{cursor: 'pointer'}} onClick={event => {
                                this.setState({
                                    userFilterExpand: !this.state.userFilterExpand
                                })
                            }}>
                                {
                                    (this.state.userFilterExpand) ? (<FiArrowUpCircle/>) : ((<FiArrowDownCircle/>))
                                }
                                &nbsp;{label.AdvanceFilter} ({label.ClickToExpandFilterContent})
                            </CardHeader>
                            <Collapse isOpen={this.state.userFilterExpand}>
                                <CardHeader>
                                    <Row>
                                        <Col md={3}>
                                            <Label for="createdStartDate">{label.CreatedStartDate}</Label>
                                            <DateInput
                                                id="createdStartDate"
                                                value={this.state.createdStartDate}
                                                maxdate={this.state.createdEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdStartDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="createdEndDate">{label.CreatedEndDate}</Label>
                                            <DateInput
                                                id="createdEndDate"
                                                mindate={this.state.createdStartDate}
                                                value={this.state.createdEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdEndDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedStartDate">{label.UpdatedStartDate}</Label>
                                            <DateInput
                                                id="updatedStartDate"
                                                value={this.state.updatedStartDate}
                                                maxdate={this.state.updatedEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedStartDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="updatedEndDate">{label.UpdatedEndDate}</Label>
                                            <DateInput
                                                id="updatedEndDate"
                                                mindate={this.state.updatedStartDate}
                                                value={this.state.updatedEndDate}
                                                onChange={(e) => {
                                                    let value = e.target.value
                                                    this.setState({
                                                        updatedEndDate: value ? value : null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectProduct}
                                                    objects={products}
                                                    default={!isEmpty(product) ? product.id : null}
                                                    fieldForLabel={"name"}
                                                    callback={(product) => {
                                                        this.setState({
                                                            product: product
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectSpotLocation}
                                                    fieldForLabel={"name"}
                                                    objects={spotLocations}
                                                    default={spotLocation.id}
                                                    callback={(spotLocation)=>{
                                                        if(!isEmpty(spotLocation)){
                                                            if(spotLocation.id!=this.state.spotLocation.id){
                                                                smartDevice = {}
                                                            }
                                                            this.setState({
                                                                spotLocation:spotLocation,
                                                                smartDevice:{},
                                                                smartDevices:[]
                                                            }, () => {
                                                                this.fetchSmartDevices(spotLocation)
                                                            })

                                                        }else{
                                                            this.setState({
                                                                spotLocation:{},
                                                                smartDevices:[]
                                                            })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectSmartDevice}
                                                    fieldForLabel={"name"}
                                                    objects={smartDevices}
                                                    default={smartDevice.id}
                                                    callback={(smartDevice)=>{
                                                        if(smartDevice){
                                                            this.setState({
                                                                smartDevice:smartDevice
                                                            })
                                                        }else{
                                                            this.setState({
                                                                smartDevice:{}
                                                            })
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label>{label.Searching}</Label>
                                                <SearchInput
                                                    placeholder={label.SearchReferenceSerialModelUser}
                                                    value={this.state.inputSearch}
                                                    onChange={e => {
                                                        this.setState({
                                                            inputSearch: e.target.value
                                                        }, () => {
                                                            if (this.state.inputSearch === '') {
                                                                changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                            }
                                                        })
                                                    }}
                                                    onEnter={e => {
                                                        if (this.state.inputSearch !== '') {
                                                            changeParam3(this.props, 'page', 1, 'tab', this.state.key, 'search', this.state.inputSearch)
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalRecord}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color: 'green'}}><strong>{totalElement}</strong>
                                                            </h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalPage}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color: 'blue'}}>{totalPage}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={6}>
                                            <Row>
                                                <Col md={6}>
                                                    <Button
                                                        block
                                                        className={"float-left"}
                                                        color={"primary"}
                                                        onClick={event => {
                                                            navigatePage(this.props, 1)
                                                        }}>
                                                        <IoMdSettings/>&nbsp;{label.ApplyFilter}
                                                    </Button>
                                                </Col>
                                                <Col md={6}>
                                                    <Button block className={"float-left"} color={"yellow"}
                                                            onClick={event => {
                                                                this.resetFilter()
                                                            }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={12}>
                                                    <Button block className={"float-left"} color={"green"}
                                                            onClick={event => {
                                                                this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true, true)
                                                            }}><FaFileExcel/>&nbsp;{label.DownloadExcel}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                {this.renderproductOrder()}
            </Page>
        )
    }

    fetchBadgeCount(productOrderStatusId) {
        let {spotLocation, smartDevice} = this.state
        let params = {
            productOrderStatusId: productOrderStatusId,
            ascending: this.state.ascending,
            sortir: this.state.sortir,
            search: this.state.search,
            page: this.state.page - 1,
            createdStartDate: this.state.createdStartDate,
            createdEndDate: this.state.createdEndDate,
            updatedStartDate: this.state.updatedStartDate,
            updatedEndDate: this.state.updatedEndDate,
            productId: this.state.product.id,
            spotLocationId : spotLocation.id,
            smartDeviceId : smartDevice.id,
            excelMode: false,
            badgeCountMode:true
        }
        this.get(Endpoint.API.PRODUCT_ORDERS, {params: params}, null, (response) => {
            if (response && response.code == 200) {
                this.setState({
                    ['size-' + productOrderStatusId ? productOrderStatusId : "all"]: response.totalElement
                })
            }
        }, false, false);
    }


    badgeTabLabel(name, productOrderStatusId, color) {
        let size = this.state['size-' + productOrderStatusId ? productOrderStatusId : "all"];
        if (!size || size == 0) {
            return (<span>{label(name)}</span>)
        }
        return (
            <>
                <span>{label(name)}</span>
                &nbsp;&nbsp;
                <span
                    style={{
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                        background: color ? color : 'red',
                        color: 'white',
                        borderRadius: '20%',
                        minWidth: '24px',
                        display: 'inline-block',
                        textAlign: 'center'
                    }}>
                    {size}
                </span>
            </>)
    }

    renderproductOrder = () => {
        let {companySelectionModal, newProductOrderInquiryModal, selectedCompany} = this.state
        return (
            <>
                <CompanySelectionModal
                    showing={companySelectionModal}
                    onClose={() => {
                        this.setState({companySelectionModal:false})
                    }}
                    onSelect={(company)=>{
                        this.setState({companySelectionModal:false, newProductOrderInquiryModal:true, selectedCompany:company})
                    }}/>
                <NewProductOrderInquiryModal
                    show={newProductOrderInquiryModal}
                    company={selectedCompany}
                    onClose={()=>{
                        this.setState({companySelectionModal:false, newProductOrderInquiryModal:false, selectedCompany:{}})
                    }}
                    onCreateOrder={()=>{
                        this.setState({companySelectionModal:false, newProductOrderInquiryModal:false, selectedCompany:{}}, () => {
                            this.resetFilter()
                        })
                    }}/>
                <Row key={2}>
                    <Col>
                        <Card>
                            <CardHeader>
                                <strong>{label.ProductOrder}</strong>
                                <br/>
                                <Row>
                                    <Col md={3}>
                                        {label.SortBy} :
                                        <UncontrolledButtonDropdown key={1}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    sortirMap(this.state.sortir.toString())
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu style={{transform: 'none'}}>
                                                <DropdownItem
                                                    onClick={e => (this.refreshProductOrders(this.state.ascending, "ja.reference", true))}>{label.Reference}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => (this.refreshProductOrders(this.state.ascending, "ja.paymentReference", true))}>{label.PaymentReference}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => (this.refreshProductOrders(this.state.ascending, "ja.created", true))}>{label.Created}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => (this.refreshProductOrders(this.state.ascending, "ja.updated", true))}>{label.Updated}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={3}>
                                        {label.Sortir} :
                                        <UncontrolledButtonDropdown key={2}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    this.state.ascending ? label.Ascending : label.Descending
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem
                                                    onClick={e => (this.refreshProductOrders(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => (this.refreshProductOrders(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={4}>
                                    </Col>
                                    <Col md={2}>
                                        <Button
                                            block
                                            className={"float-left"}
                                            color={"primary"}
                                            onClick={event => {
                                                this.setState({companySelectionModal:true})
                                            }}>
                                            {label.CreateOrder}&nbsp;&nbsp;<IoMdAdd/>
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Tabs
                                    activeKey={this.state.key}
                                    onSelect={key => {
                                        changeParam2(this.props, 'page', 1, 'tab', key)
                                    }
                                    }>
                                    <Tab
                                        eventKey={label.All}
                                         title={this.badgeTabLabel(label.All, undefined, 'blue')}>
                                        {this.renderproductOrderTable()}
                                    </Tab>
                                    {
                                        this.state.productOrderStatuses.map((item, index) => (
                                            <Tab
                                                key={index}
                                                eventKey={item.id}
                                                title={this.badgeTabLabel(item.label, item.id, item.color)}>
                                                {this.renderproductOrderTable()}
                                            </Tab>
                                        ))
                                    }
                                </Tabs>
                            </CardBody>
                            <SockJsClient
                                url={Global.BASE_URL}
                                topics={[Endpoint.API.LISTEN_UPDATE_PRODUCT_ORDERS]}
                                autoReconnect={true}
                                debug={Global.DEBUG}
                                heartbeat={5000}
                                onMessage={(message) => {
                                    this.successToast(message)
                                    this.fetchAll(this.state.ascending, this.state.sortir, this.state.inputSearch, true)
                                }}
                                onConnectFailure={(object)=>{
                                    console.error(object)
                                }}
                                ref={(client) => {
                                    this.clientRef = client
                                }}/>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }


    renderproductOrderTable = () => {
        return (
            <Card>
                <CardBody>
                    <Table hover responsive>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.ReferenceCode}</th>
                            <th>{label.Company}</th>
                            <th>{label.User}</th>
                            <th>{label.Amount}</th>
                            <th>{label.Status}</th>
                            <th>{label.Created}</th>
                            <th>{label.Updated}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.productOrders.map((item, index) => (
                                <tr key={item.id}>
                                    <th scope="row">{((this.state.page - 1) * this.state.pageElement) + (++index)}</th>
                                    <td><a href={ProductOrderDetailPage.ENDPOINT+'?id=' + item.id}>{item.reference}</a></td>
                                    <td><a href={CompanyDetailPage.ENDPOINT+'?id=' + item.id}>{item.company.fullName}</a></td>
                                    <td><a href={UserDetailPage.ENDPOINT+'?id=' + item.id}>{item.user.fullname}</a></td>
                                    <td>
                                        {currencyFormat(item.totalProductOrderAmount, CURRENCY_SYMBOL)}
                                    </td>
                                    <td><Button size="sm" disabled={true} style={{backgroundColor:item.productOrderStatus.color}}>{item.productOrderStatus.label}</Button></td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage}/>
            </Card>
        )
    }
}

export default Object.assign(ProductOrderPage, {ENDPOINT : "/productOrder"})
