import * as React from 'react';
import Page from './../components/Page';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody, CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle, FormGroup, Input, Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import * as queryString from 'query-string/index';
import {isEmpty, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
import ItemOption from "../components/Widget/ItemOption";
import NumberInput from "../components/Widget/NumberInput";
import ActiveOption from "../components/Widget/ActiveOption";
import ActiveLabel from "../components/Widget/ActiveLabel";

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class ProductPage extends BasePage{



    constructor(props) {
        super(props);
        let products  = new Array();
        this.state ={
            products : products,
            product:{},
            cabinets:[],
            modalDeleteConfirm:false,
            ascending:false,
            sortir:'created',
            search:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
    }
    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
            })
        }
    }



    fetchAll = (ascending, sortir, search, page, progressing) => {
        this.get(Endpoint.API.PRODUCTS, {
            params:{
                ascending:ascending,
                sortir:sortir,
                search:search,
                page: page-1
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    products : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        },progressing, true);
    }


    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }

    delete = () => {
        this.setState({
            modalDeleteConfirm:false
        }, () => {
            this.get(Endpoint.API.PRODUCT_DELETE+"/"+this.state.product.id, null, null, res=>{
                if(res.code===200){
                    this.successToast(res.message)
                    this.setState({
                        category:null
                    }, () => {
                        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
                    })
                }
            }, true, true);
        })
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false
        })
    }

    refreshProduct = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir
        }, () => {
            this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, progressing)
        })
    )

    render() {
        let {product, products, cabinets} = this.state
        let cabinet = product.cabinet?product.cabinet:{}
        var i = 0 ;

        return (
            <Page
                title={label.Products}
                breadcrumbs={[{ name: label.Product, active: true }]}
                className="TablePage"
            >
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message="Do you want to delete this product item from your list ?"
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <Row key={1}>
                    <Col md={12}>
                        <Card className="mb-6">
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        {label.SortBy} :
                                        <UncontrolledButtonDropdown key={1}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    sortirMap(this.state.sortir)
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshProduct(this.state.ascending, "name", true))}>{label.Title}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshProduct(this.state.ascending, "created", true))}>{label.Created}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshProduct(this.state.ascending, "updated", true))}>{label.Updated}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={3}>
                                        {label.Sortir} :
                                        <UncontrolledButtonDropdown key={2}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    this.state.ascending?label.Ascending:label.Descending
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshProduct(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshProduct(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={4}>

                                    </Col>
                                    <Col md={2}>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table responsive hover>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{label.Name}</th>
                                            <th>{label.Code}</th>
                                            <th style={{width:'400px'}}>{label.Description}</th>
                                            <th>{label.SmartDeviceType}</th>
                                            <th>{label.Status}</th>
                                            <th>{label.Created}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.products.map((item, index)=>(
                                                <tr key={index}>
                                                    <th scope="row">
                                                        {((this.state.page-1)* this.state.pageElement)+(++index)}
                                                    </th>
                                                    <td>{item.name}</td>
                                                    <td>{item.code}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.smartDeviceType.type}</td>
                                                    <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.published}/></td>
                                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
        );
    }
};

export default Object.assign(ProductPage, {ENDPOINT : "/products"})
