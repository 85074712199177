import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  NavLink,
  Row
} from 'reactstrap';
import {AuthConsumer} from '../utils/AuthContext';
import Global from '../utils/Global';
import BaseComponent from './BaseComponent';
import {getData, storeData} from '../utils/StorageUtil';
import Img from 'react-image';
import label from 'na-utilities/src/label/Label'
import {isEmpty, isResponseOk} from "na-utilities/src/utils/Utilities";
import WarningLabel from "./Widget/WarningLabel";
import Endpoint from "na-utilities/src/api/Endpoint";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import ApplicationIcons from "na-utilities/src/misc/ApplicationIcons";
import ResetPasswordPage from "../pages/ResetPasswordPage";

class AuthForm extends BaseComponent {

  constructor(props, context) {
    super(props, context);
    this.handleSubmit = this.handleSubmit.bind(this);
    let state = this.state
    state.username = Global.DEV_LOGIN_USERNAME
    state.password = Global.DEV_LOGIN_PASSWORD
    state.rememberMe = false
    state.configuration = getData(Global.CONFIGURATION)
    state.configurationAdmin = getData(Global.CONFIGURATION_ADMIN)
    state.message = this.props.message
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAppSetting()
    this.fetchConfigurationAdmin()
  }

  handleSubmit = (event, authProps) => {
    event.preventDefault();
    let {username, password, rememberMe} = this.state
    if(isEmpty(username) || isEmpty(password)){
      this.setState({
        message : label.PleaseCompleteTheFields
      })
      return ;
    }
    let form = new FormData()
    form.append("usernameOrEmail", username)
    form.append("password", password)
    form.append("rememberMe", rememberMe)

    this.post(Endpoint.API.AUTH, form, null, async response=>{
      if(isResponseOk(response)){
        let user = response.data
        if(user.shouldChangePassword){
          let form = new FormData()
          form.append("userId", user.id)
          let res = await this.asyncPost(Endpoint.API.USER_FETCH_RESET_PASSWORD, form, null, true, true);
          if(isResponseOk(res)){
            let resetPassword = res.data
            window.location.href = ResetPasswordPage.ENDPOINT+"/"+resetPassword.token
            return
          }
        }
        storeData(Global.AUTHORIZATION, response.data.authorization)
        let res = await this.asyncGet(Endpoint.API.USER_ROLE_MENUS, {}, null, true, true);
        if(isResponseOk(res)){
          storeData(Global.MENUS, res.data)
          authProps.login(user)
        }
      }else{
        console.error(response.message)
        this.setState({
          message :response.message
        }, () => {
          this.forceUpdate()
        })
      }
    }, true, false)
  };

  fetchAppSetting=()=>{
    this.get(Endpoint.API.CONFIGURATION_CURRENT_ACTIVE, null, null, response=>{
      if(response.code===200){
        let configuration = response.data
        storeData(Global.CONFIGURATION, configuration)
        this.setState({
          configuration:configuration
        })
      }
    })
  }

  fetchConfigurationAdmin=()=>{
    this.get(Endpoint.API.CONFIGURATION_ADMIN, null, null, response=>{
      if(response.code===200){
        let configurationAdmin = response.data
        storeData(Global.CONFIGURATION_ADMIN, configurationAdmin)
        this.setState({
          configurationAdmin:configurationAdmin
        })
      }
    })
  }


  render() {
    let {username, password, rememberMe} = this.state
    const {} = this.props;
    let {message} = this.state

    return (
          <AuthConsumer>
            {
              (authProps) => (
                  <div>
                    {super.render()}
                    <div className="text-center" style={{marginTop:'50px'}}>
                      <Img
                          src={ApplicationIcons.Icons.smartSupplies}
                          className="rounded"
                          style={{width:'80%', height:'20%'}}
                          alt="logo"
                      />
                    </div>
                    <Form onSubmit={e=>this.handleSubmit(e,authProps)}>
                      <br/>
                      <FormGroup>
                        <Label for={label.Username}>{label.Username}</Label>
                        <Input
                            type='username'
                            placeholder={label.EnterUsername}
                            value={username}
                            onChange={e=>{this.setState({username:e.target.value})}}/>
                      </FormGroup>
                      <FormGroup>
                        <Label for={label.Password}>{label.Password}</Label>
                        <Input
                            type="password"
                            placeholder={label.EnterPassword}
                            value={password}
                            onChange={e=>{this.setState({password:e.target.value})}}
                            onKeyDown={e=>{
                              if(e.key==='Enter'){
                                e.preventDefault()
                                this.handleSubmit(e, authProps)
                              }
                            }} />
                      </FormGroup>
                      <Row>
                        <Col md={8}>
                          <InputGroup
                              className={"clickable"}
                              onClick={()=>{

                              }}>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <Input
                                    checked={rememberMe}
                                    addon type="checkbox"
                                    onChange={(e)=>{
                                      this.setState({rememberMe:!rememberMe})
                                    }}
                                    aria-label=""/>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder={label.KeepLoggedIn} className="border-0"/>
                          </InputGroup>
                        </Col>
                        <Col className="text-right">
                          <NavLink href={ForgetPasswordPage.ENDPOINT} className="border-0">{label.ForgetPassword}</NavLink>
                        </Col>
                      </Row>
                    <hr />
                      {message&&(
                          <>
                            <WarningLabel style={{textAlign:'center', fontSize:'14px'}} show={message} message={message}/>
                            <hr />
                          </>
                      )}

                      <Button
                          size="lg"
                          block
                          color={"secondary"}
                          onClick={e=>this.handleSubmit(e, authProps)}>
                        {label.LOGIN}
                      </Button>
                    </Form>

                  </div>
              )
            }
          </AuthConsumer>
    );
  }
}

AuthForm.propTypes = {
  message:PropTypes.string,
};

AuthForm.defaultProps = {
  message: '',
};

export default AuthForm;
