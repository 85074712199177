import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {CURRENCY_SYMBOL, DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {cloneObject, currencyFormat, isEmpty, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import label from 'na-utilities/src/label/Label'
import NumberInput from "../components/Widget/NumberInput";
import {Link} from "react-router-dom";
import ItemOption from "../components/Widget/ItemOption";
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class ProductPackagePage extends BasePage{
    constructor(props) {
        super(props);
        this.state = {
            products:[],
            productPackages : [],
            productPackage:{},
            cabinets : [],
            modalAddConfirm:false,
            modalDeleteConfirm:false,
            modal: false,
            ascending:true,
            sortir:'created',
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchProducts()
        this.fetchCabinets()
        this.fetchAll()
    }

    confirmSave = () =>{
        let {productPackage} = this.state
        if(!productPackage.product || !productPackage.product.id){
            alert(label.SelectProduct)
            return
        }
        if(!productPackage.cabinet || !productPackage.cabinet.id){
            alert(label.SelectCabinet)
            return
        }
        if(!productPackage.name){
            alert(label.EnterProductPackageName)
            return
        }
        if(!productPackage.monthPeriod || productPackage.monthPeriod === 0){
            alert(label.EnterMonthPeriod)
            return
        }
        if(!productPackage.suggestion || productPackage.suggestion === 0){
            alert(label.EnterSuggestion)
            return
        }
        if(!productPackage.price || productPackage.price === 0){
            alert(label.EnterPrice)
            return
        }
        this.setState({
            modalAddConfirm:true
        })
    }


    save = () => {
        let {productPackage} = this.state
        var formData = new FormData();

        if(!productPackage.id && !productPackage.active){
            productPackage.active = false ;
        }

        formData.append("productPackageJson", JSON.stringify(productPackage))
        this.setState({
            modalAddConfirm:false
        }, () => {
            this.post(Endpoint.API.PRODUCT_PACKAGE_SAVE, formData, null, res=>{
                if(res.code===200){
                    this.setState({
                        productPackage:{}
                    }, () => {
                        this.showDialog(label.Success, res.message)
                        this.fetchAll()
                    })
                }
            },  true, true)
        })
    }


    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }

    delete = () => {
        let {productPackage} = this.state
        this.setState({
            modalDeleteConfirm:false
        }, () => {
            this.get(Endpoint.API.PRODUCT_PACKAGE_DELETE+"/"+productPackage.id, null, null, res => {
                if(res.code===200){
                    this.setState({
                        productPackage:{}
                    }, () => {
                        this.fetchAll()
                    })
                }
            },true, true);
        })
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false
        })
    }

    fetchAll = () => {
        this.get(Endpoint.API.PRODUCT_PACKAGES, {
            params:{
                ascending:this.state.ascending,
                sortir:this.state.sortir,
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    productPackages : response.data
                })
            }
        }, true, true);
    }

    fetchProducts = () => {
        this.get(Endpoint.API.PRODUCTS, {
            params:{
                ascending:true,
                sortir:"suggestion",
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    products : response.data
                })
            }
        }, true, true);
    }

    fetchCabinets = () => {
        this.get(Endpoint.API.CABINETS, {
            params:{
                ascending:true,
                sortir:"name",
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    cabinets : response.data
                })
            }
        }, true, true);
    }



    refresh = (ascending, sortir) => (
        this.setState({
            ascending:ascending,
            sortir:sortir
        }, () => {
            this.fetchAll()
        })
    )


    render() {
        let {productPackage, productPackages, products, cabinets} = this.state
        let product = productPackage.product?productPackage.product:{}
        let cabinet = productPackage.cabinet?productPackage.cabinet:{}

        return (
            <Page
                title={label.ProductPackage}
                breadcrumbs={[{ name: label.ProductPackage, active: true }]}
                className="TablePage">
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalAddConfirm}
                    title={label.UpdateConfirmation}
                    message={label.UpdateConfirmation}
                    okCallback={this.save}
                    cancelCallback={this.closeDialog}/>
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message={label.DeleteConfirmationMessage}
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader>{label.ProductPackageDetail}</CardHeader>
                            <CardBody >
                                <Row>
                                    <Col md={3}>
                                        <Card>
                                            <CardHeader>{label.Form}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="code">{label.Code}</Label>
                                                            <Input
                                                                type="text"
                                                                name="code"
                                                                value={!isEmpty(productPackage.code)?productPackage.code:""}
                                                                disabled={true}
                                                                placeholder={label.GenerateAutomatically}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.Product}
                                                                objects={products}
                                                                default={product.id}
                                                                callback={(product)=>{
                                                                    productPackage.product = product
                                                                    this.setState({
                                                                        productPackage:productPackage
                                                                    })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.Cabinet}
                                                                objects={cabinets}
                                                                default={cabinet.id}
                                                                callback={(cabinet)=>{
                                                                    productPackage.cabinet = cabinet
                                                                    this.setState({
                                                                        productPackage:productPackage
                                                                    })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="name">{label.Name}</Label>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={!isEmpty(productPackage.name)?productPackage.name:""}
                                                                onChange={(e) =>{
                                                                    productPackage.name = e.target.value
                                                                    this.setState({
                                                                        productPackage:productPackage
                                                                    })
                                                                }}
                                                                placeholder={label.EnterProductPackageName}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="name">{label.Description}</Label>
                                                            <Input
                                                                type="textarea"
                                                                name="name"
                                                                value={!isEmpty(productPackage.description)?productPackage.description:""}
                                                                onChange={(e) =>{
                                                                    productPackage.description = e.target.value
                                                                    this.setState({
                                                                        productPackage:productPackage
                                                                    })
                                                                }}
                                                                placeholder={label.EnterDescription}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="monthPeriod">{label.MonthlyPeriod}</Label>
                                                            <NumberInput
                                                                name="monthPeriod"
                                                                className="form-control"
                                                                value={(productPackage)?productPackage.monthPeriod:""}
                                                                onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                                    e.preventDefault()
                                                                    productPackage.monthPeriod = floatvalue
                                                                    this.setState({
                                                                        productPackage: productPackage,
                                                                    })
                                                                }}
                                                                placeholder="0"
                                                                maxLength={2}>
                                                            </NumberInput>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="suggestion">{label.Suggestion}</Label>
                                                            <NumberInput
                                                                name="suggestion"
                                                                className="form-control"
                                                                value={(productPackage)?productPackage.suggestion:""}
                                                                onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                                    e.preventDefault()
                                                                    productPackage.suggestion = floatvalue
                                                                    this.setState({
                                                                        productPackage: productPackage,
                                                                    })
                                                                }}
                                                                placeholder="0"
                                                                maxLength={2}>
                                                            </NumberInput>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label for="price">{label.Price}</Label>
                                                            <NumberInput
                                                                name="price"
                                                                className="form-control"
                                                                value={(productPackage)?productPackage.price:""}
                                                                onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                                    e.preventDefault()
                                                                    productPackage.price = floatvalue
                                                                    this.setState({
                                                                        productPackage: productPackage,
                                                                    })
                                                                }}
                                                                placeholder="0"
                                                                maxLength={9}>
                                                            </NumberInput>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <ActiveOption
                                                                default={productPackage.published}
                                                                callback={(active)=>{
                                                                    productPackage.published = active
                                                                    this.setState({
                                                                        productPackage:productPackage
                                                                    })
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    productPackage:{}
                                                                })
                                                            )}>{label.Cancel}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>(
                                                                this.confirmSave()
                                                            )}> {
                                                                (productPackage && productPackage.id)?label.Update:label.Add
                                                            } </Button>

                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={9}>
                                        <Card body>
                                            <Row>
                                                <Col>
                                                    {label.SortBy} :
                                                    <UncontrolledButtonDropdown key={1}>
                                                        <DropdownToggle
                                                            caret
                                                            color="white"
                                                            className="text-capitalize m-1">
                                                            {
                                                                sortirMap(this.state.sortir)
                                                            }
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                                            <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                                            <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </Col>
                                                <Col>
                                                    {label.Sortir} :
                                                    <UncontrolledButtonDropdown key={2}>
                                                        <DropdownToggle
                                                            caret
                                                            color="white"
                                                            className="text-capitalize m-1">
                                                            {
                                                                this.state.ascending?label.Ascending:label.Descending
                                                            }
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                                            <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Table responsive hover>
                                                        <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>{label.Code}</th>
                                                            <th>{label.Name}</th>
                                                            <th>{label.Product}</th>
                                                            <th>{label.Month}</th>
                                                            <th>{label.View}</th>
                                                            <th>{label.Price}</th>
                                                            <th>{label.Status}</th>
                                                            <th>{label.Updated}</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            productPackages.map((item, index) =>(
                                                                <tr key={index}>
                                                                    <th scope="row">{++index}</th>
                                                                    <td>
                                                                        <Link
                                                                            to={"#"}
                                                                            onClick={(e) => {
                                                                                e.preventDefault()
                                                                                let productPackage = cloneObject(item)
                                                                                this.setState({
                                                                                    productPackage: productPackage
                                                                                })
                                                                            }}>
                                                                            {item.code}
                                                                        </Link>
                                                                    </td>
                                                                    <td>{item.name}</td>
                                                                    <td>{item.product.name+" - "+item.cabinet.name}</td>
                                                                    <td>{item.monthPeriod}</td>
                                                                    <td>{item.suggestion}</td>
                                                                    <td>{currencyFormat(item.price, CURRENCY_SYMBOL)}</td>
                                                                    <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.published}/></td>
                                                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Page>
        );
    }
}

export default Object.assign(ProductPackagePage, {ENDPOINT : "/productPackages"})
