import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY, DD_MM_YYYY_HH_MM_SS, PRODUCT_SUBSCRIBE_STATUS_COLOR} from '../utils/Global';
import SearchInput from '../components/SearchInput';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import {changeParam2, deleteParam, navigate, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import label from 'na-utilities/src/label/Label'
import CompanyDetailPage from "./CompanyDetailPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import SmartDeviceDetailPage from "./SmartDeviceDetailPage";
import ProductSubscribeDetailPage from "./ProductSubscribeDetailPage";
import UserDetailPage from "./UserDetailPage";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CompanySubscribeListPage from "./CompanySubscribeListPage";
import {IoMdEye} from "react-icons/all";

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class ProductSubscribePage extends BasePage{


  constructor(props) {
    super(props);
    this.state ={
        productSubscribes : [],
        productSubscribe:{},
        productSubscribeStatusId:undefined,
        productSubscribeStatuses: [],
        modalDeleteConfirm:false,
        ascending:false,
        sortir:'ps.created',
        search:"",
        page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
        key: queryString.parse(this.props.query).tab ? queryString.parse(this.props.query).tab : label.All,
        totalPage:0,
        totalElement:0,
        pageElement:0,
    }
  }

  componentDidMount() {
      super.componentDidMount();
      this.fetchAllStatus()
  }

    fetchAllStatus = () => {
        let {key} = this.state
        this.get(Endpoint.API.PRODUCT_SUBSCRIBE_STATUSES, null, null, response => {
            if (response.code === 200) {
                let productSubscribeStatuses = response.data
                this.fetchBadgeCount()
                productSubscribeStatuses.forEach((item, index) => {
                    this.fetchBadgeCount(item.id)
                })
                let productSubscribeStatusId = null
                productSubscribeStatuses.map((productSubscribeStatus)=>{
                    if (key == productSubscribeStatus.sortir) {
                        productSubscribeStatusId = productSubscribeStatus.id
                    }
                })
                this.setState({
                    productSubscribeStatusId:productSubscribeStatusId,
                    productSubscribeStatuses: productSubscribeStatuses
                }, () => {
                    this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
                })
            }
        }, true, true)
    }

    fetchBadgeCount(productSubscribeStatusId) {
        let {ascending, sortir, search} = this.state
        let params = {
            productSubscribeStatusId:productSubscribeStatusId,
            ascending:ascending,
            sortir:sortir,
            search:search,
            badgeCountMode:true
        }
        this.get(Endpoint.API.PRODUCT_SUBSCRIBES, {params: params}, null, (response) => {
            if (response && response.code == 200) {
                this.setState({
                    ['size-' + productSubscribeStatusId ? productSubscribeStatusId : "all"]: response.totalElement
                })
            }
        }, false, false);
    }
    fetchAll = (ascending, sortir, search, page, progressing) => {
        let {productSubscribeStatusId} = this.state
        this.get(Endpoint.API.PRODUCT_SUBSCRIBES, {
            params:{
                productSubscribeStatusId:productSubscribeStatusId,
                ascending:ascending,
                sortir:sortir,
                search:search,
                page: page-1
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    productSubscribes : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        },progressing, true);
    }

    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            let page = queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
            let key = queryString.parse(props.query).tab ? queryString.parse(props.query).tab : label.All
            let search = queryString.parse(props.query).search ? queryString.parse(props.query).search : ""
            let productSubscribeStatuses = this.state.productSubscribeStatuses?this.state.productSubscribeStatuses:[]
            let productSubscribeStatusId = null
            if (key === label.All) {
                productSubscribeStatusId = null
            } else {
                productSubscribeStatuses.map((productSubscribeStatus)=>{
                    if (key == productSubscribeStatus.sortir) {
                        productSubscribeStatusId = productSubscribeStatus.id
                    }
                })
            }
            this.setState({
                page: page,
                key: key,
                search: search,
                productSubscribeStatusId: productSubscribeStatusId
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
            })
        }
    }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  refreshProductSubscribe = (ascending, sortir, progressing) => (
      this.setState({
          ascending:ascending,
          sortir:sortir
      }, () => {
          this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, progressing)
      })
  )

    render() {
        var i = 0 ;
        let {productSubscribeStatuses} = this.state

    return (
        <Page
            title={label.ProductSubscribe}
            breadcrumbs={[{ name: label.ProductSubscribe, active: true }]}
            className="TablePage"
        >
            {super.render()}
          <Row key={1}>
            <Col>
              <Card className="mb-6">
                <CardHeader>{label.ProductSubscribe}</CardHeader>
                <CardBody>
                  <Row>
                        <Col md={3}>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        sortirMap(this.state.sortir)
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshProductSubscribe(this.state.ascending, "ps.code", true))}>{label.Code}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshProductSubscribe(this.state.ascending, "ps.created", true))}>{label.Created}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshProductSubscribe(this.state.ascending, "ps.updated", true))}>{label.Updated}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={3}>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        this.state.ascending?label.Ascending:label.Descending
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshProductSubscribe(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshProductSubscribe(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={4}>
                            <SearchInput
                                placeholder={label.TypeAndEnterToSearch}
                                value={this.state.search}
                                onChange={e=>{
                                    this.setState({
                                        search:e.target.value
                                    }, () => {
                                        if(this.state.search===''){
                                            deleteParam(this.props, 'page')
                                        }
                                    })
                                }}
                                onEnter={e=>{
                                    if(this.state.search!==''){
                                        deleteParam(this.props, 'page')
                                    }
                                }}
                            />
                        </Col>
                        <Col md={2}>
                        </Col>
                    </Row>
                </CardBody>
                  <CardBody>
                      <Tabs
                          activeKey={this.state.key}
                          onSelect={key => {
                              changeParam2(this.props, 'page', 1, 'tab', key)
                          }
                          }>
                          <Tab eventKey={label.All}
                               title={this.badgeTabLabel(label.All, undefined, "blue")}>
                              {this.renderProductSubscribeTable()}
                          </Tab>
                          {
                              productSubscribeStatuses.map((item, index) => (
                                  <Tab key={index} eventKey={item.id}
                                       title={this.badgeTabLabel(item.label, item.id, item.color)}>
                                      {this.renderProductSubscribeTable()}
                                  </Tab>
                              ))
                          }
                      </Tabs>
                  </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
    badgeTabLabel(label, productSubscribeStatusId, color) {
        let size = this.state['size-' + productSubscribeStatusId ? productSubscribeStatusId : "all"];
        if (!size || size == 0) {
            return (<span>{label}</span>)
        }
        return (
            <>
                <span>{label}</span>
                &nbsp;&nbsp;
                <span
                    style={{
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                        background: color ? color : 'red',
                        color: 'white',
                        borderRadius: '20%',
                        minWidth: '24px',
                        display: 'inline-block',
                        textAlign: 'center'
                    }}>
                    {size}
                </span>
            </>)
    }


    renderProductSubscribeTable = () => {
        return (
            <Card>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Code}</th>
                            <th>{label.Company}</th>
                            <th>{label.Creator}</th>
                            <th>{label.SmartDevice}</th>
                            <th>{label.ProductPackage}</th>
                            <th>{label.ValidFrom}</th>
                            <th>{label.ValidUntil}</th>
                            <th>{label.Status}</th>
                            <th>{label.Created}</th>
                            <th>{label.More}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.productSubscribes.map((item, index)=>(
                                <tr key={index}>
                                    <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                    <td><a href={ProductSubscribeDetailPage.ENDPOINT+"?id="+(item.id)}>{item.code}</a></td>
                                    <td><a href={CompanyDetailPage.ENDPOINT+"?id="+(item.company?item.company.id:undefined)}>{item.company.fullName}</a></td>
                                    <td><a href={UserDetailPage.ENDPOINT+"?id="+(item.user.id)}>{item.user.fullname}</a></td>
                                    <td><a href={SmartDeviceDetailPage.ENDPOINT+"?id="+(item.smartDevice?item.smartDevice.id:undefined)}>{item.smartDevice.deviceId}</a></td>
                                    <td>{item.productPackage.name}</td>
                                    <td>{parseDate(item.startDate, DD_MM_YYYY)}</td>
                                    <td>{parseDate(item.endDate, DD_MM_YYYY)}</td>
                                    <td><Button size="sm" disabled={true} style={{backgroundColor:item.productSubscribeStatus.color}}>{item.productSubscribeStatus.label}</Button></td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>
                                        <Button size="sm" onClick={() => {
                                            navigate(this.props, CompanySubscribeListPage.ENDPOINT+"?id="+item.company.id)
                                        }}>
                                            <IoMdEye/>&nbsp;{label.SeeAll}
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage}/>
            </Card>
        )
    }

};


export default Object.assign(ProductSubscribePage, {ENDPOINT : "/productSubscribes"})
