import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {APK_MAX_FILE_SIZE, BANNER_IMAGE_MAX_FILE_SIZE, DD_MM_YYYY_HH_MM_SS, FORCE_TO_UPDATES} from '../utils/Global';
import {allIsEmpty, cloneObject, fileSelector, isEmpty, parseDate, sortirMap} from 'na-utilities/src/utils/Utilities';
import {MdDelete, MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ItemOption from '../components/Widget/ItemOption';
import {Editor} from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


export default class ApplicationVersionPage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      applicationVersions: [],
      applicationVersion: {},
      modalAddConfirm: false,
      modalPublishConfirm: false,
      modal: false,
      ascending: true,
      sortir: 'created',
      provinces: [],
      cities: [],
      districts: [],
      thumbnailBlob: null,
      imageBlob: null,
      apkFile: {},
      apkFileBlob: undefined,
      forceToUpdate: false,
      descriptionEditorState: EditorState.createEmpty()
    }

  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))
    this.fetchAll()
  }

  confirmSave = () =>{
    var applicationVersion = this.state.applicationVersion
    if(applicationVersion.forceToUpdate===undefined || applicationVersion.forceToUpdate===null){
        applicationVersion.forceToUpdate = true
    }
    if(allIsEmpty(applicationVersion.title, applicationVersion.description, applicationVersion.versionCode, applicationVersion.versionName)){
      this.showDialog("Notice", "Please complete this form fields")
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }

  save = () => {
    let {applicationVersion, apkFileBlob} = this.state
    var formData = new FormData();
    formData.append("applicationVersionGson", JSON.stringify(applicationVersion))
    if(apkFileBlob){
      formData.append("multipartFile", apkFileBlob);
    }

    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Endpoint.API.APPLICATION_VERSION_SAVE_UPLOAD, formData, null, res => {
        if(res.code===200){
          this.successToast(res.message)
          this.setState({
            applicationVersion:{},
            descriptionEditorState:null,
            apkFile: {},
            apkFileBlob: undefined,
            forceToUpdate:FORCE_TO_UPDATES[0]
          }, () => {
            this.fetchAll()
          })
        }
      }, true, true);
    })
  }

  confirmDelete = () =>{
    this.setState({
      modalPublishConfirm:true
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalPublishConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Endpoint.API.APPLICATION_VERSIONS, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        let applicationVersions = response.data
        this.setState({
          applicationVersions : applicationVersions
        }, () => {
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )

  onDescriptionEditorStateChange= (descriptionEditorState) => {
    let applicationVersion = this.state.applicationVersion
    applicationVersion.description = draftToHtml(convertToRaw(descriptionEditorState.getCurrentContent()))
    this.setState({
      applicationVersion:applicationVersion,
      descriptionEditorState:descriptionEditorState,
    });
  }



  render() {
    let {apkFile, apkFileBlob, forceToUpdate, descriptionEditorState, applicationVersion} = this.state

    return (
        <Page
            title={label.ApplicationVersion}
            breadcrumbs={[{ name: label.ApplicationVersion, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message="Do you want to update this Application Version item to the list ?"
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col md={3}>
              <Card>
                <CardHeader>{label.FormCreation}</CardHeader>
                <CardBody>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="title">{label.Title}</Label>
                        <Input
                            type="text"
                            name="title"
                            value={!isEmpty(applicationVersion)&&!isEmpty(applicationVersion.title)?applicationVersion.title:""}
                            onChange={(e) =>{
                              if(applicationVersion!=null){
                                applicationVersion.title = e.target.value
                                this.setState({
                                  applicationVersion:applicationVersion
                                })
                              }
                            }}
                            placeholder={label.EnterTitle}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="versionCode">Version Code</Label>
                        <Input
                            type="text"
                            name="versionCode"
                            value={!isEmpty(applicationVersion)&& !isEmpty(applicationVersion.versionCode)?applicationVersion.versionCode:""}
                            onChange={(e) =>{
                              if(applicationVersion){
                                applicationVersion.versionCode = e.target.value.replace(/\D/,'')
                                this.setState({
                                  applicationVersion:applicationVersion
                                })
                              }
                            }}
                            placeholder="0"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="versionName">Version Name</Label>
                        <Input
                            type="text"
                            name="versionName"
                            value={!isEmpty(applicationVersion)&& !isEmpty(applicationVersion.versionName)?applicationVersion.versionName:""}
                            onChange={(e) =>{
                              if(applicationVersion){
                                applicationVersion.versionName = e.target.value
                                this.setState({
                                  applicationVersion:applicationVersion
                                })
                              }
                            }}
                            placeholder="1.0.0"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <ItemOption
                            title={"Force to update"}
                            objects={FORCE_TO_UPDATES}
                            default={forceToUpdate.id}
                            hideOptionLabel={true}
                            callback={(forceToUpdate)=>{
                              applicationVersion.forceToUpdate = forceToUpdate.name
                              this.setState({
                                applicationVersion:applicationVersion,
                                forceToUpdate:forceToUpdate
                              })
                            }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label for="file">{label.APKFile}</Label>
                        <Row>
                          <Col md={8}>
                            <Input
                                type="text"
                                name="file"
                                readOnly={true}
                                value={apkFile.name}
                            />
                          </Col>
                          <Col md={4}>
                            <Button onClick={() => {
                                fileSelector((file, text) =>{
                                  var url = URL.createObjectURL(file)
                                  this.downloadBlobFile(url, (blobFile)=>{
                                    apkFileBlob = blobFile
                                    applicationVersion.fileName = file.name
                                    this.setState({apkFileBlob, apkFile:file, applicationVersion})
                                  })
                                }, "application/vnd.android.package-archive", APK_MAX_FILE_SIZE).click()
                            }}>{label.Upload}</Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Card>
                        <CardHeader>{label.Description}</CardHeader>
                        <CardBody>
                          <Editor
                              editorState={descriptionEditorState}
                              onEditorStateChange={this.onDescriptionEditorStateChange}/>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col>
                      <ButtonGroup className="float-left">
                        <Button color="danger" onClick={e => (
                            this.setState({
                              applicationVersion:{}
                            })
                        )}>{label.Cancel}</Button>
                      </ButtonGroup>
                    </Col>
                    <Col>
                      <ButtonGroup className="float-right">
                        <Button color="primary" onClick={e=>(
                            this.confirmSave()
                        )}> {
                          (this.state.applicationVersion!=null && this.state.applicationVersion.id!=null)?label.Update:label.Add
                        } </Button>

                      </ButtonGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      {label.SortBy} :
                      <UncontrolledButtonDropdown key={1}>
                        <DropdownToggle
                            caret
                            color="white"
                            className="text-capitalize m-1">
                          {
                            sortirMap(this.state.sortir)
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                    <Col>
                      {label.Sortir} :
                      <UncontrolledButtonDropdown key={2}>
                        <DropdownToggle
                            caret
                            color="white"
                            className="text-capitalize m-1">
                          {
                            this.state.ascending?label.Ascending:label.Descending
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive hover>
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>{label.Title}</th>
                          <th>Version Code</th>
                          <th>Force Update</th>
                          <th>Link</th>
                          <th>{label.Created}</th>
                          <th>{label.Updated}</th>
                          <th>Edit / Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          this.state.applicationVersions.map((item, index) =>(
                              <tr key={index}>
                                <th scope="row">{++index}</th>
                                <td>{item.title}</td>
                                <td>{item.versionCode}</td>
                                <td>{item.forceToUpdate?"TRUE":"FALSE"}</td>
                                <td><a href={item.completeUrl} target="_blank">Link</a></td>
                                <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                <td>
                                  <Button color="secondary" onClick={e=>{
                                    let descriptionEditorState = this.state.descriptionEditorState
                                    if(item.description){
                                      const descriptionContentBlock = htmlToDraft(item.description);
                                      if (descriptionContentBlock) {
                                        let contentState = ContentState.createFromBlockArray(descriptionContentBlock.contentBlocks);
                                        descriptionEditorState = EditorState.createWithContent(contentState);
                                      }else{
                                        descriptionEditorState = EditorState.createEmpty()
                                      }
                                    }else{
                                      descriptionEditorState = EditorState.createEmpty()
                                    }
                                    let forceToUpdate = {}
                                    FORCE_TO_UPDATES.forEach(value => {
                                      if(item.forceToUpdate===value.name){
                                        forceToUpdate = value ;
                                      }
                                    })
                                    this.setState({
                                      applicationVersion:cloneObject(item),
                                      descriptionEditorState:descriptionEditorState,
                                      forceToUpdate:forceToUpdate,
                                    })
                                  }}>
                                    <MdEdit/>
                                  </Button>
                                  &nbsp;
                                  <Button color="danger" onClick={e=>{
                                    this.openConfirmDialog(label.Confirmation, "Do you want to delete this form version from the list", ()=>{
                                      this.get(Endpoint.API.APPLICATION_VERSION_DELETE, {params:{id:item.id}}, null, response=>{
                                        if(response.code === 200){
                                          this.setState({
                                            applicationVersion:{},
                                            descriptionEditorState:null,
                                            forceToUpdate:FORCE_TO_UPDATES[0]
                                          }, () => {
                                            this.fetchAll()
                                          })
                                        }
                                      }, true,true)
                                    })
                                  }}>
                                    <MdDelete/>
                                  </Button>
                                </td>
                              </tr>
                          ))
                        }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}
