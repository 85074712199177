import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table,} from 'reactstrap';
import productBanner from 'assets/img/banner/rack.png';
import Page from '../components/Page';
import ActiveOption from '../components/Widget/ActiveOption';
import ItemOption from '../components/Widget/ItemOption';
import Global, {BANNER_IMAGE_MAX_FILE_SIZE, MIME_JPEG,} from '../utils/Global';
import {imageSelector, isEmpty, isResponseOk, slugBuilder} from "na-utilities/src/utils/Utilities";
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import {getData} from '../utils/StorageUtil';

import ImageCropper from '../components/modal/ImageCropper';

import {ContentState, convertToRaw, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import WarningLabel from '../components/Widget/WarningLabel';
import DateInput from '../components/Widget/DateInput';
import NumberInput from '../components/Widget/NumberInput';
import Checkbox from '../components/Checkbox';
import label from 'na-utilities/src/label/Label'
import sanitizeHtml from 'sanitize-html';
import MyCardImage from "../components/Card/MyCardImage";
import {MdAdd, MdDelete} from "react-icons/md";
import Endpoint from "na-utilities/src/api/Endpoint";

const PRODUCT = {autoSlug:true}

export let PERIOD_TYPES = [
    {
        id:1,
        name:"NEVER_END",
        label:label.NeverEnd,
    },
    {
        id:2,
        name:"PERIODIC",
        label:label.Periodic,
    }
]

export let PRODUCT_ORDER_LIMIT_TYPES = [
    {
        id:1,
        name:"NO_LIMIT",
        label:label.NoLimit,
    },
    {
        id:2,
        name:"LIMITED",
        label:label.Limited,
    }
]


class ProductDetailPage extends BasePage{
    successToast(message) {
        super.successToast(message);
    }

    constructor(props) {
        super(props);
        let state = this.state
        state.product = PRODUCT
        state.periodType = PERIOD_TYPES[0]
        state.inspectionLimitType = PRODUCT_ORDER_LIMIT_TYPES[0]
        state.productCategories = []
        state.cabinets = []
        state.iconPhoto  = null
        state.productIdQuery = queryString.parse(this.props.query).id
        state.productCategory = {}
        state.modalAddConfirm = false
        state.modalDeleteConfirm = false
        state.inventories = []
        state.inventory = null
        state.ascending = true
        state.sortir = 'created'
        state.quantityModal = false
        state.page = queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1
        state.totalPage = 0
        state.totalElement = 0
        state.pageElement = 0
        state.scopeEditorState = EditorState.createEmpty()
        state.referenceDocumentEditorState = EditorState.createEmpty()
        state.testingEquipmentAndAccessoriesEditorState = EditorState.createEmpty()
        state.slugAlreadyUsed = false ;
        state.validation = {}
        state.systemSpecifications = []
        state.components = []
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchCabinets()
        this.fetchModelDetail(this.state.productIdQuery)
        this.fetchAllCategories()

    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                // this.fetchAllInventories(this.state.product.id, this.state.ascending, this.state.sortir, true)
            })
        }
    }

    onScopeEditorStateChange= (scopeEditorState) => {
        let product = this.state.product
        let validation = this.state.validation
        product.scope = draftToHtml(convertToRaw(scopeEditorState.getCurrentContent()))
        validation.scope = undefined
        this.setState({
            product:product,
            scopeEditorState:scopeEditorState,
            validation:validation
        });
    }

    onReferenceDocumentEditorStateChange= (referenceDocumentEditorState) => {
        let product = this.state.product
        let validation = this.state.validation
        product.referenceDocument = draftToHtml(convertToRaw(referenceDocumentEditorState.getCurrentContent()))
        validation.referenceDocument = undefined
        this.setState({
            product:product,
            referenceDocumentEditorState:referenceDocumentEditorState,
        });
    }

    onTestingEquipmentAndAccessoriesEditorStateChange= (testingEquipmentAndAccessoriesEditorState) => {
        let product = this.state.product
        let validation = this.state.validation
        product.testingEquipmentAndAccessories = draftToHtml(convertToRaw(testingEquipmentAndAccessoriesEditorState.getCurrentContent()))
        validation.testingEquipmentAndAccessories = undefined
        this.setState({
            product:product,
            testingEquipmentAndAccessoriesEditorState:testingEquipmentAndAccessoriesEditorState,
        });
    }


    confirmSave = () =>{
        var product = this.state.product
        var validation = this.state.validation?this.state.validation:{}
        var iconPhoto = this.state.iconPhoto
        if(isEmpty(product.productName)){
            validation.productName = label.EnterModelName
            this.showDialog(label.Warning, label.EnterModelName)
            this.setState({
                validation:validation
            })
            return ;
        }
        if(isEmpty(product.systemType)){
            validation.systemType = label.EnterSystemType
            this.showDialog(label.Warning, label.EnterSystemType)
            this.setState({
                validation:validation
            })
            return ;
        }

        if(isEmpty(product.partName)){
            validation.partName = label.EnterPartName
            this.showDialog(label.Warning, label.EnterPartName)
            this.setState({
                validation:validation
            })
            return ;
        }

        if(isEmpty(product.pn)){
            validation.pn = label.EnterPn
            this.showDialog(label.Warning, label.EnterPn)
            this.setState({
                validation:validation
            })
            return ;
        }

        if(isEmpty(product.acInput)){
            validation.acInput = label.EnterACInput
            this.showDialog(label.Warning, label.EnterACInput)
            this.setState({
                validation:validation
            })
            return ;
        }

        if(isEmpty(product.frequency)){
            validation.frequency = label.EnterFrequency
            this.showDialog(label.Warning, label.EnterFrequency)
            this.setState({
                validation:validation
            })
            return ;
        }

        if(isEmpty(product.acInputCurrent)){
            validation.acInputCurrent = label.EnterACInputCurrent
            this.showDialog(label.Warning, label.EnterACInputCurrent)
            this.setState({
                validation:validation
            })
            return ;
        }

        if(isEmpty(product.dcOutput)){
            validation.dcOutput = label.EnterDCOutput
            this.showDialog(label.Warning, label.EnterDCOutput)
            this.setState({
                validation:validation
            })
            return ;
        }

        if(isEmpty(product.polarity)){
            validation.polarity = label.EnterPolarity
            this.showDialog(label.Warning, label.EnterPolarity)
            this.setState({
                validation:validation
            })
            return ;
        }

        // if(isEmpty(product.coRef)){
        //     validation.coRef = label.EnterCORef
        //     this.showDialog(label.Warning, label.EnterCORef)
        //     this.setState({
        //         validation:validation
        //     })
        //     return ;
        // }

        if(this.state.slugAlreadyUsed){
            this.showDialog(label.Warning, label.SlugAlreadyBeenUsed)
            return ;
        }

        if(product.inspectionLimitType===PRODUCT_ORDER_LIMIT_TYPES[1].name){
            if(product.inspectionLimitPerUser && product.inspectionLimitPerUser>product.inspectionLimitCount){
                validation.inspectionLimitPerUser = label.MaxUsagePerUserCannotBeLargerThanMaxUsageGeneral
                this.showDialog(label.Warning, label.MaxUsagePerUserCannotBeLargerThanMaxUsageGeneral)
                this.setState({
                    validation:validation
                })
                return;
            }
            if(product.inspectionLimitCount && product.inspectionLimitCount<product.inspectionCount){
                validation.inspectionLimitCount = label.MaxUsageCannotBeSmallerThanCurrentUsage
                this.showDialog(label.Warning, label.MaxUsageCannotBeSmallerThanCurrentUsage)
                this.setState({
                    validation:validation
                })
                return;
            }
        }
        if(isEmpty(product.description) || product.description.trim() === '<p></p>'){
            validation.description = label.PleaseEnterDescription
            this.showDialog(label.Warning, label.PleaseEnterDescription)
            this.setState({
                validation:validation
            })
            return ;
        }
        if(isEmpty(product.referenceDocument) || product.referenceDocument.trim() === '<p></p>'){
            validation.referenceDocument = label.PleaseEnterReferenceDocument
            this.showDialog(label.Warning, label.PleaseEnterReferenceDocument)
            this.setState({
                validation:validation
            })
            return ;
        }
        if(isEmpty(product.productCategory)){
            validation.productCategory = label.EnterProductPackageName
            this.showDialog(label.Warning, label.EnterProductPackageName)
            this.setState({
                validation:validation
            })
            return ;
        }
        this.setState({
            modalAddConfirm:true
        })
    }

    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }

    delete = () => {
        if(this.state.inventory!=null){
            this.setState({
                modalDeleteConfirm:false
            }, () => {
                this.get(Endpoint.API.INVENTORY_DELETE+"/"+this.state.inventory.id, null, null, res => {
                    if(res.code===200){
                        this.setState({
                            inventory:null
                        }, () => {
                            // this.fetchAllInventories(this.state.product.id, this.state.ascending, this.state.sortir, true)
                        })
                    }
                }, true, true);
            })
        }
    }

    saveUpload = (product, systemSpecifications, components) => {
        console.log("####### ="+JSON.stringify(systemSpecifications))
        var formData = new FormData();
        let newData = !product.id
        let inspectionLimitType = this.state.inspectionLimitType
        product.inspectionLimitType = inspectionLimitType.name
        let periodType = this.state.periodType
        product.periodType = periodType.name
        product.systemSpecifications = systemSpecifications
        product.components = components

        formData.append("productDtoGson", JSON.stringify(product))
        formData.append("multipartFile", this.state.imageBlob);
        this.setState({
            modalAddConfirm:false
        }, () => {
            this.post(Endpoint.API.PRODUCT_SAVE_UPLOAD, formData, null, res=>{
                if(res.code===200){
                    this.successToast(label.SuccessUpdatingData)
                    let product = res.data
                    if(newData){
                        this.props.history.goBack()
                        window.location.href = ProductDetailPage.ENDPOINT+"?id="+product.id
                    }else{
                        // window.location.reload()
                        this.setState({
                            product : product
                        }, () => {
                            this.fetchModelDetail(product.id)
                        })

                    }

                }
            },  true, true)
        })
    }

    async validateSlug (){
        let {product} = this.state
        let response = await this.asyncGet(Endpoint.API.PRODUCT_VALIDATE_SLUG+"/"+product.slug, {params:{id:product.id}}, false, false);
        if(isResponseOk(response)){
            let slugAlreadyUsed = response.data===true
            this.setState({slugAlreadyUsed:slugAlreadyUsed})
        }
    }


    fetchModelDetail = (id) => {
        if(id!=null){
            this.get(Endpoint.API.PRODUCT, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let product = response.data
                    let scopeEditorState = this.state.scopeEditorState
                    if(product.description){
                        const descriptionContentBlock = htmlToDraft(sanitizeHtml(product.description));
                        if (descriptionContentBlock) {
                            let contentState = ContentState.createFromBlockArray(descriptionContentBlock.contentBlocks);
                            scopeEditorState = EditorState.createWithContent(contentState);
                        }else{
                            scopeEditorState = EditorState.createEmpty()
                        }
                    }else{
                        scopeEditorState = EditorState.createEmpty()
                    }
                    let referenceDocumentEditorState = this.state.referenceDocumentEditorState
                    if(product.referenceDocument){
                        const referenceDocumentContentBlock = htmlToDraft(sanitizeHtml(product.referenceDocument));
                        if (referenceDocumentContentBlock) {
                            let contentState = ContentState.createFromBlockArray(referenceDocumentContentBlock.contentBlocks);
                            referenceDocumentEditorState = EditorState.createWithContent(contentState);
                        }else{
                            referenceDocumentEditorState = EditorState.createEmpty()
                        }
                    }else{
                        referenceDocumentEditorState = EditorState.createEmpty()
                    }

                    let testingEquipmentAndAccessoriesEditorState = this.state.testingEquipmentAndAccessoriesEditorState
                    if(product.testingEquipmentAndAccessories){
                        const testingEquipmentAndAccessoriesContentBlock = htmlToDraft(sanitizeHtml(product.testingEquipmentAndAccessories));
                        if (testingEquipmentAndAccessoriesContentBlock) {
                            let contentState = ContentState.createFromBlockArray(testingEquipmentAndAccessoriesContentBlock.contentBlocks);
                            testingEquipmentAndAccessoriesEditorState = EditorState.createWithContent(contentState);
                        }else{
                            testingEquipmentAndAccessoriesEditorState = EditorState.createEmpty()
                        }
                    }else{
                        testingEquipmentAndAccessoriesEditorState = EditorState.createEmpty()
                    }


                    let periodType = this.state.periodType?this.state.periodType:PERIOD_TYPES[0]
                    if(product.periodType){
                        PERIOD_TYPES.forEach(value => {
                            if(value.name===product.periodType){
                                periodType=value
                            }
                        })
                    }
                    let inspectionLimitType = this.state.inspectionLimitType?this.state.inspectionLimitType:PRODUCT_ORDER_LIMIT_TYPES[0]
                    if(product.inspectionLimitType){
                        PRODUCT_ORDER_LIMIT_TYPES.forEach(value => {
                            if(value.name===product.inspectionLimitType){
                                inspectionLimitType = value ;
                            }
                        })
                    }
                    let systemSpecifications = product.systemSpecifications
                    let components = product.components
                    this.setState({
                        product:product,
                        systemSpecifications:systemSpecifications,
                        components:components,
                        productCategory:product.productCategory,
                        iconPhoto:product.iconPhotoUrl,
                        periodType:periodType,
                        inspectionLimitType:inspectionLimitType,
                        scopeEditorState:scopeEditorState,
                        referenceDocumentEditorState:referenceDocumentEditorState,
                        testingEquipmentAndAccessoriesEditorState:testingEquipmentAndAccessoriesEditorState,
                    }, () => {
                        this.fetchAllCategories()
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }


    fetchAllCategories = () =>{
        let product = this.state.product?this.state.product:{}
        let type = product.id?product.type:null
        this.get(Endpoint.API.PRODUCT_CATEGORIES, {
            params:{
                active:true,
                type : type,
            }
        }, null, res =>{
            if(res.code === 200){
                this.setState({
                    productCategories : res.data
                })
            }
        }, true, true);
    }

    fetchCabinets = () => {
        this.get(Endpoint.API.CABINETS, {
            params:{
                page: this.state.page-1,
                ascending:this.state.ascending,
                sortir:this.state.sortir,
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    cabinets : response.data,
                    totalPage: response.totalPage,
                    totalElement: response.totalElement,
                    pageElement: response.pageElement,

                })
            }
        }, true, true);
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }

    refreshInventory = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir
        }, () => {
            // this.fetchAllInventories(this.state.product.id, this.state.ascending, this.state.sortir, progressing)
        })
    )

    openQuantityModal=()=>{
        this.setState({
            quantityModal:true
        })
    }
    closeQuantityModal=()=>{
        this.setState({
            quantityModal:false
        })
    }


    render() {
        let { components, systemSpecifications } = this.state
        var ids = [];
        var labels = [];
        this.state.productCategories.map((item, index)=>{
            ids.push(item.id);
            labels.push(item.name);
        })

        let configuration = getData(Global.CONFIGURATION)
        let aspect = 10/10

        let scopeEditorState = this.state.scopeEditorState
        let referenceDocumentEditorState = this.state.referenceDocumentEditorState
        let testingEquipmentAndAccessoriesEditorState = this.state.testingEquipmentAndAccessoriesEditorState

        let product = this.state.product?this.state.product:PRODUCT

        let tags = this.state.tags?this.state.tags:[]
        let periodType = this.state.periodType?this.state.periodType:{}
        let inspectionLimitType = this.state.inspectionLimitType?this.state.inspectionLimitType:{}
        let validation = this.state.validation?this.state.validation:{}

        return (
            <Page
                title={label.ModelDetail}
                breadcrumbs={[{ name: label.ModelDetail, active: true }]}>
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalAddConfirm}
                    title={label.UpdateConfirmation}
                    message={label.UpdateConfirmationMessage}
                    okCallback={()=>{
                        this.saveUpload(product, systemSpecifications, components)
                    }}
                    cancelCallback={this.closeDialog}/>
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message={label.DeleteConfirmationMessage}
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader>{label.Model}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={8}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="name">{label.ModelName}</Label>
                                                    <Input
                                                        type="text"
                                                        name="productName"
                                                        value={(product&&product.productName)?product.productName:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.productName = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterModelName}
                                                    />
                                                    <WarningLabel show={!product.productName} message={label.EnterModelName}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="name">{label.SystemType}</Label>
                                                    <Input
                                                        type="text"
                                                        name="title"
                                                        value={(product&&product.systemType)?product.systemType:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.systemType = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterSystemType}
                                                    />
                                                    <WarningLabel show={!product.systemType} message={label.EnterSystemType}/>
                                                </FormGroup>
                                            </Col>

                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="name">{label.Slug}</Label>
                                                    <Input
                                                        type="text"
                                                        name="slug"
                                                        value={!isEmpty(product.slug)?product.slug:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product){
                                                                product.slug = slugBuilder(e.target.value)
                                                                this.setState({
                                                                    product:product
                                                                }, async ()  => {
                                                                    await this.validateSlug()
                                                                })
                                                            }
                                                        }}
                                                        readOnly={product.autoSlug}
                                                    />
                                                    <WarningLabel show={product.id && !product.slug } message={label.EnterSlug}/>
                                                    <WarningLabel show={this.state.slugAlreadyUsed} message={label.SlugAlreadyBeenUsed}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="autoSlug">{label.AutoSlug}</Label>
                                                    <Checkbox checked={product.autoSlug} onChange={(checked) => {
                                                        product.autoSlug = checked
                                                        if(checked){
                                                            product.slug = slugBuilder(product.title)
                                                        }else{

                                                        }
                                                        this.setState({
                                                            product:product
                                                        }, async ()  => {
                                                            await this.validateSlug()
                                                        })
                                                    }} formMode={true}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="partName">{label.PartName}</Label>
                                                    <Input
                                                        type="text"
                                                        name="partName"
                                                        value={(product&&product.partName)?product.partName:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.partName = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterPartName}
                                                    />
                                                    <WarningLabel show={!product.partName} message={label.EnterPartName}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="pn">{label.PN}</Label>
                                                    <Input
                                                        type="text"
                                                        name="pn"
                                                        value={(product&&product.pn)?product.pn:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.pn = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterPn}
                                                    />
                                                    <WarningLabel show={!product.pn} message={label.EnterPn}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="pn">{label.ACInput}</Label>
                                                    <Input
                                                        type="text"
                                                        name="acInput"
                                                        value={(product&&product.acInput)?product.acInput:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.acInput = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterACInput}
                                                    />
                                                    <WarningLabel show={!product.acInput} message={label.EnterACInput}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="frequency">{label.Frequency}</Label>
                                                    <Input
                                                        type="text"
                                                        name="frequency"
                                                        value={(product&&product.frequency)?product.frequency:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.frequency = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterFrequency}
                                                    />
                                                    <WarningLabel show={!product.frequency} message={label.EnterFrequency}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="pn">{label.ACInputCurrent}</Label>
                                                    <Input
                                                        type="text"
                                                        name="acInputCurrent"
                                                        value={(product&&product.acInputCurrent)?product.acInputCurrent:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.acInputCurrent = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterACInputCurrent}
                                                    />
                                                    <WarningLabel show={!product.acInputCurrent} message={label.EnterACInputCurrent}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="pn">{label.DCOutput}</Label>
                                                    <Input
                                                        type="text"
                                                        name="dcOutput"
                                                        value={(product&&product.dcOutput)?product.dcOutput:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.dcOutput = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterDCOutput}
                                                    />
                                                    <WarningLabel show={!product.dcOutput} message={label.EnterDCOutput}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="polarity">{label.Polarity}</Label>
                                                    <Input
                                                        type="text"
                                                        name="polarity"
                                                        value={(product&&product.polarity)?product.polarity:""}
                                                        onChange={(e) =>{
                                                            e.preventDefault()
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.polarity = e.target.value
                                                                this.setState({
                                                                    product:product
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterPolarity}
                                                    />
                                                    <WarningLabel show={!product.polarity} message={label.EnterPolarity}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                {/*<FormGroup>*/}
                                                {/*    <Label for="pn">{label.CORef}</Label>*/}
                                                {/*    <Input*/}
                                                {/*        type="text"*/}
                                                {/*        name="coRef"*/}
                                                {/*        value={(product&&product.coRef)?product.coRef:""}*/}
                                                {/*        onChange={(e) =>{*/}
                                                {/*            e.preventDefault()*/}
                                                {/*            let product = this.state.product*/}
                                                {/*            if(product!=null){*/}
                                                {/*                product.coRef = e.target.value*/}
                                                {/*                this.setState({*/}
                                                {/*                    product:product*/}
                                                {/*                })*/}
                                                {/*            }*/}
                                                {/*        }}*/}
                                                {/*        placeholder={label.EnterCORef}*/}
                                                {/*    />*/}
                                                {/*    <WarningLabel show={!product.coRef} message={label.EnterCORef}/>*/}
                                                {/*</FormGroup>*/}
                                            </Col>
                                        </Row>
                                        <FormGroup>
                                            <Label for="name">{label.Description}</Label>
                                            <Input
                                                type="textarea"
                                                name="description"
                                                value={(product&& product.description)?product.description:""}
                                                style={{height:'75px', verticalAlign:'text-top'}}
                                                align={"top"}
                                                onChange={(e) =>{
                                                    e.preventDefault()
                                                    let itm = this.state.product
                                                    if(itm!=null){
                                                        itm.description = e.target.value
                                                        this.setState({
                                                            product:itm
                                                        })
                                                    }
                                                }}
                                                placeholder={label.EnterDescription}
                                            />
                                            <WarningLabel show={!product.description} message={label.EnterDescription}/>
                                        </FormGroup>
                                        <FormGroup>
                                            <ItemOption
                                                title={label.Period}
                                                objects={PERIOD_TYPES}
                                                fieldForLabel={"label"}
                                                default={periodType?periodType.id:null}
                                                hideOptionLabel={true}
                                                callback={(periodType)=>{
                                                    let product = this.state.product
                                                    if(product!=null){
                                                        product.periodType = periodType.name
                                                        this.setState({
                                                            product:product,
                                                            periodType:periodType
                                                        })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                        {
                                            (product.periodType===PERIOD_TYPES[1].name)?(
                                                <Row>
                                                    <Col>
                                                        <Label for="startPeriod">{label.StartPeriod}</Label>
                                                        <DateInput
                                                            id="startPeriod"
                                                            maxdate={product.endPeriod}
                                                            mindate={new Date()}
                                                            value={product.startPeriod}
                                                            onChange={(e) =>{
                                                                let value = e.target.value
                                                                product.startPeriod = value
                                                                this.setState({
                                                                    product : product
                                                                })
                                                            }}
                                                            placeholder={label.EnterStartPeriod}>
                                                        </DateInput>
                                                    </Col>
                                                    <Col>
                                                        <Label for="endPeriod">{label.EndPeriod}</Label>
                                                        <DateInput
                                                            id="endPeriod"
                                                            maxdate={null}
                                                            mindate={product.startPeriod}
                                                            value={product.endPeriod}
                                                            onChange={(e) =>{
                                                                let value = e.target.value
                                                                product.endPeriod = value
                                                                this.setState({
                                                                    product : product
                                                                })
                                                            }}
                                                            placeholder={label.EnterEndPeriod}>
                                                        </DateInput>
                                                    </Col>
                                                </Row>
                                            ):(null)
                                        }
                                        <FormGroup>
                                            <ItemOption
                                                title={label.MaximumInspectionCanBeApplied}
                                                objects={PRODUCT_ORDER_LIMIT_TYPES}
                                                fieldForLabel={"label"}
                                                default={inspectionLimitType?inspectionLimitType.id:null}
                                                hideOptionLabel={true}
                                                callback={(inspectionLimitType)=>{
                                                    let product = this.state.product
                                                    if(product!=null){
                                                        product.inspectionLimitType = inspectionLimitType.name
                                                        this.setState({
                                                            product:product,
                                                            inspectionLimitType:inspectionLimitType
                                                        })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                        {
                                            product.inspectionLimitType===PRODUCT_ORDER_LIMIT_TYPES[1].name&&(
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="inspectionLimitCount">{label.MaximumApplicationUsage}</Label>
                                                            <NumberInput
                                                                name="inspectionLimitCount"
                                                                className="form-control"
                                                                value={!isEmpty(product.inspectionLimitCount)?product.inspectionLimitCount:""}
                                                                onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                                    e.preventDefault()
                                                                    product.inspectionLimitCount = floatvalue
                                                                    validation.inspectionLimitCount = undefined
                                                                    this.setState({
                                                                        product: product,
                                                                        validation:validation
                                                                    })
                                                                }}
                                                                placeholder="0"
                                                                maxLength={15}>
                                                            </NumberInput>
                                                            <WarningLabel show={validation.inspectionLimitCount} message={validation.inspectionLimitCount}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="inspectionCount">{label.CurrentApplicationUsage}</Label>
                                                            <NumberInput
                                                                name="inspectionCount"
                                                                className="form-control"
                                                                value={!isEmpty(product.inspectionCount)?product.inspectionCount:""}
                                                                placeholder="0"
                                                                maxLength={15}
                                                                disabled={true}>
                                                            </NumberInput>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <ItemOption
                                                        title={label.SelectCategory}
                                                        objects={this.state.productCategories}
                                                        default={this.state.productCategory?this.state.productCategory.id:null}
                                                        callback={(productCategory)=>{
                                                            let prd = this.state.product
                                                            if(prd!=null){
                                                                prd.productCategory = productCategory
                                                                this.setState({
                                                                    product:prd,
                                                                    productCategory:productCategory,
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <WarningLabel show={!product.productCategory} message={label.SelectCategory}/>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <ItemOption
                                                        title={label.Cabinet}
                                                        objects={this.state.cabinets}
                                                        default={product.cabinet?product.cabinet.id:null}
                                                        callback={(cabinet)=>{
                                                            let product = this.state.product
                                                            if(product!=null){
                                                                product.cabinet = cabinet
                                                                validation.cabinet = undefined
                                                                this.setState({
                                                                    product:product,
                                                                    cabinet:cabinet,
                                                                    validation:validation
                                                                })
                                                            }
                                                        }}
                                                    />
                                                    <WarningLabel show={validation.cabinet} message={validation.cabinet}/>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <ActiveOption
                                                        label={label.Publication}
                                                        trueLabel={label.Published}
                                                        falseLabel={label.Unpublished}
                                                        callback={(active)=>{
                                                            let prd = this.state.product
                                                            if(prd!=null){
                                                                prd.published = active
                                                                this.setState({
                                                                    product:prd
                                                                })
                                                            }

                                                        }}
                                                        default={product?product.published:false}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={4}>
                                        <Card>
                                            <CardBody>
                                                <MyCardImage
                                                    top
                                                    src={
                                                    this.state.iconPhoto?this.state.iconPhoto:productBanner}
                                                    onError={(elm)=>this.defaultImage(elm, productBanner)}
                                                    onClick={e=>{
                                                    imageSelector(file=>{
                                                        this.setState({
                                                            iconPhoto:file,
                                                            cropperModal:true
                                                        })
                                                    }, MIME_JPEG, BANNER_IMAGE_MAX_FILE_SIZE).click()
                                                }}
                                                onError={(elm)=>this.defaultImage(elm, productBanner)}/>
                                                <br/>
                                                <br/>
                                                <span>{label.ModelIconRatio}</span>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={label.UseImageTypeInstruction("jpeg", BANNER_IMAGE_MAX_FILE_SIZE)}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    product:null,
                                                                    iconPhoto:null
                                                                })
                                                            )}>{label.Cancel}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        iconPhoto:file,
                                                                        cropperModal:true
                                                                    })
                                                                }, MIME_JPEG, BANNER_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="inspectionLimitPerUser">{label.MaxInspectionPerUserDefaultUnlimitedPlaceholder}</Label>
                                            <NumberInput
                                                name="inspectionLimitPerUser"
                                                className="form-control"
                                                value={!isEmpty(product.inspectionLimitPerUser)?product.inspectionLimitPerUser:""}
                                                placeholder={label.MaxInspectionPerUserDefaultUnlimitedPlaceholder}
                                                maxLength={9}
                                                onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                    e.preventDefault()
                                                    product.inspectionLimitPerUser = floatvalue
                                                    validation.inspectionLimitPerUser = undefined
                                                    this.setState({
                                                        product: product,
                                                        validation:validation
                                                    })
                                                }}>
                                            </NumberInput>
                                            <WarningLabel show={validation.inspectionLimitPerUser} message={validation.inspectionLimitPerUser}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="suggestion">{label.Suggestion}</Label>
                                            <NumberInput
                                                name="suggestion"
                                                className="form-control"
                                                value={!isEmpty(product.suggestion)?product.suggestion:""}
                                                onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                    e.preventDefault()
                                                    product.suggestion = floatvalue
                                                    this.setState({
                                                        product: product
                                                    })
                                                }}
                                                placeholder="0"
                                                maxLength={15}>
                                            </NumberInput>
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="suggestion">{label.SizeOfRectifierControllerSerialNumber}</Label>
                                            <NumberInput
                                                name="suggestion"
                                                className="form-control"
                                                value={!isEmpty(product.sizeOfRectifierControllerSerialNumber)?product.sizeOfRectifierControllerSerialNumber:""}
                                                onChangeEvent={(e, maskedvalue, floatvalue) => {
                                                    e.preventDefault()
                                                    product.sizeOfRectifierControllerSerialNumber = floatvalue
                                                    this.setState({
                                                        product: product
                                                    })
                                                }}
                                                placeholder="0"
                                                maxLength={15}>
                                            </NumberInput>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.renderComponent(components, product)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>{label.Scope}</CardHeader>
                                            <CardBody>
                                                <Editor
                                                    editorState={scopeEditorState}
                                                    onEditorStateChange={this.onScopeEditorStateChange}/>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader>{label.ReferenceDocument}</CardHeader>
                                            <CardBody>
                                                <Editor
                                                    editorState={referenceDocumentEditorState}
                                                    onEditorStateChange={this.onReferenceDocumentEditorStateChange}/>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>{label.TestingEquipmentAndAccessories}</CardHeader>
                                            <CardBody>
                                                <Editor
                                                    editorState={testingEquipmentAndAccessoriesEditorState}
                                                    onEditorStateChange={this.onTestingEquipmentAndAccessoriesEditorStateChange}/>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        {this.renderSystemSpecification(systemSpecifications, product)}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ButtonGroup className="float-left">
                                            <Button color="danger" onClick={e => (
                                                this.setState({
                                                    product:{}
                                                }, () => {
                                                    this.props.history.goBack();
                                                })
                                            )}>
                                                {label.Back}
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <ButtonGroup className="float-right">
                                            <Button color="primary" onClick={e=>{
                                                e.preventDefault()
                                                this.confirmSave()
                                            }}> {
                                                (this.state.product!=null && this.state.product.id!=null)?label.Update:label.Create
                                            } </Button>

                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <ImageCropper src={this.state.iconPhoto} aspect={aspect} show={this.state.cropperModal} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            iconPhoto:file,
                            cropperModal:false,
                            imageBlob:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal:false,
                        iconPhoto:product.iconPhotoUrl,
                        imageBlob:null,
                    })
                }}/>

            </Page>
        );
    }
    renderComponent(components, product) {
        // components = clone(components)
        // systemSpecifications.forEach((value, index) => {
        //     value.tag = form.tag + "." + (index + 1)
        //     subInputs[index].tag = form.tag + "." + (index + 1)
        // })
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            {label.SerialComponent}
                            <Button style={{ float: 'right' }} onClick={() => {
                                // components.push({ })
                                // this.setState({
                                //     components: components
                                // })
                                this.setState({
                                    components: [...components, {id:-(components.length+1)}]
                                })
                            }}><MdAdd /></Button>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th width={'5%'}>#</th>
                                    <th width={'40%'}>{label.Label}</th>
                                    <th width={'55%'}>{label.Description}</th>
                                    <th>{label.Remove}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    components.map((item, index) => (
                                        <tr>
                                            <td><div className={"form-control border-0"}>{index+1}</div></td>
                                            <td>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="label"
                                                        value={item.label ? item.label : ""}
                                                        onChange={(e) => {
                                                            components[index].label = e.target.value
                                                            this.setState({
                                                                components: components,
                                                            })
                                                        }}
                                                        placeholder={label.EnterLabel}
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="description"
                                                        value={item.description ? item.description : ""}
                                                        onChange={(e) => {
                                                            components[index].description = e.target.value
                                                            this.setState({
                                                                components: components,
                                                            })
                                                        }}
                                                        placeholder={label.EnterDescription}
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <Button
                                                    color={"danger"}
                                                    onClick={() => {
                                                        components.splice(index, 1)
                                                        this.setState({
                                                            components: components
                                                        })
                                                    }}>
                                                    <MdDelete />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }


    renderSystemSpecification(systemSpecifications, product) {
        // systemSpecifications = clone(systemSpecifications)
        // systemSpecifications.forEach((value, index) => {
        //     value.tag = form.tag + "." + (index + 1)
        //     subInputs[index].tag = form.tag + "." + (index + 1)
        // })
        return (
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            {label.SystemSpecification}
                            <Button style={{ float: 'right' }} onClick={() => {
                                // systemSpecifications.push({ })
                                this.setState({
                                    systemSpecifications: [...systemSpecifications, {id:-(systemSpecifications.length+1)}]
                                })
                                // this.setState({
                                //     systemSpecifications: systemSpecifications
                                // })
                            }}><MdAdd /></Button>
                        </CardHeader>
                        <CardBody>
                            <Table responsive>
                                <thead>
                                <tr>
                                    <th width={'5%'}>#</th>
                                    <th width={'30%'}>{label.Label}</th>
                                    <th width={'55%'}>{label.Description}</th>
                                    <th>{label.Remove}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    systemSpecifications.map((item, index) => (
                                        <tr>
                                            <td><div className={"form-control border-0"}>{index+1}</div></td>
                                            <td>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="label"
                                                        value={item.label ? item.label : ""}
                                                        onChange={(e) => {
                                                            systemSpecifications[index].label = e.target.value
                                                            this.setState({
                                                                systemSpecifications: systemSpecifications,
                                                            })
                                                        }}
                                                        placeholder={label.EnterLabel}
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input
                                                        type="text"
                                                        name="description"
                                                        value={item.description ? item.description : ""}
                                                        onChange={(e) => {
                                                            systemSpecifications[index].description = e.target.value
                                                            this.setState({
                                                                systemSpecifications: systemSpecifications,
                                                            })
                                                        }}
                                                        placeholder={label.EnterDescription}
                                                    />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                <Button
                                                    color={"danger"}
                                                    onClick={() => {
                                                        systemSpecifications.splice(index, 1)
                                                        this.setState({
                                                            systemSpecifications: systemSpecifications
                                                        })
                                                    }}>
                                                    <MdDelete />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}
export default Object.assign(ProductDetailPage, {ENDPOINT : "/productDetail"})