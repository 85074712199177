import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import ItemOption from '../Widget/ItemOption';
import Global from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import label from 'na-utilities/src/label/Label'
import {MdRefresh} from "react-icons/all";
import {isEmpty, isResponseOk} from "na-utilities/src/utils/Utilities";
import QRCode from "react-qr-code";
import Endpoint from "na-utilities/src/api/Endpoint";
/*= (message, okCallback, cancelCallback) =>*/
export default class ContainerModal extends BaseComponent{

    constructor(props){
        super(props);
        let container = this.props.container?this.props.container:{}
        let smartDevice = this.props.smartDevice?this.props.smartDevice:{}
        let spotLocation = smartDevice.spotLocation?smartDevice.spotLocation:{}
        let state = this.state
        state.containerType = container.containerType?container.containerType:{}
        state.container = container
        state.spotLocations = []
        state.smartDevices = []
        state.spotLocation = spotLocation
        state.modal= this.props.modal
        state.backdrop= false
        state.smartDevice = smartDevice
        state.containerTypes = []
        state.okCallback = this.props.okCallback
        state.closeCallback = this.props.closeCallback
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.state.modal
        if(modal!=props.modal){
            let container = props.container?props.container:{}
            let smartDevice = props.smartDevice?props.smartDevice:{}
            let spotLocation = smartDevice.spotLocation?smartDevice.spotLocation:{}
            let containerType = container.containerType?container.containerType:{}

            this.setState({
                modal:props.modal,
                smartDevice: smartDevice,
                spotLocation:spotLocation,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
                container:container,
                containerType:containerType,
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchContainerTypes()
        this.fetchSpotLocations()
        if(this.state.spotLocation){
            this.fetchSmartDevices(this.state.spotLocation)
        }
        this.generateCode(false)


    }

    fetchSpotLocations = () =>{
        this.get(Endpoint.API.SPOT_LOCATIONS, {
            params:{
                ascending:true,
                sortir:"jc.name"
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    spotLocations : res.data,
                })
            }
        },false, true);

    }
    fetchContainerTypes = () =>{
        this.get(Endpoint.API.CONTAINER_TYPES, null, null, response=>{
            if(response.code===200){
                let containerTypes = response.data
                this.setState({
                    containerTypes : containerTypes
                })
            }
        }, true, true)
    }

    fetchSmartDevices = (spotLocation) =>{
        this.get(Endpoint.API.SMART_DEVICES, {
            params:{
                ascending:true,
                sortir:"jt.created",
                spotLocationId:spotLocation.id
            }
        }, null, response=>{
            if(response.code===200){
                let smartDevices = response.data
                this.setState({
                    smartDevices : smartDevices
                })
            }
        }, true, true)
    }

    async generateCode(force){
        let {container} = this.state
        if(!force && container.code){
            return container.code
        }
        let response = await this.asyncGet(Endpoint.API.CONTAINER_GENERATE_CODE, {}, null, true, true);
        let code = undefined;
        if(isResponseOk(response)){
            code = response.data
        }
        container.code = code
        this.setState({container})
        return code;
    }


    saveContainer=()=>{
        let {smartDevice, containerType, container} = this.state
        if(isEmpty(smartDevice)){
            return
        }
        if(isEmpty(container.code)){
            return
        }
        if(isEmpty(container.label)){
            return
        }
        if(isEmpty(containerType)){
            return
        }
        container.smartDevice = smartDevice
        container.containerType = containerType
        this.post(Endpoint.API.CONTAINER_SAVE, container, null, response=>{
            if(response.code===200){
                let container = response.data
                this.successToast(response.message)
                this.setState({
                    modal:false
                }, () => {
                    if(this.state.okCallback){
                        this.state.okCallback(container)
                    }
                })
            }
        }, true, true)
    }

    render(){
        let {spotLocations, spotLocation, smartDevices, smartDevice, container, containerType, containerTypes} = this.state

        return (
            <Modal size="md" centered={true}
                isOpen={this.state.modal}
                backdrop={false}>
                {super.render()}
                <ModalHeader>
                    {label.Container}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody style={{textAlign:'center'}}>
                                    <QRCode value={container.code?container.code:""}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col md={10}>
                            <FormGroup>
                                <Label for="code">{label.Code}</Label>
                                <Input
                                    id="code"
                                    type="text"
                                    name="code"
                                    value={container.code}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label>&nbsp;&nbsp;</Label>
                                <br/>
                                <Button onClick={() => this.generateCode(true)}><MdRefresh/></Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="label">{label.Label}</Label>
                                <Input
                                    id="label"
                                    type="text"
                                    name="label"
                                    value={container.label}
                                    onChange={(e) =>{
                                        e.preventDefault()
                                        container.label = e.target.value
                                        this.setState({
                                            container:container
                                        })

                                    }}
                                    placeholder={label.EnterPosition}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="position">{label.Position}</Label>
                                <Input
                                    id="position"
                                    type="text"
                                    name="position"
                                    value={container.position}
                                    onChange={(e) =>{
                                        e.preventDefault()
                                        container.position = e.target.value
                                        container.position = container.position.replace(/[^\d+]/g,'')
                                        this.setState({
                                            container:container
                                        })

                                    }}
                                    placeholder={label.EnterPosition}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.ContainerType}
                                    fieldForLabel={"type"}
                                    objects={containerTypes}
                                    default={containerType.id}
                                    callback={(containerType)=>{
                                        if(containerType){
                                            container.containerType = containerType
                                            this.setState({
                                                containerType:containerType
                                            })
                                        }else{
                                            container.containerType = undefined
                                            this.setState({
                                                containerType:{}
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        this.props.smartDeviceOption&&(
                            <>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <ItemOption
                                                title={label.SpotLocations}
                                                fieldForLabel={"name"}
                                                objects={spotLocations}
                                                default={spotLocation.id}
                                                callback={(spotLocation)=>{
                                                    if(!isEmpty(spotLocation)){
                                                        if(spotLocation.id!=this.state.spotLocation.id){
                                                            smartDevice = {}
                                                        }
                                                        this.setState({
                                                            spotLocation:spotLocation,
                                                            smartDevice:{},
                                                            smartDevices:[]
                                                        }, () => {
                                                            this.fetchSmartDevices(spotLocation)
                                                        })

                                                    }else{
                                                        this.setState({
                                                            spotLocation:{},
                                                            smartDevices:[]
                                                        })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <ItemOption
                                                title={label.SmartDevices}
                                                fieldForLabel={"name"}
                                                objects={smartDevices}
                                                default={smartDevice.id}
                                                callback={(smartDevice)=>{
                                                    if(smartDevice){
                                                        this.setState({
                                                            smartDevice:smartDevice
                                                        })
                                                    }else{
                                                        this.setState({
                                                            smartDevice:{}
                                                        })
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={e=>{
                                this.saveContainer()
                            }}>
                                {label.Save}
                            </Button>
                        </Col>
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                })
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

ContainerModal.propTypes = {
    modal:PropTypes.bool,
    smartDeviceOption:PropTypes.bool.isRequired,
    smartDevice:PropTypes.object.isRequired,
    container:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
