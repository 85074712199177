import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import ItemOption from '../Widget/ItemOption';
import Global from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import {statusColor} from 'na-utilities/src/utils/Utilities';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
/*= (message, okCallback, cancelCallback) =>*/
export default class UpdateProductOrderStatusModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.modal = this.props.modal
        state.backdrop= false
        state.productOrder = this.props.productOrder
        state.productOrderStatus = {}
        state.productOrderStatuses = []
        state.newProductOrderStatus = {}
        state.okCallback = this.props.okCallback
        state.closeCallback = this.props.closeCallback
        state.note = ""
        state.resiCode = ""
        state.resiCodeMessage=""
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.state.modal
        if(this.state.modal!=props.modal){
            this.setState({
                modal:props.modal,
                productOrder: props.productOrder,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
                productOrderStatus:{},
                productOrderStatuses:[],
                newProductOrderStatus:{},
                note:"",
                resiCode : "",
                resiCodeMessage : "",
            }, () => {
                if(props.modal && modal!==props.modal){
                    this.fetchProductOrderStatus()
                }
            })
        }
    }

    fetchProductOrderStatus = () =>{
        let productOrder = this.state.productOrder?this.state.productOrder:{}
        let productOrderStatus = productOrder.productOrderStatus?productOrder.productOrderStatus:{}
        if(productOrderStatus.id){
            this.get(Endpoint.API.PRODUCT_ORDER_STATUSES, null, null, response=>{
                if(response.code===200){
                    let productOrderStatuses = response.data
                    let productOrderStatusOptions = []
                    productOrderStatuses.map((item, index)=>{
                        if(item.id>productOrderStatus.id){
                            productOrderStatusOptions.push(item)
                        }
                    })
                    this.setState({
                        productOrderStatuses : productOrderStatusOptions
                    })
                }
            }, true, true)
        }
    }


    updateProductOrderStatus=()=>{
        let productOrder = this.state.productOrder?this.state.productOrder:{}
        let productOrderStatus = productOrder.productOrderStatus?productOrder.productOrderStatus:{}
        let newProductOrderStatus = this.state.newProductOrderStatus?this.state.newProductOrderStatus:{}
        let note = this.state.note?this.state.note:""
        let resiCode = this.state.resiCode?this.state.resiCode:""
        let confirmationMessage = "";
        // if(newProductOrderStatus.id===_DELIVERY){
        //     confirmationMessage= "Do you want to update this productOrder status from = "+productOrderStatus.name+" to = "+newProductOrderStatus.name+" with resi code is "+resiCode+" ? "
        // }else{
        // }
        confirmationMessage= "Do you want to update this productOrder status from = "+productOrderStatus.name+" to = "+newProductOrderStatus.name+" ? "
        this.openConfirmDialog(label.Confirmation, confirmationMessage, ()=>{
            let formData = new FormData();
            formData.append("productOrderId", productOrder.id)
            formData.append("productOrderStatusId", newProductOrderStatus.id)
            formData.append("note", note)
            formData.append("resiCode", resiCode)
            this.post(Endpoint.API.PRODUCT_ORDER_UPDATE_STATUS, formData, null, response=>{
                if(response.code===200){
                    let productOrder = response.data
                    this.successToast(response.message)
                    this.setState({
                        modal:false
                    }, () => {
                        if(this.state.okCallback){
                            this.state.okCallback(productOrder)
                        }
                    })
                }
            }, true, true)
        })
    }

    render(){
        let productOrder = this.state.productOrder?this.state.productOrder:{}
        let productOrderStatus = productOrder.productOrderStatus?productOrder.productOrderStatus:{}
        let productOrderStatuses = this.state.productOrderStatuses?this.state.productOrderStatuses:[]
        let newProductOrderStatus = this.state.newProductOrderStatus?this.state.newProductOrderStatus:{}

        return (
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={false}>
                {super.render()}
                <ModalHeader>
                    Update Status Pesanan : {productOrder&&productOrder.refCode}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="currentStatus">{label.CurrentStatus}</Label>
                                <Input
                                    style={{fontSize:'bold', color:statusColor(productOrderStatus.id), borderColor: statusColor(productOrderStatus.id)}}
                                    id="currentStatus"
                                    type="text"
                                    name="currentStatus"
                                    value={productOrderStatus.label}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.SelectStatus}
                                    objects={productOrderStatuses}
                                    default={newProductOrderStatus.id}
                                    fieldForLabel={"label"}
                                    callback={(newProductOrderStatus)=>{
                                        if(newProductOrderStatus!=null){
                                            this.setState({
                                                newProductOrderStatus:newProductOrderStatus
                                            })
                                        }else{
                                            this.setState({
                                                newProductOrderStatus:{}
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <FormGroup>
                            <Label for="note">Note</Label>
                            <Input
                                id="note"
                                type="text"
                                name="note"
                                value={this.state.note}
                                onChange={(e) =>{
                                    let value = e.target.value
                                    this.setState({
                                        note : value?value:null
                                    })
                                }}
                                placeholder={label.EnterNoteRecommended}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                    {/*{*/}
                        {/*(newProductOrderStatus.id===_DELIVERY)&&(*/}
                            {/*<Row>*/}
                                {/*<Col>*/}
                                    {/*<FormGroup>*/}
                                        {/*<Label for="resiCode">Resi Code</Label>*/}
                                        {/*<Input*/}
                                            {/*id="resiCode"*/}
                                            {/*type="text"*/}
                                            {/*name="resiCode"*/}
                                            {/*value={this.state.resiCode}*/}
                                            {/*onChange={(e) =>{*/}
                                                {/*let value = e.target.value*/}
                                                {/*let resiCodeMessage = this.state.resiCodeMessage*/}
                                                {/*if(value!==null || value!==''){*/}
                                                    {/*resiCodeMessage = ""*/}
                                                {/*}*/}
                                                {/*this.setState({*/}
                                                    {/*resiCode : value?value:null,*/}
                                                    {/*resiCodeMessage : resiCodeMessage*/}
                                                {/*})*/}
                                            {/*}}*/}
                                            {/*placeholder="Enter resi code..."*/}
                                        {/*/>*/}
                                        {/*<br/>*/}
                                        {/*<span style={{color:"#ff061a"}}>{this.state.resiCodeMessage}</span>*/}
                                    {/*</FormGroup>*/}
                                {/*</Col>*/}
                            {/*</Row>*/}
                        {/*)*/}
                    {/*}*/}
                </ModalBody>
                <ModalFooter>
                    <Row>
                        {
                            newProductOrderStatus.id&&(
                                <Col>
                                    <Button color="primary" onClick={e=>{
                                        this.updateProductOrderStatus()
                                    }}>
                                        Update
                                    </Button>
                                </Col>
                            )
                        }
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                })
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

UpdateProductOrderStatusModal.propTypes = {
    modal:PropTypes.bool,
    productOrder:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
