import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
} from 'reactstrap';
import shoppingBag from 'assets/img/products/shopping-bag.png';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import Global, {COMPANY_IMAGE_MAX_FILE_SIZE, DEFAULT_LOCATION, MIME_PNG_JPEG} from '../utils/Global';
import {allIsEmpty, imageSelector, isEmpty} from 'na-utilities/src/utils/Utilities';
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
// import InventoryQuantityModal from '../components/modal/InventoryQuantityModal';
import {getData} from '../utils/StorageUtil';
import ImageCropper from '../components/modal/ImageCropper';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import WarningLabel from '../components/Widget/WarningLabel';
import DateInput from '../components/Widget/DateInput';
import NumberInput from '../components/Widget/NumberInput';
import label from 'na-utilities/src/label/Label'
import {MapContainer} from "../components/Widget/MapContainer";
import Endpoint from "na-utilities/src/api/Endpoint";


class CompanyDetailPage extends BasePage{

    constructor(props) {
        super(props);
        let state = this.state
        state.company = {}
        state.categories = []
        state.image  = null
        state.provinces = []
        state.cities = []
        state.districts = []
        state.villages = []
        state.province = {}
        state.city= {}
        state.district = {}
        state.village = {}
        state.companyIdQuery = queryString.parse(this.props.query).id
        state.category = {}
        state.modalAddConfirm = false
        state.modalDeleteConfirm = false
        state.inventories = []
        state.inventory = null
        state.ascending = true
        state.sortir = 'created'
        state.quantityModal = false
        state.page = queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1
        state.totalPage = 0
        state.totalElement = 0
        state.pageElement = 0
        state.validation = {}
        this.setState(state)
        this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))

    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchCompanyDetail(this.state.companyIdQuery)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                // this.fetchAllInventories(this.state.company.id, this.state.ascending, this.state.sortir, true)
            })
        }

    }


    confirmSave = () =>{
        var company = this.state.company
        let validation = this.state.validation
        let modalAddConfirm = true
        var image = this.state.image

        if (isEmpty(company.fullName)) {
            validation.fullName = label.EnterFullname
            modalAddConfirm = false;
        }
        if (isEmpty(company.code) || !isEmpty(validation.code)) {
            validation.code = validation.code?validation.code:label.GenerateCode
            modalAddConfirm = false;
        }
        if (isEmpty(company.email)) {
            validation.email = label.EnterEmail
            modalAddConfirm = false;
        }

        if (isEmpty(company.buildDate)) {
            validation.buildDate = label.EnterBuildDate
            modalAddConfirm = false;
        }
        if (isEmpty(company.companyPhoneNumber)) {
            validation.companyPhoneNumber = label.EnterPhoneNumber
            modalAddConfirm = false;
        }
        if (isEmpty(company.address)) {
            validation.address = label.EnterAddress
            modalAddConfirm = false;
        }
        if (isEmpty(company.village)) {
            validation.village = label.SelectVillage
            modalAddConfirm = false;
        }

        this.setState({
            modalAddConfirm: modalAddConfirm,
            validation:validation
        })
    }

    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }

    delete = () => {
        if(this.state.inventory!=null){
            this.setState({
                modalDeleteConfirm:false
            }, () => {
                this.get(Endpoint.API.INVENTORY_DELETE+"/"+this.state.inventory.id, null, null, res => {
                    if(res.code===200){
                        this.setState({
                            inventory:null
                        }, () => {
                            // this.fetchAllInventories(this.state.company.id, this.state.ascending, this.state.sortir, true)
                        })
                    }
                }, true, true);
            })
        }
    }

    saveUpload = () => {
        var formData = new FormData();
        let company = this.state.company;

        formData.append("companyDtoGson", JSON.stringify(company))
        formData.append("multipartFile", this.state.imageBlob);
        this.setState({
            modalAddConfirm:false
        }, () => {
            this.post(Endpoint.API.COMPANY_SAVE_UPLOAD, formData, null, res=>{
                if(res.code===200){
                    this.setState({
                        company:res.data
                    }, () => {
                        this.showDialog("Success", res.message)
                        this.fetchCompanyDetail(this.state.company.id)
                        // changeParam(this.props, 'id', this.state.company.id)
                    })
                }
            },  true, true)
        })
    }

    fetchCompanyDetail = (id) => {
        if(id!=null){
            this.get(Endpoint.API.COMPANY, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let company = response.data
                    // let company = company.company
                    if(!company.latitude || !company.longitude){
                        company.latitude = DEFAULT_LOCATION.latitude
                        company.longitude = DEFAULT_LOCATION.longitude
                    }

                    let village = company&&company.village?company.village:null
                    let district = company&&company.village?company.village.district:null
                    let city = company&& company.village?company.village.district.city:null
                    let province = company&&company.village?company.village.district.city.province:null

                    this.setState({
                        company:company,
                        taskCategory:response.data.taskCategory,
                        image:response.data.imageUrl,
                        village:village,
                        district:district,
                        city:city,
                        province:province,
                    }, () => {
                        if(this.state.village){
                            this.fetchCities(this.state.province.id, cities=>{
                                this.setState({
                                    cities:cities
                                })
                            })
                        }
                        if(this.state.city){
                            this.fetchDistricts(this.state.city.id, districts =>{
                                this.setState({
                                    districts:districts
                                })
                            })
                        }
                        if(this.state.district){
                            this.fetchVillages(this.state.district.id, villages=>{
                                this.setState({
                                    villages:villages
                                })
                            })
                        }
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }

    generateCode(){
        let {company, validation} = this.state
        if(!company){
            company = {}
        }
        this.get(Endpoint.API.COMPANY_GENERATE_CODE, {
            params:{
                companyId:company.id
            }
        }, null, response=>{
            if(response.code===200){
                company.code = response.data
                validation.code = undefined
                this.setState({company, validation})
            }
        }, true, true)
    }
    validateCode(){
        let {company, validation} = this.state
        if(!company || !company.code){
            return;
        }
        this.get(Endpoint.API.COMPANY_VALIDATE_CODE, {
            params:{
                companyId:company.id,
                code:company.code
            }
        }, null, response=>{
            if(response.code===200){
                let valid = response.data
                if(!valid){
                    validation.code = label.CodeAlreadyBeenUsed
                }else{
                    validation.code = undefined
                }
                this.setState({validation})
            }
        }, false, true)
    }


    render() {
        var ids = [];
        var labels = [];

        let configuration = getData(Global.CONFIGURATION)
        let aspect = 4/4

        let company = this.state.company?this.state.company:{}
        let validation = this.state.validation?this.state.validation:{}
        if(!company.latitude || !company.longitude){
            company.latitude = DEFAULT_LOCATION.latitude
            company.longitude = DEFAULT_LOCATION.longitude
        }
        // let company = this.state.company?this.state.company:{}



        return (
            <Page
                title={label.CompanyForm}
                breadcrumbs={[{ name:label.CompanyForm, active: true }]}>
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalAddConfirm}
                    title={label.UpdateConfirmation}
                    message="Do you want to update this company item to your list ?"
                    okCallback={this.saveUpload}
                    cancelCallback={this.closeDialog}/>
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message="Do you want to delete this company item from your list ?"
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>

                <Row key={1}>
                    <Col>
                        <Card className="mb-6">
                            <CardHeader>{label.Company}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={2}>
                                        <Card>
                                            <CardImg top src={
                                                this.state.image!=null?this.state.image:shoppingBag} onClick={e=>{
                                                imageSelector(file=>{
                                                    this.setState({
                                                        image:file,
                                                        cropperModal:true
                                                    })
                                                }, MIME_PNG_JPEG, COMPANY_IMAGE_MAX_FILE_SIZE).click()
                                            }}
                                            onError={(elm)=>this.defaultImage(elm, shoppingBag)}/>
                                            <CardBody>
                                                <CardTitle>Company Image</CardTitle>
                                                <Row>
                                                    <Col>
                                                        <WarningLabel message={"*Use PNG or Jpeg Format / Max "+COMPANY_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                                    </Col>
                                                </Row>
                                                <br/>
                                                <Row>
                                                    <Col>
                                                        <ButtonGroup className="float-left">
                                                            <Button color="danger" onClick={e => (
                                                                this.setState({
                                                                    company:null,
                                                                    image:null
                                                                })
                                                            )}>{label.Cancel}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                    <Col>
                                                        <ButtonGroup className="float-right">
                                                            <Button color="primary" onClick={e=>{
                                                                imageSelector(file=>{
                                                                    this.setState({
                                                                        image:file,
                                                                        cropperModal:true
                                                                    })
                                                                }, MIME_PNG_JPEG, COMPANY_IMAGE_MAX_FILE_SIZE).click()
                                                            }}>{label.Upload}</Button>
                                                        </ButtonGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={10}>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Fullname}</Label>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={(company)?company.fullName:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    company.fullName = e.target.value
                                                                    validation.fullName = undefined
                                                                    this.setState({
                                                                        company:company, validation
                                                                    })
                                                                }}
                                                                placeholder={label.EnterFullname}
                                                            />
                                                            <WarningLabel show={validation.fullName} message={validation.fullName}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Website}</Label>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={(company)?company.website:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    company.website = e.target.value
                                                                    this.setState({
                                                                        company:company
                                                                    })
                                                                }}
                                                                placeholder={label.EnterWebsite}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="code">{label.Code}</Label>
                                                            <Input
                                                                type="text"
                                                                name="code"
                                                                value={(company)?company.code:""}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Email}</Label>
                                                            <Input
                                                                type="text"
                                                                name="name"
                                                                value={(company)?company.email:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    company.email = e.target.value
                                                                    validation.email = undefined
                                                                    this.setState({
                                                                        company:company, validation
                                                                    })
                                                                }}
                                                                placeholder={label.EnterEmail}
                                                            />
                                                            <WarningLabel show={validation.email} message={validation.email}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.EnterBuildDate}</Label>
                                                            <DateInput
                                                                id="buildDate"
                                                                maxdate={new Date()}
                                                                value={company.buildDate}
                                                                onChange={(e) =>{
                                                                    let value = e.target.value
                                                                    company.buildDate = value
                                                                    validation.buildDate = undefined
                                                                    this.setState({
                                                                        company : company,
                                                                        validation
                                                                    })
                                                                }}
                                                                placeholder={label.EnterBuildDate}>
                                                                <WarningLabel show={validation.buildDate} message={validation.buildDate}/>
                                                            </DateInput>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.EnterPhoneNumber}</Label>
                                                            <NumberInput
                                                                name="companyPhoneNumber"
                                                                className="form-control"
                                                                value={(company)?company.companyPhoneNumber:""}
                                                                onChangeEvent={(e, maskedvalue, floatvalue)=>{
                                                                    e.preventDefault()
                                                                    if(company!=null){
                                                                        company.companyPhoneNumber = e.target.value
                                                                        this.setState({
                                                                            company:company
                                                                        })
                                                                    }
                                                                }}
                                                                maxLength="15"
                                                                placeholder="0">
                                                            </NumberInput>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Description}</Label>
                                                            <Input
                                                                type="textarea"
                                                                rows={4}
                                                                name="companyDescription"
                                                                value={(company)?company.companyDescription:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    company.companyDescription = e.target.value
                                                                    this.setState({
                                                                        company:company
                                                                    })
                                                                }}
                                                                placeholder={label.EnterCompanyDescription}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>{label.Address}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <MapContainer
                                                            latitude={company.latitude}
                                                            longitude={company.longitude}
                                                            onGestureExecuting={(latitude, longitude)=>{
                                                                company.latitude = latitude
                                                                company.longitude = longitude
                                                                console.log("LATLNG = "+latitude+" - "+longitude)
                                                                this.setState({company:company})
                                                            }}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Address}</Label>
                                                            <Input
                                                                type="text"
                                                                name="address"
                                                                value={(company)?company.address:""}
                                                                onChange={(e) =>{
                                                                    e.preventDefault()
                                                                    company.address = e.target.value
                                                                    validation.address = undefined
                                                                    this.setState({
                                                                        company:company,
                                                                        validation
                                                                    })
                                                                }}
                                                                placeholder={label.EnterCompanyAddress}
                                                            />
                                                            <WarningLabel show={validation.address} message={validation.address}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectProvince}
                                                                objects={this.state.provinces}
                                                                default={!allIsEmpty(this.state.province)?this.state.province.id:null}
                                                                callback={(province)=>{
                                                                    if(province!=null){
                                                                        this.setState({
                                                                            province:province,
                                                                            cities:[],
                                                                            districts:[],
                                                                            villages:[],
                                                                        }, () => {
                                                                            this.fetchCities(province.id, cities=>{
                                                                                this.setState({
                                                                                    cities:cities
                                                                                })
                                                                            })
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            province:null,
                                                                            cities:[],
                                                                            districts:[],
                                                                            villages:[],
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectCity}
                                                                objects={this.state.cities}
                                                                default={!allIsEmpty(this.state.city)?this.state.city.id:null}
                                                                callback={(city)=>{
                                                                    if(city!=null){
                                                                        this.setState({
                                                                            city:city,
                                                                            districts:[],
                                                                            villages:[],
                                                                        }, () => {
                                                                            this.fetchDistricts(city.id, districts =>{
                                                                                this.setState({
                                                                                    districts:districts
                                                                                })
                                                                            })
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            city:null,
                                                                            districts:[],
                                                                            villages:[],
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectDistrict}
                                                                objects={this.state.districts}
                                                                default={!allIsEmpty(this.state.district)?this.state.district.id:null}
                                                                callback={(district)=>{
                                                                    if(district!=null){
                                                                        this.setState({
                                                                            district:district,
                                                                            villages:[],
                                                                        }, () => {
                                                                            this.fetchVillages(district.id, villages=>{
                                                                                this.setState({
                                                                                    villages:villages
                                                                                })
                                                                            })
                                                                        })
                                                                    }else{
                                                                        this.setState({
                                                                            district:null,
                                                                            villages:[],
                                                                        })
                                                                    }
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <ItemOption
                                                                title={label.SelectVillage}
                                                                objects={this.state.villages}
                                                                default={!allIsEmpty(this.state.village)?this.state.village.id:null}
                                                                callback={(village)=>{
                                                                    company.village = village
                                                                    validation.village = undefined
                                                                    this.setState({
                                                                        company:company,
                                                                        village:village,
                                                                        validation
                                                                    })
                                                                }}
                                                            />
                                                            <WarningLabel show={validation.village} message={validation.village}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ButtonGroup className="float-left">
                                            <Button color="danger" onClick={e => (
                                                this.setState({
                                                    company:null
                                                }, () => {
                                                    this.props.history.goBack();
                                                })
                                            )}>{label.Back}</Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <ButtonGroup className="float-right">
                                            <Button color="primary" onClick={e=>{
                                                e.preventDefault()
                                                this.confirmSave()
                                            }}> {
                                                (this.state.company!=null && this.state.company.id!=null)?label.Update:label.Create
                                            } </Button>

                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ImageCropper src={this.state.image} aspect={aspect} show={this.state.cropperModal} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        this.setState({
                            image:file,
                            cropperModal:false,
                            imageBlob:fileBlob,
                        })
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        cropperModal:false,
                        image:company.imageUrl,
                        imageBlob:null,
                    })
                }}/>

            </Page>
        );
    }


}
export default Object.assign(CompanyDetailPage, {ENDPOINT : "/companyDetail"})