import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import userProfilePicture from 'assets/img/users/user_pp.png';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import {DD_MM_YYYY_HH_MM_SS, USER_STATUSES,} from '../utils/Global';
import {
    allIsEmpty,
    cleanPhoneNumber,
    cloneObject,
    deleteParam,
    imageSelector,
    isEmpty,
    isResponseOk,
    isValidEmail, navigate,
    parseDate,
    sortirMap,
} from "na-utilities/src/utils/Utilities";
import queryString from 'query-string';
import FormText from 'reactstrap/es/FormText';
import SearchInput from '../components/SearchInput';
// import {UserDto} from '../model/model.ts';
import DateInput from '../components/Widget/DateInput';
import ImageCropper from '../components/modal/ImageCropper';
import {MdDelete, MdDetails, MdEdit, MdHistory, MdSave, MdSecurity, MdFilterList} from 'react-icons/md';
import UpdateUserStatusModal from '../components/modal/UpdateUserStatusModal';
import label, {GENDERS} from 'na-utilities/src/label/Label'
import WarningLabel from "../components/Widget/WarningLabel";
import UserStatusHistoryModal from "../components/modal/UserStatusHistoryModal";
import MyCardImage from "../components/Card/MyCardImage";
import Checkbox from "../components/Checkbox";
import CompanySelectionModal from "../components/modal/CompanySelectionModal";
import Endpoint from "na-utilities/src/api/Endpoint";
import UserRoleDetailPage from "./UserRoleDetailPage";

class UserDetailPage extends BasePage{

    constructor(props) {
        super(props);
        this.state = {
            phoneNumberWarning:"",
            user:{},
            roles:[],
            photoBlob:null,
            image:null,
            userIdQuery: queryString.parse(this.props.query).id,
            gender:{},
            userStatus:{},
            ascending:false,
            sortir:'created',
            banks:[],
            bank:{},
            provinces:[],
            cities:[],
            districts:[],
            villages:[],
            province:{},
            city:{},
            district:{},
            village:{},
            productOrderStatuses:[],
            productOrders:[],
            productOrderStatus:null,
            refCode:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            updateUserStatusModal:false,
            userStatusModal:false,
            deviceSortir:"ud.created",
            deviceSearch:"",
            deviceAscending:false,
            companyModal:false
        }
        this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAllRole()
        this.fetchBanks()
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1,
                userIdQuery: queryString.parse(props.query).id,
            }, () => {
                this.fetchUserDetail(this.state.userIdQuery)
            })
        }
    }


    confirmSave = async () =>{
        let {user} = this.state
        let valid = true ;

        if(isEmpty(user.firstname) || user.firstname.length<3){
            this.setState({
                fistNameWarning:label.PleaseEnterFirstName
            })
            valid = false ;
        }

        if(isEmpty(user.userCode)){
            this.setState({
                refCodeWarning:label.PleaseGenerateARefCodeForThisUser
            })
            valid = false ;
        }

        if(isEmpty(user.email)){
            this.setState({
                emailWarning:label.PleaseEnterEmail
            })
            valid = false ;
        }

        valid = await this.verifyEmail(user.id, this.state.user.email)

        if(isEmpty(user.mobilePhone) || user.mobilePhone.length<11){
            this.setState({
                mobilePhoneWarning:label.PleaseEnterARightMobilePhone
            })
            valid = false ;
        }

        if(valid){
            this.openConfirmDialog(label.Confirmation, (user.id?label.DoYouWantToUpdateInformationOfThisUser:label.DoYouWantToCreateANewUser), ()=>{
                this.saveUpload()
            })
        }
    }

    async saveUpload(){
        let photoBlob = this.state.photoBlob
        if(photoBlob){
            photoBlob = await new Promise(resolve => {
                axios({
                    url: photoBlob,
                    method: 'GET',
                    responseType: 'blob', // important
                }).then((response) => {
                    this.closeProgress()
                    var file = response.data ;
                    resolve(file)
                })
            })
        }
        let user = this.state.user
        user.photoUrl = null

        var formData = new FormData();
        formData.append("userDtoGson", JSON.stringify(user))
        if(photoBlob){
            formData.append("photoMultipartFile", photoBlob);
        }
        this.post(Endpoint.API.USER_SAVE_UPLOAD, formData, null, res=>{
            let user = res.data
            let {roles} = this.state
            this.setState({
                user:user,
                userIdQuery:res.data.id
            }, () => {
                // this.openSuccessDialog(label.Success, label.SuccessUpdatingData, ()=>{
                //     this.props.history.goBack();
                // })
            })
        },  true, true)

    }


    generateUserCode(){
        let user = this.state.user
        this.get(Endpoint.API.USER_GENERATE_AGENT_CODE, null, null, response=>{
            if(response.code===200){
                user.userCode = response.data
                this.setState({
                    refCodeWarning:"",
                    user:user
                })
            }

        }, true, true)
    }

    fetchUserDetail = (id) => {
        if(id!=null){
            this.get(Endpoint.API.USER, {
                params :{
                    id : id
                }
            }, null, res=>{
                if(res.code===200){
                    let {roles} = this.state
                    let user = res.data;
                    let gender = null
                    GENDERS.forEach(value => {
                        if(value.key===user.gender){
                            gender = value ;
                        }
                    })
                    let userStatus = null
                    USER_STATUSES.forEach(value => {
                        if(value.name===user.userStatus){
                            userStatus = value ;
                        }
                    })

                    this.setState({
                        user:user,
                        userStatus:userStatus,
                        gender:gender,
                        bank:user.bank,
                        village:user.village?user.village:null,
                        district:user.village?user.village.district:null,
                        city:user.village?user.village.district.city:null,
                        province:user.village?user.village.district.city.province:null,
                    }, () => {
                        if(user.id){
                            this.fetchUserDevices()
                        }
                        if(this.state.village){
                            this.fetchCities(this.state.province.id, cities=>{
                                this.setState({
                                    cities:cities
                                })
                            })
                        }
                        if(this.state.city){
                            this.fetchDistricts(this.state.city.id, districts =>{
                                this.setState({
                                    districts:districts
                                })
                            })
                        }
                        if(this.state.district){
                            this.fetchVillages(this.state.district.id, villages=>{
                                this.setState({
                                    villages:villages
                                })
                            })
                        }

                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true);
        }
    }

    fetchUserDevices = () => {
        let user = this.state.user ;
        this.get(Endpoint.API.USER_DEVICES, {
            params:{
                ascending:this.state.deviceAscending,
                sortir:this.state.deviceSortir,
                search:this.state.deviceSearch,
                userId:user.id,
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    userDevices : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, true, true);
    }

    fetchBanks = () => {
        this.get(Endpoint.API.BANKS, {
            params:{
                ascending:this.state.ascending,
                sortir:this.state.sortir
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    banks : response.data
                })
            }
        }, true, true);
    }

    fetchAllRole = () =>{
        this.get(Endpoint.API.ROLES, undefined, null, (response)=>{
            if(isResponseOk(response)){
                this.setState({
                    roles : response.data
                },() => {
                    this.fetchUserDetail(this.state.userIdQuery)
                })
            }
        }, true, true)
    }



    async verifyEmail(id, email){
        let {user} = this.state
        let response = await this.asyncGet(Endpoint.API.USER_VALIDATE_EMAIL, {
            params:{
                id:user.id,
                email:email
            }
        }, null, false, false)

        if(response.code!=200){
            this.setState({
                emailWarning:response.message
            })
            return false ;
        }
        return true

    }

    refreshTransaction = (ascending, sortir) => (
        this.setState({
            ascending:ascending,
            sortir:sortir,
        }, () => {
        })
    )


    openCropper(image, cropperCallback, cancelCallback){
        this.setState({
            image:image,
            imageCropperModal:true,
            cropperCallback:cropperCallback,
            cancelCallback:cancelCallback
        })
    }

    render() {
        let {companyModal, user} = this.state
        let company = user.company?user.company:{}

        let imageAspect = 4/4
        let userStatusModal = this.state.userStatusModal
        let userDevices = this.state.userDevices?this.state.userDevices:[]
        let gender = this.state.gender?this.state.gender:{}
        let injectBalanceModalMode = this.state.injectBalanceModalMode?this.state.injectBalanceModalMode:false

        let userStatusColor = user.userStatus&&user.userStatus===USER_STATUSES[0].name?"green":"#d80718"

        let canOpenMap = false ;
        //https://www.google.com/maps/dir/33.93729,-106.85761/33.91629,-106.866761/33.98729,-106.85861
        let url = "https://www.google.com/maps/dir"
        if(user.deviceCount>0 && userDevices.length>0){
            userDevices.map((item, index)=>{
                if(item.latitude && item.longitude){
                    canOpenMap = true
                    url = url+"/"+item.latitude+","+item.longitude
                }
                return item ;
            })
        }
        let evenOdd = 1 ;

        return (
            <Page
                title={label.UserDetail}
                breadcrumbs={[{ name: label.UserDetail, active: true }]}>
                {super.render()}
                <UpdateUserStatusModal
                    user={user} modal={this.state.updateUserStatusModal}
                    closeCallback={()=>{
                        this.setState({
                            updateUserStatusModal:false
                        })
                    }}
                    okCallback={()=>{
                        this.setState({
                            updateUserStatusModal:false
                        }, () => {
                            this.fetchUserDetail(user.id)
                        })
                    }}/>
                <UserStatusHistoryModal
                    showing={userStatusModal}
                    onClose={()=>{
                        this.setState({userStatusModal:false})
                    }}
                    user={user}/>
                <CompanySelectionModal
                    showing={companyModal}
                    company={company}
                    onClose={()=>{
                        this.setState({companyModal:false})
                    }}
                    onSelect={(company)=>{
                        company = company
                        user.company = company
                        this.setState({companyModal:false, user:user})
                    }}
                />

                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col>
                                        <ButtonGroup block>
                                            <Button color="warning" onClick={(e)=>{
                                                e.preventDefault()
                                                navigate(this.props, UserRoleDetailPage.ENDPOINT+"?id="+user.id)
                                            }}>
                                                {
                                                    label.Role
                                                }
                                                &nbsp;
                                                <MdFilterList/>
                                            </Button>
                                            &nbsp;&nbsp;
                                            <Button
                                                color="danger"
                                                onClick={(e)=>{
                                                    e.preventDefault()
                                                    this.openConfirmDialog(label.ResendPassword, label.DoYouWantToResendAPasswordForThisUser, async ()=>{
                                                        let response = await this.asyncGet(Endpoint.API.USER_RESEND_PASSWORD, {params:{userId:user.id}},
                                                            false, true, false)
                                                        if(response.code===200){
                                                            this.openSuccessDialog(label.Success, label.SuccessSendingThePassword)
                                                        }else{
                                                            this.openFailedDialog(label.Failed, response.message)
                                                        }
                                                    })
                                                }}
                                            >
                                                {label.ResendPassword}&nbsp;<MdSecurity/>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                    <Col>
                                        <ButtonGroup className="float-right" block>
                                            <Button
                                                color="primary"
                                                onClick={async (e)=>{
                                                    e.preventDefault()
                                                    await this.confirmSave()
                                                }}>
                                                {
                                                    label.Update
                                                }
                                                &nbsp;
                                                <MdSave/>
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader>
                                                        {label.UserPhoto}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <MyCardImage top src={
                                                            user.photoUrl!=null?user.photoUrl:userProfilePicture}
                                                                     onClick={e=>{
                                                                         if(!allIsEmpty(user, user.id)) {
                                                                             this.openLightBoxSingleImage(user.photoUrl)
                                                                         }
                                                                     }}
                                                                     onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                                    </CardBody>
                                                    <CardBody style={{verticalAlign:'text-center'}}>
                                                        <Row>
                                                            <Col>
                                                                <ButtonGroup className="float-left">
                                                                    <Button color="danger" onClick={e => {
                                                                        user.photoUrl = null
                                                                        this.setState({
                                                                            user:user
                                                                        })
                                                                    }}>{label.Cancel}</Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                            <Col>
                                                                <ButtonGroup className="float-right">
                                                                    <Button color="primary" onClick={e=>{
                                                                        imageSelector(file =>{
                                                                            this.openCropper(file, (blob)=>{
                                                                                user.photoUrl = blob
                                                                                this.setState({
                                                                                    photoBlob:blob,
                                                                                    user:user
                                                                                })
                                                                            },  () =>{
                                                                                this.setState({
                                                                                    photoBlob:null,
                                                                                })
                                                                            })
                                                                        }).click()
                                                                    }}>{label.Upload}</Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            user.id&&(
                                                                <>
                                                                    <br/>
                                                                    <Row>
                                                                        <Col>
                                                                            <Button
                                                                                style={{width:'100%', background:userStatusColor}}
                                                                                onClick={event => {
                                                                                    this.setState({
                                                                                        updateUserStatusModal:true
                                                                                    })
                                                                                }}>
                                                                                {!user.userStatus?USER_STATUSES[1].name:user.userStatus}
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            user.id&&(
                                                                <>
                                                                    <Row>
                                                                        <Col>
                                                                            <Button
                                                                                block
                                                                                color={"primary"}
                                                                                onClick={event => {
                                                                                    this.setState({
                                                                                        userStatusModal:true
                                                                                    })
                                                                                }}>
                                                                                {label.StatusHistory}
                                                                                &nbsp;&nbsp;<MdHistory/>
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            )
                                                        }
                                                        <br/>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.RegisteredOn}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {user.created?parseDate(user.created, DD_MM_YYYY_HH_MM_SS):"-"}
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.EverLoggedIn}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {
                                                                    !user.firstLogin?(<span style={{color:'green'}}>{label.Yes}</span>):(<span style={{color:'red'}}>{label.Never}</span>)
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.LastOpen}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {user.lastOpenDate?parseDate(user.lastOpenDate, DD_MM_YYYY_HH_MM_SS):"-"}
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.Role}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {
                                                                    user.roleInformation
                                                                }
                                                            </Col>
                                                        </Row>


                                                        {
                                                            user.inspectionStatusCount&&(
                                                                Object.keys(user.inspectionStatusCount).map((key, index)=>(
                                                                    <Row  style={{backgroundColor:((++evenOdd)%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                                        <Col md={5} style={{margin:'auto'}}>
                                                                            {key}
                                                                        </Col>
                                                                        <Col md={1} style={{margin:'auto'}}>
                                                                            :
                                                                        </Col>
                                                                        <Col md={6} style={{margin:'auto'}}>
                                                                            {user.inspectionStatusCount[key]}
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            )
                                                        }

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col md={10}>
                                                                {label.Company}
                                                            </Col>
                                                            <Col md={2} style={{textAlign:'right'}}>
                                                                <Button onClick={()=>{
                                                                    this.setState({companyModal:true})
                                                                }}>
                                                                    <MdEdit/>
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.fullName">{label.Company}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.fullName"
                                                                        value={(company.fullName)?company.fullName:""}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.companyDescription">{label.Description}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.companyDescription"
                                                                        value={(company.companyDescription)?company.companyDescription:""}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={8}>
                                        <Card>
                                            <CardBody>
                                                <Card>
                                                    <CardHeader>{label.Profile}</CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="firstname">{label.Firstname}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="firstname"
                                                                        className="text-capitalize"
                                                                        value={user.firstname}
                                                                        onChange={(e) =>{
                                                                            let firstname = e.target.value
                                                                            user.firstname = firstname
                                                                            this.setState({
                                                                                fistNameWarning:"",
                                                                                user:user
                                                                            })
                                                                        }}
                                                                        placeholder={label.EnterFirstname}
                                                                    />
                                                                    <WarningLabel message={this.state.fistNameWarning} show={!isEmpty(this.state.fistNameWarning)}/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="lastname">{label.Lastname}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="lastname"
                                                                        className="text-capitalize"
                                                                        value={user.lastname}
                                                                        onChange={(e) =>{
                                                                            user.lastname = e.target.value
                                                                            this.setState({
                                                                                lastNameWarning:"",
                                                                                user:user
                                                                            })
                                                                        }}
                                                                        placeholder={label.EnterLastname}
                                                                    />
                                                                    <WarningLabel message={this.state.lastNameWarning} show={!isEmpty(this.state.lastNameWarning)}/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="username">{label.Username}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="username"
                                                                        disabled={true}
                                                                        value={user.username}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={4}>
                                                                <FormGroup>
                                                                    <Label for="userCode">{label.UserCode}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="userCode"
                                                                        value={user.userCode}
                                                                        disabled={true}
                                                                        onChange={(e) =>{
                                                                            user.userCode = e.target.value
                                                                            this.setState({
                                                                                refCodeWarning:"",
                                                                                user:user
                                                                            })
                                                                        }}
                                                                        placeholder={label.ClickButtonToGenerate}
                                                                    />
                                                                    <WarningLabel message={this.state.refCodeWarning} show={!isEmpty(this.state.refCodeWarning)}/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={2}>
                                                                <FormGroup>
                                                                    <Label for="postalCode">&nbsp;</Label>
                                                                    <br/>
                                                                    <Button
                                                                        block
                                                                        onClick={event => {
                                                                        this.generateUserCode()
                                                                        }}>{
                                                                        user.userCode?label.Regenerate:label.Generate
                                                                    }</Button>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={6}>
                                                                <FormGroup>
                                                                    <Label for="email">{label.Email}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="email"
                                                                        value={user.email}
                                                                        onChange={(e) =>{
                                                                            let email = e.target.value
                                                                            let user = this.state.user
                                                                            user.email = email
                                                                            this.setState({
                                                                                emailWarning:"",
                                                                                user:user
                                                                            })
                                                                            if(!isValidEmail(email)){
                                                                                this.setState({
                                                                                    emailWarning:label.InvalidEmailAddress
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    emailWarning:undefined
                                                                                }, async () => {
                                                                                    let valid = await this.verifyEmail(user.id, this.state.user.email)
                                                                                })
                                                                            }
                                                                        }}
                                                                        placeholder={label.EnterEmail}
                                                                    />
                                                                    <WarningLabel message={this.state.emailWarning} show={!isEmpty(this.state.emailWarning)}/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="mobilePhone">{label.MobilePhone}</Label>
                                                                    <Input type="text"
                                                                           className="form-control"
                                                                           value={user.mobilePhone}
                                                                           pattern={"[0-9]*"}
                                                                           onChange={(e)=>{
                                                                               let mobilePhone = e.target.value
                                                                               let user = this.state.user
                                                                               if(user!=null && mobilePhone!=null){
                                                                                   user.mobilePhone = cleanPhoneNumber(mobilePhone)
                                                                                   this.setState({
                                                                                       mobilePhoneWarning:"",
                                                                                       user:user
                                                                                   })
                                                                               }
                                                                           }}
                                                                           placeholder={label.EnterMobilePhone}/>
                                                                    <WarningLabel message={this.state.mobilePhoneWarning} show={!isEmpty(this.state.mobilePhoneWarning)}/>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="phoneNumber">{label.Phone}</Label>
                                                                    <Input type="text"
                                                                           className="form-control"
                                                                           value={user.homePhoneNumber}
                                                                           pattern={"[0-9]*"}
                                                                           onChange={(e)=>{
                                                                               let homePhoneNumber = e.target.value
                                                                               let user = this.state.user
                                                                               if(user!=null && homePhoneNumber!=null){
                                                                                   user.homePhoneNumber = cleanPhoneNumber(homePhoneNumber)
                                                                                   this.setState({
                                                                                       user:user
                                                                                   })
                                                                               }
                                                                           }}
                                                                           placeholder={label.EnterPhoneNumber}/>
                                                                    <FormText className={"font-italic"}><span style={{color:'red'}}>{this.state.phoneNumberWarning}</span></FormText>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="birthdate">{label.BirthDate}</Label>
                                                                    <DateInput
                                                                        id="birthdate"
                                                                        maxdate={new Date()}
                                                                        value={user.birthdate}
                                                                        onChange={(e) =>{
                                                                            let value = e.target.value
                                                                            user.birthdate = value
                                                                            this.setState({
                                                                                user:user
                                                                            })
                                                                        }}
                                                                        placeholder={label.EnterBirthDate}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        title={label.Gender}
                                                                        objects={GENDERS}
                                                                        default={gender.id?gender.id:null}
                                                                        callback={(gender)=>{
                                                                            user.gender = gender.key
                                                                            this.setState({
                                                                                user:user,
                                                                                gender:gender
                                                                            })
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                                <br/>
                                                <Card>
                                                    <CardHeader>
                                                        {label.Address}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={9}>
                                                                <FormGroup>
                                                                    <Label for="address">{label.Address}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="address"
                                                                        className="text-capitalize"
                                                                        value={user.address}
                                                                        onChange={(e) =>{
                                                                            let user = this.state.user
                                                                            if(user!=null){
                                                                                user.address = e.target.value
                                                                                this.setState({
                                                                                    user:user
                                                                                })
                                                                            }
                                                                        }}
                                                                        placeholder={label.EnterAddress}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col md={3}>
                                                                <FormGroup>
                                                                    <Label for="postalCode">{label.PostalCode}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="postalCode"
                                                                        disabled={true}
                                                                        value={(this.state.user!=null && this.state.user.village)?this.state.user.village.postalCode:""}
                                                                        placeholder={label.PostalCode}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        title={label.SelectProvince}
                                                                        objects={this.state.provinces}
                                                                        default={!allIsEmpty(this.state.province)?this.state.province.id:null}
                                                                        callback={(province)=>{
                                                                            if(province!=null){
                                                                                this.setState({
                                                                                    province:province,
                                                                                    cities:[],
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                }, () => {
                                                                                    this.fetchCities(province.id, cities=>{
                                                                                        this.setState({
                                                                                            cities:cities
                                                                                        })
                                                                                    })
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    province:null,
                                                                                    cities:[],
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        title={label.SelectCity}
                                                                        objects={this.state.cities}
                                                                        default={!allIsEmpty(this.state.city)?this.state.city.id:null}
                                                                        callback={(city)=>{
                                                                            if(city!=null){
                                                                                this.setState({
                                                                                    city:city,
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                }, () => {
                                                                                    this.fetchDistricts(city.id, districts =>{
                                                                                        this.setState({
                                                                                            districts:districts
                                                                                        })
                                                                                    })
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    city:null,
                                                                                    districts:[],
                                                                                    villages:[],
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        title={label.SelectDistrict}
                                                                        objects={this.state.districts}
                                                                        default={!allIsEmpty(this.state.district)?this.state.district.id:null}
                                                                        callback={(district)=>{
                                                                            if(district!=null){
                                                                                this.setState({
                                                                                    district:district,
                                                                                    villages:[],
                                                                                }, () => {
                                                                                    this.fetchVillages(district.id, villages=>{
                                                                                        this.setState({
                                                                                            villages:villages
                                                                                        })
                                                                                    })
                                                                                })
                                                                            }else{
                                                                                this.setState({
                                                                                    district:null,
                                                                                    villages:[],
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <ItemOption
                                                                        title={label.SelectVillage}
                                                                        objects={this.state.villages}
                                                                        default={!allIsEmpty(this.state.village)?this.state.village.id:null}
                                                                        callback={(village)=>{
                                                                            if(village!=null){
                                                                                let user = this.state.user
                                                                                user.village = village
                                                                                this.setState({
                                                                                    user:user,
                                                                                    village:village,
                                                                                })
                                                                            }
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {
                    !isEmpty(user.id)?
                        this.renderDevice()
                        :
                        null
                }
                <ImageCropper src={this.state.image} aspect={imageAspect} show={this.state.imageCropperModal} callback={file=>{
                    axios({
                        url: file,
                        method: 'GET',
                        responseType: 'blob', // important
                    }).then((response) => {
                        var fileBlob = response.data ;
                        let cropperCallback = this.state.cropperCallback
                        this.setState({
                            image:file,
                            imageCropperModal:false,
                        })
                        if(cropperCallback){
                            cropperCallback(file)
                        }
                    })
                }} cancelCallback={()=>{
                    this.setState({
                        image:null,
                        imageCropperModal:false,
                    })
                    let cancelCallback = this.state.cancelCallback
                    if(cancelCallback){
                        cancelCallback()
                    }
                }}/>

            </Page>

        );
    }

    refreshUserDevice = (ascending, sortir, progressing) => (
        this.setState({
            deviceAscending:ascending,
            deviceSortir:sortir
        }, () => {
            this.fetchUserDevices()
        })
    )

    renderDevice = () =>{
        var i = 0 ;
        let user = this.state.user
        return (
            <>
                <Card className="mb-6">
                    <CardHeader>
                        <strong>{label.Devices}</strong>
                        <br/><br/>
                        <Row>
                            <Col md={3}>
                                {label.SortBy} :
                                <UncontrolledButtonDropdown key={1}>
                                    <DropdownToggle
                                        caret
                                        color="white"
                                        className="text-capitalize m-1">
                                        {
                                            sortirMap(this.state.deviceSortir)
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.deviceAscending, "ud.created", true))}>{label.Created}</DropdownItem>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(this.state.deviceAscending, "ud.updated", true))}>{label.Updated}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md={3}>
                                {label.Sortir}&nbsp;:
                                <UncontrolledButtonDropdown key={2}>
                                    <DropdownToggle
                                        caret
                                        color="white"
                                        className="text-capitalize m-1">
                                        {
                                            this.state.deviceAscending?label.Ascending:label.Descending
                                        }
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(true, this.state.deviceSortir, true))}>{label.Ascending}</DropdownItem>
                                        <DropdownItem onClick={e=>(this.refreshUserDevice(false, this.state.deviceSortir, true))}>{label.Descending}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            </Col>
                            <Col md={6}>
                                <SearchInput
                                    placeholder={label.SearchDeviceId}
                                    value={this.state.search}
                                    onChange={e=>{
                                        this.setState({
                                            search:e.target.value
                                        }, () => {
                                            if(this.state.search===''){
                                                deleteParam(this.props, 'page')
                                            }
                                        })
                                    }}
                                    onEnter={e=>{
                                        if(this.state.search!==''){
                                            deleteParam(this.props, 'page')
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Table responsive hover>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{label.Platform}</th>
                                    <th>{label.IPAddress}</th>
                                    <th style={{width:'25%'}}>{label.UserAgent}</th>
                                    <th>{label.DeviceId}</th>
                                    <th>{label.Created}</th>
                                    <th>{label.LastOpen}</th>
                                    <th>{label.Delete}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.userDevices&&this.state.userDevices.map((item, index)=>(
                                        <tr key={item.id}>
                                            <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                            <td>{item.platform}</td>
                                            <td>{item.ipAddress}</td>
                                            <td>{item.userAgent}</td>
                                            <td>{item.deviceId}</td>
                                            <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                            <td>{parseDate(item.lastOpenDate, DD_MM_YYYY_HH_MM_SS)}</td>
                                            <td>
                                                <Button size="sm" color="danger" onClick={e => {
                                                    e.preventDefault()
                                                    this.setState({
                                                        userDevice:item
                                                    }, () => {
                                                        this.openConfirmDialog(label.DeleteConfirmationTitle, label.BanThisDevice_TheUserWillBeBannedAfterThisPerform,()=>{
                                                            this.get(Endpoint.API.USER_DEVICE_DELETE+"/"+this.state.userDevice.id, null, null, res => {
                                                                if(res.code===200){
                                                                    this.successToast(res.message)
                                                                    this.fetchUserDevices()
                                                                }
                                                            }, true, true);

                                                        })
                                                    })
                                                }}>
                                                    <MdDelete/>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </Row>
                    </CardBody>
                </Card>
                <br/>
            </>
        );
    }
}

export default Object.assign(UserDetailPage, {ENDPOINT : "/userDetail"})

