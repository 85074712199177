import React from 'react';
import BasePage from './BasePage';

import {Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row,} from 'reactstrap';
import userProfilePicture from 'assets/img/users/user_pp.png';
import Page from '../components/Page';
import {DD_MM_YYYY_HH_MM_SS,} from '../utils/Global';
import {isResponseOk, parseDate,} from "na-utilities/src/utils/Utilities";
import queryString from 'query-string';
// import {UserDto} from '../model/model.ts';
import label from 'na-utilities/src/label/Label'
import MyCardImage from "../components/Card/MyCardImage";
import Endpoint from "na-utilities/src/api/Endpoint";
import UserRoleTable from "../components/UserRoleTable";
import {CompanyDto} from "../model/model";

class UserRoleDetailPage extends BasePage{

    constructor(props) {
        super(props);
        this.state = {
            user : {},
            modules:[],
            roles:[],
            userRoles:[],
            userIdQuery: queryString.parse(this.props.query).id,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.getUserDetail()
    }

    getUserDetail(){
        let {user,userCode,userRoles,userIdQuery} = this.state
        this.get(Endpoint.API.USER, {params:{"id":userIdQuery}}, null, (res)=>{
            if(isResponseOk(res)){
                let user = res.data
                let userRoles = user.userRoles
                let company = user.company
                this.setState({user,userRoles}, () => {
                    this.getRoles(company)
                })
            }
        }, false, false)
    }

    getRoles(company){
        let {user,roles,modules} = this.state
        let groupName = ""
        if(company.code !== "ABACUS"){
            groupName = "USER"
        }
        this.get(Endpoint.API.ROLES, {params:{"groupName":groupName}}, null, (res)=>{
            if(isResponseOk(res)){
                roles = res.data
                let moduleKeyValue = {}
                roles.forEach((role)=>{
                    let module = role.module
                    moduleKeyValue[module.id] = module
                })
                const moduleValue = Object.values(moduleKeyValue);
                moduleValue.forEach((value, index) => {
                    modules[index] = value
                })
                this.setState({roles,modules})
            }
        }, false, false)
    }


    render() {
        let {user, modules, roles,userRoles} = this.state
        let company = user.company?user.company:{}

        let evenOdd = 1 ;


        return (
            <Page
                title={label.UserRole}
                breadcrumbs={[{ name: label.UserRole, active: true }]}>
                {super.render()}

                <Row key={1}>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={4}>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader>
                                                        {label.UserPhoto}
                                                    </CardHeader>
                                                    <CardBody>
                                                        <MyCardImage top src={
                                                            user.photoUrl!=null?user.photoUrl:userProfilePicture}
                                                             onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                                    </CardBody>
                                                    <CardBody style={{verticalAlign:'text-center'}}>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?"":'#d5d5d5'), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.Fullname}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {
                                                                    user.fullname
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?"":'#d5d5d5'), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.Email}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {
                                                                    user.email
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?"":'#d5d5d5'), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.RegisteredOn}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {user.created?parseDate(user.created, DD_MM_YYYY_HH_MM_SS):"-"}
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?"":'#d5d5d5'), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.EverLoggedIn}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {
                                                                    !user.firstLogin?(<span style={{color:'green'}}>{label.Yes}</span>):(<span style={{color:'red'}}>{label.Never}</span>)
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?"":'#d5d5d5'), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.LastOpen}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {user.lastOpenDate?parseDate(user.lastOpenDate, DD_MM_YYYY_HH_MM_SS):"-"}
                                                            </Col>
                                                        </Row>
                                                        <Row style={{backgroundColor:(++evenOdd%2>0?"":'#d5d5d5'), margin:'auto', padding:'8px'}}>
                                                            <Col md={5} style={{margin:'auto'}}>
                                                                {label.Role}
                                                            </Col>
                                                            <Col md={1} style={{margin:'auto'}}>
                                                                :
                                                            </Col>
                                                            <Col md={6} style={{margin:'auto'}}>
                                                                {
                                                                    user.roleInformation
                                                                }
                                                            </Col>
                                                        </Row>


                                                        {
                                                            user.inspectionStatusCount&&(
                                                                Object.keys(user.inspectionStatusCount).map((key, index)=>(
                                                                    <Row  style={{backgroundColor:((++evenOdd)%2>0?'#d5d5d5':""), margin:'auto', padding:'8px'}}>
                                                                        <Col md={5} style={{margin:'auto'}}>
                                                                            {key}
                                                                        </Col>
                                                                        <Col md={1} style={{margin:'auto'}}>
                                                                            :
                                                                        </Col>
                                                                        <Col md={6} style={{margin:'auto'}}>
                                                                            {user.inspectionStatusCount[key]}
                                                                        </Col>
                                                                    </Row>
                                                                ))
                                                            )
                                                        }

                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col md={10}>
                                                                {label.Company}
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.fullName">{label.Company}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.fullName"
                                                                        value={(company.fullName)?company.fullName:""}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.companyDescription">{label.Description}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.companyDescription"
                                                                        value={(company.companyDescription)?company.companyDescription:""}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={8}>
                                        <UserRoleTable
                                            user={user}
                                            modules={modules}
                                            roles={roles}
                                            onChange={(user) => {
                                                userRoles = user.userRoles
                                                this.setState({user, userRoles})
                                            }}
                                            userRoles={userRoles}
                                        />
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>

        );
    }
}

export default Object.assign(UserRoleDetailPage, {ENDPOINT : "/userRoleDetail"})

