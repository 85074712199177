import {getData} from "../utils/StorageUtil";
import Global from "../utils/Global";
import {isEmpty} from "na-utilities/src/utils/Utilities";

let key = getData(Global.LANGUAGE);
if(isEmpty(key)){
    key = Global.DEFAULT_LANGUAGE
}
const translation =  require('./Label.'+key+'.js')

const varToString = varObj => Object.keys(varObj)[0]

const message = function (key) {
    if(!key){
        key = varToString({key})
    }
    key = key.replace(/ /g, "");
    
    let res = translation[key]
    if(!res){
        res = key
        if(res.length>0){
            res = res.split(/(?=[A-Z])/).join(" ")
            res = res.charAt(0).toUpperCase() + res.slice(1)
        }
    }
    return res
}

const IN = "in";
const EN = "en";

export const LANGUAGE = {
    "in":{id:1, name:"Bahasa Indonesia", key:IN, flag:"https://flag.pk/flags/4x3/ID.svg"},
    "en":{id:2, name:"English", key:EN, flag:"https://flag.pk/flags/4x3/US.svg"}
}

export const LANGUAGES = [LANGUAGE[IN],LANGUAGE[EN]]

export const GENDERS = [
    {
        id:1,
        key:"MALE",
        name:translation.Male
    },
    {
        id:2,
        key:"FEMALE",
        name:translation.Female
    }
]

// const content = Object.freeze(translation, GENDERS)
const label = Object.assign(message, translation)
export default label
