import React from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import PropTypes from 'prop-types';


export default class NumberOption extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            callback : this.props.callback,
            numbers:this.props.numbers,
            default:this.props.default,
            disable:this.props.disable,
            title:this.props.title
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.props!=nextProps){
            this.setState({
                callback : nextProps.callback,
                numbers:nextProps.numbers,
                default:nextProps.default,
                disable:nextProps.disable,
                title:nextProps.title
            })
        }
    }
    componentDidMount() {
    }

    render() {
        const numbers = this.state.numbers!=null?this.state.numbers:[];
        const defaultId = this.state.default
        const disabled = this.state.disable
        return (
            <FormGroup>
                {
                    !this.props.hideLabel&&(
                        <Label for="select">{this.props.title}</Label>
                    )
                }
                <Input
                    type="select"
                    name="select"
                    value={defaultId?defaultId:""}
                    onChange={
                        e=>{
                            e.preventDefault()
                            let value = e.target.value
                            if(this.props.callback!=undefined && this.props.numbers.length>0 && e.target.value!=undefined){
                                this.props.callback(value)
                            }
                            this.setState({
                                default:value
                            })
                        }
                    }
                    disabled={disabled}>
                    {
                        !this.props.hideOptionLabel&&(
                            <option value={null}>-- {this.state.title} --</option>
                        )
                    }
                    {
                        numbers.map((item, index) =>(
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))
                    }
                </Input>
            </FormGroup>
        );
    }
}

NumberOption.propTypes ={
    callback : PropTypes.func,
    numbers:PropTypes.any,
    default:PropTypes.any,
    disable:PropTypes.bool,
    title:PropTypes.string,
    hideLabel:PropTypes.bool,
    hideOptionLabel:PropTypes.bool
}
