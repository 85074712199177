import React from "react";
import {CardImg} from "reactstrap";
import {canUseWebP} from "na-utilities/src/utils/Utilities";
import PropTypes from "prop-types";

class MyCardImage extends React.Component{

    render() {
    let {src} = this.props
        if(src && src.includes("base64")){
            // Base 64 nothing to do
        }else{
            if(canUseWebP()){
                src = src+"?webp=true"
            }else{
                src = src+"?webp=false"
            }
        }
    return(
        <CardImg
            {...this.props}
            src={src}
            onError={(e)=>{
                e.target.src = src
                if(this.props.onError) {
                    this.props.onError(e)
                }
            }}/>
    )
    }
}
MyCardImage.propTypes = {
    onError: PropTypes.func
}
export default MyCardImage