import React from 'react';
import BaseComponent from '../components/BaseComponent';
import Endpoint from "na-utilities/src/api/Endpoint";


export default class BasePage extends BaseComponent{

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        super.componentDidMount();
        // validatePrivilege(this.props)
        this.openPage()
    }

    openPage(){
        this.get(Endpoint.API.USER_OPEN_PAGE, null, null, (res)=>{}, false, false)

    }

    // render() {
    //     return (
    //         <div>
    //             {super.render()}
    //         </div>
    //     );
    // }

}
