import React from 'react';
import BasePage from './BasePage';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table
} from 'reactstrap';
import userProfilePicture from 'assets/img/users/user_pp.png';
import Page from '../components/Page';
import {DD_MMMM_YYYY_HH_MM_SS, USER_STATUSES,} from '../utils/Global';
import {allIsEmpty, isEmpty, isResponseOk, navigate, parseDate, toCurrency} from 'na-utilities/src/utils/Utilities';
import queryString from 'query-string';
import FormText from 'reactstrap/es/FormText';
// import {UserDto} from '../model/model.ts';
import {FaEye, MdAdd, MdDelete, MdPictureAsPdf, MdSend} from 'react-icons/all';
import label, {GENDERS} from 'na-utilities/src/label/Label'
import WarningLabel from "../components/Widget/WarningLabel";
import MyCardImage from "../components/Card/MyCardImage";
import CompanyDetailPage from "./CompanyDetailPage";
import UserDetailPage from "./UserDetailPage";
import ItemOption from "../components/Widget/ItemOption";
import {BASE_URL} from "../Variable";
import Endpoint from "na-utilities/src/api/Endpoint";
import SpotLocationSelectionModal from "../components/modal/SpotLocationSelectionModal";

class FollowUpDetailPage extends BasePage{

    constructor(props) {
        super(props);
        this.state = {
            phoneNumberWarning:"",
            user:{},
            roles:[],
            productPackages:[],
            productPackage:{},
            paymentAttribute:{},
            orderItems:[],
            photoBlob:null,
            image:null,
            userIdQuery: queryString.parse(this.props.query).id,
            gender:{},
            userStatus:{},
            ascending:false,
            sortir:'created',
            banks:[],
            bank:{},
            provinces:[],
            cities:[],
            districts:[],
            villages:[],
            province:{},
            city:{},
            district:{},
            village:{},
            productOrderStatuses:[],
            productOrders:[],
            spotLocations:[],
            productOrderStatus:null,
            refCode:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            deviceSortir:"ud.created",
            deviceSearch:"",
            deviceAscending:false,
            orderItemModal:false,
            spotLocationSelectorModal:false,
            selectedProductPackage:{}
        }
        this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchUserByRegistration(this.state.userIdQuery)
        this.fetchProductPackages((productPackages)=>{
            this.setState({productPackages})
        })
        this.fetchPaymentMediaAttribute((paymentAttribute)=>{
            this.setState({paymentAttribute})
        })
    }

    fetchPaymentMediaAttribute(callback){
        this.get(Endpoint.API.PAYMENT_MEDIA_ATTRIBUTE, {},null, (response)=>{
            if(isResponseOk(response)){
                callback(response.data)
            }
        },   false, false)
    }

    createOrder(){
        let {user, orderItems} = this.state
        let registration = user.registration?user.registration:{}
        let productPackageIdsBase64 = "";
        let productPackageIds = ""
        orderItems.forEach((item, index)=>{
            let productPackage = item.productPackage
            productPackageIds += productPackage.id
            if(index<orderItems.length-1){
                productPackageIds += ","
            }
        })
        productPackageIdsBase64 = btoa(productPackageIds)
        let form = new FormData()
        form.append("orderItemListGson", JSON.stringify(orderItems))
        this.post(Endpoint.API.PRODUCT_ORDER_CREATE+"/"+user.id,form,null, (response)=>{
            if(isResponseOk(response)){
                this.props.history.goBack();
            }
        }, true, true)
    }

    previewInvoice(){
        let {user, orderItems} = this.state
        let registration = user.registration?user.registration:{}
        let productPackageIdsBase64 = "";
        let productPackageIds = ""
        orderItems.forEach((item, index)=>{
            let productPackage = item.productPackage
            productPackageIds += productPackage.id
            if(index<orderItems.length-1){
                productPackageIds += ","
            }
        })
        productPackageIdsBase64 = btoa(productPackageIds)
        window.open(BASE_URL+"/invoice/preview/"+registration.id+"/"+productPackageIdsBase64,'_blank');
    }

    onAddOrderItem(productPackage, spotLocation){
        let {user, productPackages, orderItems, orderItemModal, spotLocations} = this.state
        // spotLocation = {}
        // if(spotLocations && spotLocations.length==1){
        //     spotLocation = spotLocations[0]
        //     orderItems.push({productPackage:productPackage, spotLocation:spotLocation})
        //     this.setState({orderItems})
        // }else{
            this.setState({spotLocationSelectorModal:true, selectedProductPackage:productPackage})
        // }
    }

    fetchSpotLocations(){
        let {user,sortOption} = this.state
        let company = user.company?user.company:{}
        this.get(Endpoint.API.SPOT_LOCATIONS, {
            params :{
                companyId : company.id
            }
        }, null, response=>{
            if(response.code===200){
                let spotLocations = response.data
                this.setState({spotLocations:spotLocations})
            }
        }, false, true)
    }


    fetchUserByRegistration = (id) => {
        if(id){
            this.get(Endpoint.API.USER_BY_REGISTRATION, {
                params :{
                    id : id
                }
            }, null, res=>{
                if(res.code===200){
                    let {roles} = this.state
                    let user = res.data;
                    let gender = null
                    GENDERS.forEach(value => {
                        if(value.key===user.gender){
                            gender = value ;
                        }
                    })
                    let userStatus = null
                    USER_STATUSES.forEach(value => {
                        if(value.name===user.userStatus){
                            userStatus = value ;
                        }
                    })


                    this.setState({
                        user:user,
                        userStatus:userStatus,
                        gender:gender,
                        bank:user.bank,
                        village:user.village?user.village:null,
                        district:user.village?user.village.district:null,
                        city:user.village?user.village.district.city:null,
                        province:user.village?user.village.district.city.province:null,
                    }, () => {
                        if(this.state.village){
                            this.fetchCities(this.state.province.id, cities=>{
                                this.setState({
                                    cities:cities
                                })
                            })
                        }
                        if(this.state.city){
                            this.fetchDistricts(this.state.city.id, districts =>{
                                this.setState({
                                    districts:districts
                                })
                            })
                        }
                        if(this.state.district){
                            this.fetchVillages(this.state.district.id, villages=>{
                                this.setState({
                                    villages:villages
                                })
                            })
                        }
                        this.fetchSpotLocations()

                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true);
        }
    }



    render() {
        let {user, productPackages, orderItems, orderItemModal, productPackage, paymentAttribute, selectedProductPackage, spotLocationSelectorModal} = this.state
        let company = user.company?user.company:{}
        let industry = company.industry?company.industry:{}
        let companyType = company.companyType?company.companyType:{}
        let registration = user.registration?user.registration:{}

        let imageAspect = 4/4
        let userDevices = this.state.userDevices?this.state.userDevices:[]
        let gender = this.state.gender?this.state.gender:{}
        let adminFee = paymentAttribute.adminFee?paymentAttribute.adminFee:0
        let taxPercent = paymentAttribute.taxPercent?paymentAttribute.taxPercent:0
        let taxAmount = 0
        let total = 0
        let totalAmount = 0
        orderItems.forEach((item)=>{
            let productPackage = item.productPackage
            total += productPackage.price
        })
        // total = total+adminFee
        taxAmount = (total+adminFee)*(taxPercent/100)
        totalAmount = total+taxAmount+adminFee


        return (
            <Page
                title={label.FollowUp}
                breadcrumbs={[{ name: label.UserDetail, active: true }]}>
                {super.render()}
                <SpotLocationSelectionModal
                    showing={spotLocationSelectorModal}
                    company={company}
                    onClose={()=>{
                        this.setState({selectedProductPackage:{}, spotLocationSelectorModal:false})
                    }}
                    onSelect={(spotLocation)=>{
                        orderItems.push({productPackage:selectedProductPackage, spotLocation:spotLocation})
                        this.setState({orderItems, selectedProductPackage:{}, spotLocationSelectorModal:false})
                    }}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col>
                                                                {label.UserPhoto}
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <MyCardImage top src={
                                                            user.photoUrl!=null?user.photoUrl:userProfilePicture}
                                                                     onClick={e=>{
                                                                         if(!allIsEmpty(this.state.user, this.state.user.id)) {
                                                                             this.openLightBoxSingleImage(user.photoUrl)
                                                                         }
                                                                     }}
                                                                     onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={10}>
                                                <FormGroup>
                                                    <Label for="firstname">{label.Fullname}</Label>
                                                    <Input
                                                        type="text"
                                                        name="firstname"
                                                        className="text-capitalize"
                                                        readOnly={true}
                                                        value={(this.state.user&& this.state.user.fullname)?this.state.user.fullname:""}
                                                        placeholder={label.EnterFirstname}
                                                    />
                                                    <WarningLabel message={this.state.fistNameWarning} show={!isEmpty(this.state.fistNameWarning)}/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>&nbsp;</Label>
                                                    <Button onClick={() => {
                                                        navigate(this.props, UserDetailPage.ENDPOINT+"?id="+user.id)
                                                    }}><FaEye/></Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="email">{label.Email}</Label>
                                                    <Input
                                                        type="text"
                                                        name="email"
                                                        value={user.email}
                                                        readOnly={true}
                                                        placeholder={label.EnterEmail}
                                                    />
                                                    <WarningLabel message={this.state.emailWarning} show={!isEmpty(this.state.emailWarning)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="mobilePhone">{label.MobilePhone}</Label>
                                                    <Input type="text"
                                                           className="form-control"
                                                           value={user.mobilePhone}
                                                           pattern={"[0-9]*"}
                                                           readOnly={true}
                                                           placeholder={label.EnterMobilePhone}/>
                                                    <WarningLabel message={this.state.mobilePhoneWarning} show={!isEmpty(this.state.mobilePhoneWarning)}/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="phoneNumber">{label.Phone}</Label>
                                                    <Input type="text"
                                                           className="form-control"
                                                           value={user.homePhoneNumber}
                                                           pattern={"[0-9]*"}
                                                           readOnly={true}
                                                           placeholder={label.EnterPhoneNumber}/>
                                                    <FormText className={"font-italic"}><span style={{color:'red'}}>{this.state.phoneNumberWarning}</span></FormText>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={9}>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardHeader>
                                                        <Row>
                                                            <Col>
                                                                {label.Company}
                                                            </Col>
                                                            <Col style={{textAlign:'right'}}>
                                                                <Button onClick={() => {
                                                                    navigate(this.props, CompanyDetailPage.ENDPOINT+"?id="+company.id)
                                                                }}><FaEye/></Button>
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="registration.created">{label.RegisteredOn}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="registration.created"
                                                                        value={parseDate(registration.created, DD_MMMM_YYYY_HH_MM_SS)}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="registration.verifiedAt">{label.Verified}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="registration.verifiedAt"
                                                                        value={parseDate(registration.verifiedAt, DD_MMMM_YYYY_HH_MM_SS)}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.fullName">{label.Company}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.fullName"
                                                                        value={(company.fullName)?company.fullName:""}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.email">{label.Email}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.email"
                                                                        value={(company.email)?company.email:""}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.companyPhoneNumber">{label.Phone}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.companyPhoneNumber"
                                                                        value={(company.companyPhoneNumber)?company.companyPhoneNumber:""}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.industry">{label.Industry}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.industry"
                                                                        value={industry.name}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="company.companyType">{label.CompanyType}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        name="company.companyType"
                                                                        value={companyType.name}
                                                                        readOnly={true}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Card>
                                                    <CardBody>
                                                        <Row>
                                                            <Col md={7}>
                                                                {label.Order}
                                                            </Col>
                                                            <Col md={4}>
                                                                <ItemOption
                                                                    hideLabel={true}
                                                                    hideOptionLabel={false}
                                                                    fieldForLabel={"name"}
                                                                    objects={productPackages}
                                                                    default={productPackage.id}
                                                                    callback={(productPackage)=>{
                                                                        this.setState({productPackage:productPackage})
                                                                    }}
                                                                    title={label.SelectProduct}
                                                                    className="float-right"/>
                                                            </Col>
                                                            <Col md={1}>
                                                                <ButtonGroup className="float-right">
                                                                    <Button
                                                                        disabled={!productPackage.id}
                                                                        color="primary"
                                                                        onClick={e=>{
                                                                            if(productPackage && productPackage.id){
                                                                                this.onAddOrderItem(productPackage)
                                                                            }
                                                                        }}><MdAdd/></Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={12}>
                                                                <Table responsive hover>
                                                                    <thead>
                                                                    <tr>
                                                                        <th>#</th>
                                                                        <th>{label.Name}</th>
                                                                        <th>{label.Description}</th>
                                                                        <th>{label.Product}</th>
                                                                        <th>{label.Placement}</th>
                                                                        <th>{label.MonthPeriod}</th>
                                                                        <th>{label.Price}</th>
                                                                        <th>{label.Remove}</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        orderItems.map((item, index)=>(
                                                                            <tr key={index}>
                                                                                <th scope="row">{(index+1)}</th>
                                                                                <td>{item.productPackage.name}</td>
                                                                                <td>{item.productPackage.description}</td>
                                                                                <td>{item.productPackage.name}</td>
                                                                                <td>{item.spotLocation.name}</td>
                                                                                <td className="text-center">{item.productPackage.monthPeriod}</td>
                                                                                <td>{item.productPackage.priceView}</td>
                                                                                <td>
                                                                                    <Button
                                                                                        color={"danger"}
                                                                                        onClick={() => {
                                                                                            orderItems.splice(index, 1)
                                                                                            this.setState({orderItems})
                                                                                        }}>
                                                                                        <MdDelete/>
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    </tbody>
                                                                </Table>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                    <CardFooter>
                                                        <Row>
                                                            <Col md={6}></Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{label.Total}</Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{"Rp. "+toCurrency(total)}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}></Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{label.AdminFee}</Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{"Rp. "+toCurrency(adminFee)}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}></Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{label.Tax}</Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{"Rp. "+toCurrency(taxAmount)}</Col>
                                                        </Row>
                                                        <Row>
                                                            <Col md={6}></Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{label.TotalAmount}</Col>
                                                            <Col md={3} style={{textAlign:'right'}}>{"Rp. "+toCurrency(totalAmount)}</Col>
                                                        </Row>
                                                    </CardFooter>
                                                    <CardFooter>
                                                        <Row>
                                                            <Col md={6}></Col>
                                                            <Col md={6} style={{textAlign:'right'}}>
                                                                <ButtonGroup>
                                                                    <Button
                                                                        color="warning"
                                                                        disabled={orderItems.length<=0}
                                                                        onClick={() => {
                                                                            if(orderItems.length>=0) {
                                                                                this.previewInvoice()
                                                                            }
                                                                        }}
                                                                    >
                                                                        {label.PreviewInvoice} &nbsp; <MdPictureAsPdf/>
                                                                    </Button>
                                                                    &nbsp;&nbsp;
                                                                    <Button
                                                                        color="secondary"
                                                                        disabled={orderItems.length<=0}
                                                                        onClick={()=>{
                                                                            if(orderItems.length>=0) {
                                                                                this.openConfirmDialog(label.Confirmation, label.CreateOrderByAdminConfirmation, () => {
                                                                                    this.createOrder()
                                                                                })
                                                                            }
                                                                        }}
                                                                    >
                                                                        {label.ApproveAndCreateOrder} &nbsp; <MdSend/>
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </Col>
                                                        </Row>
                                                    </CardFooter>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>

        );
    }
}

export default Object.assign(FollowUpDetailPage, {ENDPOINT : "/followUpDetail"})

