import AuthForm from 'components/AuthForm';
import React from 'react';
import {Card, Col, Row} from 'reactstrap';
import queryString from "query-string";
import BaseComponent from "../components/BaseComponent";

class AuthPage extends BaseComponent {

    constructor(props) {
        super(props);
        let state = this.state
        state.message = queryString.parse(this.props.query).message
        this.setState(state)
    }

    render() {

        let {message} = this.state
        return (
            <Row
                style={{
                    height: '100vh',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Col md={6} lg={4}>
                    <Card body>
                        <AuthForm
                            message={message}
                        />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default Object.assign(AuthPage, {ENDPOINT: "/login"})
