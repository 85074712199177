import * as React from 'react'
import Typography from "../../components/Typography";
import label from 'na-utilities/src/label/Label'
import {isEmpty} from "na-utilities/src/utils/Utilities";

class Props{
    active : boolean = false
    trueLabel : string = ""
    falseLabel : string = ""
    singleLabel : string = ""
}

export default class ActiveLabel extends React.Component{

    props : Props = new Props()
    state : any = {}

    constructor(props : any) {
        super(props);
        this.state = {
            trueLabel:props.trueLabel,
            falseLabel:props.falseLabel,
            singleLabel:props.singleLabel
        }
    }
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        this.setState({
            trueLabel:nextProps.trueLabel,
            falseLabel:nextProps.falseLabel,
            singleLabel:nextProps.singleLabel
        })
    }

    render() {
        let trueLabel = this.state.trueLabel
        let falseLabel = this.state.falseLabel
        let singleLabel = this.state.singleLabel
        if(!trueLabel){
            trueLabel = label.Active
        }
        if(!falseLabel){
            falseLabel = label.Inactive
        }

        return (
            <Typography tag={""} type={""} className={!isEmpty(singleLabel)?"text-warning":(this.props.active?"text-primary":"text-warning")}>
                <strong>
                    {
                        !isEmpty(singleLabel)?singleLabel:(this.props.active?trueLabel:falseLabel)
                    }
                </strong>
            </Typography>
        );
    }
}

// ActiveLabel.propTypes = {
//     active:PropTypes.bool.isRequired,
//     trueLabel:PropTypes.string,
//     falseLabel:PropTypes.string,
// }
