import Page from 'components/Page';
import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import kitchen from '../utils/AxiosInstance';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {isEmpty, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveLabel from '../components/Widget/ActiveLabel';
import {IoMdMenu} from 'react-icons/io';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class RolePage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      roles : [],
      role:{},
      categories:[],
      category:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var role = this.state.role
    if(isEmpty(role.name)){
      this.showDialog("Notice", "Please complete this form fields")
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }

  save = () => {
    let role = this.state.role
    if(!role.id && !role.active){
      role.active = false ;
    }
    this.setState({
      modalAddConfirm:false
    }, () => {
      kitchen.post(Endpoint.API.ROLE_SAVE, role, null).then(res => {
        if(res.data.code===200){
          this.setState({
            role:{}
          }, () => {
            this.fetchAll()
          })
        }else{

        }
      });
    })
  }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      kitchen.get(Endpoint.API.ROLE_DELETE+"/"+this.state.role.id).then(res => {
        if(res.data.code===200){
          this.setState({
            role:{}
          }, () => {
            this.fetchAll()
          })
        }else{

        }
      });
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Endpoint.API.ROLES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          roles : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )

  render() {
    return (
        <Page
            title={label.Role}
            breadcrumbs={[{ name: label.Role, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message="Do you want to update this role item to your list ?"
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message="Do you want to delete this role item from your list ?"
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.Role}</CardHeader>
                <CardBody >
                  <Row>
                    <Col>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.Module}</th>
                                <th>{label.Status}</th>
                                <th>{label.Updated}</th>
                                <th>Menu</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.roles.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.name}</td>
                                      <td>{item.module.name}</td>
                                      <td><ActiveLabel active={item.active}/></td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button
                                            color="primary"
                                            onClick={event=>{
                                              event.preventDefault()
                                              this.props.history.push("/menu?roleId="+item.id)
                                            }}>
                                          <IoMdMenu/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}
export default Object.assign(RolePage, {ENDPOINT : "/roles"})
