
const label =  {
    Username:"Username",
    Submit:"Submit",
    Cancel:"Cancel",
    Back:"Back",
    Or:"Or",
    And:"And",
    Ok:"Ok",
    Edit:"Edit",
    Remove:"Remove",
    Save: "Save",
    Close:"Close",
    Email:"Email",
    Page:"Page",
    Detail:"Detail",
    AddProduct:"Add Product",
    AddInventory:"Add Inventory",
    EnterYourMail:"Enter Your Email",
    YourEmail:"emailanda@email.com",
    Password:"Password",
    ConfirmPassword:"Confirm Password",
    EnterPassword:"Enter Password",
    EnterConfirmPassword:"Enter confirm password",
    EnterOldPassword:"Enter old password",
    EnterNewPassword:"Enter new password",
    ForgetPassword:"Forget Password",
    NotRegisteredYet:"Not Registered Yet",
    InvalidEmailFormat:"Invalid Email Format",
    PleaseEnterYourEmailWeWillSendYouInstructionToResetYourPassword:"Please Enter Your Email We Will Send You Instruction To Reset Your Password",


    SelectProduct:"Select Product",
    SelectCabinet:"Select Cabinet",
    SelectSize:"Select Size",
    Sortir:"Sortir",
    SortBy:"Sort By",
    Ascending:"Ascending",
    Descending:"Descending",
    Confirmation:"Confirmation",
    UpdateConfirmationMessage:"Do you want to update your content",
    DeleteConfirmationTitle:"Delete Confirmation",
    DeleteConfirmationMessage:"Do you want to delete the selected content",

    _Maked:"Created",
    Created:"Created",
    Name:"Name",
    BrandName:"Brand Name",
    Heading:"Menu",
    Category:"Sub Menu",
    Updated:"Updated",
    Create:"Create",
    Update:"Update",
    Add:"Add",
    SelectStatus:"Select Status",
    Active:"Active",
    Inactive:"Inactive",
    Expired:"Expired",
    All:"All",
    AllInventory:"All Inventory",
    Type:"Type",
    Quantity:"Quantity",
    Weight:"Weight",
    Price:"Price",
    Fullname:"Fullname",
    Phone:"Phone",

    Search:"Search",
    SearchCreator:"SearchCreator",
    TypeAndEnterToSearch:"Enter the keyword..",
    SearchByName:"Search By Name",
    HashTag:"Hashtag",
    Image:"Image",
    Slug:"Slug",
    Status:"Status",
    ChannelSize:"Channel Size",
    LastStatus:"Last Status",
    PreviousStatus:"Previous Status",
    Warning:"Warning",
    PleaseCompleteTheFields:"Please Complete The Fields",
    ProductForm:"Product Form",
    UseImageTypeInstruction:(type, size)=>"Use "+type+" image type, with maximum size of file is "+size+" Kb",
    Upload:"Upload",
    EnterProductName:"Enter Product Name",
    EnterQuantity:"Enter Quantity",
    EnterSlug:"Enter Slug",
    EnterWeight:"Enter Weight",
    EnterPrice:"Enter Price",
    EnterPricePerKW:"Enter Price Per KW",
    HoursExpiredAfterCreated:"Hours Expiring After Created",
    HoursExpiredAfterPaid:"Hours Expiring After Paid",
    HoursExpiredOnWaitingForPayment:"Hours Expiring After Waiting For Payment",
    EnterHoursExpiredAfterCreated:"Enter Hours Expiring After Created",
    EnterHoursExpiredAfterPaid:"Enter Hours Expiring After Paid",
    EnterHoursExpiredOnWaitingForPayment:"Enter Hours Expiring After Waiting For Payment",
    AutoSlug:"Auto Slug",
    SelectBrand:"Select Brand",
    SelectHeading:"Select Heading",
    SelectCategory:"Select Category",
    SelectFormType:"Select Form Type",
    RelatedHashTag:"Related HashTag",
    Description:"Description",
    EnterDescription:"Enter Description",
    Size:"Size",
    EnterSize:"Enter Size",
    EnterName:"Enter Name",
    EnterBrandName:"Enter Brand Name",
    CategoryDescription:"Category Description",
    CategoryName:"Category Name",
    EnterCategoryName:"Enter Category Name",
    EnterCategoryDescription:"Enter Category Description",
    EnterProvinceName:"Enter Province Name",
    SelectProvince:"Select Province",
    SelectSmartDeviceType:"Select Smart Device Type",
    EnterCityName:"Enter City Name",
    SelectCity:"Select City",
    EnterDistrictName:"Enter District Name",
    SelectDistrict:"Select District",
    EnterVillageName:"Enter Village Name",
    SelectVillage:"Select Village",
    PostalCode:"Postal Code",
    Creator:"Creator",
    ContentOrStockLabel:"Content / Stock",
    SoldStock:"Sold Stock",
    Stock:"Stock",
    StockLog:"Stock History",
    SelectSortir:"Select Sortir",
    SelectDirection:"Select Direction",
    SelectInOurOut:"Select In / Out",
    SelectStockAuditType:"Select Auditn Type",
    StartDate:"Start Date",
    EndDate:"End Date",
    CreatedStartDate:"Created Start Date",
    CreatedEndDate:"Created End Date",
    UpdatedStartDate:"Updated Start Date",
    UpdatedEndDate:"Updated End Date",
    EnterStartDate:"Enter Start Date",
    EnterEndDate:"Enter End Date",
    Note:"Note",
    View:"View",
    IconClass:"Icon Class",
    EnterIconClass:"Enter Icon Class",
    InventoryForm:"Inventory Form",
    AdminForm:"Admin Form",
    CustomerForm:"Customer Form",
    PointForm:"Point Form",
    VoucherForm:"Voucher Form",
    PopularProduct:"Popular Product",
    LatestProduct:"Latest Product",
    Latest:"Latest",
    OrderQuantity:"Order Quantity",
    SoldOut:"Sold Out",
    Added:"Added",
    EnterBankLabel:"Enter Bank Label",
    EnterBankName:"Enter Bank Name",
    EnterBankCode:"Enter Bank Code",
    EnterAccountBankNumber:"Enter Bank Account Number",
    EnterAccountBankName:"Enter Bank Account Name",
    SMSBankingSenderId:"SMS Banking Sender Id",
    SMSBankingTemplate:"SMS Banking Template",
    EnterSmsBankingSenderId:"Enter Sms Banking Sender Id",
    SMSPrefixIndicator:"SMS Prefix Indicator",
    EnterSMSPrefixIndicator:"Enter SMS Prefix Indicator",
    CurrencyFormat:"Currency Format",
    CurrencyFormatExample:"Example : ##.###,##",
    EnterMobilePhone:"Enter Mobile Phone",
    EnterCustomerReceiverEmail:"Enter Customer Receiver Email",
    EnterSubscriberReceiverEmail:"Enter Subscriber Receiver Email",
    EnterSortir:"Enter Sortir",
    EnterPageLink:"Enter Page Link",
    ProductOrderCount:"Product Order Count",
    ProductOrderDate:"Tanggal Order",
    FavoriteCount:"Product Date",
    CartCount:"Cart Count",
    OriginalPrice:"Original Price",
    FinalPrice:"Final Price",
    Select:"Select",
    Check:"Check",
    CompanyDescription:"Company Description",
    EnterCompanyDescription:"Enter Company Description",
    VoucherCode:"Voucher Code",
    Owner:"Owner",
    Theme:"Theme",
    Period:"Period",
    Benefit:"Benefit",
    Aggregate:"Aggregate",
    StartPeriod:"Start Period",
    EndPeriod:"End Period",
    SelectPeriod:"Select Period",
    SelectVoucherBenefit:"Select Voucher Benefit",
    SelectPointBenefit:"Select Point Benefit",
    SelectVoucherOwnerType:"Select Voucher Owner Type",
    SelectVoucherDiscountType:"Select Voucher Discount Type",
    SelectAggregateType:"Select Aggregate Type",
    MaxUsage:"Maximum Usage",
    CurrentUsage:"Current Usage",
    Publish:"Publish",
    Unpublish:"Unpublish",
    ValidDay:"Valid Day",
    PurchaseDiscount: "Purchase Discount",
    ShippingDiscount:"Shipping Discount",
    FreeShipping:"Free Shipping",
    PercentageDiscount : "Percentage Discount",
    PriceDiscount : "Price Discount",
    AllTime:"All Time",
    Dynamic:"Dynamic",
    Static:"Static",
    SpecificCustomer:"Specific Customer",
    AnyCustomer:"Any Customer",
    SingleItem:"Single Item",
    JoinItem:"Join Item",
    AnyItem:"Any Item",
    PointExchange:"Point Exchange",
    EnterVoucherCode:"Enter Voucher Code",
    EnterVoucherName:"Enter Voucher Name",
    ThemeColor:"Theme Color",
    AvailableItemForThisVoucher:"Available Item For This Voucher",
    MinimumPurchaseQuantity:"Minimum Purchase Quantity",
    AvailableStock:"Available Stock",
    CustomerWhoHasThisVoucher:"Customer Who Has This Voucher",
    SelectPointType:"Select Point Type",
    PurchaseItemPoin:"Purchase Item Point",
    PurchasePricePoin:"Purchase Price Point",
    PurchasePercentagePoint:"Purchase Percentage Point",
    FixedPoint:"Fixed Point",
    PointBonus:"Bonus Point",
    PurchaseMinimum:"Purchase Minimum",
    ProductItemBelongToThisPoin:"Product Item Belong To This Point",
    RegisteredForThisPoin:"Registered For This Point",
    RegisteredForAnotherPoin:"Registered For Another Point",
    NotRegisteredForAnyPoin:"Not Registered For Any Point",
    PublishConfirmation:"Publish Confirmation",
    UnpublishConfirmation:"Unpublish Confirmation",
    PublishConfirmationMessage:"Do you want to publish this point ?",
    UnpublishConfirmationMessage:"Do you want to unpublish this point ?",
    FlashSaleContent:"Flash Sale Content",
    Inactivate:"Inactivate",
    Activate:"Activate",
    InactivateConfirmation:"Inactivate Confirmation",
    ActivateConfirmation:"Activate Confirmation",
    InactivateFlashSaleConfirmationMessage:"Do you want to unactivate this flash sale ?",
    ActivateFlashSaleConfirmationMessage:"Do you want to activate this flash sale ?",
    SearchProduct:"Search Product",
    FlashSaleStock:"Flash Sale Stock",
    FilterCustomerSearch:"Filter Customer Search",
    RegistrationStartDate:"Registration Start Date",
    RegistrationEndDate:"Registration End Date",
    Gender:"Gender",
    FilterCustomerByProductOrder:"Customer Search Filtering By Order Filter",
    ProductOrderStartDate:"Order Start Date",
    ProductOrderEndDate:"Order End Date",
    MobilePhone:"Mobile Phone",
    ProductOrderDuring:"Order During",
    ProductOrderFavoriteCartColumnLabel:"Trx/Fav/Cart",
    Address:"Address",
    CustomerAddress:"Customer Address",
    ListOfDestinationAddress:"List Of Destination Address",
    EnterAddress:"Enter Address",
    Firstname:"Firstname",
    EnterFirstname:"Enter Firstname",
    Lastname:"Lastname",
    EnterLastname:"Enter Lastname",
    EnterEmail:"Enter Email",
    EnterPhoneNumber:"Enter Phone Number",
    BirthDate:"Birth Date",
    EnterBirthDate:"Enter Birth Date",
    SelectGender:"Select Gender",
    ProductOrderItemQuantity:"Order Item Quantity",
    TotalPay:"Total Payment",

    /**/
    // Product Order Status
    /**/
    Paid:"Paid",
    Packaging:"Packaging",
    Delivery:"Delivery",
    Done:"Done",
    Rejected:"Rejected",
    OnReturn:"OnReturn",
    AcceptedReturn:"Accepted Return",
    Order:"Order",
    /**/
    // End Product Order Status
    /**/

    Payment:"Payment",
    PaymentFee:"PaymentFee",
    ResiAndTracking:"Resi & Tracking",
    Print:"Print",
    SearchByReferenceCode:"Search By Reference Code",
    YourCustomerCart:"Your Customer Cart",
    RemoveAllCustomerCartConfirmationMessage:"Do you want to remove every customer's cart ?",
    RemoveCustomerCartConfirmationMessage:"Do you want to remove this customer's cart ?",
    RemoveAllCustomerCart:"Remove All Customer Cart",
    RemoveCartRespectively:"Remove Cart Respectively",
    PerCustomer:"Per Customer",
    RemoveHisCart:"Remove His Cart",
    SelectType:"Select Type",
    SearchCustomer:"Search Customer",
    CustomerName:"Customer Name",
    SearchAdmin:"Search Administrator",
    Attribute:"Attribute",
    AttributeForm:"Attribute Form",
    Open:"Open",
    OpenPageLink:"Open Pagelink",
    PageLink:"Page Link",
    EnterColorName:"Enter Color Name",
    Hexa:"Hexa",
    EnterLabel:"Enter Label",
    Label:"Label",
    SendEmail:"Send Email",
    Subject:"Subject",
    SendAt:"Send At",
    SendAnnouncement:"Send Announcement",

    PublishConfigurationConfirmationMessage:"Do you want to publish this configuration ? Configuration would be implemented through your website setting",
    ConfigurationDetail:"Configuration Detail",
    PageRow:"Page Row",
    FavoriteRow:"Favorite Row",
    LatestRow:"Latest Row",
    DiscountRow:"Discount Row",
    EnterDiscountRow:"Enter Discount Row",
    FlashSaleRow:"Flash Sale Row",
    EnterFlashSaleRow:"Enter Flash Sale Row",
    SelebgramRow:"Selebgram Row",
    EnterSelebgramRow:"Enter Selebgram Row",
    ImageSize:"Image Size",
    MainProductMaximumRow:"Main Product Maximum Row",
    EnterMainProductMaximumRow:"Enter Main Product Maximum Row",
    EnterCourierApiName:"Enter Courier Api Name",

    FreePaymentFee:"Free Payment Fee",
    BubbleWrapFee:"Bubble Wrap Fee",
    CompanyName:"Company Name",
    EnterCompanyName:"Enter Company Name",
    CompanyPhoneNumber:"Company Phone Number",
    EnterCompanyPhoneNumber:"Enter Company Phone Number",
    ContactPersonPhoneNumber:"Contact Person Phone Number",
    EnterContactPersonPhoneNumber:"Enter Contact Person Phone Number",
    ProductRelatedSize:"Product Related Size",
    EnterProductRelatedSize:"Enter Product Related Size That You Need To Display",
    EnterFavoriteRow:"Enter Favorite Row That You Need To Display",
    EnterLatestRow:"Enter Latest Row That You Need To Display",
    InventoryImageSize:"Maximum Inventory Image Size",
    EnterInventoryImageSize:"Enter Maximum Inventory Image Size",
    VoucherCodeLength:"Voucher Code Length",
    EnterVoucherCodeLength:"Enter Voucher Code Length",
    BankImageDimension:"Bank Image Dimension",
    Width:"Width",
    Height:"Height",
    BrandImage:"Brand Image",
    MidtransMediatorImageDimension:"Midtrans Mediator Image Dimension",
    CourierImageDimension:"Courier Image Dimension",
    ProductImageDimension:"Product Image Dimension",
    ProductOrderConfiguration:"Order Configuration",
    CourierServiceBaseURL:"Courier Service Base URL",
    EnterCourierServiceBaseURL:"Enter Courier Service Base URL",
    CourierServiceApiKey:"Courier Service Api Key",
    EnterCourierServiceApiKey:"Enter Courier Service Api Key",
    ShippingOriginAddress:"Shipping Origin Address",
    EnterShippingOriginAddress:"Enter Shipping Origin Address",
    SocialMedia:"Social Media",
    EnterHashTag:"Enter #hashtag",
    InstagramLink:"Instagram Link",
    EnterInstagramLink:"Enter Instagram Link",
    FacebookLink:"Facebook Link",
    EnterFacebookLink:"Enter Facebook Link",
    TwitterLink:"Twitter Link",
    EnterTwitterLink:"Enter Twitter Link",
    FacebookApplicationId:"Facebook Application Id",
    EnterFacebookApplicationId:"Enter Facebook Application Id",
    GoogleApplicationId:"Google Application Id",
    EnterGoogleApplicationId:"Enter Google Application Id",
    TawkToReference:"Tawk To Reference",
    EnterTawkToReference:"Enter Tawk To Reference",
    SelectRole:"Select Role",
    ResendPassword:"Resend Password",
    AccessMenu:"Access Menu",
    Menu:"Menu",
    Domain:"Domain",
    ClientKey:"Client Key",
    ServerKey:"Server Key",
    Environment:"Environment",
    PaymentLimitExpiration:"Payment Expiration Limit",
    BankCode:"Bank Code",
    BankAccountNumber:"Bank Account Number",
    BankAccountName:"Bank Account Name",
    SelectBank:"Select Bank",
    BalanceInformation:"Balance Information",
    History:"History",
    BalanceMutation:"Balance Mutation",
    Mutation:"Mutation",
    WithdrawalHistory:"Withdrawal History",
    ReferenceCode:"Reference Code",
    Reference:"Reference",
    Credit:"Credit",
    Debit:"Debit",
    SelectDebitOrCredit:"Select Debit / Credit",
    Amount:"Amount",
    RemainBalance:"RemainBalance",
    WithdrawalConfirmationTitle:"Withdrawal Confirmation",
    RequestWithdrawConfirmationMessage:(amount)=>"You will issued withdrawal with amount "+amount+", " +
        "\nPlease ensure you have entered bank account & account number well. " +
        "We will proceed around 2x24 hours. " +
        "Please wait or if you need to ask, do not hestitate to talk with our customer support, Thank You",
    YesIamSure:"Yes Im Sure",
    Sure:"Sure",
    ReCheck:"Recheck",
    WarningWithdrawExist:"You have another incomplete withdrawal request, please wait, Our staff would process it soon",
    Waiting:"Waiting",
    Processing:"Processing",
    Issued:"Issued",
    Postponed:"Postponed",
    StatusHistory:"Status History",
    WithdrawalStatusHistory:"Withdrawal Status History",
    WaitingForPayment:"Waiting For Payment",
    WaitingForSubscriptionPayment:"Waiting For Subscription Payment",
    WaitingForUpgradePayment:"Waiting For Upgrade Payment",
    ExpiredOn:"Expired On",
    Channel:"Channel",
    Account:"Account",
    Total:"Total",
    Tax:"Tax",
    AccountInformation:"Account Information",
    RegisteredOn:"Registered At",
    ActivePeriod:"Active Period",
    YourProductService:"Your Product Service",
    ExtendPeriod:"Extend Period",
    ExtendSubscriptionPeriod:"Extend Subscription Period",
    PayNow:"Pay Now",
    PAY_NOW:"PAY NOW",
    RequestUpgrade:"Request Upgrade",
    REQUEST_UPGRADE:"REQUEST UPGRADE",
    MonthlyPeriod:"Monthly Period",
    Month:"Month",
    Months:"Months",
    ProductOption:"Product Option",
    AdditionalPayment:"Additional Payment",
    AdditionalDiscount:"Additional Discount",
    PaymentMethod:"Payment Method",
    ProductInformation:"Product Information",
    ProductName:"Product Name",
    ValidityInformation:"Validity Information",
    ValidFrom:"Valid From",
    ValidUntil:"Valid Until",
    CreateProductSubscribe:"Create Product Subscription",
    Item:"Item",
    PleaseEnterReceiverBankAccountOnYourAccountPage:"Please complete your receiver bank account, it can be complete it on Account Page and only can be accessed by owner !",
    Refresh:"Refresh",
    TotalCustomer:"Total Customer",
    TotalCustomerProductOrder:"Total Customer Order",
    PreviousMonth:"Previous Month",
    ThisMonth:"This Month",
    PreviousYear:"Previous Year",
    ThisYear:"This Year",
    TotalProductOrder:"Total Order",
    TotalProductOrderAmount:"Total Order Amount",
    OnGoingProductOrder:"On Going Order",
    Date:"Date",
    SelectCriteria:"Select Criteria",
    TotalAmount:"Total Amount",
    BubbleWrapAmount:"Bubble Wrap Amount",
    CourierAmount:"Courier Amount",
    TotalQuantity:"Total Quantity",
    PurchaseItemQuantity:"Purchase Item Quantity",
    EnterYourTicketMessageHereInstruction:"Enter your message here... \nYou can also add an image attachment for more clearly information..",
    Attachment:"Attachment",
    PhysicalServiceAddress:"Physical Service Address",
    PublicDomain:"Public Domain",
    ProductSubscribe:"Product Subscribe",
    SubscribePeriod:"Subscribe Period",
    CloudVPS:"Cloud VPS",
    VPSInformation:"VPS Information",
    Yes:"Yes",
    TotalRecord:"Total Record",
    TotalPage:"Total Page",
    YourAccount:"Your Account",
    TakeAPayment:"Take A Payment",
    ExtendNow:"Extend Now",
    DoYouWantToLogoutFormThiApplication:"Do You Want To Logout From This Application ?",
    RegistrationDate:"Registration Date",
    Until:"Until",

    Branch:"Branch",
    Title:"Title",
    EnterTitle:"Enter Title",
    FlashSaleName:"Flash Sale Name",
    EnterFlashSaleName:"Enter Flash Sale Name",
    EnterSenderEmail:"Enter Sender Email",
    WhenItCouldBeSent:"When It Could Be Sent ?",
    EnterNoteRecommended:"Enter Note (Recommended)",
    EnterResiCode:"Enter Resi Code",
    EnterDailyOperationInAPlainWords:"Enter Daily Operation In Plain Words",
    EnterSettingName:"Enter Setting Name",
    EnterStock:"Enter Stock",
    EnterPercentOfDiscount:"Enter Percent Of Discount",
    ChangePassword:"Change Password",
    OldPassword:"Old Password",
    NewPassword:"New Password",
    Receiver:"Receiver",
    Origin:"Origin",
    Destination:"Destination",
    DailyOperation:"Daily Operation",
    UpdateResiCode:"Update Resi Code",
    SubjectEmail:"Subject Email",
    Base:"Base",
    Api:"Api",
    Simulator:"Simulator",
    EditFlashSale:"Edit Flash Sale",
    CreateFlashSale:"Create Flash Sale",
    Scheduled:"Scheduled",
    MessageForm:"Message Form",
    ReplyFrom:"Reply From",
    SeeTicket:"See Ticket",
    CreateTicket:"Create Ticket",
    InvalidInputOfWaybill:"Invalid Input Of Waybill",
    No:"No",
    VoucherPublishMessage:"Do you want to publish this voucher to your customer ?",
    VoucherUnpublishMessage:"Do you want to unpublish this voucher from your customer ?",
    OriginAddress:"Origin Address",
    StockAllocation:"Stock Allocation",
    Allocation:"Allocation",
    SpecialCloudVPS:"Special Cloud VPS",
    Filter:"Filter",
    DeviceFilter:"Device Filter",
    UserFilter:"User Filter",
    CategoryFilter:"Category Filter",
    AdvanceFilter:"Advance Filter",
    ClickToExpandFilterContent:"Click To Expand Filter Content",
    productOrderStartDate:"Order Start Date",
    productOrderEndDate:"Order End Date",
    DestinationProvince:"Destination Province",
    DestinationCity:"Destination City",
    DestinationDistrict:"Destination District",
    DestinationVillage:"Destination Village",
    WithVoucher:"With Voucher",
    EnterNameOrEmailOrPhoneNumberOrRefCodeOrResiCode:"Enter name, email, phone number, reference code or resi code",
    ApplyFilter:"Apply Filter",
    ResetFilter:"Reset Filter",
    DownloadExcel:"Download Excel",
    DownloadFile:"File Download",
    Download:"Download",
    DeliveryDuration:"Delivery Duration",
    Main:"Main",
    Profile:"Profile",
    Help:"Help",
    Guide:"Guide",
    Signout:"Signout",
    Message:"Message",
    Ticket:"Ticket",
    ListOfImage:"List Of Image",
    EnterHeadingName:"Enter heading name",
    SearchFlashSaleName:"Search flashsale name...",
    Information:"Information",
    EnterInformation:"Enter Information",
    EmailSubjectCannotBeingEmpty:"Email subject cannot being empty",
    IfYouChooseScheduledModeThenSendAtCannotBeingEmpty:"If you choose schedule mode, then send at field cannot being empty",
    IfYouChooseNonRegulerThenSenderFieldCannotBeingEmpty:"If you choose non reguler then sender field cannot being empty",
    PleaseEnterAMinimumSingleReceiver:"Please enter a minimum single receiver",
    PleaseEnterTheContentOfThisAnnouncement:"Please enter the content of this announcement",
    DoYouWantToSendThisAnnouncementRightNowPleaseItCannotBePostponed:"Do you want to send this announcement right now, please be careful, it can't be canceled",
    DoYouWantScheduleThisAnnouncementAtTheMomentYouFillInPleaseItCannotBePostponed:"Do you want to make a schedule foor this announcement at the time you fill in, please be careful, it can't be canceled",
    RegularSender:"Regular Sender",
    RightNow:"Right Now",
    AllCustomer:"All Customer",
    AllSubscriber:"All Subscriber",
    AnnouncementContentOptions:"Announcement Content Options",
    ManualTyping:"Manual Typing",
    UploadHtmlFile:"Upload Html File",
    CustomerReceiver:"Customer Receiver",
    Sender:"Sender",
    PreviewHtmlContent:"Preview Html Content",
    PreviewContent:"Preview Content",
    WriteYourCSSInline:"Write your CSS inline or on your head tag, so it only need a single file html",
    UseImageWichisAlreadyStoredOnServer:"Use image wich can be accessed by public internet, and insert the src url to image src inline like https://domain.com/image.jpg, " +
        "or you may use also base 64 format",
    ThirdPartyKeys:"Third Party Keys",
    ListMenu:"List Menu",

    AppName:"Smart Supplies",
    LOGIN:"LOGIN",
    SIGNUP:"SIGNUP",
    UpdateConfirmation:"Update Confirmation",
    Logout:"Logout",
    TotalUser:"Total User",
    TotalInspection:"Total Inspection",
    TotalInspectionDone:"Total Inspection Done",
    TotalTransfer:"Total Transfer",
    OnGoingInspection:"On Going Inspection",
    Pass:"Pass",
    Taken:"Taken",
    NotPass:"NotPass",
    Closed:"Closed",
    Suspended:"Suspended",
    Opened:"Opened",
    OnWorking:"On Working",
    UserCode:"User Code",
    ProductCategory:"Product Category",
    BalanceOfAllUser:"Balance Of All User",
    PaidAmountOfAllUser:"Paid Amount Of All Users",
    Success:"Success",
    ProductCategoryDetail:"Product Category Detail",
    MONTHS_LABEL:["January", "February","March","April","May","June","July","Augustus","September","October","November","December"],
    Failed:"Failed",
    ModelCount:"Model Count",
    EnterFullname:"Enter Fullname",
    EnterMessage:"Enter Message",
    GenerateAutomatically:"Generate Automatically",
    EnterProductPackageCode:"Enter product package code",
    EnterProductPackageName:"Enter product package name",
    AddModel:"Add Model",
    AddBrand:"Add Brand",
    Fee:"Fee",
    Limitation:"Limitation",
    InspectionLimit:"Inspection Limit",
    User:"User",
    InspectionDone:"Inspection Done",
    Website:"Website",
    Suggestion:"Showing Priority",
    Publication:"Publication",
    Published:"Published",
    Unpublished:"Unpublished",
    ModelList:"Model List",
    Cabinet:"Cabinet",
    CabinetDetail:"Detil Grup Cabinet",
    CabinetList:"Grup Cabinet List",
    AddCabinet:"Add Cabinet",
    FormBuilder:"Cabinet Container Builder",
    FormName:"Cabinet Container Name",
    EnterFormName:"Enter Form Name",
    CabinetRack:"Cabinet Rack",
    AddCabinetRow:"Add Row",
    AddCabinetRack:"Add Rack",
    EnterFormPage:"Enter Cabinet Container page",
    FormCard:"Cabinet Container Input",
    AddFormCard:"Add Cabinet Container Input",
    Tag:"Tag",
    EnterTag:"Enter tag",
    Mandatory:"Mandatory",
    True:"True",
    False:"Tidak",
    UnallowedCharacters:"Unallowed Characters",
    EnterUnallowedCharacters:"Enter Unallowed Characters",
    EnterFormType:"Enter form type",
    EnterFormTitle:"Enter form title",
    Placeholder:"Placeholder",
    EnterPlaceholder:"Enter placeholder",
    MaxLength:"Max Length",
    EnterMaxLength:"Enter max length",
    LineCount:"Line Count",
    EnterLineCount:"Enter line count",
    ImageDescription:"Image Description",
    ImageDirectory:"Image Directory",
    ExampleReplacer:"Example replacer",
    ExampleDirectoryFormat:"Example directory format",

    PleaseEnterFormTag:"Please enter a form tag",
    PleaseEnterAFormTitle:"Please enter a form title",
    SaveCabinet:"Save Cabinet",
    UserList:"User List",
    Installation:"Installation",
    Installed:"Installed",
    Uninstalled:"Uninstalled",
    SoftwareInstallation:"Software Installation",
    LastOpen:"Last Open",
    EnterReceiverEmail:"Enter receiver emails",
    SlugAlreadyBeenUsed:"Slug already been used",
    CodeAlreadyBeenUsed:"Code already been used",
    Subtitle:"Subtitle",
    EnterSubtitle:"Enter Subtitle",
    PleaseEnterFormName:"Mohon enter form name",
    PleaseSelectLockerType:"Please select device locker",
    PleaseSelectRFIDReaderType:"Please select RFID reader",
    PleaseSelectParallelPosition:"Please select parallel position",
    PleaseSelectLockerPosition:"Please select locker position",
    PleaseSelectRfidChannelPosition:"Please select channel position",
    EnterRoleName:"Enter role name",
    EnterMidtransMediatorLabel:"Enter midtrans mediator label",
    EnterMidtransMediatorName:"Enter midtrans mediator name",

    EnterGroupMenu:"Enter group menu",
    EnterQueryKey:"Enter query's key",
    EnterReference:"Enter reference",
    EnterRecommendedInformation:"Enter additional information (Recommended)",
    EnterRecommendedReceiptCode:"Enter receipt code (Recommended)",
    EnterFAQTitle:"Enter FAQ title",
    EnterFAQSubTitle:"Enter FAQ subtitle",
    EnterWebsite:"Enter website",
    EnterBusinessField:"Enter business field",
    EnterBuildDate:"Enter build date",
    EnterCompanyAddress:"Enter company address",
    EnterStartPeriod:"Enter start period",
    EnterEndPeriod:"Enter end period",
    EnterIdCardNumber:"Enter ID Card / Government ID",
    ClickButtonToGenerate:"Click button to generate",
    Organization:"Organization",
    EnterOrganization:"Enter Organization",
    EnterAccountName:"Enter account name",
    AccountName:"Nama Akun",
    EnterAccountNumber:"Enter account number",
    AccountNumber:"Account Number",
    EnterForSpecificEmailSender:"Enter for specific email sender",
    ModelDetail:"Model Detail",
    TimeValidating:"Time Validating",
    ModelIconRatio:"Model Icon, Dimention 1 (width) * 1 (height)",
    InspectionDetail:"Inspection Detail",
    ModelAddressLink:"Model Link Address",
    Platform:"Platform",
    DeviceId:"Device ID",
    FCMToken:"Firebase Cloud Messaging Token",
    Delete:"Delete",
    SearchDeviceId:"Search device id...",
    ManufacturingSite:"Manufacturing Site",
    ManufacturingSiteDetail:"Manufacturing Site Detail",
    EnterManufacturingSiteName:"Enter manufacturing site name",
    PleaseSelectManufacturingSite:"Please select manufacturing site",
    Companies:"Companies",
    Company:"Company",
    SelectCompany:"Select Company",
    Stations:"Stations",
    Station:"Station",
    SelectStation:"Select Station",
    GenerateCode:"Generate Code",
    AddLocation:"Add Location",
    AddCompany:"Add Company",
    CompanyForm:"Company Form",
    PriceConfiguration:"Price Configuration",
    ProductOrderPerStation:"Order Per Station",
    SoC:"SoC",
    SoCAvailable:"SoC Available",
    SetSoCAvailability:"Set SoC Availability",
    SuspendedEVToFinishInSecond:"Suspended EV To Finish In Second",
    EnterSuspendedEVToFinishInSecond:"Enter Suspended EV To Finish In Second",
    UnchangingMeterToFinishInSecond:"Unchanging Meter To Finish In Second",
    UnchangingMeterToFinishInFrequent:"Unchanging Meter To Finish In Frequent",
    EnterUnchangingMeterToFinishInSecond:"Enter Unchanging Meter To Finish In Second",
    EnterUnchangingMeterToFinishInFrequent:"Enter Unchanging Meter To Finish In Frequent",
    Module:"Module",
    GroupName:"Group Name",
    CompleteYourRegistrationInformation:"Complete Your Registration Information",
    CompanyInformation:"Company Information",
    Industry:"Industry",
    Verified:"Verified",
    ProductPackage:"Product Package",
    ProductPackageDetail:"Product Package Detail",
    MonthPeriod:"Month Period",
    EnterMonthPeriod:"Enter Month Period",
    EnterSuggestion:"Enter Suggestion",
    FollowUp:"Follow Up",
    FollowUpList:"Follow Up List",
    CompanyType:"Company Type",
    SelectIndustry:"Select Industry",
    AgreeWithTermAndCondition:"Agree with term and condition",
    AgreeWith:"Agree with",
    TermAndCondition:"Term and condition",
    SignUp:"Sign Up",
    VerifyYourMobilePhone:"Verify your mobile phone",
    CheckYourMobilePhoneAt : (mobilePhone) => "Check your mobile phone with number "+mobilePhone,
    OTPCodeSentToYourMobilePhone : (mobilePhone) => "OTP Code sent to your mobile phone : "+mobilePhone,
    WeAreDoingVerifyYourMobilePhone_PleaseFillThisFormWithTheOTPCodeThatWeSendToYourMobilePhone:"We are doing verify your mobil phone, please fill this form with the otp code that we sent to your mobile phone.",
    RegistrationConfirmation:"Registration Confirmation",
    RegistrationSucceed:"Registration Succeed",
    RegistrationSucceedNote:"Thank you for submission, your information has been proceed well, please go to sign in page and login with your account authentication",
    PleaseMakeSureYouHaveFulfillTheFormWithCorrectValueWeWillSendToYouOTPCodeToVerifyYourPhoneNumber_PleaseHoldYourPhoneCloseToYouAndVerifyYourPhoneNumberAtTheNextStep:
        "Please make sure you have fulfill the form with correct value, we will send to you OTP code to verify your phone number. " +
        "\nPlease make your phone close to you and verify your phone number at the next step",
    Continue:"Continue",
    PhoneNumberHasAlreadyBeenUsed:"Phone number has already been used",
    UsernameHasAlreadyBeenUsed:"Username has already been used",
    CompanyNameHasAlreadyBeenUsed:"Company name has already ben used",
    WebsiteHasAlreadyBeenUsed:"Website has already been used",
    SomethingUnexpected:"Something unexpected",
    YouWillBeRedirectToLoginPageForMoment:"You will be redirect to login page for a moment",


}
const baru = {
    SearchByCustomerNameOrModelNameOrRefCode:"Search by user, model or ref code...",
    LimitType:"Limit Type",
    Limit:"Limit",
    VerificationType:"Verification Type",
    WorkerVerificationType:"Worker Verification Type",
    InspectionCount:"Inspection Count",
    TotalFee:"Total Fee",
    InspectionReferenceCode:"Inspection Reference Code",
    BrandReferenceCode:"Brand Reference Code",
    StatusHistory:"Status History",
    BrandStatus:"Brand Status",
    ModelApplicationInfo:"Model Application Info",
    SelectVerificationStatus:"Select Verification Status",
    SelectUserStatus:"Select User Status",
    ReferralAgent:"Referral User",
    EverLoggedIn:"Ever Logged In",
    Never:"Never",
    TotalIncome:"Total Income",
    CurrentBalance:"Current Balance",
    CompletedInspection:"Completed Inspection",
    PostponedInspection:"Postponed Inspection",
    IDCard:"ID Card",
    InvalidEmailAddress:"Invalid email address",
    Regenerate:"Regenerate",
    Generate:"Generate",
    UserDetail:"User Detail",
    AddUser:"Add User",
    LocationOnMap:"Location On Map",
    OpenInMap:"Open In Map",
    EnterTitleSubtitleSlugBrandOrCategoryToSearch:"Enter title, description, slug, brand, or category to search..",
    SearchNameEmailPhoneUserCodeOrOrganization:"Search name, email, phone, or user code.. ",
    LastOpenStart:"Last Open Start",
    LastOpenEnd:"Last Open End",
    Male:"Male",
    Female:"Female",
    UnrecognizedDueNotAuthenticated:"Unrecognized due not authenticated",
    EnterDeviceIdUserCodeOrNameToSearch:"Enter device id, user code, or name to search..",
    AuthenticatedAsUser:"Authenticated",
    NotAuthenticatedAsUser:"Not Authenticated",
    AuthenticationOption:"Authentication Option",
    ReceiverOption:"Receiver Option",
    NotificationDetail:"Notification Detail",
    SendingConfirmationTitle:"Sending Confirmation",
    DoYouWantToSendThisNotificationWithTheSelectedTopic:"Apakah anda ingin mengirim notifikasi ini dengan spesifikasi topic yang anda isi ?",
    InspectionMustBeingOrderedSequentially:"Inspection must being ordered sequentially one by one",
    InspectionCanBeAppliedMoreThanOneSimultaneously:"Inspection can be applied more than one in simultaneously",
    InspectionSubmissionOrder:"Inspection Order",
    MaxUsagePerUser:"Max usage per User",
    MaxInspectionPerUserDefaultUnlimitedPlaceholder:"Max productOrder per User (Default 0 for unlimited)",
    MaximumInspectionCanBeApplied:"Maximum productOrder can be applied",
    MaximumApplicationUsage:"Maximum Inspection Usage",
    CurrentApplicationUsage:"Current Inspection Usage",
    MaxUsagePerUserCannotBeLargerThanMaxUsageGeneral:"Max productOrder per user cannot be larger than general max usage",
    MaxUsageCannotBeSmallerThanCurrentUsage:"Max productOrder cannot be smaller than current usage",
    PleaseEnterModelAddressLink:"Please complete model address link",
    PleaseEnterCallbackId:"Please enter callback id",
    PleaseEnterCabinet:"Please enter group form",
    PleaseEnterModelTimeValidationByAdmin:"Please enter model time validation by admin",
    ModelFee:"Model Fee",
    PleaseEnterModelFee:"Please enter model fee",
    CanBeAppliedByUserVerificationAt:"Can be applied by user verification at",
    CanOnlyBeAppliedByVerifiedUser:"Can onlu be applied by verified user",
    CanBeAppliedByUnverifiedUser:"Can be applied by unverified user",
    TimeApplicationValidationByAdminInHour:"Time Application Validation Process By Admin (In Hour)",
    PleaseEnterDescription:"Please enter description",
    PleaseEnterReferenceDocument:"Please enter additional info",
    ReferenceDocument:"Reference Document",
    MainModelDetail:"Main Model Detail",
    EnterMainModelLabel:"Enter main model label",
    SelectModel:"Select Model",
    MainModelIcon:"Main model icon",
    MultiFinanceDetail:"Multi Finance Detail",
    Label:"Label",
    EnterMultiFinanceLabel:"Enter multi finance label",
    EnterMultiFinanceName:"Enter multifinance name",
    EnterLabelOfInstallmentPeriod:"Masukkan label dari tenor",
    EnterPeriodOfInstallmentPeriod:"Masukkan tenor",
    InstallmentPeriod:"Installment Period",
    InstallmentPeriodDetail:"Installment Period Detail",
    EnterDiscussionCategoryName:"Enter discussion category name",
    DiscussionCategoryDetail:"Discussion Category Detail",
    AgentFee:"User Fee",
    AgentFeeDetail:"User Fee Detail",
    SelectProductAgent:"Select Product User",
    SelectMultiFinance:"Select MultiFinance",
    SelectInstallmentPeriod:"Select Installment Period",
    PreviewCount:"Preview Count",
    ShareCount:"Share Count",
    CommentCount:"Comment Count",
    ReplyCount:"Reply Count",
    Comment:"Comment",
    CommentReply:"Comment Reply",
    PartName:"Part Name",
    PN:"P/M",
    ACInput:"AC Input",
    Frequency:"Frequency",
    ACInputCurrent:"Ac Input Current",
    DCOutput:"DC Output",
    Polarity:"Polarity",
    CORef:"CO Ref",

    EnterPartName:"Enter Part Name",
    EnterPn:"Enter PN",
    EnterACInput:"Enter AC Input",
    EnterFrequency:"Enter Frequency",
    EnterACInputCurrent:"Enter Ac Input Current",
    EnterDCOutput:"Enter DC Output",
    EnterPolarity:"Enter Polarity",
    EnterCORef:"Enter CO Ref",
    Condition:"Condition",
    Container:"Container",
    Containers:"Containers",
    Enabled:"Enabled",
    SmartDevices:"SmartDevices",
    SmartDevice:"SmartDevice",
    AddContainer:"Add Container",
    AddSmartDevice:"Add SmartDevice",
    ContainerType:"Container Type",
    EnterContainerType:"Enter Container Type",
    QRCode:"QR Code",
    ProductOrderList:"Order List",
    SelectProduct:"Select Product",
    SelectSmartDevice:"Pilih Smart Device",
    PaymentReference:"Payment Reference",
    PaymentMethod:"Payment Method",
    ProductOrderAmount:"Order Amount",
    ProductOrderDetail: "Order Detail",
    AmountInformation: "Amount Information",
    Purchase:"Purchase",
    Usage:"Penggunaan",
    Remain:"Remain",
    Refund:"Refund",
    OnCharging:"On Charging",
    Canceled:"Dibatalkan",
    PricePerKW:"Price per KW",
    BuyAmount:"Buy Amount",
    AdminFee:"Admin Fee",
    ParkingHour:"Parking Hour",
    ParkingFeeHour:"Parking fee per Hour",
    TotalParkingFee:"Total Parking Fee",
    TotalProductOrderAmount:"Total Order Amount",
    OtherInformation:"Other Information",
    SubPaymentMedia:"Sub Payment Media",
    SubPaymentMediaDetail:"Sub Payment Media Detail",
    SmartDeviceConfiguration:"Smart Device Configuration",
    Key:"Key",
    ReadOnly:"Read Only",
    Original:"Original",
    ResetAll:"Reset All",
    Position:"Position",
    EnterPosition:"Enter Position",
    On:"On",
    Off:"Off",
    Used:"Used",
    Products:"Products",
    SmartDeviceType:"Smart Device Type",
    LockerDevice:"Locker Device",
    RegisteredLockerDevice:"Registered Locker Device",
    EnterSmartDeviceType:"Enter Smart Device Type",
    _24Hours:"24 Hours",
    OpenOperational:"Open",
    CloseOperational:"Close",
    EnterOpenTime:"Enter open time",
    EnterCloseTime:"Enter close time",
    GenerateCode:"Generate Code",
    AddLocation:"Add Stasiun",
    AddCompany:"Add Company",
    CompanyForm:"Company Registration",
    SuccessCallbackUrl:"Success Callback Url",
    FailedCallbackUrl:"Failed Callback Url",
    EnterSuccessCallbackUrl:"Enter Success Callback Url",
    EnterFailedCallbackUrl:"Enter Failed Callback Url",
    HaveUserAccess:"Have User Access",
    HaveAdminAccess:"Have Admin Access",
    Current:"Current",
    ApproveAndCreateOrder:"Approve and Create Order",
    CreateOrder:"Create Order",
    CreateOrderByAdminConfirmation:"Do you want to create order for this customer",
    PleaseEnterCompanyNameCorrectly:"Please enter company name correctly",
    PleaseEnterWebsiteCorrectly:"Please enter website name correctly",
    PleaseSelectIndustry:"Please select industry",
    PleaseSelectCompanyType:"Please select company type",
    PleaseEnterAddressCorrectly:"Please enter address correctly",
    PleaseSelectProvince:"Please select province",
    PleaseSelectCity:"Please select city",
    PleaseSelectDistrict:"Please select district",
    PleaseSelectVillage:"Please select village",
    SignIn:"Sign In",
    SignInNow:"Sign In Now",
    RegisterNow:"Register Now",
    PageNotFound:"Page Not Found",
    PageNotFoundDescription:"The page you want to access is invalid, or something unexpected happen at a system, if you think your destination should exist do not hestitate to contact us",
    InvalidLink:"Invalid Link",
    InvalidLinkDescription:"The link you saw is invalid, you may get invalid link or expired link. Please look at the new correct one.",
    UsernameOrEmail:"Username or Email",
    EnterYourUsernameOrEmail:"Enter your username or email",
    KeepLoggedIn:"Keep logged in",
    SignInToApplication:"Sign in to Application",
    DoesntHaveAnAccount:"Doesn\'t have an account ?",
    HomePage:"Home Page",
    SignInWithRFIDOrFingerPrint:"Signin with RFID or Fingerprint",
    SignInWithScanner:"Signin with Scanner",
    SignInWithUsernamePassword:"Signin with Username",
}

const menu = {
    Dashboard:"Dashboard",
    Account:"Account",
    Product:"Product",
    Brand:"Brand",
    Heading:"Menu",
    Category:"Category",
    ItemProduct:"Item Product",
    Inventory:"Inventory",
    Popular:"Popular",
    Latest:"Latest",
    MainProduct:"Main Product",
    StockAudit:"Stock Audit",
    Program:"Program",
    Discount:"Discount",
    Voucher:"Voucher",
    Point:"Point",
    PointName:"Point Name",
    FlashSale:"Flash Sale",
    Shopper:"Shopper",
    Customer:"Customer",
    ProductOrder:"Product Order",
    Cart:"Cart",
    Activity:"Activity",
    CustomerActivity:"Customer Activity",
    AdminActivity:"Admin Activity",
    Miscellaneous:"Miscellaneous",
    Banner:"Banner",
    Selebgram:"Selebgram",
    Color:"Color",
    Size:"Size",
    OnlineShop:"Online Shop",
    Branches:"Branches",
    CustomerGuide:"Customer Guide",
    Announcement:"Announcement",
    Broadcast:"Broadcast",
    Subscriber:"Subscriber",
    Administrator:"Administrator",
    Configuration:"Configuration",
    Admin:"Admin",
    Role:"Role",
    UserRole:"User Role",
    PaymentMedia:"Payment Media",
    Bank:"Bank",
    BankAccount:"Bank Account",
    MidtransMediator:"Midtrans Mediator",
    Courier:"Courier",
    Region:"Region",
    Province:"Province",
    City:"City",
    District:"District",
    Village:"Village",
    PaymentTypeCode:"Payment Type Code",
    EnterPaymentTypeCode:"Enter Payment Type Code",
    AmountFee:"Amount Fee",
    PercentFee:"Percent Fee (%)",
    EnterAmountFee:"Enter Amount Fee",
    EnterPercentFee:"Enter Percent Fee",
    EnterCourierLabel:"Enter Courier Label",
    CourierCode:"Courier Code",
    Code:"Code",
    RequestWithdrawal:"Request Withdrawal",
    ProductOrderDetail:"Order Detail",
    ProductOrderInfo:"Order Info",
    ResiCode:"Resi Code",
    CourierCost:"Courier Cost",
    Service:"Service",
    ProductOrderStatus:"Product Order Status",
    ItemPrice:"Item Price",
    UniquePrice:"Unique Price",
    BubbleWrap:"Bubble Wrap",
    ShippingAddress:"Shipping Address",
    ProductOrderItem:"Order Item",
    TotalPrice:"Total Price",
    Source:"Source",
    TotalWeight:"Total Weight",
    UpdateStatus:"Update Status",
    ChangeStatus:"Change Status",
    CurrentStatus:"Current Status",

    Business:"Business",
    Inspection:"Inspection",
    InspectionScheme:"Inspection Scheme",
    Entities:"Entities",
    InspectionEvent:"Inspection Event",
    SubMenu:"Sub Menu",
    Model:"Model",
    FormBuilder:"Cabinet Container Builder",

    Worker:"Worker",
    QCManagement:"QC Management",
    Devices:"Devices",
    UserDevices:"User Devices",
    ConfigurationAdmin:"Admin Configuration",
    ConfigurationEmail:"Email Configuration",
    AdminDevices:"Admin Devices",
    User:"User",
    Verification:"Verification",
    RegHistory:"Reg History",
    Withdraw:"Withdraw",
    WithdrawalRequest:"Withdrawal Request",
    Mutation:"Mutation",
    UserActivity:"User Activity",
    Notification:"Notification",
    ApplicationVersion:"Application Version",
    GoodsCategory:"Goods Category",
    MainModel:"Main Model",
    Reward:"Reward",
    Fee:"Fee",
    MultiFinance:"Multi Finance",
    InstallmentPerio:"Installment Period",
    Discussion:"Discussion",
    DiscussionCategory:"Discussion Category",
    CategoryImage:"Category Image",
    UserPhoto:"User Photo",
    UserAgent:"User Agent",
    SystemType:"System Type",
    ModelName:"Model Name",
    Form:"Form",
    EnterSystemType:"Enter system type name / model",
    EnterModelName:"Enter model name",
    Scope:"Scope",
    TestingEquipmentAndAccessories:"Testing Equipment And Accessories",
    SystemSpecification:"System Specification",
    SerialComponent:"Serial Component",
    Value:"Value",
    PassInspection:"Pass Inspection",
    NotPassInspection:"Not Pass Inspection",
    PostponedInspection:"Postponed Inspection",
    SerialCode:"Serial Code",
    InspectionList:"Inspection List",
    BrandCategory:"Category",
    SelectBrandCategory:"Select Category",
    Searching:"Searching",
    SearchReferenceSerialModelUser:"Search reference, payment reference, payment method, or customer name.. ",
    Copy:"Copy",
    CopyToTheNewFormBuilder:(formName) => "Copy ("+formName+") to the new form builder",
    CopyToTheNewForm:(formName) => "Copy ("+formName+") to the new form",
    NewFormBuilderName:"new Cabinet Container Name",
    EnterNewFormBuilderName:"Enter new form name",
    CreateNewCabinet:"Create New Cabinet",
    CreateCabinetRow:"Cabinet Row Creator",
    CabinetRowTitle:"Cabinet Row Title",
    EnterCabinetRowTitle:"Enter Cabinet Row Tttle",
    PleaseEnterCabinetRowTitle:"Please enter Cabinet Row Title",
    ApiDescription:"API Description",
    PositiveLabel:"Positive Label",
    EnterPositiveLabel:"Enter positive label",
    NegativeLabel:"Negative Label",
    EnterNegativeLabel:"Enter negative label",
    HidePrintout:"Hide Printout",
    ContainerInputConfiguration:"Container Configuration",
    SizeOfRectifierControllerSerialNumber:"Size Of Rectifier/Controller SN",
    SubInput:"Sub Input",
    Type:"Type",
    DoYouWantToRemoveThisContainer:"Do you want to remove this container ?",
    DoYouWantToRemoveThisRack:"Do you want to remove this rack ?",
    QualityController:"Quality Controller",
    YourInspectionHistory:"Your Inspection History",
    ThereIsNoHistory:"There is no history",
    NewInspection:"New Inspection",
    SerialCodeOrReferenceCode:"Serial code or reference code",
    SearchInspection:"Search Inspection",
    ContinueInspection:"Return Inspection",
    InspectionDetailInfo:"Inspection Detail Info",
    CreateNewInspection:"Create New Inspection",
    ModelOrSystemType:"Model / System Type",
    ItemInspectionIsNotAvailable:"Itenm Inspection Is Not Available",
    DoYouWantToCreateNewInspection:"Do you want to create a new productOrder ?",
    DoYouWantToContinueInspection:"Do you want to continue productOrder ?",
    InspectionItem:"Inspection Item",
    TakingInspectionItem:"Taking Inspection Item",
    ModelInformation:"Model Information",
    CurrentInspectionStatus : "Current Inspection Status",
    PerformedBy : "Performed By",
    PreviousInspectionUser:"Previous Inspection User",
    PreviousInspectionStatus:"Previous Inspection Status",
    PreviousInspectionDate:"Previous Inspection Date",
    LastInspectionPerformedBy:"Last Inspection Performed By",
    LastInspectionDate:"Last Inspection Date",
    LastInspectionStatus:"Last Inspection Status",
    InspectionStatus:"Inspection Status",
    Next:"Next",
    Previous:"Previous",
    PageNext:"Next >>",
    PagePrevious:"<< Previous",
    InspectionInstruction:"Inspection Instruction",
    TakePicture:"Take Picture",
    SetPicture:"Set Picture",
    OkIUnderstand:"OK, I Understand",
    RectifierControllerSerialNumber:"Controller/Rectifier Serial Number",
    ConfirmSubmission:"Confirm Submission",
    ScanTheSerialNumber:"Scan The Serial Number",
    OrEnterItManually:"Or Enter It Manually",
    YouWillSubmitInspectionWithReferenceVariable1AndSerialCodeVariable2WithStatusVariable3_AreYouSure : (reference, serialCode, status) =>
        "You will submit productOrder with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong>and productOrder status is become<strong><h3>"+status+"</h3></strong><p>, are you sure with this information ?</p>",
    YouWillCloseInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "You will close productOrder with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong>and productOrder status is become<strong><h3>"+status+"</h3></strong><p>, are you sure with this information ?</p>",
    YouWillPostponeInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "You will postpone productOrder with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>This action will cause this productOrder able to take by another user ?</p>",
    YouWillSuspendInspectionWithReferenceVariable1AndSerialCodeVariable2_AreYouSure : (reference, serialCode, status) =>
        "You will suspend productOrder with reference code is<br/><strong><h3>"+reference+"</h3></strong>" +
        "and serial number<br/><strong><h3>"+serialCode+"</h3></strong><p>This action will cause this productOrder unable to take by any user ?</p>",
    YesImSure:"Yes, Im sure",
    PleaseEnterAFieldValueVariable : (variableName) => "Please enter "+variableName,
    EnterMessageIfYouNeedTo:"Enter message if you need to",
    EnterNoteIfYouNeedTo:"Enter note if you need to",
    InspectionMessage:"Inspection Message",
    InspectionNote:"Inspection Note",
    Preview:"Preview",
    PreviewInvoice:"Preview Invoice",
    FormInspectionDetail:"Form Inspection Detail",
    CloseStatusAndPrint:"Close Status & Print",
    ClickToExpandMoreInformation:"Click to expand more information",
    CLoseStatus:"Close",
    SuccessUpdatingStatus:"Success Updating Status",
    FailedUpdatingStatus:"Failed Updating Status",
    Reprint:"Reprint",
    Checking:"Checking",
    CheckingList:"Checking List",
    UserStatus:"User Status",
    AdminStatus:"Admin Status",
    CheckingHistory:"Checking History",
    CheckingActivity:"Checking Activity",
    PostponeInspection:"Postpone Inspection",
    InspectionOrderedSequentially:"Inspection ordered sequentially",
    SelectSystemType:"Select System Type",
    OpenedInspection:"Opened Inspection",
    NA:"NA",
    SuspendInspection:"Suspend Inspection",
    Reopen:"Reopen",
    DoYouWantToReopenThisInspection_ThisActionWillCauseTheInspectionCouldBeTakenByAnyUser:"Do you want to reopen this productOrder ?\nThis action will cause the productOrder could be taken by any user, are you sure ?",
    ContinueReOpenInspection:"Continue Reopen Inspection",
    PleaseEnterFirstName:"Please enter a valid firstname",
    PleaseEnterSmartDeviceLabel:"Please enter smart device label",
    PleaseEnterSmartDeviceName:"Please enter smart device name",
    PleaseEnterSmartDeviceNameCorrectly:"Please enter smart device name correctly",
    PleaseEnterSmartDeviceLabelCorrectly:"Please enter smart device label correctly",
    PleaseEnterFullName:"Please enter your fullname",
    PleaseGenerateARefCodeForThisUser:"Please generate reference code for this user",
    PleaseEnterEmail:"Please enter a valid email",
    PleaseSelectRoleForThisUser:"Please select role for this user",
    PleaseSelectRoleForThisAdmin:"Please select role for this admin",
    PleaseEnterARightMobilePhone:"Please enter valid mobile phone",
    PleaseEnterPhoneNumberCorrectly:"Please enter valid phone number (with country code)",
    DoYouWantToCreateANewUser:"Do you want to create a new user ?",
    DoYouWantToCreateANewAdmin:"Do you want to create a new admin ?",
    DoYouWantToUpdateInformationOfThisUser:"Do you want to update information of this user ?",
    DoYouWantToUpdateInformationOfThisAdmin:"Do you want to update information of this admin ?",
    SuccessUpdatingData:"Success updating data",
    SuccessDeletingData:"Success deleting data",
    DoYouWantToResendAPasswordForThisUser:"Do you want to resend a password for this user ?",
    DoYouWantToResendAPasswordForThisAdmin:"Do you want to resend a password for this admin ?",
    SuccessSendingThePassword:"Success sending the password",
    DoYouWantToUpdateThisUserStatusFrom : (previousStatus, nextStatus) => "Do you want to update this user status from "+previousStatus+" to "+nextStatus,
    DoYouWantToUpdateThisAdminStatusFrom : (previousStatus, nextStatus) => "Do you want to update this admin status from "+previousStatus+" to "+nextStatus,
    Reason:"Reason",
    StatusHistory:"Status History",
    BanThisDevice_TheUserWillBeBannedAfterThisPerform:"Ban This Device, The user will be banned from the application after this perform, are you sure ?",
    BanThisDevice_TheAdminWillBeBannedAfterThisPerform:"Ban This Device, The admin will be banned from the application after this perform, are you sure ?",
    ApplicationSetting:"Application Setting",
    Sequence:"Order",
    TimeActivity:"Time Activity",
    TimePerform:"Time Perform",
    ProvincePage:"Province Page",
    AdminDetailInformation:"Admin Detail Information",
    CreateNewAdmin:"Create New Admin",
    AdminCode:"Admin Code",
    NeverEnd:"Never End",
    Periodic:"Periodic",
    NoLimit:"No Limitation",
    Limited:"With Limitation",
    PleaseAddAMinimumSingleContainer:"Please add at least a single container",
    PleaseEnterCabinetName:"Please enter group form name",
    PleaseEnterPositiveLabel:"Please enter positive label",
    PleaseEnterNegativeLabel:"Please enter negative label",
    PleaseEnterPlaceHolder:"Please enter placeholder",
    NEVER_END:"Never End",
    PERIODIC:"Periodic",
    NO_LIMIT:"No Limitation",
    LIMITED:"With Limitation",
    CustomerOrderNo:"Customer Order No",
    Client:"Client",
    ProductSKU:"Product SKU",
    ProductName:"Product Name",
    Controller:"Controller",
    FWVersion:"FW Version",
    ControllerInfo:"Controller Info",
    CustomerInfo:"Customer Info",
    OrderNo:"Order No",
    DoYouWantToChangeYourPassword:"Do you want to change your password ?",
    OldPasswordMustBeEntered:"Old password must be entered",
    NewPasswordMustBeEntered:"New Password must be entered",
    ConfirmPasswordMustBeEntered:"Confirm password must be entered",
    PasswordMustAtLeastContain6Characters:"Password must at least contain 6 characters",
    OldPasswordAndNewPasswordCannotBeSame:"Old password and new password cannot be same",
    ConfirmPasswordAndNewPasswordIsNotSame:"Confirm password and new password is not same",
    Performer:"Performer",
    LastPerformer:"Last Performer",
    PreviousPerformer:"Previous Performer",
    TimeAgo_future: "in %s",
    TimeAgo_past: "%s ago",
    TimeAgo_s: 'a few seconds',
    TimeAgo_m: "a minute ago",
    TimeAgo_mm: "%d minutes ago",
    TimeAgo_h: "an hour ago",
    TimeAgo_hh: "%d hours ago",
    TimeAgo_d: "a day ago",
    TimeAgo_dd: "%d days ago",
    TimeAgo_M: "a month ago",
    TimeAgo_MM: "%d months ago",
    TimeAgo_y: "a year ago",
    TimeAgo_yy: "%d years ago",
    Time_future: "in %s",
    TimeFuture_next: "%s next",
    TimeFuture_s: 'a few seconds',
    TimeFuture_next_m: "a minute later",
    TimeFuture_next_mm: "%d minutes later",
    TimeFuture_next_h: "an hour later",
    TimeFuture_hh: "%d hours later",
    TimeFuture_d: "a day later",
    TimeFuture_dd: "%d days later",
    TimeFuture_M: "a month later",
    TimeFuture_MM: "%d months later",
    TimeFuture_y: "a year later",
    TimeFuture_yy: "%d years later",
    LastModification:"Last Modification",
    DocumentNumber:"Document Number",
    EnterDocumentNumber:"Enter Document Number",
    PleaseEnterDocumentNumber:"Please Enter Document Number",
    NewDocumentNumber:"New Document Number",
    EnterNewDocumentNumber:"Enter New Document Number",
    PleaseEnterNewDocumentNumber:"Please Enter New Document Number",
    InternalPrint:"Internal Print",
    ExternalPrint:"Eksternal Print",
    SuccessSavingInspection:"Success Saving Inspection",
    InspectionEventDetail:"Inspection Event Detail",

    // start configuration admin
    EnterHostMail:"Enter your host email",
    PleaseEnterHostMail:"Please enter a valid host email",

    EnterPortMail:"Enter your port email",
    PleaseEnterPortMail:"Please enter a valid port email",

    EnterUsernameMail:"Enter your username email",
    EnterUsername:"Enter username",
    PleaseEnterUsernameMail:"Please enter a valid username email",

    EnterPasswordMail:"Enter your password email",
    PleaseEnterPasswordMail:"Please enter a valid password email",

    EnterReplyToMail:"Enter your email reply",
    PleaseEnterReplyToMail:"Please enter a valid reply email",

    EnterFromMail:"Enter your email from",
    PleaseEnterFromMail:"Please enter a valid email",

    DoYouWantToUpdateInformationOfConfigurationAdmin:"Do you want to update information of this configuration ?",
    TestSendMail:"Test Send Email",
    NotificationEmailSent:"Notification Email Sent",
    Resend:"Resend",
    CompanyType:"Company Type",
    AllocatedDeviceId:"Allocated Device Id",
    PaymentProof:"Payment Proof",
    ControlStatus:"Status Control",
    SeeAll:"See All",
    SubscribedService:"Subscribed Service",
    Trying:"Trying",
    NewOrder:"New Order",
    InventoryType:"Inventory Type",
    AllRegistered:"All Registered",
    SinceLastMonth:"Since last month",
    TodayActivity:"Today Activity",
    WeeklyActivity:"Weekly Activity",
    Top5UsageRank:"Top 5 Usage Rank",
    CurrentProductOrderList:"Current Product Order List",
    SeeOrderDetail:"See Order Detail",
    SummaryOfOrder:"Summary Of Order",
    RequestProcess:"Request To Process",
    UpdatedAt:"Updated At",
    SeeInvoice:"See Invoice",
    SelectImage:"Select Image",
    Apply:"Apply",
    CropImage:"Crop Image",
    PaymentInformation:"Payment Information",
    UploadPaymentProof:"Upload Payment Proof",
    SelectImageToUpload:"Select Image To Upload",
    CannotDeletePaymentProof:"Cannot remove this payment proof",
    PaymentGuideline:"Payment Guideline",
    TransferAsPaymentMethod:"Transfer As Payment Method",
    PaymentGuidelineDescription:"Pay through bank and account number shown above,\n" +
        "                          fill the reference or not with reference code, save the slip. Then contact to confirm your payment.",
    RequestToProcessTitle:"Are you sure about this request",
    RequestToProcessDescription:"You are requesting process to our side, please make sure that you already got confidence by send us payment or contact us to get known more what a next proper action from you to do.",
    ProductOrderStatusHistory:"Order Status History",
    HistoryOfYourProductOrder:"History of your order",
    Service:"Service",
    SessionAndActivity:"Session & Activity",
    Longest:"Longest",
    Latest:"Latest",
    TheMost:"The Most",
    Smallest:"Smallest",
    State:"State",
    Action:"Action",
    DownloadSoftware:"Download Software",
    Restart:"Restart",
    DeviceInformation:"Device Information",
    UUID:"UUID",
    PublicIpAddress:"Public IP Address",
    LocalIpAddress:"Local IP Address",
    ShortCode:"Short Code",
    ContainerAvailability:"Container Availabiity",
    NoInformation:"No Information",
    PleaseFillUpDeviceLocated:"Please fill up a device located",
    Management:"Management",
    Showing:"Showing",
    Entries:"Entries",
    Entry:"Entry",
    OutOf:"Out Of",
    Regional:"Regional",
    CreateLocation:"Create Location",
    LocationInformation:"Location Information",
    BuildDate:"Build Date",
    BuildingInformation:"Building Information",
    PhoneNumber:"Phone Number",
    SelectLocation:"Select Location",
    Reset:"Reset",
    UpdateConfirmationDetailMessage:"Please ensure information you want need to save are have been fit to your purpose, do you want to continue saving ?",
    SelectCalendar:"Select Calendar",
    PleaseSelectYourPlaceGeoLocation:"Please select your place geo location",
    LocationList:"Location List",
    CompanyLocationList:"Company Location List",
    ClientLocationList:"Client Location List",
    LastAccess:"Last Access",
    UpdateInformation:"Update Information",
    GeneralInformation:"General Information",
    ChangePhoto:"Change Photo",
    UserSession:"User Session",
    TotalAuthenticatedDevice:"Total Authenticated Device",
    Last5UserActivity:"Last 5 Activities",
    PleaseEnterCorrectPhoneNumber:"Please enter correct phone number",
    PleaseEnterCorrectEmail:"Please enter correct email",
    PleaseEnterAddress:"Please enter address",
    YourProfilePage:"Your Profile Information",
    CreateUser:"Create User",
    CreateUserAccount:"Create User Account",
    UserCreationPasswordNote:"User will get temporary password on their email, and will use it for authentication. They will asked to change their password once they authenticated.",
    NeverAccessing:"Never Accessing",
    NoActivitiesFound:"No activities found",
    SelectAtLeastOneRole:"Select at least one role",
    Latest5SmartDeviceHavingActivity : (count) => "Latest "+count+" Smart Devices having activity ",
    Latest10UserActivity : "Latest 10 User activity ",
    LogoutConfirmation:"Logout Confirmation",
    BackToSignIn:"Back to sign in page",
    WeWillSendYourPasswordOnForgetPasswordMessage:"We will give you some instruction through your valid registered email",
    PleaseRememberYourPasswordAsItIsImportantToYourAccessToApplicationAndDoNotShareWithAnyOtherPerson:"Please remember your new password as its important to you to access application and do not share with any other person.",
    RequestToChangePasswordRequiredIsExistsToEnterApplication:"Request to change password is exists to enter application",
    ResetPasswordSucceed:"Reset Password Succeed",
    ResetPasswordSucceedNote:"Thank you for doing change password well, please go to sign in page and login with using your authentication.",
    SuccessUpdatingYourPassword:"Success updating your password",
    RegisteredSince:"Registered Since",
    RegisteredUntil:"Registered Until",
    PleaseSelectCompany:"Please select company",
    PleaseSelectProduct:"Please select product",
    PleaseSelectPackage:"Please select package",
    PleaseAddAtLeastOneOrderItem:"Please add at least one order item",
    Welcome:"Welcome",
    UseUsernameOrEmailToSignIn:"Use Username Or Email To Sign In",
    UseRFIDOrFingerprintToSignIn:"Use RFID Tag or fingerprint to Sign In",
    ScanYourRFIDOrFingerprint:"Scan your RFID or Fingerprint",
    WhatWouldYouDo:"What would you do",
    Home:"Home",
    Starting:"Starting",
    LocationName:"Location Name",
    GoodsCategoryDetail:"Goods Category Detail",
    Goods:"Goods",
    GoodsList:"Goods List",
    RegisteredItem:"Registered Item",
    RegisterItem:"Register Item",
    CreateGoods:"Create Goods",
    CreateGoodsForm:"Create Goods Form",
    GoodsName:"Goods Name",
    EnterGoodsName:"Enter Goods Name",
    GoodsDescription:"Goods Description",
    EnterGoodsDescription:"Enter Goods Description",
    More:"More",
    SeeAllItem:"See All Item",
    Register:"Register",
    NewItem:"New Item",
    ScanTag:"Scan Tag",
    StartScanning:"Start Scanning",
    StopScanning:"Stop Scanning",
    NotOnScanningMode:"Not on scanning mode",
    ScanningRFIDOnAReader:"Scanning RFID on a reader",
    RegisterTagForGoodsItem:"Register Tag For Goods Item",
    RFIDTag:"RFID Tag",
    RFIDTag_OnTheGo:"RFID Tag (On The Go)",
    RFIDTag_EnterManually_PressScanButtonToRetrieveItFromReaderMachine:"RFID Tag (Enter Manually) / Press scan button to retrieve from reader machine",
    LengthDoesntFollowTheRuleOfTag:"Length doesn't follow the rule of Tag",
    AvailableMedia:"Available Media",
    Storage:"Storage",
    MediaStorage:"Media Storage",
    TakingPictureMedia:"Taking Picture Media",
    RegisterImageForGoodsItem:"Register Image For Goods Item",
    RegisterImage:"Register Image",
    GoodsItemList:"Goods Item List",
    GoodsInformation:"Goods Information",
    EnterGoodsCategory:"Enter Goods Category",
    GoodsInfoForm:"Goods Information Form",
    GoodsCategoryCreation:"Goods Category Creation",
    Rows:"Rows",
    Pages:"Pages",
    SelectGoodsCategory:"Choose Goods Category",
    Exclusive:"Exclusive",
    CreateCategory:"Create Category",
    NotYetAllocated:"Not Yet Allocated",
    UpdateRfidTag:"Update RFID Tag",
    Executor:"Executor",
    DateTime:"DateTime",
    GoodsInfo:"Goods Info",
    GoodsItem:"Item Barang",
    Goods:"Goods",
    Registered:"Registered",
    Borrowed:"Borrowed",
    Placed:"Placed",
    Returned:"Returned",
    RemovedFromMachine:"Removed From Machine",
    ToolBox:"ToolBox",
    SelectUser:"Select User",
    Selected_Count : (count) => "Selected ("+count+")",
    LockerDeviceParallelPosition:"Device Parallel Position",
    LockerPosition:"Locker Position",
    MaximumContainerSizeReached:"Maximum container size has been reached",
    Locker:"Locker",
    RFIDReaderDevice:"RFID Reader Device",
    RFIDReaderParallelPosition:"RFID Reader Parallel Position",
    RFIDChannelPosition:"RFID Channel Position",
    Placement:"Placement",
    UploadPaymentReceipt:"Upload Payment Receipt",
    PaymentReceiptNeededToRequestProcessing:"Payment receipt needed to continue request processing",
    MaxBorrowingDuration:"Max Borrowing Duration (Hour)",
    NotLimited:"Not Limited",
    Limited:"Terbatas",
    VendingMachine:"Vending Machine",
    Borrowing:"Borrowing",
    Missing:"Missing",
    BorrowingList:"Borrowing List",
    SupportTicket:"Support Ticket",
    TakenFrom:"Taken from",
    ReturnedOn:"Returned On",
    Borrower:"Borrower",
    Returner:"Returner",
    Time:"Time",
    BorrowingInformation:"Borrowing Information",
    UserInformation:"User Information",
    BorrowingTime:"Borrowing Time",
    ReturningTime:"Returning Time",
    BorrowingSmartDevice:"Borrowing Device",
    ReturningSmartDevice:"Returning Device",
    BorrowingPhysicalContainer:"Borrowing Container",
    ReturningPhysicalContainer:"Returning Container",
    ShouldReturnedAt:"Should Returned At",
    Returning:"Returning",
    LastLocation:"Last Location",
    RegistrationList:"Registration List",
    Registration:"Registration",
    NewRegistration:"New Registration",
    SucceedCreatingNewRegistration:"Succesfully creating new registration",
    HelpVerification:"Help Verification",
    CompleteData:'Complete Data',
    SearchLocation:"Search Location",
    CompanySubscription:"Company Subscription",
    SubscriptionList:"Subscription List",
    CreateNewOrder:"Create New Order",
    LastUpdate:"Last Update",
    SomeOfSubscriptionGonnaExpiredAlert:"One of your subscription is going to expired, take extend of period before its been expired soon",
    SomeOfSubscriptionIsExpiredAlert:"Some of your subscription is expired, you can extend before system move its status become inactive",
    ActivePeriod:"Active period",
    OnBorrow:"On Borrow",
    BorrowingOfItemList:"Borrowing Of Item List",
    FormCreation:"Form Creation",
    UploadFile:"Upload File",
    APKFile:"APK File",
    MiscellaneousFile:"Miscellaneous File",
    Filename:"Filename",
    Whatsapp:"Whatsapp",
    BusinessContact:"Business Contact",
    PleaseSelectLocation:"Please select location",
    UpdateSmartDevice:"Pembaruan Smart Device",
}
module.exports = Object.freeze(Object.assign(label, menu, baru))

