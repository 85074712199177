import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import CardBlock from 'reactstrap/es/CardBlock';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {isEmpty, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import {MdDelete, MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import ActiveLabel from '../components/Widget/ActiveLabel';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class ProvincePage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      provinces : [],
      province:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      ascending:true,
      sortir:'name',
      page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
      totalPage:0,
      totalElement:0,
      pageElement:0,
    }
  }

  componentWillReceiveProps(props, nextContext) {
    if(props!=this.props){
      this.setState({
        page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
      }, () => {
        this.fetchAll()
      })
    }
  }


  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var province = this.state.province
    if(isEmpty(province.name)){
      this.showDialog(label.Warning, label.PleaseCompleteTheFields)
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }

  save = () => {
    let province = this.state.province
    if(!province.id && !province.active){
      province.active = false ;
    }

    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Endpoint.API.PROVINCE_SAVE, province, null, res => {
        if(res.code===200){
          this.setState({
            province:{}
          }, () => {
            this.fetchAll()
          })
        }
      }, true, true);
    })
  }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Endpoint.API.PROVINCE_DELETE+"/"+this.state.province.id, null, null, res => {
        if(res.code===200){
          this.setState({
            province:{}
          }, () => {
            this.fetchAll()
          })
        }
      },true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Endpoint.API.PROVINCES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
        page:this.state.page-1
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          provinces : response.data,
          totalPage: response.totalPage,
          totalElement: response.totalElement,
          pageElement: response.pageElement,
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    return (
        <Page
            title={label.Province}
            breadcrumbs={[{ name: label.Province, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message={label.UpdateConfirmationMessage}
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message={label.DeleteConfirmationMessage}
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.Province}</CardHeader>
                <CardBody >
                  <Row>
                    <Col md={3}>
                      <Card body>
                        <Row>
                          <CardBlock>
                            <FormGroup>
                              <Label for="name">{label.Name}</Label>
                              <Input
                                  type="text"
                                  name="name"
                                  value={!isEmpty(this.state.province.name)?this.state.province.name:""}
                                  onChange={(e) =>{
                                    let ctg = this.state.province
                                    if(ctg!=null){
                                      ctg.name = e.target.value
                                      this.setState({
                                        province:ctg
                                      })
                                    }
                                  }}
                                 placeholder={label.EnterProvinceName}
                              />
                            </FormGroup>
                            <FormGroup>
                              <ActiveOption
                                default={this.state.province.active}
                                callback={(active)=>{
                                  let ctg = this.state.province
                                  if(ctg!=null){
                                    ctg.active = active
                                    this.setState({
                                      province:ctg
                                    })
                                  }

                                }}
                            />
                            </FormGroup>
                        </CardBlock>
                        </Row>
                        <Row>
                          <Col>
                            <ButtonGroup className="float-left">
                              <Button color="danger" onClick={e => (
                                  this.setState({
                                    province:null
                                  })
                              )}>{label.Cancel}</Button>
                            </ButtonGroup>
                          </Col>
                          <Col>
                            <ButtonGroup className="float-right">
                              <Button color="primary" onClick={e=>(
                                  this.confirmSave()
                              )}> {
                                (this.state.province!=null && this.state.province.id!=null)?label.Update:label.Add
                              } </Button>

                            </ButtonGroup>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    <Col md={9}>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.Status}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Edit}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.provinces.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                      <td>{item.name}</td>
                                      <td><ActiveLabel active={item.active}/></td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button color="danger" onClick={e => {
                                          this.setState({
                                            province:item
                                          }, () => {
                                            this.confirmDelete()
                                          })
                                        }}>
                                          <MdDelete/>
                                        </Button>
                                        &nbsp;
                                        <Button color="primary" onClick={e=>(
                                            this.setState({
                                              province:item
                                            })
                                        )}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}

export default Object.assign(ProvincePage, {ENDPOINT : "/provinces"})
