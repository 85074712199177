import React from 'react';
import BasePage from './BasePage';
import axios from 'axios';

import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardImg,
    CardTitle,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
} from 'reactstrap';
import shoppingBag from 'assets/img/products/shopping-bag.png';
import Page from '../components/Page';
import ItemOption from '../components/Widget/ItemOption';
import Global, {DEFAULT_LOCATION, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE} from '../utils/Global';
import {allIsEmpty, imageSelector, isEmpty, parseDate} from "na-utilities/src/utils/Utilities";
import queryString from 'query-string';
import ConfirmDialog from '../components/modal/ConfirmDialog';
// import InventoryQuantityModal from '../components/modal/InventoryQuantityModal';
import {getData} from '../utils/StorageUtil';
import ImageCropper from '../components/modal/ImageCropper';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import WarningLabel from '../components/Widget/WarningLabel';
import DateInput from '../components/Widget/DateInput';
import NumberInput from '../components/Widget/NumberInput';
import label from 'na-utilities/src/label/Label'
import {MapContainer} from "../components/Widget/MapContainer";
import Checkbox from "../components/Checkbox";
import {TimePicker} from "antd";
import moment from "moment";
import {IoMdKey} from "react-icons/all";
import Endpoint from "na-utilities/src/api/Endpoint";


class SpotLocationDetailPage extends BasePage {

    constructor(props) {
        super(props);
        let state = this.state
        state.spotLocation = {}
        state.spotLocationType = {}
        state.spotLocation = null
        state.categories = []
        state.image = null
        state.provinces = []
        state.cities = []
        state.districts = []
        state.villages = []
        state.province = {}
        state.city = {}
        state.district = {}
        state.village = {}
        state.spotLocationIdQuery = queryString.parse(this.props.query).id
        state.weeklyOpens = []
        state.category = {}
        state.modalAddConfirm = false
        state.modalDeleteConfirm = false
        state.inventories = []
        state.inventory = null
        state.ascending = true
        state.sortir = 'created'
        state.quantityModal = false
        state.page = queryString.parse(this.props.query).page ? queryString.parse(this.props.query).page : 1
        state.totalPage = 0
        state.totalElement = 0
        state.pageElement = 0
        state.validation = {}
        this.setState(state)
        this.fetchProvinces((provinces) => this.setState({provinces: provinces}))

    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchSpotLocationDetail(this.state.spotLocationIdQuery)
    }

    componentWillReceiveProps(props, nextContext) {
        if (props != this.props) {
            this.setState({
                page: queryString.parse(props.query).page ? queryString.parse(props.query).page : 1
            }, () => {
                // this.fetchAllInventories(this.state.spotLocation.id, this.state.ascending, this.state.sortir, true)
            })
        }

    }


    confirmSave = () => {
        let spotLocation = this.state.spotLocation
        let validation = this.state.validation
        let modalAddConfirm = true
        // let weeklyOpens = spotLocation.id ? spotLocation.weeklyOpens : []
        let weeklyOpens = this.state.weeklyOpens? this.state.weeklyOpens : []

        weeklyOpens.forEach((value, index) => {
            if (value.open && !value._24Hours && !value.openTime) {
                weeklyOpens[index].openErrorMessage = label.EnterOpenTime
                modalAddConfirm = false;
            }
            if (value.open && !value._24Hours && !value.closeTime) {
                weeklyOpens[index].closeErrorMessage = label.EnterCloseTime
                modalAddConfirm = false;
            }
        })

        if (isEmpty(spotLocation.name)) {
            validation.name = label.EnterName
            modalAddConfirm = false;
        }
        if (isEmpty(spotLocation.description)) {
            validation.description = label.EnterDescription
            modalAddConfirm = false;
        }
        if (isEmpty(spotLocation.code) || !isEmpty(validation.code)) {
            validation.code = validation.code?validation.code:label.GenerateCode
            modalAddConfirm = false;
        }
        if (isEmpty(spotLocation.buildDate)) {
            validation.buildDate = label.EnterBuildDate
            modalAddConfirm = false;
        }
        if (isEmpty(spotLocation.phoneNumber)) {
            validation.phoneNumber = label.EnterPhoneNumber
            modalAddConfirm = false;
        }
        if (isEmpty(spotLocation.address)) {
            validation.address = label.EnterAddress
            modalAddConfirm = false;
        }
        if (isEmpty(spotLocation.village)) {
            validation.village = label.SelectVillage
            modalAddConfirm = false;
        }
        this.setState({
            weeklyOpens: weeklyOpens,
            modalAddConfirm: modalAddConfirm,
            validation:validation
        })

    }

    confirmDelete = () => {
        this.setState({
            modalDeleteConfirm: true
        })
    }

    delete = () => {
        if (this.state.inventory != null) {
            this.setState({
                modalDeleteConfirm: false
            }, () => {
                this.get(Endpoint.API.INVENTORY_DELETE + "/" + this.state.inventory.id, null, null, res => {
                    if (res.code === 200) {
                        this.setState({
                            inventory: null
                        }, () => {
                            // this.fetchAllInventories(this.state.spotLocation.id, this.state.ascending, this.state.sortir, true)
                        })
                    }
                }, true, true);
            })
        }
    }

    saveUpload = () => {
        let spotLocation = this.state.spotLocation;
        let weeklyOpens = this.state.weeklyOpens
        spotLocation.weeklyOpens = weeklyOpens
        var formData = new FormData();

        formData.append("spotLocationDtoGson", JSON.stringify(spotLocation))
        formData.append("multipartFile1", this.state.imageBlob1);
        formData.append("multipartFile2", this.state.imageBlob2);
        formData.append("multipartFile3", this.state.imageBlob3);
        formData.append("multipartFile4", this.state.imageBlob4);
        this.setState({
            modalAddConfirm: false
        }, () => {
            this.post(Endpoint.API.SPOT_LOCATION_SAVE_UPLOAD, formData, null, res => {
                if (res.code === 200) {
                    this.setState({
                        spotLocation: res.data
                    }, () => {
                        this.showDialog("Success", res.message)
                        this.fetchSpotLocationDetail(this.state.spotLocation.id)
                        // changeParam(this.props, 'id', this.state.spotLocation.id)
                    })
                }
            }, true, true)
        })
    }


    fetchSpotLocationDetail = (id) => {
        if (id) {
            this.get(Endpoint.API.SPOT_LOCATION, {
                params: {
                    id: id
                }
            }, null, response => {
                if (response.code === 200) {
                    let spotLocation = response.data
                    // let spotLocation = spotLocation.spotLocation
                    if (!spotLocation.latitude || !spotLocation.longitude) {
                        spotLocation.latitude = DEFAULT_LOCATION.latitude
                        spotLocation.longitude = DEFAULT_LOCATION.longitude
                    }
                    let weeklyOpens = spotLocation.weeklyOpens ? spotLocation.weeklyOpens : []
                    let village = spotLocation && spotLocation.village ? spotLocation.village : null
                    let district = spotLocation && spotLocation.village ? spotLocation.village.district : null
                    let city = spotLocation && spotLocation.village ? spotLocation.village.district.city : null
                    let province = spotLocation && spotLocation.village ? spotLocation.village.district.city.province : null

                    this.setState({
                        spotLocation: spotLocation,
                        weeklyOpens: weeklyOpens,
                        taskCategory: response.data.taskCategory,
                        image1: spotLocation.imageLink1,
                        image2: spotLocation.imageLink2,
                        image3: spotLocation.imageLink3,
                        image4: spotLocation.imageLink4,
                        village: village,
                        district: district,
                        city: city,
                        province: province,
                    }, () => {
                        this.fetchWeeklyOpens()
                        if (this.state.village) {
                            this.fetchCities(this.state.province.id, cities => {
                                this.setState({
                                    cities: cities
                                })
                            })
                        }
                        if (this.state.city) {
                            this.fetchDistricts(this.state.city.id, districts => {
                                this.setState({
                                    districts: districts
                                })
                            })
                        }
                        if (this.state.district) {
                            this.fetchVillages(this.state.district.id, villages => {
                                this.setState({
                                    villages: villages
                                })
                            })
                        }
                        // this.fetchAllInventories(this.state.spotLocation.id, this.state.ascending, this.state.sortir, true)
                    })
                } else {
                    this.props.history.goBack();
                }
            }, true, true)
        }else{
            this.fetchWeeklyOpens()
        }
    }

    fetchWeeklyOpens(){
        let {spotLocation} = this.state
        if(!spotLocation){
            spotLocation = {}
        }
        this.get(Endpoint.API.WEEKLY_OPENS, {
            params:{
                spotLocationId:spotLocation.id
            }
        }, null, response=>{
            if(response.code===200){
                let weeklyOpens = response.data?response.data:[]
                this.setState({weeklyOpens})
            }
        }, false, false)
    }
    closeDialog = () => {
        this.setState({
            modalAddConfirm: false,
            modalDeleteConfirm: false,
        })
    }

    generateCode(){
        let {spotLocation, validation} = this.state
        if(!spotLocation){
            spotLocation = {}
        }
        this.get(Endpoint.API.SPOT_LOCATION_GENERATE_CODE, {
            params:{
                spotLocationId:spotLocation.id
            }
        }, null, response=>{
            if(response.code===200){
                spotLocation.code = response.data
                validation.code = undefined
                this.setState({spotLocation, validation})
            }
        }, true, true)
    }
    validateCode(){
        let {spotLocation, validation} = this.state
        if(!spotLocation || !spotLocation.code){
            return;
        }
        this.get(Endpoint.API.SPOT_LOCATION_VALIDATE_CODE, {
            params:{
                spotLocationId:spotLocation.id,
                code:spotLocation.code
            }
        }, null, response=>{
            if(response.code===200){
                let valid = response.data
                if(!valid){
                    validation.code = label.CodeAlreadyBeenUsed
                }else{
                    validation.code = undefined
                }
                this.setState({validation})
            }
        }, false, true)
    }




    render() {
        var ids = [];
        var labels = [];

        let configuration = getData(Global.CONFIGURATION)
        let aspect = 4 / 4
        let {spotLocation, weeklyOpens, validation} = this.state
        if(!spotLocation){
            spotLocation = {}
        }
        if (!spotLocation.latitude || !spotLocation.longitude) {
            spotLocation.latitude = DEFAULT_LOCATION.latitude
            spotLocation.longitude = DEFAULT_LOCATION.longitude
        }
        let company = spotLocation.company?spotLocation.company:{}
        let creator = spotLocation.creator?spotLocation.creator:{}


            return (
                <Page
                    title={label.Location}
                    breadcrumbs={[{name: label.Location, active: true}]}>
                    {super.render()}
                    <ConfirmDialog
                        showing={this.state.modalAddConfirm}
                        title={label.UpdateConfirmation}O
                        message="Do you want to update this spot location item to your list ?"
                        okCallback={this.saveUpload}
                        cancelCallback={this.closeDialog}/>
                    <ConfirmDialog
                        showing={this.state.modalDeleteConfirm}
                        title={label.DeleteConfirmationTitle}
                        message="Do you want to delete this spot location item from your list ?"
                        okCallback={this.delete}
                        cancelCallback={this.closeDialog}/>

                    <Row key={1}>
                        <Col>
                            <Card className="mb-6">
                                <CardHeader>{label.SpotLocation}</CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image1 != null ? this.state.image1 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image1: file,
                                                                     cropperModal1: true
                                                                 })
                                                             }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>SpotLocation Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + SPOT_LOCATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        spotLocation: null,
                                                                        image1: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image1: file,
                                                                            cropperModal1: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>Upload</Button>

                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image2 != null ? this.state.image2 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image2: file,
                                                                     cropperModal2: true
                                                                 })
                                                             }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>Spot Location Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + SPOT_LOCATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        spotLocation: null,
                                                                        image2: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image2: file,
                                                                            cropperModal2: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>{label.Upload}</Button>

                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image3 != null ? this.state.image3 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image3: file,
                                                                     cropperModal3: true
                                                                 })
                                                             }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>Spot Location Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + SPOT_LOCATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        spotLocation: null,
                                                                        image3: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image3: file,
                                                                            cropperModal3: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>{label.Upload}</Button>

                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={3}>
                                            <Card>
                                                <CardImg top src={
                                                    this.state.image4 != null ? this.state.image4 : shoppingBag}
                                                         onClick={e => {
                                                             imageSelector(file => {
                                                                 this.setState({
                                                                     image1: file,
                                                                     cropperModal4: true
                                                                 })
                                                             }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                         }}
                                                         onError={(elm) => this.defaultImage(elm, shoppingBag)}/>
                                                <CardBody>
                                                    <CardTitle>Spot Location Image</CardTitle>
                                                    <Row>
                                                        <Col>
                                                            <WarningLabel
                                                                message={"*Use PNG or Jpeg Format / Max " + SPOT_LOCATION_IMAGE_MAX_FILE_SIZE + " Kb"}/>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col>
                                                            <ButtonGroup className="float-left">
                                                                <Button color="danger" onClick={e => (
                                                                    this.setState({
                                                                        spotLocation: null,
                                                                        image4: null
                                                                    })
                                                                )}>{label.Remove}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        <Col>
                                                            <ButtonGroup className="float-right">
                                                                <Button color="primary" onClick={e => {
                                                                    imageSelector(file => {
                                                                        this.setState({
                                                                            image4: file,
                                                                            cropperModal4: true
                                                                        })
                                                                    }, MIME_PNG_JPEG, SPOT_LOCATION_IMAGE_MAX_FILE_SIZE).click()
                                                                }}>{label.Upload}</Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader>{label.Information}</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Company}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="name"
                                                                    disabled={true}
                                                                    value={(company) ? company.fullName : ""}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Creator}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="name"
                                                                    disabled={true}
                                                                    value={(creator) ? creator.fullname : ""}
                                                                />
                                                            </FormGroup>
                                                        </Col>

                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.LocationName}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="name"
                                                                    value={(spotLocation) ? spotLocation.name : ""}
                                                                    disabled={true}
                                                                    placeholder={label.EnterName}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="code">{label.Code}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="code"
                                                                    value={(spotLocation) ? spotLocation.code : ""}
                                                                    disabled={true}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.BuildDate}</Label>
                                                                <DateInput
                                                                    id="buildDate"
                                                                    maxdate={new Date()}
                                                                    value={spotLocation.buildDate}
                                                                    disabled={true}
                                                                    placeholder={label.EnterBuildDate}>
                                                                </DateInput>
                                                                <WarningLabel show={validation.buildDate} message={validation.buildDate}/>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.PhoneNumber}</Label>
                                                                <NumberInput
                                                                    name="phoneNumber"
                                                                    className="form-control"
                                                                    value={(spotLocation) ? spotLocation.phoneNumber : ""}
                                                                    disabled={true}
                                                                    maxLength="15"
                                                                    placeholder="0">
                                                                </NumberInput>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Description}</Label>
                                                                <Input
                                                                    type="textarea"
                                                                    rows={4}
                                                                    name="description"
                                                                    disabled={true}
                                                                    value={(spotLocation) ? spotLocation.description : ""}
                                                                    placeholder={label.EnterDescription}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader>{label.Address}</CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <MapContainer
                                                                latitude={spotLocation.latitude}
                                                                longitude={spotLocation.longitude}
                                                                disabled={true}
                                                                onGestureExecuting={(latitude, longitude) => {
                                                                    spotLocation.latitude = latitude
                                                                    spotLocation.longitude = longitude
                                                                    this.setState({spotLocation: spotLocation})
                                                                }}/>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <Label for="name">{label.Address}</Label>
                                                                <Input
                                                                    type="text"
                                                                    name="address"
                                                                    value={(spotLocation) ? spotLocation.address : ""}
                                                                    disabled={true}
                                                                    placeholder={label.Address}
                                                                />
                                                                <WarningLabel show={validation.address} message={validation.address}/>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectProvince}
                                                                    objects={this.state.provinces}
                                                                    default={!allIsEmpty(this.state.province) ? this.state.province.id : null}
                                                                    disable={true}
                                                                    callback={(province) => {}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectCity}
                                                                    objects={this.state.cities}
                                                                    default={!allIsEmpty(this.state.city) ? this.state.city.id : null}
                                                                    disable={true}
                                                                    callback={(city) => {}}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectDistrict}
                                                                    objects={this.state.districts}
                                                                    default={!allIsEmpty(this.state.district) ? this.state.district.id : null}
                                                                    disable={true}
                                                                    callback={(district) => {
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col>
                                                            <FormGroup>
                                                                <ItemOption
                                                                    title={label.SelectVillage}
                                                                    objects={this.state.villages}
                                                                    default={!allIsEmpty(this.state.village) ? this.state.village.id : null}
                                                                    disable={true}
                                                                    callback={(village) => {
                                                                    }}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <ImageCropper src={this.state.image1} aspect={aspect} show={this.state.cropperModal1}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image1: file,
                                              cropperModal1: false,
                                              imageBlob1: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal1: false,
                            image1: spotLocation.imageLink1,
                            imageBlob1: null,
                        })
                    }}/>
                    <ImageCropper src={this.state.image2} aspect={aspect} show={this.state.cropperModal2}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image2: file,
                                              cropperModal2: false,
                                              imageBlob2: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal2: false,
                            image2: spotLocation.imageLink2,
                            imageBlob2: null,
                        })
                    }}/>
                    <ImageCropper src={this.state.image3} aspect={aspect} show={this.state.cropperModal3}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image3: file,
                                              cropperModal3: false,
                                              imageBlob3: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal3: false,
                            image3: spotLocation.imageLink3,
                            imageBlob3: null,
                        })
                    }}/>
                    <ImageCropper src={this.state.image4} aspect={aspect} show={this.state.cropperModal4}
                                  callback={file => {
                                      axios({
                                          url: file,
                                          method: 'GET',
                                          responseType: 'blob', // important
                                      }).then((response) => {
                                          var fileBlob = response.data;
                                          this.setState({
                                              image4: file,
                                              cropperModal4: false,
                                              imageBlob4: fileBlob,
                                          })
                                      })
                                  }} cancelCallback={() => {
                        this.setState({
                            cropperModal4: false,
                            image4: spotLocation.imageLink4,
                            imageBlob4: null,
                        })
                    }}/>

                </Page>
            );
        }

}
export default Object.assign(SpotLocationDetailPage, {ENDPOINT : "/spotLocationDetail"})