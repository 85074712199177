import GAListener from 'components/GAListener';
import {EmptyLayout, LayoutRoute, MainLayout} from 'components/Layout';
import AuthPage from './pages/AuthPage';
import {AuthProvider} from './utils/AuthContext';
import kitchen from '../src/utils/AxiosInstance';
// pages
import React from 'react';
import componentQueries from 'react-component-queries';
import {BrowserRouter, Redirect, Switch} from 'react-router-dom';
import './styles/reduction.scss';
import ProtectedRoute from 'components/Layout/ProtectedRoute';
import RolePage from './pages/RolePage';
import ConfigurationAdminPage from './pages/ConfigurationAdminPage';
import RoleMenuDetailPage from './pages/RoleMenuDetailPage';
import ProfilePage from './pages/ProfilePage';
import ProvincePage from './pages/ProvincePage';
import CityPage from './pages/CityPage';
import DistrictPage from './pages/DistrictPage';
import VillagePage from './pages/VillagePage';
import Global from './utils/Global';
import {storeData} from './utils/StorageUtil';
import UserActivityPage from './pages/UserActivityPage';
import MainDashboardPage from './pages/MainDashboardPage';
import './App.css';
import ProductPage from './pages/ProductPage';
import ModelDetailPage from './pages/ProductDetailPage';
import TransactionPage from './pages/ProductOrderPage';
import UserPage from './pages/UserPage';
import UserDetailPage from './pages/UserDetailPage';
import UserDevicePage from './pages/UserDevicePage';
import ProductCategoryPage from "./pages/ProductCategoryPage";
import 'antd/dist/antd.css';
import BannerPage from "./pages/BannerPage";
import CompanyPage from "./pages/CompanyPage";
import CompanyDetailPage from "./pages/CompanyDetailPage";
import SmartDeviceTypePage from "./pages/SmartDeviceTypePage";
import SpotLocationPage from "./pages/SpotLocationPage";
import SpotLocationDetailPage from "./pages/SpotLocationDetailPage";
import SmartDevicePage from "./pages/SmartDevicePage";
import SmartDeviceDetailPage from "./pages/SmartDeviceDetailPage";
import ContainerPage from "./pages/ContainerPage";
import ContainerTypePage from "./pages/ContainerTypePage";
import TransactionDetailPage from "./pages/ProductOrderDetailPage";
import SubPaymentMediaPage from "./pages/SubPaymentMediaPage";
import ApplicationVersionPage from "./pages/ApplicationVersionPage";
import QRCodePage from "./pages/QRCodePage";
import RegistrationPage from "./pages/RegistrationPage";
import ProductPackagePage from "./pages/ProductPackagePage";
import FollowUpPage from "./pages/FollowUpPage";
import FollowUpDetailPage from "./pages/FollowUpDetailPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import UserRoleDetailPage from "./pages/UserRoleDetailPage";
import ProductSubscribePage from "./pages/ProductSubscribePage";
import ProductSubscribeDetailPage from "./pages/ProductSubscribeDetailPage";
import CabinetPage from "./pages/CabinetPage";
import CabinetDetailPage from "./pages/CabinetDetailPage";
import GoodsCategoryPage from "./pages/GoodsCategoryPage";
import RegisteredLockerDevicePage from "./pages/RegisteredLockerDevicePage";
import RegisteredRFIDReaderDevicePage from "./pages/RegisteredRFIDReaderDevicePage";
import CompanySubscribeListPage from "./pages/CompanySubscribeListPage";
import MiscellaneousFilePage from "./pages/MiscellaneousFilePage";

const getBasename = () => { 
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

class App extends React.Component {


  componentDidMount() {
    fetchAppSetting()
  }


  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <AuthProvider>
          <GAListener>
            <Switch>
              <ProtectedRoute
                exact
                rootMenu={ProductPage.ENDPOINT}
                path={ProductPage.ENDPOINT}
                layout={MainLayout}
                component={ProductPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ProductPackagePage.ENDPOINT}
                  path={ProductPackagePage.ENDPOINT}
                  layout={MainLayout}
                  component={ProductPackagePage}
              />
              <ProtectedRoute
                exact
                rootMenu={ProductPage.ENDPOINT}
                path={ModelDetailPage.ENDPOINT}
                layout={MainLayout}
                component={ModelDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={TransactionPage.ENDPOINT}
                path={TransactionPage.ENDPOINT}
                layout={MainLayout}
                component={TransactionPage}
              />
              <ProtectedRoute
                exact
                rootMenu={TransactionPage.ENDPOINT}
                path={TransactionDetailPage.ENDPOINT}
                layout={MainLayout}
                component={TransactionDetailPage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={RegistrationPage.ENDPOINT}
                  path={RegistrationPage.ENDPOINT}
                  layout={MainLayout}
                  component={RegistrationPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={FollowUpPage.ENDPOINT}
                  path={FollowUpPage.ENDPOINT}
                  layout={MainLayout}
                  component={FollowUpPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={FollowUpPage.ENDPOINT}
                  path={FollowUpDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={FollowUpDetailPage}
              />

              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserPage.ENDPOINT}
                layout={MainLayout}
                component={UserPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserPage.ENDPOINT}
                path={UserDetailPage.ENDPOINT}
                layout={MainLayout}
                component={UserDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={UserPage.ENDPOINT}
                  path={UserRoleDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={UserRoleDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserDevicePage.ENDPOINT}
                path={UserDevicePage.ENDPOINT}
                layout={MainLayout}
                component={UserDevicePage}
              />

              <ProtectedRoute
                exact
                path={ProductCategoryPage.ENDPOINT}
                rootMenu={ProductCategoryPage.ENDPOINT}
                layout={MainLayout}
                component={ProductCategoryPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ProvincePage.ENDPOINT}
                path={ProvincePage.ENDPOINT}
                layout={MainLayout}
                component={ProvincePage}
              />
              <ProtectedRoute
                exact
                rootMenu={CityPage.ENDPOINT}
                path={CityPage.ENDPOINT}
                layout={MainLayout}
                component={CityPage}
              />
              <ProtectedRoute
                exact
                rootMenu={DistrictPage.ENDPOINT}
                path={DistrictPage.ENDPOINT}
                layout={MainLayout}
                component={DistrictPage}
              />
              <ProtectedRoute
                exact
                rootMenu={VillagePage.ENDPOINT}
                path={VillagePage.ENDPOINT}
                layout={MainLayout}
                component={VillagePage}
              />

              <ProtectedRoute
                exact
                rootMenu={RolePage.ENDPOINT}
                path={RolePage.ENDPOINT}
                layout={MainLayout}
                component={RolePage}
              />
              <ProtectedRoute
                exact
                rootMenu={RolePage.ENDPOINT}
                path={RoleMenuDetailPage.ENDPOINT}
                layout={MainLayout}
                component={RoleMenuDetailPage}
              />
              <ProtectedRoute
                exact
                rootMenu={ConfigurationAdminPage.ENDPOINT}
                path={ConfigurationAdminPage.ENDPOINT}
                layout={MainLayout}
                component={ConfigurationAdminPage}
              />
              <ProtectedRoute
                exact
                rootMenu={UserActivityPage.ENDPOINT}
                path={UserActivityPage.ENDPOINT}
                layout={MainLayout}
                component={UserActivityPage}
              />
              <LayoutRoute
                exact
                path={AuthPage.ENDPOINT}
                layout={EmptyLayout}
                component={props => (
                  <AuthPage {...props}/>
                )}
              />
              <LayoutRoute
                  exact
                  path={ForgetPasswordPage.ENDPOINT}
                  layout={EmptyLayout}
                  component={props => (
                      <ForgetPasswordPage {...props}/>
                  )}
              />
              <LayoutRoute
                  exact
                  path={ResetPasswordPage.ENDPOINT+"/:token"}
                  layout={EmptyLayout}
                  component={ResetPasswordPage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={"*"}
                  path={QRCodePage.ENDPOINT}
                  layout={EmptyLayout}
                  component={QRCodePage}
              />

              <ProtectedRoute
                exact
                rootMenu={MainDashboardPage.ENDPOINT}
                path={MainDashboardPage.ENDPOINT}
                layout={MainLayout}
                component={MainDashboardPage}
              />
              <ProtectedRoute
                exact
                rootMenu={"*"}
                path={ProfilePage.ENDPOINT}
                layout={MainLayout}
                component={ProfilePage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={CabinetPage.ENDPOINT}
                  path={CabinetPage.ENDPOINT}
                  layout={MainLayout}
                  component={CabinetPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={CabinetPage.ENDPOINT}
                  path={CabinetDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={CabinetDetailPage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={BannerPage.ENDPOINT}
                  path={BannerPage.ENDPOINT}
                  layout={MainLayout}
                  component={BannerPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={CompanyPage.ENDPOINT}
                  path={CompanyPage.ENDPOINT}
                  layout={MainLayout}
                  component={CompanyPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={CompanyPage.ENDPOINT}
                  path={CompanyDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={CompanyDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={RegisteredLockerDevicePage.ENDPOINT}
                  path={RegisteredLockerDevicePage.ENDPOINT}
                  layout={MainLayout}
                  component={RegisteredLockerDevicePage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={RegisteredRFIDReaderDevicePage.ENDPOINT}
                  path={RegisteredRFIDReaderDevicePage.ENDPOINT}
                  layout={MainLayout}
                  component={RegisteredRFIDReaderDevicePage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={SmartDeviceTypePage.ENDPOINT}
                  path={SmartDeviceTypePage.ENDPOINT}
                  layout={MainLayout}
                  component={SmartDeviceTypePage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={SpotLocationPage.ENDPOINT}
                  path={SpotLocationDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={SpotLocationDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={SpotLocationPage.ENDPOINT}
                  path={SpotLocationPage.ENDPOINT}
                  layout={MainLayout}
                  component={SpotLocationPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ProductSubscribePage.ENDPOINT}
                  path={ProductSubscribePage.ENDPOINT}
                  layout={MainLayout}
                  component={ProductSubscribePage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ProductSubscribePage.ENDPOINT}
                  path={ProductSubscribeDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={ProductSubscribeDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ProductSubscribePage.ENDPOINT}
                  path={CompanySubscribeListPage.ENDPOINT}
                  layout={MainLayout}
                  component={CompanySubscribeListPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={SmartDevicePage.ENDPOINT}
                  path={SmartDevicePage.ENDPOINT}
                  layout={MainLayout}
                  component={SmartDevicePage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={SmartDevicePage.ENDPOINT}
                  path={SmartDeviceDetailPage.ENDPOINT}
                  layout={MainLayout}
                  component={SmartDeviceDetailPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ContainerPage.ENDPOINT}
                  path={ContainerPage.ENDPOINT}
                  layout={MainLayout}
                  component={ContainerPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={ContainerTypePage.ENDPOINT}
                  path={ContainerTypePage.ENDPOINT}
                  layout={MainLayout}
                  component={ContainerTypePage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={SubPaymentMediaPage.ENDPOINT}
                  path={SubPaymentMediaPage.ENDPOINT}
                  layout={MainLayout}
                  component={SubPaymentMediaPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={GoodsCategoryPage.ENDPOINT}
                  path={GoodsCategoryPage.ENDPOINT}
                  layout={MainLayout}
                  component={GoodsCategoryPage}
              />

              <ProtectedRoute
                  exact
                  rootMenu={"/applicationVersions"}
                  path="/applicationVersions"
                  layout={MainLayout}
                  component={ApplicationVersionPage}
              />
              <ProtectedRoute
                  exact
                  rootMenu={MiscellaneousFilePage.ENDPOINT}
                  path={MiscellaneousFilePage.ENDPOINT}
                  layout={MainLayout}
                  component={MiscellaneousFilePage}
              />
              <Redirect to={MainDashboardPage.ENDPOINT} />
            </Switch>
          </GAListener>
        </AuthProvider>
      </BrowserRouter>
    );
  }
}
function fetchAppSetting() {
  get(Endpoint.API.CONFIGURATION_CURRENT_ACTIVE, null, null, response => {
    if (response.data.code === 200) {
      storeData(Global.CONFIGURATION, response.data.data)
    }
  })
}

function get(url, params, config, callback) {
  kitchen.get(url, params, config).then(response => {
    callback(response)
  }).catch(e => {

  });
}




const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
