import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {cloneObject, isEmpty, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import {MdDelete, MdEdit} from 'react-icons/md';
import {FaClone} from 'react-icons/fa';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import CabinetCopyModal from "../components/modal/CabinetCopyModal";
import label from "na-utilities/src/label/Label";
import Global from "na-utilities/src/utils/Global";
import Endpoint from "na-utilities/src/api/Endpoint";
import {DD_MM_YYYY_HH_MM_SS} from "../utils/Global";
import CabinetCreateModal from "../components/modal/CabinetCreateModal";


class CabinetPage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      cabinets : [],
      cabinet:{},
      heading:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      modal: false,
      ascending:true,
      sortir:'created',
      page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
      totalPage:0,
      totalElement:0,
      pageElement:0,
      formCopyModal:false,
      createNewCabinetModal:false,
    }
  }

  componentWillReceiveProps(props, nextContext) {
    if(props!=this.props){
      this.setState({
        page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
      }, () => {
        this.fetchAll()
      })
    }
  }


  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var cabinet = this.state.cabinet
    if(isEmpty(cabinet.name) || isEmpty(cabinet.heading)){
      this.showDialog("Notice", "Please complete this form fields")
    }else{
      this.setState({
        modalAddConfirm:true
      })
    }
  }

  save = () => {
    let cabinet = this.state.cabinet
    if(!cabinet.id && !cabinet.active){
      cabinet.active = false ;
    }

    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Global.API.CABINET_SAVE, cabinet, null, res => {
        if(res.code===200){
          this.setState({
            cabinet:{}
          }, () => {
            this.fetchAll()
          })
        }
      }, true, true);
    })
  }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Endpoint.API.CABINET_DELETE+"/"+this.state.cabinet.id, null, null, res => {
        if(res.code===200){
          this.setState({
            cabinet:{}
          }, () => {
            this.fetchAll()
          })
        }
      },true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Endpoint.API.CABINETS, {
      params:{
        page: this.state.page-1,
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          cabinets : response.data,
          totalPage: response.totalPage,
          totalElement: response.totalElement,
          pageElement: response.pageElement,

        })
      }
    }, true, true);
  }

  cloneCabinet = (cabinet, name, documentNumber) => {
    let form = new FormData()
    form.append("cabinetId", cabinet.id)
    form.append("name", name)
    form.append("documentNumber", documentNumber)
    this.post(Endpoint.API.CLONE_CABINET, form, null, response=>{
      if(response.code === 200){
        this.fetchAll()
      }
    }, true, true);
  }
  createNewCabinet = (name, documentNumber) => {
    let {cabinets} = this.state
    let form = new FormData()
    form.append("name", name)
    form.append("documentNumber", documentNumber)
    this.post(Endpoint.API.CREATE_NEW_CABINET, form, null, response=>{
      if(response.code === 200){
        let cabinet = response.data
        cabinets.push(cabinet)
        this.setState({cabinets}, () => {
          this.props.history.push('/cabinetDetail?id='+cabinet.id)
        })
      }
    }, true, true);
  }



  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let {formCopyModal, createNewCabinetModal, cabinet} = this.state
    return (
        <Page
            title={label.Cabinet}
            breadcrumbs={[{ name: label.Cabinet, active: true }]}
            className="TablePage">
          {super.render()}
          <CabinetCreateModal
              okCallback={(formName, documentNumber)=>{
                this.createNewCabinet(formName, documentNumber)
                this.setState({
                  createNewCabinetModal:false
                })
              }}
              closeCallback={()=>{
                this.setState({
                  createNewCabinetModal:false
                })
              }}
              modal={createNewCabinetModal}
          />

          <CabinetCopyModal
              cabinet={cabinet}
              okCallback={(cabinet, formName, documentNumber)=>{
                this.cloneCabinet(cabinet, formName, documentNumber)
                this.setState({
                  formCopyModal:false
                })
              }}
              closeCallback={()=>{
                this.setState({
                  formCopyModal:false
                })
              }}
              modal={formCopyModal}
          />
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message="Do you want to udpate this cabinet item to your list ?"
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message="Do you want to delete this cabinet item from your list ?"
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.CabinetList}</CardHeader>
                <CardBody >
                  <Row>
                    <Col>
                      <Card body>
                        <Row>
                          <Col md={3}>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "documentNumber"))}>{label.DocumentNumber}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col md={4}>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col md={5}>
                            <ButtonGroup className="float-right">
                              <Button
                                  color="primary"
                                  onClick={e=>{
                                    this.setState({createNewCabinetModal:true})
                                    // this.props.history.push('/cabinetDetail')
                                  }}> + {label.AddCabinet}
                              </Button>
                            </ButtonGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.DocumentNumber}</th>
                                <th>{label.Created}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Delete}&nbsp;|&nbsp;{label.Copy}&nbsp;|&nbsp;{label.Edit}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.cabinets.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.name}</td>
                                      <td>{item.documentNumber}</td>
                                      <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button color="danger" onClick={e => {
                                          this.setState({
                                            cabinet:cloneObject(item)
                                          }, () => {
                                            this.confirmDelete()
                                          })
                                        }}>
                                          <MdDelete/>
                                        </Button>
                                        &nbsp;
                                        <Button color="warning" onClick={e => {
                                          this.setState({
                                            cabinet:cloneObject(item)
                                          }, () => {
                                            this.setState({formCopyModal: true})
                                          })
                                        }}>
                                          <FaClone/>
                                        </Button>
                                        &nbsp;
                                        <Button color="primary" onClick={e=>{
                                          this.props.history.push('/cabinetDetail?id='+item.id)
                                        }}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
                <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}

export default Object.assign(CabinetPage, {ENDPOINT : "/cabinets"})
