
export let INITIATED_ID = 1;
export let ACTIVE_ID = 2;
export let INACTIVE_ID = 3;
export let ON_MAINTENANCE_ID = 4;


export default {
    LANGUAGE:'LANGUAGE',
    DEFAULT_LANGUAGE:'in',
    GoodsItemStatuses : {
        TAGGED : {
            id:1,
            label:"Tagged"
        },
        REGISTERED : {
            id:2,
            label:"Registered"
        },
        PLACED : {
            id:3,
            label:"Placed"
        },
        TAKEN_OUT : {
            id:4,
            label:"Taken Out"
        },
        PUT_IN : {
            id:5,
            label:"Put In"
        },
        BORROWED : {
            id:6,
            label:"Borrowed"
        },
        RETURNED : {
            id:7,
            label:"Returned"
        },
        REMOVED_FROM_MACHINE : {
            id:8,
            label:"Removed From Machine"
        },
        MISSING : {
            id:9,
            label:"Missing"
        }
    },
    BorrowingOfItemStatuses : {
        ON_BORROW : {
            id:1,
            label:"Borrowed"
        },
        RETURNED : {
            id:2,
            label:"Returned"
        },
        REPLACED : {
            id:3,
            label:"Replaced"
        },
        REMOVED_FROM_MACHINE : {
            id:4,
            label:"Removed From Machine"
        },
        MISSING : {
            id:5,
            label:"Missing"
        }
    },


    SmartDeviceType:{
        TOOLBOX:  {
            "id": 1,
            "type": "Toolbox",
            "active": true
        },
        VENDING_MACHINE:  {
            "id": 2,
            "type": "Vending Machine",
            "active": true
        },
    }
}