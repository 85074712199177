import React from 'react';
import {Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import PropTypes from 'prop-types';
import {CURRENCY_SYMBOL} from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
import {currencyFormat, isEmpty, isResponseOk} from "na-utilities/src/utils/Utilities";
import WarningLabel from "../Widget/WarningLabel";
import {IoMdRemove} from "react-icons/all";
import SpotLocationSelectionModal from "./SpotLocationSelectionModal";
import {ButtonGroup} from "react-bootstrap";
import Checkbox from "../Checkbox";
import ItemOption from "../Widget/ItemOption";

/*= (message, okCallback, cancelCallback) =>*/

export default class ExtendProductOrderInquiryModal extends BaseComponent {

    constructor(props){
        super(props);
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()

        this.state = {
            show:false,
            company: props.company?props.company:{},
            orderItems:[],
            productSubscribes:props.productSubscribes?props.productSubscribes:[],
            selectedProductPackage:{},
            validation,
            validationMessage,
            spotLocationSelectionModal:false,
            selectedSpotLocation:{},
            paymentAttribute:{}
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.company = true ;
        validation.productPackage = true ;
        validation.orderItems = true ;
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.company = "" ;
        validationMessage.productPackage = "" ;
        validationMessage.orderItems = "" ;
        return validationMessage ;
    }
    componentDidMount() {
        super.componentDidMount();
        this.fetchPaymentMediaAttribute((paymentAttribute)=>{
            this.setState({paymentAttribute})
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            this.setState({show},async () => {
                if(show && !prevState.show){
                    let validation = this.defaultValidation()
                    let validationMessage = this.defaultValidationMessage()
                    let productSubscribes = this.props.productSubscribes?this.props.productSubscribes:[]
                    let orderItems = []
                    for (let productSubscribe of productSubscribes) {
                        let orderItem = {}
                        orderItem.productPackage = productSubscribe.productPackage
                        orderItem.spotLocation = productSubscribe.smartDevice.spotLocation
                        orderItem.allocatedDeviceId = productSubscribe.smartDevice.deviceId
                        orderItem.type = "EXTENSION"
                        orderItem.checked = false
                        let productPackages = await this.fetchProductPackages(orderItem.productPackage)
                        orderItem.productPackages = productPackages
                        orderItems.push(orderItem)
                    }
                    this.setState({
                        show:show,
                        company:this.props.company?this.props.company:{},
                        productSubscribes:productSubscribes,
                        selectedProductPackage:{},
                        orderItems:orderItems,
                        validation,
                        validationMessage
                    })
                }
            })
        }
    }

    async fetchProductPackages(productPackage){
        let selectedProduct = productPackage.product
        if(selectedProduct) {
            let response = await this.asyncGet(Endpoint.API.PRODUCT_PACKAGES, {
                params: {
                    "published": true,
                    productId: selectedProduct.id
                }
            }, null,  false, false)
            if (isResponseOk(response)) {
                let productPackages = response.data
                return productPackages
            }
            return []
        }
        return []
    }

    fetchPaymentMediaAttribute(callback){
        this.get(Endpoint.API.PAYMENT_MEDIA_ATTRIBUTE, {},null, (response)=>{
            if(isResponseOk(response)){
                callback(response.data)
            }
        },   false, false)
    }


    s

    validateFormSubmit() {
        // await super.validateFormSubmit();
        let {show, company, orderItems, spotLocationSelectionModal, paymentAttribute,
            validation, validationMessage} = this.state
        let valid = true;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if (isEmpty(company)) {
            validation.company = false
            validationMessage.company = label.PleaseSelectCompany
            valid = false;
        }

        if (isEmpty(orderItems) || orderItems.length===0) {
            validation.orderItems = false
            validationMessage.orderItems = label.PleaseAddAtLeastOneOrderItem
            valid = false;
        }
        valid = false
        for (let orderItem of orderItems) {
            if(orderItem.checked){
                valid = true
            }
        }
        if(!valid){
            validation.orderItems = false
            validationMessage.orderItems = label.PleaseAddAtLeastOneOrderItem
            alert(label.PleaseAddAtLeastOneOrderItem)
        }
        this.setState({validation, validationMessage}, () => {
            if(valid){
                this.submit()
            }
        })
    }

    submit(){
        let {company, orderItems} = this.state
        let {onCreateOrder, onClose} = this.props
        let form = new FormData()
        form.append("orderItemListGson", JSON.stringify(orderItems))
        this.post(Endpoint.API.PRODUCT_ORDER_CREATE_WITH_COMPANY+"/"+company.id, form, null, (res)=>{
            if(isResponseOk(res)){
                let productOrder = res.data
                this.successToast(label.SucceedCreatingNewRegistration)
                if(onCreateOrder){
                    onCreateOrder(productOrder)
                }
            }else{
                this.errorToast(res.message)
                if(onClose){
                    onClose()
                }
            }
        }, true, false)
    }

    render(){
        let {show, company, selectedProduct, selectedProductPackage, selectedSpotLocation,
            products, productPackages, orderItems, spotLocationSelectionModal, paymentAttribute,
            validation, validationMessage} = this.state
        let {onClose} = this.props

        let adminFee = paymentAttribute.adminFee?paymentAttribute.adminFee:0
        let taxPercent = paymentAttribute.taxPercent?paymentAttribute.taxPercent:0
        let taxAmount = 0
        let total = 0
        let totalAmount = 0
        orderItems.forEach((item)=>{
            let productPackage = item.productPackage
            if(item.checked) {
                total += productPackage.price
            }
        })
        taxAmount = (total+adminFee)*(taxPercent/100)
        totalAmount = total+taxAmount+adminFee

        return (
            <>
                <SpotLocationSelectionModal
                    showing={spotLocationSelectionModal}
                    onClose={()=>{
                        this.setState({spotLocationSelectionModal:false})
                    }}
                    company={company}
                    onSelect={(spotLocation)=>{
                        let orderItem = {}
                        orderItem.productPackage = selectedProductPackage
                        orderItem.spotLocation = spotLocation
                        orderItems.push(orderItem)
                        this.setState({spotLocationSelectionModal:false, orderItems})
                    }}/>
                <Modal
                    size="xl"
                    isOpen={show}
                    centered={true}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.ExtendSubscriptionPeriod}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody className="p-0">
                                        <Table hover responsive>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{label.Product}</th>
                                                <th>{label.ProductPackage}</th>
                                                <th>{label.Description}</th>
                                                <th>{label.MonthPeriod}</th>
                                                <th style={{maxWidth:'210px'}}>{label.Location}</th>
                                                <th>{label.Price}</th>
                                                <th>{label.Select}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                orderItems.map((item, index) => (
                                                    <>
                                                        <tr key={item.id}>
                                                            <th scope="row">{index+1}</th>
                                                            <td>{item.productPackage.product.name}</td>
                                                            <td>
                                                                <ItemOption
                                                                    hideLabel={true}
                                                                    hideOptionLabel={true}
                                                                    fieldForLabel={"name"}
                                                                    default={item.productPackage.id}
                                                                    objects={item.productPackages}
                                                                    style={{fontSize:'14px'}}
                                                                    callback={(productPackage) => {
                                                                        item.productPackage = productPackage
                                                                        orderItems[index] = item
                                                                        this.setState({orderItems})
                                                                    }}/>
                                                            </td>
                                                            <td>{item.productPackage.description}</td>
                                                            <td>{item.productPackage.monthPeriod+" "+label.Month}</td>
                                                            <td style={{maxWidth:'210px'}}>{item.spotLocation?item.spotLocation.fullAddress:""}</td>
                                                            <td>
                                                                {currencyFormat(item.productPackage.price, CURRENCY_SYMBOL)}
                                                            </td>
                                                            <td>
                                                                <Checkbox
                                                                    onChange={(checked) => {
                                                                        item.checked = checked
                                                                        orderItems[index] = item
                                                                        this.setState({orderItems})
                                                                    }}
                                                                    checked={item.checked}/>
                                                            </td>
                                                        </tr>
                                                        {
                                                            index===orderItems.length-1?(
                                                                <>
                                                                    <tr>
                                                                        <th><WarningLabel show={validation.orderItems} message={validationMessage.orderItems} /></th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><h6>{label.AdminFee}</h6></td>
                                                                        <td><h6>{currencyFormat(adminFee, 'Rp')}</h6></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th></th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><h6>{label.Tax}</h6></td>
                                                                        <td><h6>{currencyFormat(taxAmount, 'Rp')}</h6></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th></th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><h5>{label.Total}</h5></td>
                                                                        <td><h5>{currencyFormat(totalAmount, 'Rp')}</h5></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </>
                                                            ):null
                                                        }
                                                    </>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button
                                color="warning" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                }, () => {
                                    if(this.props.onClose!=null){
                                        this.props.onClose()
                                    }
                                })
                            }}>
                                {label.Cancel}
                            </Button>
                            &nbsp;
                            <Button
                                color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.validateFormSubmit()
                            }}>
                                {label.ExtendPeriod}
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

ExtendProductOrderInquiryModal.propTypes = {
    show:PropTypes.bool.isRequired,
    company:PropTypes.object.isRequired,
    productSubscribes:PropTypes.array.isRequired,
    onClose:PropTypes.func.isRequired,
    onCreateOrder:PropTypes.func.isRequired
}
