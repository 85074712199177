import React from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ValidatorLabel from '../Widget/ValidatorLabel';
import PropTypes from 'prop-types';
import BaseComponent from '../BaseComponent';
import Endpoint from "na-utilities/src/api/Endpoint";
import {firstLetterUppercase, isEmpty, isResponseOk} from "na-utilities/src/utils/Utilities";
import ItemOption from "../Widget/ItemOption";
import label from "na-utilities/src/label/Label"

/*= (message, okCallback, cancelCallback) =>*/

export default class SmartDeviceUpdateModal extends BaseComponent {

    constructor(props){
        super(props);
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()

        this.state = {
            show:false,
            smartDevice:props.smartDevice?props.smartDevice:{},
            spotLocations:[],
            name:"",
            labeling:"",
            description:"",
            spotLocation:{},
            validation,
            validationMessage
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.name = true ;
        validation.labeling = true ;
        validation.description = true ;
        validation.spotLocation = true
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.name = "" ;
        validationMessage.labeling = "" ;
        validationMessage.description = "" ;
        validationMessage.spotLocation = "" ;
        return validationMessage ;
    }
    componentDidMount() {
        super.componentDidMount();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            this.setState({show},() => {
                if(show && !prevState.show){
                    let validation = this.defaultValidation()
                    let validationMessage = this.defaultValidationMessage()
                    let smartDevice = this.props.smartDevice?this.props.smartDevice:{}
                    let name = smartDevice.name
                    let description = smartDevice.description
                    let labeling = smartDevice.label
                    let spotLocation = smartDevice.spotLocation
                    this.setState({
                        show,
                        smartDevice,
                        name, labeling, description, spotLocation,
                        validation,
                        validationMessage
                    }, () => {
                        this.fetchSpotLocations()
                    })
                }
            })
        }
    }

    fetchSpotLocations(){
        let {smartDevice} = this.state
        let company = smartDevice.company?smartDevice.company:{}
        this.get(Endpoint.API.SPOT_LOCATIONS, {
            params:{
                companyId:company.id,
                active:true,
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    spotLocations:response.data,
                    totalPage: response.totalPage,
                    totalElement: response.totalElement,
                    pageElement: response.pageElement,
                })
            }
        }, true, false)
    }



    validateFormSubmit() {
        // await super.validateFormSubmit();
        let {show, name, labeling, spotLocation, validation, validationMessage} = this.state
        let valid = true;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if (isEmpty(name)) {
            validation.name = false
            validationMessage.name = label.PleaseEnterSmartDeviceName
            valid = false;
        }
        if (isEmpty(name) || name.length <= 2) {
            validation.name = false
            validationMessage.name = label.PleaseEnterSmartDeviceNameCorrectly
            valid = false;
        }
        if (isEmpty(labeling)) {
            validation.labeling = false
            validationMessage.labeling = label.PleaseEnterSmartDeviceLabel
            valid = false;
        }
        if (isEmpty(labeling) || labeling.length <= 2) {
            validation.labeling = false
            validationMessage.labeling = label.PleaseEnterSmartDeviceLabelCorrectly
            valid = false;
        }
        if (isEmpty(spotLocation)) {
            validation.spotLocation = false
            validationMessage.spotLocation = label.PleaseSelectLocation
            valid = false;
        }


        this.setState({validation, validationMessage}, () => {
            if(valid){
                this.save()
            }
        })
    }

    save(){
        let {show, smartDevice, name, labeling, description, spotLocation, validation, validationMessage} = this.state
        let {onClose} = this.props
        let form = new FormData()
        form.append("name", name)
        form.append("label", labeling)
        form.append("description", description)
        form.append("locationId", spotLocation.id)
        form.append("id", smartDevice.id)
        this.post(Endpoint.API.SMART_DEVICE_UPDATE_INFORMATION, form, null, (res)=>{
            if(isResponseOk(res)){
                let smartDevice = res.data
                this.successToast(label.SuccessUpdatingData)
                if(onClose){
                    onClose(smartDevice)
                }
            }else{
                this.errorToast(res.message)
                if(onClose){
                    onClose()
                }
            }
        }, true, false)
    }

    render(){
        let {name, labeling, description, spotLocation, show, spotLocations, validation, validationMessage} = this.state
        let {onClose} = this.props
        return (
            <Modal
                isOpen={show}
                centered={true}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.UpdateSmartDevice}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">{label.Name}</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        name = firstLetterUppercase(value)
                                        if(name.length>0){
                                            validation.name = true
                                            validationMessage.name = ""
                                        }
                                        this.setState({
                                            name, validation, validationMessage
                                        })
                                    }}
                                    placeholder={label.PleaseEnterSmartDeviceName}
                                />
                                <ValidatorLabel message={validationMessage.name}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="labeling">{label.Label}</Label>
                                <Input
                                    type="text"
                                    name="labeling"
                                    value={labeling}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        labeling = firstLetterUppercase(value)
                                        if(labeling.length>0){
                                            validation.labeling = true
                                            validationMessage.labeling = ""
                                        }
                                        this.setState({
                                            labeling, validation, validationMessage
                                        })
                                    }}
                                    placeholder={label.PleaseEnterSmartDeviceLabel}
                                />
                                <ValidatorLabel message={validationMessage.labeling}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="description">{label.Description}</Label>
                                <Input
                                    type="textarea"
                                    name="description"
                                    value={description}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        description = firstLetterUppercase(value)
                                        this.setState({
                                            labeling
                                        })
                                    }}
                                    placeholder={label.PleaseEnterSmartDeviceLabel}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    hideOptionLabel={true}
                                    title={label.Location}
                                    fieldForLabel={"name"}
                                    hideLabel={false}
                                    default={spotLocation}
                                    callback={(value) => {
                                        spotLocation = value
                                        if(!isEmpty(spotLocation)){
                                            validation.spotLocation = true
                                            validationMessage.spotLocation = ""
                                        }
                                        this.setState({
                                            spotLocation, validation, validationMessage
                                        })
                                    }}
                                    objects={spotLocations}/>
                                <ValidatorLabel message={validationMessage.spotLocation}/>
                            </FormGroup>

                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning" onClick={e=>{
                        e.preventDefault()
                        this.setState({
                            modal:false
                        }, () => {
                            if(this.props.onClose!=null){
                                this.props.onClose()
                            }
                        })
                    }}>
                        {label.Cancel}
                    </Button>
                    &nbsp;
                    <Button
                        color="secondary" onClick={e=>{
                        e.preventDefault()
                        this.validateFormSubmit()
                    }}>
                        {label.Save}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

SmartDeviceUpdateModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    smartDevice:PropTypes.object.isRequired,
}
