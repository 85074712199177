import * as React from 'react';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import { MdDelete, MdEdit } from 'react-icons/md';
import PropTypes from 'prop-types';
import {Button, FormGroup, Label, Table} from 'reactstrap';
import { FaClone } from "react-icons/fa";
import CabinetContainerModal from "../modal/CabinetContainerModal";
import BaseComponent from "../BaseComponent";
import label from "na-utilities/src/label/Label";
import {cloneObject} from "na-utilities/src/utils/Utilities";
import Input from "reactstrap/es/Input";

const { htmlToText } = require('html-to-text');


export default class ContainerCard extends BaseComponent {
    props;

    constructor(props) {
        super(props);
        this.state = {
            formModal: false,
            form: this.props.form,
            board: this.props.board,
            lanes: this.props.lanes,
            cabinet:this.props.cabinet,
            formCopy: {}
        }

    }
    componentWillReceiveProps(props, nextContext) {
        this.setState({
            formModal: false,
            form: props.form,
            board: props.board,
            lanes: props.lanes,
            cabinet:props.cabinet,
            formCopy: {}
        })
    }

    containerSize(){
        let {board} = this.state
        let size = 0 ;
        let lanes = board.lanes?board.lanes:[]
        lanes.forEach(value => {
            let cards = value.cards
            let cardSize = cards.length
            size += cardSize
        })
        return size ;
    }


    render() {
        let form = this.state.form
        let formCopy = this.state.formCopy
        let board = this.state.board
        let formModal = this.state.formModal
        let lanes = this.state.lanes ? this.state.lanes : []
        let cabinet = this.state.cabinet
        return (
            <>
                {super.render()}
                <CabinetContainerModal
                    cabinet={this.props.cabinet}
                    showing={formModal}
                    lanes={cloneObject(lanes)}
                    lane={cloneObject(formCopy && formCopy.lane ? formCopy.lane : (form && form.lane ? form.lane : {}))}
                    form={formCopy ? formCopy : (form ? form : {})}
                    // board={cloneObject(board)}
                    board={board}
                    // form={form?form:{}}
                    onAdd={(form, lane, newBoard) => {
                        if (newBoard) {
                            if (this.props.onNewFormClone) {
                                this.props.onNewFormClone(newBoard)
                            }
                        } else {
                            let laneIndex = -1;
                            let laneId = -1;
                            board.lanes.forEach((value, index) => {
                                if (value.id === lane.id) {
                                    laneIndex = index;
                                    laneId = value.id
                                }
                            })
                            if (laneIndex > -1) {
                                let lanes = board.lanes
                                if (formCopy.lane && formCopy.lane.id !== laneId) {
                                    console.log("Prepare Delete Card 1")
                                    board.lanes.forEach((item, index) => {
                                        if (form.lane.id == item.id) {
                                            console.log("Prepare Delete Card 2")
                                            item.cards.forEach((value, index1) => {
                                                if (value.id == form.id) {
                                                    console.log("Delete Card")
                                                    item.cards.splice(index1, 1)
                                                }
                                            })
                                        }
                                    })
                                    board.lanes[laneIndex].cards.push(form)
                                } else {
                                    // still on previous lane position
                                    let indexToReplace = -1;
                                    board.lanes[laneIndex].cards.forEach((value, index) => {
                                        if (value.id === form.id) {
                                            indexToReplace = index
                                        }
                                    })
                                    if (indexToReplace > -1) {
                                        board.lanes[laneIndex].cards[indexToReplace] = form
                                    } else {
                                        board.lanes[laneIndex].cards.push(form)
                                    }
                                }
                                formCopy.lane = lane
                                board.lanes = lanes
                                this.setState({
                                    formModal: false,
                                }, () => {
                                    if (this.props.onNewFormClone) {
                                        this.props.onNewFormClone(board)
                                    }
                                })
                            }
                        }
                    }}
                    close={() => {
                        this.setState({
                            formModal: false,
                        })
                    }}
                />
                <Card style={{ width: '600px', marginBottom: '10px' }}>
                    <CardBody style={{ padding: 0 }}>
                        <Table hover>
                            <thead>
                                <tr>
                                    <th style={{ width: '25%' }}>{label.Container}</th>
                                    <th style={{ width: '45%' }}></th>
                                    <th style={{ width: '30%', textAlign: "right" }}>
                                        <span style={{
                                            background: "blue",
                                            color: 'white',
                                            borderRadius: '20%',
                                            marginRight: '2px',
                                            paddingLeft: '16px',
                                            paddingRight: '16px',
                                            paddingTop: '4px',
                                            paddingBottom: '4px',
                                            fontSize: '20px'
                                        }}>{form.tag}</span></th>
                                </tr>
                            </thead>
                            <tr key={3}>
                                <td scope="row">{label.Title}</td>
                                <td colSpan={2}>{form.title}</td>
                            </tr>
                            <tr>
                                <td colSpan={3}>
                                    <FormGroup>
                                        <Label for="name">{label.Description}</Label>
                                        <Input
                                            type="textarea"
                                            name="placeHolder"
                                            rows={5}
                                            value={htmlToText(form.placeHolder, { wordWrap: 50 })}
                                            disabled
                                        />
                                    </FormGroup>
                                </td>
                            </tr>
                            <tfoot>
                                <td colSpan={3}>
                                    <Button color={"primary"} onClick={(e) => {
                                        if (this.props.editCard) {
                                            this.props.editCard(form)
                                        }
                                    }}>
                                        <MdEdit />
                                    </Button>
                                    &nbsp;
                                    <Button
                                        color="warning"
                                        onClick={e => {
                                            if(cabinet.maximumContainer<=this.containerSize()){
                                                this.errorToast(label.MaximumContainerSizeReached)
                                                return ;
                                            }
                                            formCopy = cloneObject(form)
                                            formCopy.id = undefined
                                            formCopy.lockerPosition = undefined
                                            this.setState({
                                                formCopy: cloneObject(formCopy)
                                            }, () => {
                                                this.setState({ formModal: true })
                                            })
                                        }}>
                                        <FaClone />
                                    </Button>
                                    &nbsp;
                                    <Button color='danger' onClick={(e) => {
                                        if (this.props.cardBag) {
                                            this.openConfirmDialog(label.Confirmation, label.DoYouWantToRemoveThisContainer, () => {
                                                this.props.cardBag.removeCard()
                                            })
                                        }
                                    }}>
                                        <MdDelete />
                                    </Button>
                                </td>
                            </tfoot>
                        </Table>
                    </CardBody>
                </Card>
            </>
        );
    }
}
ContainerCard.propTypes = {
    editCard: PropTypes.func,
    form: PropTypes.object.isRequired,
    board: PropTypes.object.isRequired,
    lanes: PropTypes.array.isRequired,
    onNewFormClone: PropTypes.func,
    cabinet:PropTypes.any,
}