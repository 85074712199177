import Page from 'components/Page';
import {NumberWidget} from 'components/Widget';
import React from 'react';
import {Button, Col, Row} from 'reactstrap';
import {getColor} from 'utils/colors';
import BasePage from './BasePage';
import Global, {_PAID} from '../utils/Global';
import {MdRefresh} from 'react-icons/md/index';
import DashboardTransactionCard from '../components/tab/dashboard/DashboardTransactionCard';
import DashboardCategoryAnalytic from './../components/tab/dashboard/DashboardCategoryAnalytic';
import SockJsClient from 'react-stomp';
import label from 'na-utilities/src/label/Label'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Endpoint from "na-utilities/src/api/Endpoint";

const today = new Date();

let productOrderCardRef =  React.createRef()
let categoryCardRef =  React.createRef()
let productOrderItemCardRef =  React.createRef()
let productOrderAnalyticCardRef =  React.createRef()

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2
  }
};

class MainDashboardPage extends BasePage {

  constructor(props) {
    super(props);
    let state = this.state
    state.mounted = false
    state.adminDashboard = {}
    state.productOrderStatusId = _PAID
    this.setState(state)
  }


  componentDidMount() {
    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    if(!this.state.mounted){
      this.setState({
        mounted : true
      }, () => {
        this.fetchAdminDashboard(true)
      })
    }
  }


  fetchAdminDashboard=(progressing)=>{
    this.get(Endpoint.API.ADMIN_DASHBOARD, null, null, response=>{
      if(response.code===200){
        this.setState({
          adminDashboard:response.data
        })
      }else{
        this.errorToast(response.message)
      }
    }, progressing, true)
  }

  refreshDashboard=(progressing)=>{
    this.fetchAdminDashboard(progressing)
    if(productOrderCardRef){
      productOrderCardRef.current.refreshDashboard()
    }
    if(categoryCardRef){
      categoryCardRef.current.fetchCategoryAnalytic()
    }
    if(productOrderItemCardRef){
      productOrderItemCardRef.current.fetchTransactionAnalytic()
    }
    if(productOrderAnalyticCardRef){
      productOrderAnalyticCardRef.current.fetchTransactionAnalytic()
    }
  }

  render() {
    const primaryColor = getColor('primary');
    const secondaryColor = getColor('secondary');
    let adminDashboard = this.state.adminDashboard
    let productOrderStatusId = this.state.productOrderStatusId
    return (
      <Page
        className="DashboardPage"
        title={label.Dashboard}
        breadcrumbs={[{ name: label.Dashboard, active: true }]}
      >
        {super.render()}
        <Row>
          <Col>
            <Button className="float-right" onClick={event=>{
              // this.fetchAdminDashboard(true)
              this.refreshDashboard(true)
            }}>{label.Refresh} <MdRefresh/></Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{textAlign:'center'}}>
              <Carousel
                  autoPlay={true}
                  infinite={true}
                  partialVisible={true}
                  autoPlaySpeed={3000}
                  style={{maxWidth:'10%'}}
                  responsive={responsive}
                  itemClass="carousel-item-padding-40-px">
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.TotalTransaction}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthTransaction}
                    number={adminDashboard.totalTransaction&&adminDashboard.totalTransaction.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthTransactionPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthTransaction,
                    }}
                />
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.Done}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthDONE}
                    number={adminDashboard.totalDone&&adminDashboard.totalDone.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthDONEPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthDONE,
                    }}
                />
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label.Refund}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthREFUND}
                    number={adminDashboard.totalRefund&&adminDashboard.totalRefund.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthRefundPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthREFUND,
                    }}
                />
                <NumberWidget
                    style={{margin:'12px'}}
                    title={label._Maked}
                    subtitle={label.PreviousMonth+" : "+adminDashboard.previousMonthCREATED}
                    number={adminDashboard.totalCreated&&adminDashboard.totalCreated.toString()}
                    color="secondary"
                    progress={{
                      value: Math.ceil(adminDashboard.thisMonthCreatedPercent),
                      label: label.ThisMonth+' : '+adminDashboard.thisMonthCREATED,
                    }}
                />

              </Carousel>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <DashboardTransactionCard ref={productOrderCardRef} {...this.props}/>
          </Col>
          <Col md={5}>
            <DashboardCategoryAnalytic ref={categoryCardRef} {...this.props}/>
          </Col>
        </Row>
        <SockJsClient
            url={Global.BASE_URL}
            topics={[Endpoint.API.LISTEN_UPDATE_PRODUCT]}
            autoReconnect={true}
            debug={Global.DEBUG}
            heartbeat={5000}
            onMessage={(message) => {
              this.refreshDashboard(message)
            }}
            onConnectFailure={(object)=>{
              console.error(object)
            }}
            ref={ (client) => { this.clientRef = client }} />
        <SockJsClient
            url={Global.BASE_URL}
            topics={[Endpoint.API.LISTEN_UPDATE_PRODUCT_ORDERS]}
            autoReconnect={true}
            debug={Global.DEBUG}
            heartbeat={5000}
            onMessage={(message) => {
              this.refreshDashboard(message)
            }}
            onConnectFailure={(object)=>{
              console.error(object)
            }}
            ref={ (client) => { this.clientRef = client }} />
      </Page>
    );
  }
}
export default Object.assign(MainDashboardPage, {ENDPOINT : "/"})
