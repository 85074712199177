import React from 'react';
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Table
} from 'reactstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ValidatorLabel from '../Widget/ValidatorLabel';
import PropTypes from 'prop-types';
import {CURRENCY_SYMBOL} from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
import {currencyFormat, isEmpty, isResponseOk, isValidEmail} from "na-utilities/src/utils/Utilities";
import ItemOption from "../Widget/ItemOption";
import WarningLabel from "../Widget/WarningLabel";
import {IoMdRemove} from "react-icons/all";
import SpotLocationSelectionModal from "./SpotLocationSelectionModal";
import {ButtonGroup} from "react-bootstrap";

/*= (message, okCallback, cancelCallback) =>*/

export default class NewProductOrderInquiryModal extends BaseComponent {

    constructor(props){
        super(props);
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()

        this.state = {
            show:false,
            company: props.company?props.company:{},
            products:[],
            productPackages:[],
            orderItems:[],
            selectedProduct:{},
            selectedProductPackage:{},
            companyName:"",
            validation,
            validationMessage,
            spotLocationSelectionModal:false,
            selectedSpotLocation:{},
            paymentAttribute:{}
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.company = true ;
        validation.product = true ;
        validation.productPackage = true ;
        validation.orderItems = true ;
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.company = "" ;
        validationMessage.product = "" ;
        validationMessage.productPackage = "" ;
        validationMessage.orderItems = "" ;
        return validationMessage ;
    }
    componentDidMount() {
        super.componentDidMount();
        this.fetchPaymentMediaAttribute((paymentAttribute)=>{
            this.setState({paymentAttribute})
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            this.setState({show},() => {
                if(show && !prevState.show){
                    let validation = this.defaultValidation()
                    let validationMessage = this.defaultValidationMessage()
                    this.setState({
                        show:show,
                        company:this.props.company?this.props.company:{},
                        selectedProduct:{},
                        selectedProductPackage:{},
                        orderItems:[],
                        validation,
                        validationMessage
                    }, () => this.fetchProducts())
                }
            })
        }
    }

    fetchProducts = () => {
        this.get(Endpoint.API.PRODUCTS, {
            params:{
                ascending:true,
                sortir:"suggestion",
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    products : response.data
                })
            }
        }, true, true);
    }

    fetchProductPackages(){
        let {selectedProduct} = this.state
        this.get(Endpoint.API.PRODUCT_PACKAGES, {params:{"published":true, productId:selectedProduct.id}},null, (response)=>{
            if(isResponseOk(response)){
                this.setState({
                    productPackages : response.data
                })
            }
        },   false, false)
    }

    fetchPaymentMediaAttribute(callback){
        this.get(Endpoint.API.PAYMENT_MEDIA_ATTRIBUTE, {},null, (response)=>{
            if(isResponseOk(response)){
                callback(response.data)
            }
        },   false, false)
    }

    validate(callback){
        let {company, selectedProduct, selectedProductPackage, validation, validationMessage} = this.state
        let valid = true;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if (isEmpty(company)) {
            validation.company = true
            validationMessage.company = label.PleaseSelectCompany
            valid = false;
        }
        if (isEmpty(selectedProduct)) {
            validation.product = true
            validationMessage.product = label.PleaseSelectProduct
            valid = false;
        }
        if (isEmpty(selectedProductPackage) ) {
            validation.productPackage = true
            validationMessage.productPackage = label.PleaseSelectPackage
            valid = false;
        }
        this.setState({validation, validationMessage}, () => {
            callback(valid)
        })

    }

    validateFormSubmit() {
        // await super.validateFormSubmit();
        let {show, company, selectedProduct, selectedProductPackage, selectedSpotLocation,
            products, productPackages, orderItems, spotLocationSelectionModal, paymentAttribute,
            validation, validationMessage} = this.state
        let valid = true;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if (isEmpty(company)) {
            validation.company = false
            validationMessage.company = label.PleaseSelectCompany
            valid = false;
        }
        if (isEmpty(selectedProduct)) {
            validation.product = false
            validationMessage.product = label.PleaseSelectProduct
            valid = false;
        }
        if (isEmpty(selectedProductPackage) ) {
            validation.productPackage = false
            validationMessage.productPackage = label.PleaseSelectPackage
            valid = false;
        }

        if (isEmpty(orderItems) || orderItems.length===0) {
            validation.orderItems = false
            validationMessage.orderItems = label.PleaseAddAtLeastOneOrderItem
            valid = false;
        }
        this.setState({validation, validationMessage}, () => {
            if(valid){
                this.submit()
            }
        })
    }

    submit(){
        let {show, company, selectedProduct, selectedProductPackage, selectedSpotLocation,
            products, productPackages, orderItems, spotLocationSelectionModal, paymentAttribute,
            validation, validationMessage} = this.state
        let {onCreateOrder, onClose} = this.props
        let form = new FormData()
        form.append("orderItemListGson", JSON.stringify(orderItems))
        this.post(Endpoint.API.PRODUCT_ORDER_CREATE_WITH_COMPANY+"/"+company.id, form, null, (res)=>{
            if(isResponseOk(res)){
                let productOrder = res.data
                this.successToast(label.SucceedCreatingNewRegistration)
                if(onCreateOrder){
                    onCreateOrder(productOrder)
                }
            }else{
                this.errorToast(res.message)
                if(onClose){
                    onClose()
                }
            }
        }, true, false)
    }

    render(){
        let {show, company, selectedProduct, selectedProductPackage, selectedSpotLocation,
            products, productPackages, orderItems, spotLocationSelectionModal, paymentAttribute,
            validation, validationMessage} = this.state
        let {onClose} = this.props

        let adminFee = paymentAttribute.adminFee?paymentAttribute.adminFee:0
        let taxPercent = paymentAttribute.taxPercent?paymentAttribute.taxPercent:0
        let taxAmount = 0
        let total = 0
        let totalAmount = 0
        orderItems.forEach((item)=>{
            let productPackage = item.productPackage
            total += productPackage.price
        })
        // total = total+adminFee
        taxAmount = (total+adminFee)*(taxPercent/100)
        totalAmount = total+taxAmount+adminFee

        return (
            <>
                <SpotLocationSelectionModal
                    showing={spotLocationSelectionModal}
                    onClose={()=>{
                        this.setState({spotLocationSelectionModal:false})
                    }}
                    company={company}
                    onSelect={(spotLocation)=>{
                        let orderItem = {}
                        orderItem.productPackage = selectedProductPackage
                        orderItem.spotLocation = spotLocation
                        orderItems.push(orderItem)
                        this.setState({spotLocationSelectionModal:false, orderItems})
                    }}/>
                <Modal
                    size="xl"
                    isOpen={show}
                    centered={true}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.NewOrder}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="companyName">{label.Company}</Label>
                                    <Input
                                        type="text"
                                        name="companyName"
                                        value={company.fullName}
                                        enabled={false}
                                    />
                                    <WarningLabel show={validation.company} message={validationMessage.company} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <ItemOption
                                        title={label.Product}
                                        fieldForLabel={"name"}
                                        hideLabel={false}
                                        default={selectedProduct.id}
                                        callback={(product) => {
                                            validation.product = true
                                            validationMessage.product = true
                                            this.setState({
                                                validation: validation,
                                                validationMessage:validationMessage,
                                                selectedProduct:product
                                            }, () => {
                                                if(product.id){
                                                    this.fetchProductPackages()
                                                }
                                            })
                                        }}
                                        objects={products}/>
                                    <WarningLabel show={validation.product} message={validationMessage.product} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <ItemOption
                                        title={label.ProductPackage}
                                        fieldForLabel={"name"}
                                        hideLabel={false}
                                        default={selectedProductPackage.id}
                                        callback={(productPackage) => {
                                            validation.productPackage = true
                                            validationMessage.productPackage = true
                                            this.setState({
                                                validation: validation,
                                                validationMessage:validationMessage,
                                                selectedProductPackage:productPackage
                                            })
                                        }}
                                        objects={productPackages}/>
                                    <WarningLabel show={validation.productPackage} message={validationMessage.productPackage} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label for="companyName">{label.Description}</Label>
                                    <Input
                                        type="text"
                                        name="companyName"
                                        value={selectedProductPackage.description}
                                        enabled={false}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label for="companyName">{label.MonthPeriod}</Label>
                                    <Input
                                        type="text"
                                        name="companyName"
                                        value={selectedProductPackage.monthPeriod}
                                        enabled={false}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label for="companyName">{label.Price}</Label>
                                    <Input
                                        type="text"
                                        name="price"
                                        value={currencyFormat(selectedProductPackage.price, 'Rp')}
                                        enabled={false}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={2} className="text-right">
                                <FormGroup>
                                    <Label for="companyName">&nbsp;&nbsp;</Label>
                                    <br/>
                                    <Button
                                        block
                                        color="warning"
                                        onClick={e=>{
                                            this.validate((valid)=>{
                                                if(valid){
                                                    this.setState({spotLocationSelectionModal:true})
                                                }
                                            })
                                        }}>
                                        {label.Add}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody className="p-0">
                                        <Table hover responsive>
                                            <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>{label.Product}</th>
                                                <th>{label.ProductPackage}</th>
                                                <th>{label.Description}</th>
                                                <th>{label.MonthPeriod}</th>
                                                <th style={{maxWidth:'210px'}}>{label.Location}</th>
                                                <th>{label.Price}</th>
                                                <th>{label.Remove}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                orderItems.map((item, index) => (
                                                    <>
                                                        <tr key={item.id}>
                                                            <th scope="row">{index+1}</th>
                                                            <td>{item.productPackage.product.name}</td>
                                                            <td>{item.productPackage.name}</td>
                                                            <td>{item.productPackage.description}</td>
                                                            <td>{item.productPackage.monthPeriod+" "+label.Month}</td>
                                                            <td style={{maxWidth:'210px'}}>{item.spotLocation?item.spotLocation.fullAddress:""}</td>
                                                            <td>
                                                                {currencyFormat(item.productPackage.price, CURRENCY_SYMBOL)}
                                                            </td>
                                                            <td>
                                                                <Button
                                                                    size="sm"
                                                                    className="btn-danger"
                                                                    onClick={() => {
                                                                        orderItems.splice(index, 1)
                                                                        this.setState({orderItems})
                                                                    }}>
                                                                    <IoMdRemove/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                        {
                                                            index===orderItems.length-1?(
                                                                <>
                                                                    <tr>
                                                                        <th></th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><h6>{label.AdminFee}</h6></td>
                                                                        <td><h6>{currencyFormat(adminFee, 'Rp')}</h6></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th></th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><h6>{label.Tax}</h6></td>
                                                                        <td><h6>{currencyFormat(taxAmount, 'Rp')}</h6></td>
                                                                        <td></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th></th>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td><h5>{label.Total}</h5></td>
                                                                        <td><h5>{currencyFormat(totalAmount, 'Rp')}</h5></td>
                                                                        <td></td>
                                                                    </tr>
                                                                </>
                                                            ):null
                                                        }
                                                    </>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                        <WarningLabel show={validation.orderItems} message={validationMessage.orderItems} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button
                                color="warning" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                }, () => {
                                    if(this.props.onClose!=null){
                                        this.props.onClose()
                                    }
                                })
                            }}>
                                {label.Cancel}
                            </Button>
                            &nbsp;
                            <Button
                                color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.validateFormSubmit()
                            }}>
                                {label.CreateOrder}
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

NewProductOrderInquiryModal.propTypes = {
    show:PropTypes.bool.isRequired,
    company:PropTypes.object.isRequired,
    onClose:PropTypes.func.isRequired,
    onCreateOrder:PropTypes.func.isRequired
}
