import React from 'react';
import {
    Button,
    Card,
    CardBody, CardHeader,
    Col,
    FormGroup, Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
} from 'reactstrap';
import {allIsEmpty, isEmpty, navigatePage, parseDate} from 'na-utilities/src/utils/Utilities';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../../utils/Global';
import label from 'na-utilities/src/label/Label'
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import SearchInput from "../SearchInput";
import Checkbox from "../Checkbox";
import ModalPagination from "../ModalPagination";
import queryString from "query-string";
import ItemOption from "../Widget/ItemOption";
import {IoMdSettings} from "react-icons/io";
import {MdRestore} from "react-icons/md";
import Endpoint from "na-utilities/src/api/Endpoint";

export default class SpotLocationSelectionModal extends BaseComponent{

    constructor(props) {
        super(props);
        let spotLocation = this.props.spotLocation?this.props.spotLocation:{}
        let spotLocations = []
        this.state ={
            company:props.company?props.company:{},
            spotLocation:spotLocation,
            spotLocations:spotLocations,
            sortir:'jie.updated',
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            search:queryString.parse(this.props.query).search?queryString.parse(this.props.query).search:"",
            inputSearch : "",
            totalPage:0,
            totalElement:0,
            pageElement:0,
            showing:this.props.showing,
            provinces:[],
            cities:[],
            districts:[],
            villages:[],
            province:{},
            city:{},
            district:{},
            village:{}
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props.showing){
            let company = props.company?props.company:{}
            let spotLocation = props.spotLocation?props.spotLocation:{}
            let spotLocations = []
            let page = 1
            let inputSearch = ""
            this.setState({
                company:company,
                spotLocation:spotLocation,
                spotLocations:spotLocations,
                page:page,
                inputSearch:inputSearch,
                showing:props.showing,
                provinces:[],
                cities:[],
                districts:[],
                villages:[],
                province:{},
                city:{},
                district:{},
                village:{}
            },() => {
                if(props.showing){
                    this.fetchSpotLocations()
                    this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))
                }
            })
        }
    }
    componentDidMount() {
        super.componentDidMount();
        let {showing} = this.state
        if(showing){
            this.fetchSpotLocations()
            this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))

        }
    }

    fetchSpotLocations(){
        let {company, ascending, sortir, page, province, city, district, village,inputSearch} = this.state
        this.get(Endpoint.API.SPOT_LOCATIONS, {
            params:{
                companyId:company.id,
                active:true,
                page:page-1,
                ascending:ascending,
                sortir:sortir,
                provinceId:province.id,
                pcityId:city.id,
                districtId:district.id,
                villageId:village.id,
                keyword:inputSearch
            }
        }, null, response=>{
            if(response.code===200){
                this.setState({
                    spotLocations:response.data,
                    totalPage: response.totalPage,
                    totalElement: response.totalElement,
                    pageElement: response.pageElement,
                })
            }
        }, true, false)
    }

    render() {
        let {spotLocation, spotLocations} = this.state

        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.showing}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.ClientLocationList}
                    </ModalHeader>
                    <ModalBody>
                        {this.renderSpotLocations()}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="warning"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onClose()
                            }}>
                            {label.Cancel}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={e=>{
                                e.preventDefault()
                                this.props.onSelect(spotLocation)
                            }}>
                            {label.Select}
                        </Button>
                    </ModalFooter>
                </Modal>

            </>
        )
    }

    resetFilter(){
        this.setState({
            inputSearch:"",
            page :1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            province:{},
            city:{},
            district:{},
            village:{},
        }, () => {
            this.fetchSpotLocations()
        })
    }

    renderSpotLocations = () =>{
        let {inputSearch} = this.state
        return (
            <Row key={2}>
                <Col>
                    <Row>
                        <Col md={3}>
                            <FormGroup>
                                <ItemOption
                                    title={label.Province}
                                    objects={this.state.provinces}
                                    default={!allIsEmpty(this.state.province)?this.state.province.id:null}
                                    callback={(province)=>{
                                        this.setState({
                                            province:!isEmpty(province.id)?province:{},
                                            cities:[],
                                            city:!isEmpty(province.id)?this.state.city:{},
                                            districts:[],
                                            district:!isEmpty(province.id)?this.state.district:{},
                                            villages:[],
                                            village:!isEmpty(province.id)?this.state.village:{},
                                        }, () => {
                                            if(!isEmpty(province.id)){
                                                this.fetchCities(province.id, cities=>{
                                                    this.setState({
                                                        cities:cities
                                                    })
                                                })
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <ItemOption
                                    title={label.City}
                                    objects={this.state.cities}
                                    default={!allIsEmpty(this.state.city)?this.state.city.id:null}
                                    callback={(city)=>{
                                        this.setState({
                                            city:!isEmpty(city.id)?city:{},
                                            districts:[],
                                            district:!isEmpty(city.id)?this.state.district:{},
                                            villages:[],
                                            village:!isEmpty(city.id)?this.state.village:{},
                                        }, () => {
                                            if(!isEmpty(city.id)){
                                                this.fetchDistricts(city.id, districts =>{
                                                    this.setState({
                                                        districts:districts
                                                    })
                                                })
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <ItemOption
                                    title={label.District}
                                    objects={this.state.districts}
                                    default={!allIsEmpty(this.state.district)?this.state.district.id:null}
                                    callback={(district)=>{
                                        this.setState({
                                            district:!isEmpty(district.id)?district:{},
                                            villages:[],
                                            village:!isEmpty(district.id)?this.state.village:{},
                                        }, () => {
                                            if(!isEmpty(district.id)){
                                                this.fetchVillages(district.id, villages=>{
                                                    this.setState({
                                                        villages:villages
                                                    })
                                                })
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <ItemOption
                                    title={label.Village}
                                    objects={this.state.villages}
                                    default={!allIsEmpty(this.state.village)?this.state.village.id:null}
                                    callback={(village)=>{
                                        this.setState({
                                            village:!isEmpty(village)?village:{},
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="search">{label.Searching}</Label>
                                <SearchInput
                                    value={inputSearch}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        let inputSearch = value
                                        this.setState({inputSearch}, () => {
                                            if(isEmpty(inputSearch)){
                                                this.fetchSpotLocations()
                                            }
                                        })
                                    }}
                                    onEnter={() => {
                                        this.fetchSpotLocations()
                                    }}
                                    placeholder={label.SearchLocation}/>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Button block className={"float-left"} color={"primary"}
                                        onClick={event => {
                                            this.fetchSpotLocations()
                                        }}><IoMdSettings/>&nbsp;{label.ApplyFilter}</Button>
                            </FormGroup>
                        </Col>
                        <Col md={3}>
                            <FormGroup>
                                <Label>&nbsp;</Label>
                                <Button block className={"float-left"} color={"yellow"}
                                        onClick={event => {
                                            this.resetFilter()
                                        }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            {this.renderSpotLocationsTable()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }


    renderSpotLocationsTable = () =>{
        let {spotLocations, spotLocation} = this.state
        return (
            <Card>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Name}</th>
                            <th>{label.Description}</th>
                            <th>{label.Address}</th>
                            <th>{label.LastUpdate}</th>
                            <th>{label.Select}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                           spotLocations.map((item, index)=>(
                                <tr key={item.id}>
                                    <th scope="row">{(++index)}</th>
                                    <td>{item.name}</td>
                                    <td>{item.description}</td>
                                    <td style={{maxWidth:"240px"}} className="text-wrap">{item.fullAddress}</td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                    <td>
                                        <Checkbox
                                            checked={spotLocation&&(spotLocation.id===item.id)}
                                            onChange={
                                                checked => {
                                                    if(checked && checked===true){
                                                        spotLocation = item
                                                    }else{
                                                        spotLocation = {}
                                                    }
                                                    this.setState({
                                                        spotLocation:spotLocation
                                                    })
                                                }
                                            }/>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
                <ModalPagination {...this.props} currentPage={this.state.page} pageCount={this.state.totalPage} onSelect={(page)=>{
                    this.setState({
                        page:page
                    }, () => {
                        this.fetchSpotLocations()
                    })
                }}/>
            </Card>
        )
    }


}

SpotLocationSelectionModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onSelect:PropTypes.func.isRequired,
    company:PropTypes.object,
    spotLocation:PropTypes.object
}
