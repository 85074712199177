import React from 'react';
import userImage from 'assets/img/users/user_avatar.png';
import {UserCard} from 'components/Card';

import {Col, FormGroup, Input, Label, Row} from 'reactstrap';
import Page from '../components/Page';
import Card from 'react-bootstrap/Card';
import CardBody from 'reactstrap/es/CardBody';
import {AuthConsumer} from '../utils/AuthContext';
import {imageSelector, isEmpty, parseDate} from "na-utilities/src/utils/Utilities";
import {getAdmin} from '../utils/StorageUtil';
import Button from 'reactstrap/es/Button';
import {IoMdKey} from 'react-icons/io';
import CardFooter from 'reactstrap/es/CardFooter';
import ChangePasswordDialog from '../components/modal/ChangePasswordDialog';
import ImageCropper from '../components/modal/ImageCropper';
import axios from 'axios';
import ValidatorLabel from '../components/Widget/ValidatorLabel';
import BasePage from './BasePage';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";


class ProfilePage extends BasePage{

    constructor(props) {
        super(props);
        let admin = getAdmin()
        let state = this.state
        state.admin = admin
        state.firstnameError = null
        state.lastnameError = null
        state.image = admin.image&&admin.image!=''?admin.imageLink:null
        state.changePasswordModal = false
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let admin = getAdmin()
        let state = this.state
        state.admin = admin
        state.firstnameError = null
        state.lastnameError = null
        state.image = admin.image&&admin.image!=''?admin.imageLink:null
        state.changePasswordModal = false
        this.setState(state)
    }

    updateData=(login)=>{

        let state = this.state
        state.firstnameError = null
        state.lastnameError = null
        let admin = state.admin

        if(isEmpty(admin.firstname)){
            let firstnameError = "Nama depan harus diisi"
            this.setState({
                firstnameError : firstnameError
            })
            return
        }
        let formData = new FormData()
        formData.append("adminDtoGson", JSON.stringify(this.state.admin))

        this.post(Endpoint.API.USER_SAVE_UPLOAD, formData, null, response=>{
            if(response.code===200){
                this.successToast(response.message)
                if(login){
                    login(response.data)
                    this.setState({
                        admin:response.data
                    })
                }
            }
        }, true, true)

    }

    uploadPhotoProfile=(image, login)=>{
        let formData = new FormData()
        axios({
            url: image,
            method: 'GET',
            responseType: 'blob', // important
        }).then((response) => {
            var file = response.data ;
            formData.append("multipartFile", file)
            this.post(Endpoint.API.ADMIN_UPLOAD_IMAGE, formData, null, response=>{
                if(response.code===200){
                    this.successToast(response.message)
                    if(login){
                        login(response.data)
                        this.setState({
                            admin:response.data
                        })
                    }
                }
            }, true, true)
        })

    }

    render() {
        let image = this.state.image
        return (
            <AuthConsumer>
                {({admin, logout, login, refresh})=>(
                    <Page
                        title="ProfilePage"
                        breadcrumbs={[{ name: 'profile', active: true }]}>
                        {super.render()}
                        <ChangePasswordDialog
                            modal={this.state.changePasswordModal}
                            okCallback={(oldPassword, newPassword)=>{
                                this.setState({
                                    changePasswordModal:false
                                }, () => {
                                    if(refresh){
                                        refresh()
                                    }
                                })
                            }}
                            cancelCallback={()=>{
                                this.setState({
                                    changePasswordModal:false
                                })
                            }}
                        />
                        <Row>
                            <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <UserCard
                                                avatar={image?image:userImage}
                                                onError={(elm)=>this.defaultImage(elm, userImage)}
                                                avatarSize={150}
                                                title={admin.firstname+" "+admin.lastname}
                                                subtitle={admin.roleInformation}
                                                text={label.Created+" "+parseDate(admin.created,'dddd, dd-mmmm-yyyy')}
                                                style={{
                                                    height: 300,
                                                }}
                                                onClickAvatar={event=>{
                                                    imageSelector(file=>{
                                                        this.setState({
                                                            image:file,
                                                            cropperModal:true
                                                        })
                                                    }).click()
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="username">{label.Username}</Label>
                                                    <Input
                                                        type="text"
                                                        name="username"
                                                        value={(this.state.admin!=null && this.state.admin.username)?this.state.admin.username:""}
                                                        readOnly={true}
                                                        placeholder={label.EnterUsername}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="email">{label.Email}</Label>
                                                    <Input
                                                        type="text"
                                                        name="email"
                                                        value={(this.state.admin!=null && this.state.admin.email)?this.state.admin.email:""}
                                                        onChange={(e) =>{
                                                            let admin = this.state.admin
                                                            if(admin!=null){
                                                                admin.email = e.target.value
                                                                this.setState({
                                                                    admin:admin
                                                                })
                                                            }
                                                        }}
                                                        readOnly={true}
                                                        placeholder={label.EnterEmail}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="role">Role</Label>
                                                    <Input
                                                        type="text"
                                                        name="role"
                                                        disabled={true}
                                                        value={(this.state.admin!=null && this.state.admin.adminRole)?this.state.admin.adminRole.name:""}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="firstname">{label.Firstname}</Label>
                                                    <Input
                                                        type="text"
                                                        name="firstname"
                                                        value={(this.state.admin!=null && this.state.admin.firstname)?this.state.admin.firstname:""}
                                                        onChange={(e) =>{
                                                            let admin = this.state.admin
                                                            if(admin!=null){
                                                                admin.firstname = e.target.value
                                                                this.setState({
                                                                    admin:admin
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterFirstname}
                                                    />
                                                    <ValidatorLabel message={this.state.firstnameError}/>
                                                </FormGroup>
                                            </Col>

                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="lastname">{label.Lastname}</Label>
                                                    <Input
                                                        type="text"
                                                        name="lastname"
                                                        value={(this.state.admin!=null && this.state.admin.lastname)?this.state.admin.lastname:""}
                                                        onChange={(e) =>{
                                                            let admin = this.state.admin
                                                            if(admin!=null){
                                                                admin.lastname = e.target.value
                                                                this.setState({
                                                                    admin:admin
                                                                })
                                                            }
                                                        }}
                                                        placeholder={label.EnterLastname}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="changePassword">{label.ChangePassword}</Label>
                                                    <br/>
                                                    <Button onClick={event=>{
                                                        event.preventDefault()
                                                        this.setState({
                                                            changePasswordModal:true
                                                        })
                                                    }}><IoMdKey/></Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            <Col>
                                                <Button onClick={event=>{
                                                    event.preventDefault()
                                                    this.props.history.goBack()
                                                }}>
                                                    Back
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button className="float-right" color="primary" onClick={event=>{
                                                    event.preventDefault()
                                                    this.updateData(login)
                                                }}>
                                                    Update
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                        <ImageCropper src={this.state.image} show={this.state.cropperModal} callback={file=>{
                            this.setState({
                                cropperModal:false,
                            }, () => {
                                this.uploadPhotoProfile(file, login)
                            })
                        }}/>
                    </Page>

                )}
            </AuthConsumer>
        );
    }
}
export default Object.assign(ProfilePage, {ENDPOINT : "/profile"})
