import React from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ValidatorLabel from '../Widget/ValidatorLabel';
import PropTypes from 'prop-types';
import BaseComponent from '../BaseComponent';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
import {firstLetterUppercase, isEmpty, isResponseOk, isValidEmail} from "na-utilities/src/utils/Utilities";

/*= (message, okCallback, cancelCallback) =>*/

export default class NewRegistrationModal extends BaseComponent {

    constructor(props){
        super(props);
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()

        this.state = {
            show:false,
            companyName:"",
            firstName:"",
            lastName:"",
            email:"",
            validation,
            validationMessage
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.companyName = true ;
        validation.firstName = true ;
        validation.lastName = true
        validation.email = true ;
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.companyName = "" ;
        validationMessage.firstName = "" ;
        validationMessage.lastName = "" ;
        validationMessage.email = "" ;
        return validationMessage ;
    }
    componentDidMount() {
        super.componentDidMount();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            this.setState({show},() => {
                if(show && !prevState.show){
                    let validation = this.defaultValidation()
                    let validationMessage = this.defaultValidationMessage()
                    this.setState({
                        show:show,
                        companyName:"",
                        firstName:"",
                        lastName:"",
                        email:"",
                        validation,
                        validationMessage
                    })
                }
            })
        }
    }


    validateFormSubmit() {
        // await super.validateFormSubmit();
        let {show, companyName, firstName, lastName, email, validation, validationMessage} = this.state
        let valid = true;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if (isEmpty(companyName)) {
            validation.companyName = false
            validationMessage.companyName = label.PleaseEnterCompanyNameCorrectly
            valid = false;
        }
        if (isEmpty(firstName) || firstName.length <= 2) {
            validation.firstName = false
            validationMessage.firstName = label.PleaseEnterFirstName
            valid = false;
        }
        if (isEmpty(email) || email.length <= 2) {
            validation.email = false
            validationMessage.email = label.PleaseEnterEmail
            valid = false;
        }

        if (!isValidEmail(email)) {
            validation.email = false
            validationMessage.email = label.PleaseEnterCorrectEmail
            valid = false;
        }
        this.setState({validation, validationMessage}, () => {
            if(valid){
                this.save()
            }
        })
    }

    save(){
        let {show, companyName, firstName, lastName, email, validation, validationMessage} = this.state
        let {onCreateRegistration, onClose} = this.props
        // let formData = new FormData()
        // formData.append("companyName", companyName)
        // formData.append("firstName", firstName)
        // formData.append("lastName", lastName)
        // formData.append("email", email)
        let registerForm = {}
        registerForm.companyName = companyName
        registerForm.firstName = firstName
        registerForm.lastName = lastName
        registerForm.email = email
        this.post(Endpoint.API.NEW_REGISTRATION_REGISTER, registerForm, null, (res)=>{
            if(isResponseOk(res)){
                let registration = res.data
                this.successToast(label.SucceedCreatingNewRegistration)
                if(onCreateRegistration){
                    onCreateRegistration(registration)
                }
            }else{
                this.errorToast(res.message)
                if(onClose){
                    onClose()
                }
            }
        }, true, false)
    }

    render(){
        let {companyName, firstName, lastName, email, show, validation, validationMessage} = this.state
        let {onClose} = this.props
        return (
            <Modal
                isOpen={show}
                centered={true}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.NewRegistration}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="companyName">{label.Company}</Label>
                                <Input
                                    type="text"
                                    name="companyName"
                                    value={companyName}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        value = firstLetterUppercase(value)
                                        this.setState({
                                            companyName:value
                                        })
                                    }}
                                    placeholder={label.EnterCompanyName}
                                />
                                <ValidatorLabel message={validationMessage.companyName}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="firstName">{label.Firstname}</Label>
                                <Input
                                    type="text"
                                    name="firstName"
                                    value={firstName}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        value = firstLetterUppercase(value)
                                        this.setState({
                                            firstName:value
                                        })
                                    }}
                                    placeholder={label.EnterFirstname}
                                />
                                <ValidatorLabel message={validationMessage.firstName}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="lastName">{label.Lastname}</Label>
                                <Input
                                    type="text"
                                    name="lastName"
                                    value={lastName}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        value = firstLetterUppercase(value)
                                        this.setState({
                                            lastName:value
                                        })
                                    }}
                                    placeholder={label.EnterLastname}
                                />
                                <ValidatorLabel message={validationMessage.lastName}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="email">{label.Email}</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={async (e) =>{
                                        email = e.target.value
                                        validation.email = true
                                        validationMessage.email = ""
                                        this.setState({email, validation, validationMessage})
                                    }}
                                    placeholder={label.EnterEmail}
                                />
                                <ValidatorLabel message={validationMessage.email}/>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning" onClick={e=>{
                        e.preventDefault()
                        this.setState({
                            modal:false
                        }, () => {
                            if(this.props.onClose!=null){
                                this.props.onClose()
                            }
                        })
                    }}>
                        {label.Cancel}
                    </Button>
                    &nbsp;
                    <Button
                        color="secondary" onClick={e=>{
                        e.preventDefault()
                        this.validateFormSubmit()
                    }}>
                        {label.Save}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

NewRegistrationModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onCreateRegistration:PropTypes.func.isRequired
}
