import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    ButtonGroup
} from 'reactstrap';
import {isResponseOk, parseDate} from 'na-utilities/src/utils/Utilities';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../../utils/Global';
import label from 'na-utilities/src/label/Label'
import PropTypes from "prop-types";
import BaseComponent from "../BaseComponent";
import Checkbox from "../Checkbox";
import {MdRefresh} from "react-icons/all";
import Endpoint from "na-utilities/src/api/Endpoint";


export default class SmartDeviceConfigurationModal extends BaseComponent{

    constructor(props) {
        super(props);
        let smartDevice = this.props.smartDevice?this.props.smartDevice:{}
        let smartDeviceConfigurations = smartDevice.smartDeviceConfigurations?smartDevice.smartDeviceConfigurations:[]
        this.state ={
            smartDevice:smartDevice,
            smartDeviceConfigurations:smartDeviceConfigurations
        }
    }

    componentWillReceiveProps(props, nextContext) {
        if(props.showing){
            let smartDevice = props.smartDevice?props.smartDevice:{}
            let smartDeviceConfigurations = smartDevice.smartDeviceConfigurations?smartDevice.smartDeviceConfigurations:[]

            this.setState({
                smartDevice:smartDevice, smartDeviceConfigurations:smartDeviceConfigurations
            }, async () => {
                await this.fetchSmartDeviceConfiguration()
            })
        }
    }

    async fetchSmartDeviceConfiguration(){
        let {smartDevice} = this.state
        let params = {smartDeviceId:smartDevice.id}
        let response = await this.asyncGet(Endpoint.API.SMART_DEVICE_CONFIGURATION, {params},null, true, true);
        if(isResponseOk(response)){
            let smartDeviceConfigurations = response.data
            this.setState({smartDeviceConfigurations})
        }

    }

    async save(){
        let {smartDevice, smartDeviceConfigurations} = this.state
        // let params = {smartDeviceId:smartDevice.id, smartDeviceConfigurationsGson: JSON.stringify(smartDeviceConfigurations)}
        let form = new FormData()
        form.append("smartDeviceId", smartDevice.id)
        smartDeviceConfigurations.map((item, index)=>{
            item.smartDevice = undefined;
            return item;
        })
        form.append("smartDeviceConfigurationsGson", JSON.stringify(smartDeviceConfigurations))

        let response = await this.asyncPost(Endpoint.API.SMART_DEVICE_CONFIGURATION_SAVE, form,null, true, true);
        if(isResponseOk(response)){
            let smartDevice = response.data
            this.successToast(response.message)
            this.setState({smartDevice}, () => {
                this.fetchSmartDeviceConfiguration()
            })
        }
    }
    async resetAll(){
        let {smartDevice} = this.state
        // let params = {smartDeviceId:smartDevice.id}
        let form = new FormData()
        form.append("smartDeviceId", smartDevice.id)
        let response = await this.asyncPost(Endpoint.API.SMART_DEVICE_CONFIGURATION_RESET_ALL, form,null, true, true);
        if(isResponseOk(response)){
            let smartDevice = response.data
            this.successToast(response.message)
            this.setState({smartDevice}, () => {
                this.fetchSmartDeviceConfiguration()
            })
        }
    }
     fetchCurrent(){
        let {smartDevice, smartDeviceConfigurations} = this.state
        let params = {smartDeviceId:smartDevice.id}
        this.get(Endpoint.API.DEVICE_SMART_DEVICE_CONFIGURATION, {params},null, (response)=>{
            if(isResponseOk(response)){
                let smartDeviceConfigurations = response.data
                // console.log(JSON.stringify(smartDeviceConfigurations))
                smartDeviceConfigurations.forEach((item, index)=>{
                    smartDeviceConfigurations.forEach((deviceItem, deviceIndex)=>{
                        if(item.key===deviceItem.key){
                            smartDeviceConfigurations[index].current = deviceItem.value
                        }
                    })
                })
                this.setState({smartDeviceConfigurations})
            }
        },true, true);


    }




    render() {
        let {smartDevice, smartDeviceConfigurations} = this.state

        return (
            <>
                {super.render()}
                <Modal
                    autoFocus={false}
                    style={{minWidth:'90%'}}
                    size="xl"
                    centered={true}
                    isOpen={this.props.showing}
                    backdrop={true}>
                    {super.render()}
                    <ModalHeader>
                        {label.SmartDeviceConfiguration}
                    </ModalHeader>
                    <ModalBody>
                        {this.renderSmartDeviceConfigurationesTable()}
                    </ModalBody>
                    <ModalFooter>
                        <ButtonGroup>
                            <Button
                                color="secondary"
                                onClick={e=>{
                                    e.preventDefault()
                                    this.props.onClose()
                                }}>
                                {label.Close}
                            </Button>
                            &nbsp;
                            <Button
                                color="danger"
                                onClick={e=>{
                                    e.preventDefault()
                                    this.resetAll()
                                }}>
                                {label.ResetAll}
                            </Button>
                            &nbsp;
                            <Button
                                color="warning"
                                onClick={e=>{
                                    e.preventDefault()
                                    this.save()
                                }}>
                                {label.Save}
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>

            </>
        )
    }



    renderSmartDeviceConfigurationesTable = () =>{
        let {smartDeviceConfigurations} = this.state
        return (
            <Table responsive hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>{label.Key}</th>
                    <th>{label.ReadOnly}</th>
                    <th style={{width:'25%'}}>{label.Value}</th>
                    <th style={{width:'25%'}}>{label.Original}</th>
                    <th style={{width:'25%'}}><Button onClick={()=>{
                        this.fetchCurrent()
                    }}>{label.SmartDevice}&nbsp;<MdRefresh/></Button></th>
                    {/*<th>{label.Updated}</th>*/}
                </tr>
                </thead>
                <tbody>
                {
                    smartDeviceConfigurations.map((item, index)=>(
                        <tr key={item.id}>
                            <th scope="row">{(++index)}</th>
                            <td style={{overflowWrap:'anywhere'}}>{item.key}</td>
                            <td><Checkbox checked={item.readonly} disabled/></td>
                            <td style={{overflowWrap:'break-word'}}>
                                <Input
                                    type="textarea"
                                    name="code"
                                    lines={3}
                                    value={item.value}
                                    onChange={(e) =>{
                                        e.preventDefault()
                                        smartDeviceConfigurations[index-1].value = e.target.value
                                        this.setState({
                                            smartDeviceConfigurations:smartDeviceConfigurations
                                        })
                                    }}
                                    readOnly={item.readonly}
                                />
                            </td>
                            <td style={{overflowWrap:'anywhere'}}>{item.original}</td>
                            <td style={{overflowWrap:'anywhere'}}>{item.current}</td>
                            {/*<td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>*/}
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        )
    }


}

SmartDeviceConfigurationModal.propTypes = {
    showing:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    smartDevice:PropTypes.object.isRequired
}
