import React from 'react';
import BaseComponent from '../../BaseComponent';
import {
    _EXPIRED_ID,
    _PAID,
    _REFUND_ID,
    _WAITING_FOR_PAYMENT_ID,
    _CANCELED_ID,
    _CREATED_ID,
    _ON_PREPARING_EQUIPMENT_ID
} from '../../../utils/Global';
import {Button, Card, CardBody, CardHeader} from 'reactstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// import {Tabs, Tab} from '@material-ui/core';
import DashboardTransactionTable from './DashboardTransactionTable';
import CircularProgress from '@material-ui/core/CircularProgress';
import {MdRefresh} from 'react-icons/md';
import label from 'na-utilities/src/label/Label'

let createdTransactionRef = React.createRef()
let paidTransactionRef = React.createRef()
let waitingForPaymentTransactionRef = React.createRef()
let expiredTransactionRef = React.createRef()
let onChargingTransactionRef = React.createRef()
let canceledTransactionRef = React.createRef()

export default class DashboardTransactionCard extends BaseComponent{

    constructor(props) {
        super(props);
        let state = this.state
        state.productOrderStatusId = _CREATED_ID
        state.fecthing = false
        this.setState(state)
    }

    updateTransactions=()=>{
        let productOrderStatusId = this.state.productOrderStatusId
        if(parseInt(productOrderStatusId)===_WAITING_FOR_PAYMENT_ID && this.waitingForPaymentTransactionRef){
            this.waitingForPaymentTransactionRef.fetchTransaction(true)
        }
        if(parseInt(productOrderStatusId)===_PAID && this.paidTransactionRef){
            this.paidTransactionRef.fetchTransaction(true)
        }
        if(parseInt(productOrderStatusId)===_ON_PREPARING_EQUIPMENT_ID && this.onChargingTransactionRef){
            this.onChargingTransactionRef.fetchTransaction(true)
        }
    }

    badgeTabLabel=(label, size, color)=>{
        if(!size || size==0){
            return (<span>{label}</span>)
        }
        return (
            <>
                <span>{label}</span>
                &nbsp;&nbsp;
                <span
                    style={{
                        paddingTop:'2px',
                        paddingBottom:'2px',
                        paddingLeft:'6px',
                        paddingRight:'6px',
                        background:color?color:'red',
                        color:'white',
                        borderRadius:'20%',
                        minWidth:'24px',
                        display:'inline-block',
                        textAlign:'center'}}>
                    {size}
                </span>
            </>)
    }


    loading=(fecthing)=>{
        this.setState({
            fecthing : fecthing
        })
    }


    render() {
        let productOrderStatusId = this.state.productOrderStatusId
        let fetching = this.state.fetching
        return (
            <Card>
                <CardHeader>
                    {label.OnGoingTransaction}
                    {
                        (fetching)?(
                            <div className="float-right" >
                                <CircularProgress color="secondary" size={24}/>
                            </div>
                        ):(
                            <div className="float-right" >
                                <Button className="float-right" onClick={event=>{
                                    this.updateTransactions()
                                }}><MdRefresh/></Button>
                            </div>
                        )
                    }
                </CardHeader>
                <CardBody>
                    <Tabs
                        activeKey={productOrderStatusId}
                        onSelect={key =>{
                            this.setState({
                                productOrderStatusId:key
                            })
                        }}>
                        <Tab eventKey={_WAITING_FOR_PAYMENT_ID}
                             title={this.badgeTabLabel(label.WaitingForPayment, this.waitingForPaymentTransactionRef?this.waitingForPaymentTransactionRef.totalElement():0, '#FF9803')}>
                            <DashboardTransactionTable
                                ref={(ref)=>this.waitingForPaymentTransactionRef=ref}
                                {...this.props}
                                show={parseInt(productOrderStatusId)==_WAITING_FOR_PAYMENT_ID}
                                productOrderStatusId={_WAITING_FOR_PAYMENT_ID} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>
                        <Tab eventKey={_PAID}
                             title={this.badgeTabLabel(label.Paid, this.paidTransactionRef?this.paidTransactionRef.totalElement():0, '#FF9803')}>
                            <DashboardTransactionTable
                                ref={(ref)=>this.paidTransactionRef=ref}
                                {...this.props}
                                show={parseInt(productOrderStatusId)==_PAID}
                                productOrderStatusId={_PAID} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>

                        <Tab eventKey={_ON_PREPARING_EQUIPMENT_ID}
                             title={this.badgeTabLabel(label.OnCharging, this.onChargingTransactionRef?this.onChargingTransactionRef.totalElement():0, '#108989')}>
                            <DashboardTransactionTable
                                ref={(ref)=>this.onChargingTransactionRef=ref}
                                {...this.props} show={parseInt(productOrderStatusId)==_ON_PREPARING_EQUIPMENT_ID} productOrderStatusId={_ON_PREPARING_EQUIPMENT_ID} fetching={(fetching)=>{
                                this.setState({fetching:fetching})
                            }}/>
                        </Tab>
                    </Tabs>
                </CardBody>
            </Card>
        );
    }

}
