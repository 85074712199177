export default {
    THIRD_PARTIES :{
        SUPPORT_TICKET_APPLICATION:"https://support-ticket.numeralasia.com"
    },
    API :{
        AUTH : "/sign/auth",
        LOGIN : "/sign/authAdmin",
        LOGOUT : "/admin/logout",
        PRODUCT_CATEGORIES : "/productCategories",
        PRODUCT_CATEGORY : "/productCategory",
        PRODUCT_CATEGORY_SAVE : "/productCategory/save",
        PRODUCT_CATEGORY_DELETE : "/productCategory/delete",

        LOCKER_TYPES : "/lockerTypes",
        RFID_READER_TYPES : "/rfidReaderTypes",

        SMART_DEVICE_TYPES : "/smartDeviceTypes",
        SMART_DEVICE_TYPE : "/smartDeviceType",
        SMART_DEVICE_TYPE_SAVE : "/smartDeviceType/save",
        SMART_DEVICE_TYPE_DELETE : "/smartDeviceType/delete",

        MANUFACTURING_SITES : "/manufacturingSites",
        MANUFACTURING_SITE : "/manufacturingSite",
        MANUFACTURING_SITE_SAVE : "/manufacturingSite/save",
        MANUFACTURING_SITE_DELETE : "/manufacturingSite/delete",

        AGENT_FEES : "/agentFees",
        AGENT_FEE : "/agentFee",
        AGENT_FEE_SAVE : "/agentFee/save",
        AGENT_FEE_DELETE : "/agentFee/delete",

        PRODUCT_AGENTS : "/productAgents",

        DISCUSSION_CATEGORIES : "/discussionCategories",
        DISCUSSION_CATEGORY : "/discussionCategory",
        DISCUSSION_CATEGORY_SAVE: "/discussionCategory/save",
        DISCUSSION_CATEGORY_DELETE : "/discussionCategory/delete",

        DISCUSSIONS : "/discussions",
        DISCUSSION : "/discussion",
        DISCUSSION_SAVE_UPLOAD: "/discussion/saveUpload",
        DISCUSSION_DELETE : "/discussion/delete",

        COMMENTS : "/comments",
        COMMENT : "/comment",
        COMMENT_SAVE : "/comment/save",
        COMMENT_DELETE : "/comment/delete",

        COMMENT_REPLIES : "/commentReplies",
        COMMENT_REPLY : "/commentReply",
        COMMENT_REPLY_SAVE : "/commentReply/save",
        COMMENT_REPLY_DELETE : "/commentReply/delete",

        MULTI_FINANCES : "/multiFinances",
        MULTI_FINANCE : "/multiFinance",
        MULTI_FINANCE_SAVE : "/multiFinance/save",
        MULTI_FINANCE_DELETE : "/multiFinance/delete",

        INSTALLMENT_PERIODS : "/installmentPeriods",
        INSTALLMENT_PERIOD : "/installmentPeriod",
        INSTALLMENT_PERIOD_SAVE : "/installmentPeriod/save",
        INSTALLMENT_PERIOD_DELETE : "/installmentPeriod/delete",

        CABINETS : "/cabinets",
        CABINET : "/cabinet",
        CABINET_SAVE : "/cabinet/save",
        CABINET_DELETE : "/cabinet/delete",
        CLONE_CABINET : "/cabinet/clone",
        CREATE_NEW_CABINET : "/cabinet/create",

        REGISTRATIONS : "/registrations",
        REGISTRATION_CHECK : "/registration/check",
        REGISTRATION_CONFIRMATION_SUBMIT : "/registration/confirmation/submit",
        NEW_REGISTRATION_REGISTER : "/registration/register",

        USER_DEVICES : "/userDevices",
        USER_DEVICE : "/userDevice",
        USER_DEVICE_SAVE : "/userDevice/save",
        USER_DEVICE_SAVE_UPLOAD : "/userDevice/saveUpload",
        USER_DEVICE_DELETE : "/userDevice/delete",

        USER_BY_REGISTRATION : "/user/byRegistration",

        ADMIN_DEVICES : "/userDevices",
        ADMIN_DEVICE : "/userDevices",
        ADMIN_DEVICE_SAVE : "/userDevice/save",
        ADMIN_DEVICE_SAVE_UPLOAD : "/userDevice/saveUpload",
        ADMIN_DEVICE_DELETE : "/userDevice/delete",

        HEADINGS : "/headings",
        HEADING : "/heading",
        HEADING_SAVE : "/heading/save",
        HEADING_DELETE : "/heading/delete",

        CONFIGURATIONS : "/configurations",
        CONFIGURATION : "/configuration",
        CONFIGURATION_SAVE : "/configuration/save",
        CONFIGURATION_DELETE : "/configuration/delete",
        CONFIGURATION_ACTIVATE : "/configuration/activate",

        APPLICATION_VERSIONS : "/applicationVersions",
        APPLICATION_VERSION : "/applicationVersion",
        APPLICATION_VERSION_SAVE : "/applicationVersion/save",
        APPLICATION_VERSION_DELETE : "/applicationVersion/delete",
        APPLICATION_VERSION_SAVE_UPLOAD : "/applicationVersion/saveUpload",

        MISCELLANEOUS_FILES : "/miscellaneousFiles",

        FAQS : "/faqs",
        FAQ : "/faq",
        FAQ_DELETE : "/faq/delete",
        FAQ_SAVE : "/faq/save",
        FAQ_SAVE_UPLOAD : "/faq/saveUpload",
        FAQ_UPLOAD_IMAGE : "/faq/uploadImage",

        PRODUCTS_DROPDOWN : "/products/dropdown",
        PRODUCTS : "/products",
        PRODUCT : "/product",
        PRODUCT_DELETE : "/product/delete",
        PRODUCT_SAVE : "/product/save",
        PRODUCT_SAVE_UPLOAD : "/product/saveUpload",
        PRODUCT_UPLOAD_IMAGE : "/product/uploadImage",
        PRODUCT_LATEST : "/product/latests",
        PRODUCT_UPDATE_LATEST : "/product/updateLatest",
        PRODUCT_DELETE_LATEST : "/product/deleteLatest",
        PRODUCT_VALIDATE_SLUG : "/model/validate",
        PRODUCTS_PUBLISHED : "/products/published",

        PRODUCT_PACKAGES : "/productPackages",
        PRODUCT_PACKAGE : "/productPackage",
        PRODUCT_PACKAGE_DELETE : "/productPackage/delete",
        PRODUCT_PACKAGE_SAVE : "/productPackage/save",
        PRODUCT_PACKAGE_SAVE_UPLOAD : "/productPackage/saveUpload",



        PRODUCT_POPULARS : "/product/populars",
        PRODUCT_UPDATE_POPULAR : "/product/updatePopular",
        PRODUCT_DELETE_POPULAR : "/product/deletePopular",


        ALL_INVENTORIES : "/inventory/allInventories",
        INVENTORIES : "/inventories",
        INVENTORY : "/inventory",
        INVENTORY_DELETE : "/inventory/delete",
        INVENTORY_SAVE : "/inventory/save",
        INVENTORY_UPLOAD_IMAGE : "/inventory/uploadImage",
        INVENTORY_DELETE_IMAGE : "/inventory/deleteImage",
        INVENTORY_SAVE_UPLOAD : "/inventory/saveUpload",
        INVENTORY_UPDATE_STOCK : "/inventory/updateStock",

        BANNERS:"/banners",
        BANNER:"/banner",
        BANNER_SAVE_UPLOAD:"/banner/saveUpload",
        BANNER_DELETE:"/banner/delete",

        MAIN_PRODUCTS:"/mainProducts",
        MAIN_PRODUCT:"/mainProduct",
        MAIN_PRODUCT_SAVE_UPLOAD:"/mainProduct/saveUpload",
        MAIN_PRODUCT_DELETE:"/mainProduct/delete",


        // CONFIGURATION_ADMIN : "/configurationAdmin",
        CONFIGURATION_ADMIN_SAVE : "/configurationAdmin/save",
        ADMINS : "/admins",
        ADMIN : "/user",
        ADMIN_UPLOAD_IMAGE : "/user/uploadImage",
        ADMIN_VALIDATE_EMAIL : "/user/validateEmail",
        USER_CHANGE_PASSWORD : "/user/changePassword",
        ADMIN_FIRSTNAMES : "/user/firstnames",
        ADMIN_ACTIVITIES : "/userActivities",
        ADMIN_ACTIVITY_TYPES : "/userActivity/types",
        ADMIN_RESEND_PASSWORD : "/user/resendPassword",

        ADMIN_UPDATE_STATUS : "/user/updateStatus",
        ADMIN_GENERATE_ADMIN_CODE : "/user/generateAdminCode",
        USER_FORGET_PASSWORD : "/user/forgetPassword",
        USER_RESET_PASSWORD_VALIDATE : "/user/resetPassword/validate",
        USER_RESET_PASSWORD_BY_TOKEN : "/user/resetPassword/byToken",
        USER_RESET_PASSWORD_FINISHING : "/user/resetPassword/finishing",
        USER_FETCH_RESET_PASSWORD: "/user/fetchResetPassword",
        USER_RESET_PASSWORD : "/user/resetPassword",


        ROLES : "/roles",
        ROLE : "/role",
        ROLE_SAVE : "/role/save",
        ROLE_DELETE : "/role/delete",
        ROLE_UPDATE_MENU : "/role/updateMenu",

        USER_ROLES : "/userRoles",
        USER_ROLES_BY_USER_CODE : "/userRoles/byUserCode",
        USER_ROLE : "/userRole",
        USER_ROLE_SAVE : "/userRole/save",
        USER_ROLE_DELETE : "/userRole/delete",
        USER_ROLE_UPDATE_USER_MENU : "/userRole/updateMenu",
        USER_ROLE_UPDATE : "/userRole/update",

        GROUP_MENUS : "/groupMenus",
        GROUP_MENU : "/groupMenu",
        GROUP_MENU_SAVE : "/groupMenu/save",
        GROUP_MENU_DELETE : "/groupMenu/delete",

        MENUS : "/menus",
        MENU : "/menu",
        MENU_SAVE : "/menu/save",
        MENU_DELETE : "/menu/delete",
        MENUS_TREE : "/menus/tree",

        USER_MENUS : "/userMenus",
        USER_MENU : "/userMenu",
        USER_MENU_SAVE : "/userMenu/save",
        USER_MENU_DELETE : "/userMenu/delete",
        USER_MENUS_TREE : "/userMenus/tree",

        STOCK_AUDITS : "/stockAudits",

        BANKS : "/banks",
        BANK : "/bank",
        BANK_DELETE : "/bank/delete",
        BANK_SAVE : "/bank/save",
        BANK_SAVE_UPLOAD : "/bank/saveUpload",
        BANK_UPLOAD_IMAGE : "/bank/uploadImage",

        BRANDS : "/brands",
        BRAND : "/brand",
        BRAND_DELETE : "/brand/delete",
        BRAND_SAVE : "/brand/save",
        BRAND_SAVE_UPLOAD : "/brand/saveUpload",
        BRAND_UPLOAD_IMAGE : "/brand/uploadImage",

        ONLINE_SHOPS : "/onlineShops",
        ONLINE_SHOP : "/onlineShop",
        ONLINE_SHOP_DELETE : "/onlineShop/delete",
        ONLINE_SHOP_SAVE : "/onlineShop/save",
        ONLINE_SHOP_SAVE_UPLOAD : "/onlineShop/saveUpload",
        ONLINE_SHOP_UPLOAD_IMAGE : "/onlineShop/uploadImage",


        COURIERS : "/couriers",
        COURIER : "/courier",
        COURIER_DELETE : "/courier/delete",
        COURIER_SAVE : "/courier/save",
        COURIER_SAVE_UPLOAD : "/courier/saveUpload",
        COURIER_UPLOAD_IMAGE : "/courier/uploadImage",
        COURIER_WAYBILL : "/courier/waybill",


        BANK_ACCOUNTS : "/bankAccounts",
        BANK_ACCOUNT : "/bankAccount",
        BANK_ACCOUNT_DELETE : "/bankAccount/delete",
        BANK_ACCOUNT_SAVE : "/bankAccount/save",

        MIDTRANS_MEDIATORS : "/midtransMediators",
        MIDTRANS_MEDIATOR : "/midtransMediator",
        MIDTRANS_MEDIATOR_DELETE : "/midtransMediator/delete",
        MIDTRANS_MEDIATOR_SAVE : "/midtransMediator/save",
        MIDTRANS_MEDIATOR_SAVE_UPLOAD : "/midtransMediator/saveUpload",
        MIDTRANS_MEDIATOR_UPLOAD_IMAGE : "/midtransMediator/uploadImage",


        ADMIN_STATUSES : "/adminStatuses",
        CUSTOMERS : "/customers",
        CUSTOMERS_NO_FILTER : "/customers/nofilter",
        CUSTOMER : "/customer",
        CUSTOMER_DELETE : "/customer/delete",
        CUSTOMER_SAVE : "/customer/save",
        CUSTOMER_SAVE_UPLOAD : "/customer/saveUpload",
        CUSTOMER_UPLOAD_IMAGE : "/customer/uploadImage",
        CUSTOMER_STATUSES : "/customerStatuses",
        CUSTOMER_FIRSTNAMES : "/customer/firstnames",
        CUSTOMER_EMAILS : "/customer/emails",

        USERS : "/users",
        USERS_NO_FILTER : "/users/nofilter",
        USER : "/user",
        USER_BY_USER_CODE : "/user/byUserCode",
        USER_GENERATE_AGENT_CODE : "/user/generateUserCode",
        USER_GENERATE_USERNAME : "/user/generateUsername",
        USER_VALIDATE_USERNAME : "/user/validateUsername",
        USER_VALIDATE_MOBILE_PHONE : "/user/validateUserMobilePhone",
        USER_VALIDATE_PHONE_NUMBER : "/user/validateUserPhoneNumber",
        USER_DELETE : "/user/delete",
        USER_SAVE : "/user/save",
        USER_SAVE_UPLOAD : "/user/saveUpload",
        USER_UPLOAD_IMAGE : "/user/uploadImage",
        USER_VALIDATE_EMAIL : "/user/validateEmail",
        USER_STATUSES : "/userStatuses",
        USER_FIRSTNAMES : "/user/firstnames",
        USER_EMAILS : "/user/emails",
        USER_AUTHORIZATION : "/user/authorization",
        USER_CREATE_NEW_USER : "/user/createNewUser",

        USER_UPDATE_STATUS : "/user/updateStatus",

        USER_RESEND_PASSWORD : "/user/resendPassword",


        GENDERS:"/genders",

        USER_ACTIVITIES : "/userActivities",
        USER_LAST_5_ACTIVITIES : "/last5UserActivities",
        USER_ACTIVITY_TYPES : "/userActivity/types",

        INDUSTRIES:"/industries",
        COMPANY_TYPES:"/companyTypes",

        PROVINCES:"/provinces",
        PROVINCE : "/category",
        PROVINCE_SAVE : "/category/save",
        PROVINCE_DELETE : "/category/delete",

        PAYMENT_MEDIAS:"/paymentMedias",
        PAYMENT_MEDIA : "/paymentMedia",
        PAYMENT_MEDIA_ATTRIBUTE : "/paymentMedia/paymentAttribute",


        CITIES:"/cities",
        CITY : "/city",
        CITY_SAVE : "/city/save",
        CITY_DELETE : "/city/delete",

        DISTRICTS:"/districts",
        DISTRICT : "/district",
        DISTRICT_SAVE : "/district/save",
        DISTRICT_DELETE : "/district/delete",

        VILLAGES:"/villages",
        VILLAGE : "/village",
        VILLAGE_SAVE : "/village/save",
        VILLAGE_DELETE : "/village/delete",

        PRODUCT_ORDER:"/productOrder",
        PRODUCT_ORDER_STATUSES:"/productOrderStatuses",
        PRODUCT_ORDERS:"/productOrders",
        USER_PRODUCT_ORDERS_ON_GOING:"/user/productOrders/onGoing",
        PRODUCT_ORDERS_ONGOING:"/productOrders/ongoing",
        PRODUCT_ORDERS_IN_SEARCH:"/productOrdersInSearch",
        PRODUCT_ORDER_UPDATE_STATUS:"/productOrder/changeStatus",
        PRODUCT_ORDER_REQUEST_TO_PROCESS:"/productOrder/requestToProcess",

        PRODUCT_SUBSCRIBES:"/productSubscribes",
        PRODUCT_SUBSCRIBE:"/productSubscribe",
        PRODUCT_SUBSCRIBE_HAS_EXPIRED_OR_ALMOST_EXPIRED:"/productSubscribe/hasExpiredOrAlmostExpired",

        PRODUCT_SUBSCRIBE_STATUSES:"/productSubscribeStatuses",


        PRODUCT_ORDER_STATUS_HISTORIES:"/productOrderStatusHistories",

        SMART_DEVICE_STATUSES:"/smartDeviceStatuses",
        SMART_DEVICE_UPDATE_STATUS:"/smartDevice/updateStatus",
        SMART_DEVICE_UPDATE_SPOT_LOCATION:"/smartDevice/updateSpotLocation",
        SMART_DEVICE_UPDATE_INFORMATION:"/smartDevice/updateInformation",

        PRODUCT_ORDER_EVENT:"/productOrderEvent",
        PRODUCT_ORDER_EVENTS:"/productOrderEvents",
        CURRENT_AND_PREVIOUS_PRODUCT_ORDER_EVENTS:"/productOrderEvents/currentAndPrevious",


        PRODUCT_ORDER_SUSPEND:"/productOrder/suspend",
        PRODUCT_ORDER_REERROR:"/productOrder/open",


        CHARGING_ORDER_REQUEST:"/withdrawalRequest",
        CHARGING_ORDER_STATUSES:"/withdrawalStatuses",
        CHARGING_ORDER_REQUESTS:"/withdrawalRequests",
        CHARGING_ORDER_REQUESTS_ONGOING:"/withdrawalRequest/ongoing",
        CHARGING_ORDER_REQUESTS_IN_SEARCH:"/withdrawalRequestsInSearch",
        CHARGING_ORDER_REQUEST_UPDATE_STATUS:"/withdrawalRequest/changeStatus",

        BALANCE_MUTATIONS : "/balanceMutations",
        INJECT_BALANCE : "/injectBalance",


        FLASHSALES : "/flashSales",
        FLASHSALE : "/flashSale",
        FLASHSALE_DELETE : "/flashSale/delete",
        FLASHSALE_SAVE : "/flashSale/save",
        FLASHSALE_CONTENT_SAVE : "/flashSaleContent/save",
        FLASHSALE_ACTIVATE : "/flashSale/activate",

        PRODUCT_BY_FLASHSALE : "/flashSale/products",
        PRODUCT_IN_FLASHSALE_CHECK : "productInFlashSaleCheck",
        FLASHSALE_CONTENT_BY_FLASHSALE:"flashSaleContentByFlashSale",
        FLASHSALE_PRODUCT_DELETE : "/flashSaleProduct/delete",

        SELEBGRAMS:"/selebgrams",
        SELEBGRAM:"/selebgramr",
        SELEBGRAM_SAVE_UPLOAD:"/selebgram/saveUpload",
        SELEBGRAM_DELETE:"/selebgram/delete",

        COLORS : "/colors",
        COLOR : "/color",
        COLOR_SAVE : "/color/save",
        COLOR_DELETE : "/color/delete",

        SIZES : "/sizes",
        SIZE : "/size",
        SIZE_SAVE : "/size/save",
        SIZE_DELETE : "/size/delete",


        DISCOUNTS : "/discounts",
        DISCOUNT : "/discount",
        DISCOUNT_DELETE : "/discount/delete",
        DISCOUNT_SAVE : "/discount/save",
        DISCOUNT_INVENTORY : "/discounts/byProduct",

        VOUCHERS : "/vouchers",
        VOUCHER : "/voucher",
        VOUCHER_DELETE : "/voucher/delete",
        VOUCHER_SAVE : "/voucher/save",
        VOUCHER_ACTIVATE : "/voucher/activate",
        VOUCHER_GENERATE_CODE : "/voucher/generateCode",

        POINTS : "/points",
        POINT : "/point",
        POINT_DELETE : "/point/delete",
        POINT_SAVE : "/point/save",
        POINT_ACTIVATE : "/point/activate",

        VOUCHER_ITEMS : "/voucherItems",
        VOUCHER_ITEM : "/voucherItem",
        VOUCHER_ITEM_UPDATE : "/voucherItem/update",
        VOUCHER_ITEM_UPDATE_MIN_QUANTITY : "/voucherItem/updateMinimumQuantity",
        VOUCHER_ITEM_DELETE : "/voucherItem/delete",
        VOUCHER_ITEM_SAVE : "/voucherItem/save",

        VOUCHER_CUSTOMERS : "/voucherCustomers",
        VOUCHER_CUSTOMER_DELETE : "/voucherCustomer/delete",
        VOUCHER_CUSTOMER_UPDATE : "/voucherCustomer/update",


        POINT_ITEMS : "/pointItems",
        POINT_ITEM : "/pointItem",
        POINT_ITEM_UPDATE : "/pointItem/update",
        POINT_ITEM_UPDATE_MIN_QUANTITY : "/pointItem/updateMinimumQuantity",
        POINT_ITEM_DELETE : "/pointItem/delete",
        POINT_ITEM_SAVE : "/pointItem/save",

        POINT_PRODUCTS : "/point/products",

        VOUCHER_ITEM_INVENTORIES : "/voucherItemInventories",
        VOUCHER_ITEM_INVENTORY : "/voucherItemInventory",
        VOUCHER_ITEM_INVENTORY_VALIDATE_EMPTY : "/voucherItemInventory/validateEmpty",
        VOUCHER_ITEM_INVENTORY_UPDATE : "/voucherItemInventory/update",
        VOUCHER_ITEM_INVENTORY_DELETE : "/voucherItemInventory/delete",
        VOUCHER_ITEM_INVENTORY_SAVE : "/voucherItemInventory/save",

        POINT_ITEM_INVENTORIES : "/pointItemInventories",
        POINT_ITEM_INVENTORY : "/pointItemInventory",
        POINT_ITEM_INVENTORY_VALIDATE_EMPTY : "/pointItemInventory/validateEmpty",
        POINT_ITEM_INVENTORY_UPDATE : "/pointItemInventory/update",
        POINT_ITEM_INVENTORY_DELETE : "/pointItemInventory/delete",
        POINT_ITEM_INVENTORY_SAVE : "/pointItemInventory/save",

        PRODUCT_MERGE_VOUCHER_ITEMS : "/voucherItem/productMergeByVoucherItems",

        POINT_UNREGISTERED_PRODUCT : "/point/unregisteredProducts",
        POINT_UNREGISTERED_INVENTORIES : "/point/unregisteredInventories",

        POINT_REGISTER_INVENTORY : "/point/registerInventory",
        POINT_UNREGISTER_INVENTORY : "/point/unregisterInventory",

        PRODUCT_WITH_EXIST_DISCOUNT : '/discount/allProduct',

        SELEBGRAM_PRODUCTS:"/selebgram/products",
        SELEBGRAM_ENDORSE:"/selebgram/endorse",
        SELEBGRAM_ENDORSEMENT_DELETE:"/selebgram/endorsement/delete",

        CONFIGURATION_CURRENT_ACTIVE:"/configuration/currentActive",
        CONFIGURATION_ADMIN:"/configurationAdmin",
        CONFIGURATION_ADMIN_TEST_SEND_MAIL:"/configurationEmail/test-send-mail",

        ADMIN_DASHBOARD:'/admin/dashboard',
        DASHBOARD_SPOT_LOCATION_ANALYTIC:'/admin/dashboard/spotLocationAnalytic',
        DASHBOARD_PRODUCT_ORDER_ITEM_ANALYTIC:'/admin/dashboard/productOrderItemAnalytic',
        DASHBOARD_PRODUCT_ORDER_ANALYTIC:'/admin/dashboard/productOrderAnalytic',

        SEND_EMAILS:'/sendEmails',
        SEND_EMAIL:'/sendEmail/send',
        SUBSCRIBERS:'/subscribers',
        SUBSCRIBER_EMAILS:'/subscriber/emails',
        SUBSCRIBER_DELETE:'/subscriber/delete',

        NOTIFICATIONS:'/notifications',
        NOTIFICATION:'/notification',
        NOTIFICATION_SEND:'/notification/send',
        NOTIFICATION_USERS:'/notificationUser/byNotification',


        LISTEN_PRODUCT_ORDER : "/listen/productOrder",
        LISTEN_UPDATE_PRODUCT : "/listen/updateProduct",
        LISTEN_UPDATE_PRODUCT_ORDERS : "/listen/updateInspections",
        LISTEN_UPDATE_PRODUCT_ORDER_EVENTS : "/listen/updateInspectionEvents",
        EMAIL_UPLOAD_IMAGE : "/email/image/uploadImage",
        CART_CUSTOMERS:"/customerInventoryCart/customers",
        CART_BY_CUSTOMER:"/customerInventoryCart/byCustomer",
        CART_REMOVE_ALL:"/customerInventoryCart/removeAll",
        CART_REMOVE_ALL_BY_CUSTOMER:"/customerInventoryCart/removeAllByCustomer",
        CART_REMOVE_SINGLE_ITEM:"/customerInventoryCart/deleteSingleItem",
        LISTEN_UPDATE_SMART_DEVICE_STATE : "/listen/updateSmartDevice",
        LISTEN_UPDATE_CONTAINER_STATE : "/listen/updateContainer",
        LISTEN_UPDATE_CHARGING_INFORMATION: "/listen/updateChargingInformation",

        PRODUCT_ORDER_PDF_REPORT : '/pdf/productOrder/report/admin',

        COMPANIES:"/companies",
        COMPANY:"/company",
        COMPANY_SAVE_UPLOAD:"/company/saveUpload",
        COMPANY_DELETE:"/company/delete",
        COMPANY_VALIDATE_COMPANY_NAME:"/company/validateCompanyName",
        COMPANY_VALIDATE_WEBSITE:"/company/validateWebsite",
        COMPANY_VALIDATE_COMPANY_PHONE_NUMBER:"/company/validateCompanyPhoneNumber",

        SPOT_LOCATIONS:"/spotLocations",
        SPOT_LOCATION:"/spotLocation",
        SPOT_LOCATION_SAVE_UPLOAD:"/spotLocation/saveUpload",
        SPOT_LOCATION_DELETE:"/spotLocation/delete",

        SMART_DEVICES:"/smartDevices",
        SMART_DEVICES_LATEST_5_HAVING_ACTIVITY:"/latest5SmartDevicesHavingActivity",
        SMART_DEVICE:"/smartDevice",
        SMART_DEVICE_SAVE_UPLOAD:"/smartDevice/saveUpload",
        SMART_DEVICE_DELETE:"/smartDevice/delete",
        SMART_DEVICE_CONFIGURATION:"/smartDevice/smartDeviceConfiguration",
        DEVICE_SMART_DEVICE_CONFIGURATION:"/smartDevice/deviceSmartDeviceConfiguration",
        SMART_DEVICE_CONFIGURATION_SAVE:"/smartDevice/smartDeviceConfiguration/save",
        SMART_DEVICE_CONFIGURATION_RESET_ALL:"/smartDevice/smartDeviceConfiguration/resetAll",

        CONTAINERS : "/containers",
        CONTAINER : "/container",
        CONTAINER_DELETE : "/container/delete",
        CONTAINER_SAVE : "/container/save",
        CONTAINER_UPDATE_ENABLED : "/container/updateEnabled",
        CONTAINER_GENERATE_CODE : "/container/generateCode",

        CONTAINER_TYPES : "/containerTypes",
        CONTAINER_TYPE:"/containerType",
        CONTAINER_TYPE_SAVE:"/containerType/save",
        CONTAINER_TYPE_DELETE:"/containerTypes/delete",

        SUB_PAYMENT_MEDIAS : "/subPaymentMedias",
        SUB_PAYMENT_MEDIA : "/subPaymentMedia",
        SUB_PAYMENT_MEDIA_SAVE : "/subPaymentMedia/saveUpload",
        SUB_PAYMENT_MEDIA_DELETE : "/subPaymentMedia/delete",
        WEEKLY_OPENS:"/spotLocation/weeklyOpens",
        SPOT_LOCATION_GENERATE_CODE:"/spotLocation/generateCode",
        SPOT_LOCATION_VALIDATE_CODE:"/spotLocation/validateCode",

        COMPANY_GENERATE_CODE:"/company/generateCode",
        COMPANY_VALIDATE_CODE:"/company/validateCode",

        SMART_DEVICE_GENERATE_CODE:"/smartDevice/generateCode",
        SMART_DEVICE_VALIDATE_CODE:"/smartDevice/validateCode",
        USER_ROLE_MENUS:"/userRole/menus",
        USER_OPEN_PAGE : "/user/openPage",


        PRODUCT_ORDER_CREATE : "/productOrder/createOrder",
        PRODUCT_ORDER_RESEND_EMAIL : "/productOrder/resendEmail",
        PRODUCT_ORDER_CREATE_WITH_COMPANY : "/productOrder/createOrderWithCompany",

        ORDER_ITEMS:"/orderItems",
        PAYMENT_PROOFS:"/paymentProofs",
        PAYMENT_PROOF_DELETE:"/paymentProof/delete",
        PAYMENT_PROOF_SAVE_UPLOAD:"/paymentProof/saveUpload",

        GOODS_CATEGORIES : "/goodsCategories",
        GOODS_CATEGORY : "/goodsCategory",
        GOODS_CATEGORY_SAVE : "/goodsCategory/save",
        GOODS_CATEGORY_DELETE : "/goodsCategory/delete",

        GOODS_INFOS : "/goodsInfos",
        GOODS_INFO : "/goodsInfo",
        GOODS_INFO_SAVE_UPLOAD : "/goodsInfo/saveUpload",

        GOODS_ITEM_REGISTER_COLLECTION : "/goodsItem/registerCollection",
        GOODS_ITEMS : "/goodsItems",
        GOODS_ITEM : "/goodsItem",
        GOODS_ITEMS_COUNT : "/goodsItems/count",

        GOODS_ITEM_STATUSES : "/goodsItemStatuses",
        GOODS_ITEM_STATUS_HISTORIES : "/goodsItemStatusHistories",
        GOODS_ITEM_LOCATION_COUNT_GOODS_INFO : "/goodsItemLocation/countGoodsInfo",
        GOODS_ITEM_LOCATION_COUNT_GOODS_ITEM : "/goodsItemLocation/countGoodsItem",

        USER_ADMIN_DASHBOARD_SUMMARY:"/userAdminDashboardSummary",

        SMART_DEVICE_ACTIVITIES : "/smartDeviceActivities",

        BORROWING_ITEM_STATUSES : "/borrowingOfItemStatuses",
        BORROWING_ITEMS : "/borrowingOfItems",
        BORROWING_ITEM_UPDATE_STATUS: "/borrowingOfItem/updateStatus",

        LISTEN_COMPANY_DEVICE_REFRESH : (companyCode)=> "/listen/company/"+companyCode+"/refresh",

        BUSINESS_CONTACT:"/businessContact",




    }
}