export const PROFILE = "https://smart-supplies.numeralasia.com"
export const COPYRIGHT = "https://smart-supplies-admin.numeralasia.com"
export const BASE_URL = 'https://smart-supplies-api.numeralasia.com/api'
export const USER_URL = 'https://smart-supplies-user.numeralasia.com'
export const UCVDWEYGZH2K4M5N7Q8R9TBUCV = 'ucvdweygzh2k4m5n7q8r9tbucv'
export const _tXl52uLrobXlLYjHBE8OQ0Gf4dPEvZbW = '_tXl52uLrobXlLYjHBE8OQ0Gf4dPEvZbW'
export const DEBUG = true
window.console.log = function() {
    return true;
}
window.console.debug = function() {
    return true;
}
window.console.info = function() {
    return true;
}
window.console.error = function() {
    return true;
}