import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import shoppingBag from 'assets/img/products/shopping-bag.png';
import CardBlock from 'reactstrap/es/CardBlock';
import BasePage from './BasePage';
import {CATEGORY_IMAGE_MAX_FILE_SIZE, CURRENCY_SYMBOL, DD_MM_YYYY_HH_MM_SS, MIME_PNG_JPEG} from '../utils/Global';
import {
    allIsEmpty,
    cloneObject,
    currencyFormat,
    imageSelector,
    isEmpty,
    parseDate,
    sortirMap
} from "na-utilities/src/utils/Utilities";
import {MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import WarningLabel from '../components/Widget/WarningLabel';
import axios from 'axios';
import ImageCropper from '../components/modal/ImageCropper';
import label from 'na-utilities/src/label/Label'
import MyCardImage from "../components/Card/MyCardImage";
import Image from "../components/Widget/Image";
import NumberInput from "../components/Widget/NumberInput";
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class SubPaymentMediaPage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      subPaymentMedias : [],
      subPaymentMedia:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      image : undefined,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  confirmSave = () =>{
    var subPaymentMedia = this.state.subPaymentMedia
    var image = this.state.imageBlob
    if(subPaymentMedia.id){
      if(allIsEmpty(subPaymentMedia.name)){
        this.showDialog(label.Warning, label.PleaseCompleteTheFields)
      }else{
        this.setState({
          modalAddConfirm:true
        })
      }
    }else{
      if(allIsEmpty(image, subPaymentMedia.name)){
        this.showDialog(label.Warning, label.PleaseCompleteTheFields)
      }else{
        this.setState({
          modalAddConfirm:true
        })
      }
    }
  }


  save = () => {
    let subPaymentMedia = this.state.subPaymentMedia
    let imageBlob = this.state.imageBlob
    var formData = new FormData();

    if(!subPaymentMedia.id && !subPaymentMedia.active){
      subPaymentMedia.active = false ;
    }

    formData.append("subPaymentMediaJson", JSON.stringify(subPaymentMedia))
    formData.append("multipartFile", imageBlob);
    this.setState({
      modalAddConfirm:false
    }, () => {
      this.post(Endpoint.API.SUB_PAYMENT_MEDIA_SAVE, formData, null, res=>{
        if(res.code===200){
          this.setState({
            subPaymentMedia:{},
            image:undefined
          }, () => {
            this.showDialog(label.Success, res.message)
            this.fetchAll()
          })
        }
      },  true, true)
    })
  }


  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }

  delete = () => {
    this.setState({
      modalDeleteConfirm:false
    }, () => {
      this.get(Endpoint.API.SUB_PAYMENT_MEDIA_DELETE+"/"+this.state.subPaymentMedia.id, null, null, res => {
        if(res.code===200){
          this.setState({
            subPaymentMedia:{}
          }, () => {
            this.fetchAll()
          })
        }
      },true, true);
    })
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Endpoint.API.SUB_PAYMENT_MEDIAS, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          subPaymentMedias : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let aspect = 4/4
    let {image} = this.state

    return (
        <Page
            title={label.SubPaymentMedia}
            breadcrumbs={[{ name: label.SubPaymentMedia, active: true }]}
            className="TablePage">
          {super.render()}
          <ConfirmDialog
              showing={this.state.modalAddConfirm}
              title={label.UpdateConfirmation}
              message={label.UpdateConfirmation}
              okCallback={this.save}
              cancelCallback={this.closeDialog}/>
          <ConfirmDialog
              showing={this.state.modalDeleteConfirm}
              title={label.DeleteConfirmationTitle}
              message={label.DeleteConfirmationMessage}
              okCallback={this.delete}
              cancelCallback={this.closeDialog}/>
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.SubPaymentMediaDetail}</CardHeader>
                <CardBody >
                  <Row>
                    <Col md={3}>
                      <Card body>
                        <Row>
                          <Col>
                            <Card>
                              <MyCardImage
                                  top
                                  src={image?image:shoppingBag}
                                  onError={(elm)=>this.defaultImage(elm, shoppingBag)}
                                  onClick={e=>{
                                    if(image){
                                      this.openLightBoxSingleImage(image)
                                    }
                                  }}/>
                              <CardBody>
                                <CardTitle>{label.CategoryImage}</CardTitle>
                                <Row>
                                  <Col>
                                    <WarningLabel message={"*Use Jpeg Format / Max "+CATEGORY_IMAGE_MAX_FILE_SIZE+" Kb"}/>
                                  </Col>
                                </Row>
                                <br/>
                                {(this.state.subPaymentMedia && this.state.subPaymentMedia.id)&&(
                                    <Row>
                                      <Col>
                                        <ButtonGroup className="float-left">
                                          <Button color="danger" onClick={e => (
                                              this.setState({
                                                product:undefined,
                                                image:undefined
                                              })
                                          )}>{label.Cancel}</Button>
                                        </ButtonGroup>
                                      </Col>
                                      <Col>
                                        <ButtonGroup className="float-right">
                                          <Button color="primary" onClick={e=>{
                                            imageSelector(file=>{
                                              this.setState({
                                                image:file,
                                                cropperModal:true
                                              })
                                            }, MIME_PNG_JPEG, CATEGORY_IMAGE_MAX_FILE_SIZE).click()
                                          }}>Upload</Button>
                                        </ButtonGroup>
                                      </Col>
                                    </Row>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <CardBlock>
                            <FormGroup>
                              <Label for="name">{label.Name}</Label>
                              <Input
                                  type="text"
                                  name="name"
                                  value={!isEmpty(this.state.subPaymentMedia.name)?this.state.subPaymentMedia.name:""}
                                  onChange={(e) =>{
                                    let subPaymentMedia = this.state.subPaymentMedia
                                    if(subPaymentMedia){
                                      subPaymentMedia.name = e.target.value
                                      this.setState({
                                        subPaymentMedia:subPaymentMedia
                                      })
                                    }
                                  }}
                                 placeholder={label.EnterProductPackageName}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="paymentFeePrice">{label.AmountFee}</Label>
                              <NumberInput
                                  name="paymentFeePrice"
                                  className="form-control"
                                  value={(this.state.subPaymentMedia)?this.state.subPaymentMedia.paymentFeePrice:""}
                                  onChangeEvent={(e, maskedvalue, floatvalue) => {
                                    e.preventDefault()
                                    let subPaymentMedia = this.state.subPaymentMedia
                                    if(subPaymentMedia) {
                                      subPaymentMedia.paymentFeePrice = floatvalue
                                      this.setState({
                                        subPaymentMedia: subPaymentMedia,
                                      })
                                    }
                                  }}
                                  placeholder="0"
                                  maxLength={9}>
                              </NumberInput>
                            </FormGroup>
                            <FormGroup>
                              <Label for="paymentFeePercent">{label.PercentFee}</Label>
                              <NumberInput
                                  name="paymentFeePercent"
                                  className="form-control"
                                  value={(this.state.subPaymentMedia)?this.state.subPaymentMedia.paymentFeePercent:""}
                                  onChangeEvent={(e, maskedvalue, floatvalue) => {
                                    e.preventDefault()
                                    let subPaymentMedia = this.state.subPaymentMedia
                                    if(subPaymentMedia) {
                                      subPaymentMedia.paymentFeePercent = floatvalue
                                      this.setState({
                                        subPaymentMedia: subPaymentMedia,
                                      })
                                    }
                                  }}
                                  placeholder="0"
                                  maxLength={2}>
                              </NumberInput>
                            </FormGroup>
                            <FormGroup>
                              <ActiveOption
                                default={this.state.subPaymentMedia.active}
                                callback={(active)=>{
                                  let ctg = this.state.subPaymentMedia
                                  if(ctg!=null){
                                    ctg.active = active
                                    this.setState({
                                      subPaymentMedia:ctg
                                    })
                                  }

                                }}
                            />
                            </FormGroup>
                            <FormGroup>
                              <Label for="successCallbackUrl">{label.SuccessCallbackUrl}</Label>
                              <Input
                                  type="text"
                                  name="successCallbackUrl"
                                  value={!isEmpty(this.state.subPaymentMedia.successCallbackUrl)?this.state.subPaymentMedia.successCallbackUrl:""}
                                  onChange={(e) =>{
                                    let subPaymentMedia = this.state.subPaymentMedia
                                    if(subPaymentMedia){
                                      let successCallbackUrl = e.target.value
                                      successCallbackUrl = successCallbackUrl.replace(/\t/g, '')
                                      successCallbackUrl = successCallbackUrl.replace(' ', '')
                                      subPaymentMedia.successCallbackUrl = successCallbackUrl
                                      this.setState({
                                        subPaymentMedia:subPaymentMedia
                                      })
                                    }
                                  }}
                                  placeholder={label.EnterSuccessCallbackUrl}
                              />
                            </FormGroup>
                            <FormGroup>
                              <Label for="failedCallbackUrl">{label.FailedCallbackUrl}</Label>
                              <Input
                                  type="text"
                                  name="failedCallbackUrl"
                                  value={!isEmpty(this.state.subPaymentMedia.failedCallbackUrl)?this.state.subPaymentMedia.failedCallbackUrl:""}
                                  onChange={(e) =>{
                                    let subPaymentMedia = this.state.subPaymentMedia
                                    if(subPaymentMedia){
                                      let failedCallbackUrl = e.target.value
                                      failedCallbackUrl = failedCallbackUrl.replace(/\t/g, '')
                                      failedCallbackUrl = failedCallbackUrl.replace(' ', '')
                                      subPaymentMedia.failedCallbackUrl = failedCallbackUrl
                                      this.setState({
                                        subPaymentMedia:subPaymentMedia
                                      })
                                    }
                                  }}
                                  placeholder={label.EnterFailedCallbackUrl}
                              />
                            </FormGroup>
                          </CardBlock>
                        </Row>
                        {(this.state.subPaymentMedia && this.state.subPaymentMedia.id)&&(
                            <Row>
                              <Col>
                                <ButtonGroup className="float-left">
                                  <Button color="danger" onClick={e => (
                                      this.setState({
                                        subPaymentMedia:{},
                                        image:undefined
                                      })
                                  )}>{label.Cancel}</Button>
                                </ButtonGroup>
                              </Col>
                              <Col>
                                <ButtonGroup className="float-right">
                                  <Button color="primary" onClick={e=>(
                                      this.confirmSave()
                                  )}> {
                                    (this.state.subPaymentMedia!=null && this.state.subPaymentMedia.id!=null)?label.Update:label.Add
                                  } </Button>

                                </ButtonGroup>
                              </Col>
                            </Row>
                        )}
                      </Card>
                    </Col>
                    <Col md={9}>
                      <Card body>
                        <Row>
                          <Col>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  sortirMap(this.state.sortir)
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                          <Col>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                              <DropdownToggle
                                  caret
                                  color="white"
                                  className="text-capitalize m-1">
                                {
                                  this.state.ascending?label.Ascending:label.Descending
                                }
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                                <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Table responsive hover>
                              <thead>
                              <tr>
                                <th>#</th>
                                <th>{label.Name}</th>
                                <th>{label.AmountFee}</th>
                                <th>{label.PercentFee}</th>
                                <th style={{width:'10%'}}>{label.Image}</th>
                                <th>{label.SuccessCallbackUrl}</th>
                                <th>{label.FailedCallbackUrl}</th>
                                <th>{label.Status}</th>
                                <th>{label.Updated}</th>
                                <th>{label.Edit}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                                this.state.subPaymentMedias.map((item, index) =>(
                                    <tr key={index}>
                                      <th scope="row">{++index}</th>
                                      <td>{item.name}</td>
                                      <td>{currencyFormat(item.paymentFeePrice, CURRENCY_SYMBOL)}</td>
                                      <td>{item.paymentFeePercent+" %"}</td>
                                      <td>
                                        {
                                          item.image&&(
                                              <Image src={item.imageLink} style={{width:"50%"}}/>
                                          )
                                        }
                                      </td>
                                      <td>{item.successCallbackUrl}</td>
                                      <td>{item.failedCallbackUrl}</td>
                                      <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.active}/></td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                      <td>
                                        <Button color="primary" onClick={e=> {
                                          let subPaymentMedia = cloneObject(item)
                                          image = subPaymentMedia.image?subPaymentMedia.imageLink:undefined
                                          this.setState({
                                            subPaymentMedia: subPaymentMedia,
                                            image: image
                                          })
                                        }}>
                                          <MdEdit/>
                                        </Button>
                                      </td>
                                    </tr>
                                ))
                              }
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ImageCropper src={this.state.image} aspect={aspect} show={this.state.cropperModal} callback={file=>{
            axios({
              url: file,
              method: 'GET',
              responseType: 'blob', // important
            }).then((response) => {
              var fileBlob = response.data ;
              this.setState({
                image:file,
                cropperModal:false,
                imageBlob:fileBlob,
              })
            })
          }} cancelCallback={()=>{
            this.setState({
              cropperModal:false,
              image:this.state.subPaymentMedia.imageLink,
              imageBlob:null,
            })
          }}/>

        </Page>
    );
  }
}

export default Object.assign(SubPaymentMediaPage, {ENDPOINT : "/subPaymentMedias"})
