import React from 'react';
import BasePage from './BasePage';
import Page from '../components/Page';
import Global from '../utils/Global';
import queryString from 'query-string';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import label from 'na-utilities/src/label/Label';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table,} from 'reactstrap';
import {allIsEmpty, currencyFormat, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import MyCardImage from "../components/Card/MyCardImage";
import userProfilePicture from "../assets/img/users/user_pp.png";
import {GiGears, IoMdEye, MdSend, FaBuilding} from "react-icons/all";
import SockJsClient from "react-stomp";
import UserDetailPage from "./UserDetailPage";
import CompanyDetailPage from "./CompanyDetailPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import SmartDeviceDetailPage from "./SmartDeviceDetailPage";


class ProductSubscribeDetailPage extends BasePage{
    successToast(message) {
        super.successToast(message);
    }

    constructor(props) {
        super(props);
        let state = this.state
        state.orderItems = []
        state.paymentProofs = []
        state.productSubscribe = {}
        state.productSubscribeState = {}
        state.productSubscribeStatusHistories = []
        state.productSubscribeIdQuery = queryString.parse(this.props.query).id
        state.productSubscribeCategory = {}
        state.productSubscribeStatusModal = false
        state.ascending = true
        state.sortir = 'created'
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchProductSubscribeDetail(this.state.productSubscribeIdQuery)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                // this.fetchAllInventories(this.state.productSubscribe.id, this.state.ascending, this.state.sortir, true)
            })
        }
    }

    fetchProductSubscribeDetail = (id) => {
        if(id!=null){
            this.get(Endpoint.API.PRODUCT_SUBSCRIBE, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let productSubscribe = response.data
                    let productSubscribeState = productSubscribe.productSubscribeState?productSubscribe.productSubscribeState:{}
                    this.setState({
                        productSubscribe,productSubscribeState
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }


    resendEmail = () =>{
        let {orderItems, productSubscribe} = this.state
        let form = new FormData()
        form.append("reference", productSubscribe.reference)
        this.post(Endpoint.API.PRODUCT_SUBSCRIBE_RESEND_EMAIL, form, null, res =>{
            if(res.code === 200){
                productSubscribe = res.data
                this.setState({productSubscribe})
                this.successToast(res.message)
            }
        }, true, true);
    }




    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }



    render() {
        let {productSubscribe, productSubscribeStatusHistories, productSubscribeState, orderItems, paymentProofs,productSubscribeStatusModal} = this.state
        // let container = productSubscribe.container?productSubscribe.container:{}
        let smartDevice = productSubscribe.smartDevice?productSubscribe.smartDevice:{}
        let spotLocation = smartDevice.spotLocation?smartDevice.spotLocation:{}
        let company = productSubscribe.company?productSubscribe.company:{}
        let creator = productSubscribe.user?productSubscribe.user:{}
        let productPackage = productSubscribe.productPackage?productSubscribe.productPackage:{}
        let product = productPackage.product?productPackage.product:{}
        let companyType = company.companyType?company.companyType:{}
        let industry = company.industry?company.industry:{}
        let subPaymentMedia = productSubscribe.subPaymentMedia?productSubscribe.subPaymentMedia:{}
        let paymentMedia = productSubscribe.paymentMedia?productSubscribe.paymentMedia:{}
        let productSubscribeStatus = productSubscribe.productSubscribeStatus?productSubscribe.productSubscribeStatus:{}
        return (
            <Page
                title={label.ProductSubscribe}
                breadcrumbs={[{ name: label.ProductSubscribe, active: true }]}>
                {super.render()}
                <Card>
                    <CardHeader>{label.ProductSubscribe}</CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}>
                                <Card>
                                    <CardHeader>{label.Customer}</CardHeader>
                                    <CardBody>
                                        <MyCardImage top src={
                                            creator.photoUrl!=null?creator.photoUrl:userProfilePicture}
                                                     onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                    </CardBody>
                                    <CardBody>
                                        <Row>
                                            <Col md={10}>
                                                <FormGroup>
                                                    <Label for="fullname">{label.Fullname}</Label>
                                                    <Input name={"fullname"} value={creator.fullname} disabled/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="fullname">&nbsp;&nbsp;</Label>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            navigate(this.props, UserDetailPage.ENDPOINT+"?id="+creator.id)
                                                        }}>
                                                        <IoMdEye/>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="email">{label.Email}</Label>
                                                    <Input name={"email"} value={creator.email} disabled/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="mobilePhone">{label.MobilePhone}</Label>
                                                    <Input name={"mobilePhone"} value={creator.mobilePhone} disabled/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={10}>
                                                <FormGroup>
                                                    <Label for="company">{label.Company}</Label>
                                                    <Input name={"company"} value={company.fullName} disabled/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="fullname">&nbsp;&nbsp;</Label>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            navigate(this.props, CompanyDetailPage.ENDPOINT+"?id="+company.id)
                                                        }}>
                                                        <FaBuilding/>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={3}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col>
                                                        {label.DeviceInformation}
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            size="sm"
                                                            className="float-right"
                                                            onClick={() => {
                                                                navigate(this.props, SmartDeviceDetailPage.ENDPOINT+"?id="+smartDevice.id)
                                                            }}
                                                        >
                                                            <IoMdEye/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Label}</Label>
                                                            <Input disabled value={smartDevice.label}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.IPAddress}</Label>
                                                            <Input disabled value={smartDevice.ipAddress}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.DeviceId}</Label>
                                                            <Input disabled value={smartDevice.deviceId}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.UUID}</Label>
                                                            <Input disabled value={smartDevice.uuid}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.ShortCode}</Label>
                                                            <Input disabled value={smartDevice.shortCode}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Location}</Label>
                                                            <Input disabled value={spotLocation.address+" "+spotLocation.regional}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                {label.ValidityInformation}
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.ProductPackage}</Label>
                                                            <Input disabled value={product.name}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Created}</Label>
                                                            <Input disabled value={parseDate(productSubscribe.created)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Company}</Label>
                                                            <Input disabled value={company.fullName}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.ValidFrom}</Label>
                                                            <Input disabled value={parseDate(productSubscribe.startDate)}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.ValidUntil}</Label>s
                                                            <Input disabled value={parseDate(productSubscribe.endDate)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                {label.ProductInformation}
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Product}</Label>
                                                            <Input disabled value={productPackage.name}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Description}</Label>
                                                            <Input disabled value={productPackage.description}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.MonthPeriod}</Label>
                                                            <Input disabled value={productPackage.monthPeriod+" "+label.Month}/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Price}</Label>s
                                                            <Input disabled value={currencyFormat(productPackage.price)}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Endpoint.API.LISTEN_UPDATE_CHARGING_INFORMATION+"/"+productSubscribe.id]}
                    autoReconnect={true}
                    debug={Global.DEBUG}
                    heartbeat={5000}
                    onMessage={(chargingInformation) => {
                        // console.log(chargingInformation)
                        if(chargingInformation.productSubscribeStateDto){
                            let productSubscribeState = chargingInformation.productSubscribeStateDto
                            this.setState({productSubscribeState}, () => {
                                if(productSubscribeState.finish){
                                    this.fetchProductSubscribeDetail(productSubscribe.id)
                                }
                            })
                        }
                    }}
                    onConnectFailure={(object)=>{
                        console.error(object)
                    }}
                    ref={ (client) => { this.clientRef = client }} />

            </Page>
        );
    }
}
export default Object.assign(ProductSubscribeDetailPage, {ENDPOINT : "/productSubscribeDetail"})