import * as React from 'react';
import Page from './../components/Page';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Row, Table} from 'reactstrap';
import BasePage from './BasePage';
import Board from '@lourenci/react-kanban';
import CabinetRowModal from '../components/modal/CabinetRowModal';
import CabinetContainerModal from '../components/modal/CabinetContainerModal';
import ContainerCard from '../components/Card/ContainerCard';
import { Cabinet } from '../model/formbuilder/Cabinet';
import Input from 'reactstrap/es/Input';
import WarningLabel from '../components/Widget/WarningLabel';
import {DD_MM_YYYY_HH_MM_SS, DD_MMMM_YYYY_HH_MM_SS} from '../utils/Global';
import * as queryString from 'query-string';
import { cloneObject, parseDate, isEmpty } from 'na-utilities/src/utils/Utilities';
import { CabinetRow } from "../model/formbuilder/CabinetRow";
import { MdAdd, MdDelete, MdEdit, MdSave } from 'react-icons/md';
import { FaCopy } from 'react-icons/fa';
import HorizontalScroll from 'react-horizontal-scrolling'
import label from "na-utilities/src/label/Label";
import Endpoint from "na-utilities/src/api/Endpoint";
import ActiveLabel from "../components/Widget/ActiveLabel";

const { htmlToText } = require('html-to-text');

const CircularJSON = require('circular-json')

class FormBoard {
    lanes = []
}

const tableTypes = ['', 'bordered', 'striped', 'hover'];
class CabinetDetailPage extends BasePage {
    board = new FormBoard()

    validator = {
        title: ""
    }

    constructor(props) {
        super(props);
        let cabinet = new Cabinet();
        this.state = {
            categories: [],
            category: {},
            headings: [],
            heading: {},
            modalAddConfirm: false,
            modalDeleteConfirm: false,
            cabinetRack: new CabinetRow(),
            formLaneModal: false,
            formModal: false,
            ascending: true,
            sortir: 'created',
            board: this.board,
            cabinet: cabinet,
            cabinetId: queryString.parse(this.props.query).id,
            validator: this.validator,
            formEdit: {},
        }
    }

    componentDidMount() {
        super.componentDidMount();
        if (this.state.cabinetId) {
            this.fetchDetail()
        }
    }

    fetchDetail() {
        let board = this.state.board
        this.get(Endpoint.API.CABINET + '/' + this.state.cabinetId, null, null, (res) => {
            if (res.code === 200) {
                let cabinet = res.data
                board.lanes = cabinet.cabinetRacks
                if(board.lanes){
                    board.lanes.forEach((item, index) => {
                        item.cards.forEach((value, index1) => {
                            value.lane = cloneObject(item)
                            value.lane.cards = []
                        })
                    })
                }
                // let cabinetLockerTypes = cabinet.cabinetLockerTypes?cabinet.cabinetLockerTypes:[]
                // let lockerTypes = []
                // cabinetLockerTypes.forEach((item, index)=>{
                //     lockerTypes.push(item.lockerType)
                // })
                // // alert(JSON.stringify(lockerTypes))
                // board.lockerTypes = lockerTypes
                this.setState({
                    cabinet: cabinet,
                    board: board
                })
            }
        }, true, false)
    }

    reorderFormCard = (board) => {
        board.lanes.forEach((lane, laneIndex) => {
            lane.cards.forEach((card, cardIndex) => {
                card.tag = (laneIndex + 1) + "." + (cardIndex + 1)
            })
        })
        this.setState({
            board: board
        })
    }



    onClickSave() {
        let cabinet = this.state.cabinet
        let newData = !cabinet.id;
        let validator = this.state.validator
        let board = this.state.board;

        
        let validate = true;

        if (!cabinet.name) {
            validator.title = label.PleaseEnterCabinet
            this.setState({
                validator: validator
            })
            return;
        }
        if (!cabinet.documentNumber) {
            validator.documentNumber = label.PleaseEnterDocumentNumber
            this.setState({
                validator: validator
            })
            return;
        }
        if (!board.lanes || board.lanes.length === 0) {
            validate = false
            this.showDialog(label.Warning, label.PleaseAddAMinimumSingleContainer)
            return;
        }
        if ( board  && board.lanes && Object.keys(board.lanes).length > 0) {
            board.lanes.forEach(value => {
                
                if (!value || !value.cards || Object.keys(value.cards).length <= 0) {
                    validate = false
                    this.showDialog(label.Warning, label.PleaseAddAMinimumSingleContainer)
                    return
                }
            })
        }

         if(!validate){
            return
         }


        cabinet.cabinetRacks = board.lanes
        cabinet.cabinetRacks.forEach((value, index) => {
            value.id = index + 1
            value.cards.forEach((value1, index1) => {
                value1.id = index1 + 1
            })
        })
        cabinet.cabinetRFIDReaderTypes = []
        cabinet.cabinetLockerTypes = []
        cabinet = CircularJSON.stringify(cabinet)
        cabinet = JSON.parse(cabinet)
        this.post(Endpoint.API.CABINET_SAVE, cabinet, null, (res) => {
            if (res.code === 200) {
                this.successToast(label.SuccessUpdatingData)
                let cabinet = res.data
                if (newData) {
                    this.props.history.goBack()
                    window.location.href = CabinetDetailPage.ENDPOINT + "?id=" + cabinet.id
                } else {
                    board = new FormBoard()
                    this.setState({
                        cabinetId: cabinet.id
                    }, () => {
                        window.location.reload()
                    })
                }
            }
        }, true, true)
    }

    containerSize(){
        let {board} = this.state
        let size = 0 ;
        let lanes = board.lanes?board.lanes:[]
        lanes.forEach(value => {
            let cards = value.cards
            let cardSize = cards.length
            size += cardSize
        })
        return size ;
    }

    render() {
        let { board,
            formLaneModal,
            cabinetRack,
            formModal,
            validator,
            cabinet
        } = this.state
        let cabinetLockerTypes = cabinet.cabinetLockerTypes?cabinet.cabinetLockerTypes:[]
        let cabinetRFIDReaderTypes = cabinet.cabinetRFIDReaderTypes?cabinet.cabinetRFIDReaderTypes:[]
        return (
            <Page
                title={label.FormBuilder}
                breadcrumbs={[{ name: label.FormBuilder, active: true }]}
                className="TablePage">
                {super.render()}
                <CabinetRowModal
                    cabinetRack={cabinetRack}
                    showing={formLaneModal}
                    onAdd={(cabinetRack) => {
                        let lanes = board.lanes
                        let indexToReplace = -1;
                        lanes.forEach((item, index) => {
                            if (item.id == cabinetRack.id) {
                                indexToReplace = index;
                            }
                        })
                        if (indexToReplace > -1) {
                            lanes[indexToReplace] = cabinetRack;
                        } else {
                            lanes.push(cabinetRack)
                        }
                        board.lanes = lanes
                        this.setState({
                            board: board,
                            formLaneModal: false,
                        }, () => {
                            this.reorderFormCard(board)
                        })
                    }} close={() => {
                        this.setState({
                            formLaneModal: false,
                        })
                    }} />
                <CabinetContainerModal
                    showing={formModal}
                    cabinet={cabinet}
                    board={board}
                    lanes={cloneObject(board.lanes)}
                    lane={cloneObject(this.state.formEdit ? this.state.formEdit.lane : null)}
                    form={this.state.formEdit}
                    onAdd={(form, lane, newBoard) => {
                        if (newBoard) {
                            this.reorderFormCard(newBoard)
                        } else {
                            let laneIndex = -1;
                            let laneId = -1;
                            board.lanes.forEach((value, index) => {
                                if (value.id === lane.id) {
                                    laneIndex = index;
                                    laneId = value.id
                                }
                            })
                            if (laneIndex > -1) {
                                let lanes = board.lanes
                                if (form.lane && form.lane.id !== laneId) {
                                    board.lanes.forEach((item, index) => {
                                        if (form.lane.id == item.id) {
                                            item.cards.forEach((value, index1) => {
                                                if (value.id == form.id) {
                                                    item.cards.splice(index1, 1)
                                                }
                                            })
                                        }
                                    })
                                    board.lanes[laneIndex].cards.push(form)
                                } else {
                                    // still on previous lane position
                                    let indexToReplace = -1;
                                    board.lanes[laneIndex].cards.forEach((value, index) => {
                                        if (value.id === form.id) {
                                            indexToReplace = index
                                        }
                                    })
                                    if (indexToReplace > -1) {
                                        board.lanes[laneIndex].cards[indexToReplace] = form
                                    } else {
                                        board.lanes[laneIndex].cards.push(form)
                                    }
                                }
                                form.lane = lane
                                board.lanes = lanes
                                this.reorderFormCard(board)
                                this.setState({
                                    formModal: false,
                                })
                            }
                        }

                    }}
                    close={() => {
                        this.setState({
                            formModal: false,
                        })
                    }}
                />
                <Row key={1}>
                    <Col md={8}>
                        <Card>
                            <CardHeader>
                                <strong>{label.FormBuilder}</strong>
                            </CardHeader>
                            <CardBody >
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="name">{label.FormName}</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={cabinet ? cabinet.name : ""}
                                                onChange={(e) => {
                                                    cabinet.name = e.target.value
                                                    validator.title = ""
                                                    this.setState({
                                                        cabinet: cabinet,
                                                        validator: validator
                                                    })
                                                }}
                                                placeholder={label.EnterFormName}
                                            />
                                            <WarningLabel message={validator.title} show={!isEmpty(validator.title)} />  
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="documentNumber">{label.DocumentNumber}</Label>
                                            <Input
                                                type="text"
                                                name="documentNumber"
                                                value={cabinet ? cabinet.documentNumber : ""}
                                                onChange={(e) => {
                                                    cabinet.documentNumber = e.target.value
                                                    validator.documentNumber = ""
                                                    this.setState({
                                                        cabinet: cabinet,
                                                        validator: validator
                                                    })
                                                }}
                                                placeholder={label.EnterDocumentNumber}
                                            />
                                            <WarningLabel message={validator.documentNumber}  show={!isEmpty(validator.documentNumber)}/>
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="name">{label.Created}</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={cabinet ? parseDate(cabinet.created, DD_MMMM_YYYY_HH_MM_SS) : "-"}
                                                onChange={(e) => {
                                                    cabinet.created = e.target.value
                                                    this.setState({
                                                        cabinet: cabinet
                                                    })
                                                }}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label for="name">{label.Updated}</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={cabinet ? parseDate(cabinet.updated, DD_MMMM_YYYY_HH_MM_SS) : "-"}
                                                onChange={(e) => {
                                                    cabinet.updated = e.target.value
                                                    this.setState({
                                                        cabinet: cabinet
                                                    })
                                                }}
                                                disabled={true}
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <strong>{label.LockerDevice}</strong>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        <Table responsive hover className="table-sm">
                                            <thead>
                                            <tr>
                                                <th className="text-center" style={{width:'10%'}}>#</th>
                                                <th style={{width:'60%'}}>{label.Type}</th>
                                                <th className="text-center">{label.ChannelSize}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                cabinetLockerTypes.map((item, index) =>(
                                                    <tr key={index}>
                                                        <th className="text-center" scope="row">{++index}</th>
                                                        <td>{item.lockerType.type}</td>
                                                        <td className="text-center">{item.lockerType.channelSize}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardHeader>
                                        <strong>{label.RFIDReaderDevice}</strong>
                                    </CardHeader>
                                    <CardBody className="p-0">
                                        <Table responsive hover className="table-sm">
                                            <thead>
                                            <tr>
                                                <th className="text-center" style={{width:'10%'}}>#</th>
                                                <th style={{width:'60%'}}>{label.Type}</th>
                                                <th className="text-center">{label.ChannelSize}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                cabinetRFIDReaderTypes.map((item, index) =>(
                                                    <tr key={index}>
                                                        <th className="text-center" scope="row">{++index}</th>
                                                        <td>{item.rfidReaderType.type}</td>
                                                        <td className="text-center">{item.rfidReaderType.channelSize}</td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Button
                                    style={{ marginRight: '10px' }}
                                    color='primary'
                                    onClick={e => {
                                        if(cabinet.maximumContainer<=this.containerSize()){
                                            this.errorToast(label.MaximumContainerSizeReached)
                                            return ;
                                        }
                                        this.setState({
                                            cabinetRack: new CabinetRow(),
                                            formLaneModal: true
                                        })
                                    }}>
                                    {label.AddCabinetRack}&nbsp;&nbsp;&nbsp;<MdAdd />
                                </Button>
                                {
                                    (board.lanes.length > 0) && (
                                        <Button
                                            color='purple'
                                            style={{ marginRight: '10px' }}
                                            onClick={e => {
                                                if(cabinet.maximumContainer<=this.containerSize()){
                                                    this.errorToast(label.MaximumContainerSizeReached)
                                                    return ;
                                                }
                                                this.setState({
                                                    formEdit: null,
                                                    formModal: true
                                                })
                                            }}>
                                            {label.AddCabinetRow}&nbsp;&nbsp;&nbsp;<MdAdd />
                                        </Button>
                                    )
                                }
                                <Button
                                    style={{ marginRight: '10px' }}
                                    onClick={(e) => this.onClickSave()}>{label.SaveCabinet}&nbsp;&nbsp;&nbsp;<MdSave />
                                </Button>
                            </CardHeader>

                            <CardBody className="body-board">
                                <Board
                                    disableCardDrag
                                    initialBoard={board}
                                    children={board}
                                    allowRemoveLane
                                    allowRenameLane
                                    allowRemoveCard
                                    allowAddLane
                                    onLaneRemove={(board, lane) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onCardRemove={(board, lane, card) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onLaneRename={(board, lane) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onCardDragEnd={(board, source, destination) => {
                                        this.reorderFormCard(board)
                                    }}
                                    onLaneDragEnd={(board, source, destination) => {
                                        this.reorderFormCard(board)
                                    }}
                                    renderLaneHeader={(lane, { removeLane, renameLane, addCard }) => (
                                        <Card style={{ border: '2px #4530E3 solid', marginBottom: '24px' }}>
                                            <CardHeader>
                                                <strong className="align-items-center h2">{lane.title}</strong>
                                                <Button color={"primary"} style={{ float: 'right' }} onClick={(e) => {
                                                    this.setState({
                                                        cabinetRack: cloneObject(lane),
                                                        formLaneModal: true,
                                                    })
                                                }}>
                                                    <MdEdit />
                                                </Button>
                                                &nbsp;
                                                {/*<Button*/}
                                                {/*    color={"warning"}*/}
                                                {/*    style={{ float: 'right', marginRight: '8px' }}*/}
                                                {/*    onClick={() => {*/}
                                                {/*        let cabinetRack = cloneObject(lane)*/}
                                                {/*        cabinetRack.id = undefined*/}
                                                {/*        this.setState({*/}
                                                {/*            cabinetRack: cabinetRack,*/}
                                                {/*            formLaneModal: true*/}
                                                {/*        })*/}
                                                {/*    }}>*/}
                                                {/*    <FaCopy />*/}
                                                {/*</Button>*/}
                                                {/*&nbsp;*/}
                                                <Button
                                                    color={"danger"}
                                                    style={{ float: 'right', marginRight: '8px' }}
                                                    onClick={() => {
                                                        this.openConfirmDialog(label.Confirmation, label.DoYouWantToRemoveThisRack, () => {
                                                            removeLane()
                                                        })
                                                    }}>
                                                    <MdDelete />
                                                </Button>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="name">{label.Description}</Label>
                                                            <Input
                                                                type="textarea"
                                                                name="description"
                                                                rows={5}
                                                                value={htmlToText(lane.description, { wordWrap: 50 })}
                                                                disabled
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    )}
                                    renderCard={(card, cardBag) => (
                                        <ContainerCard
                                            cabinet={cabinet}
                                            form={card}
                                            board={board}
                                            cardBag={cardBag}
                                            lanes={board.lanes}
                                            onNewFormClone={(board) => this.reorderFormCard(board)}
                                            editCard={(form) => {
                                                this.setState({
                                                    formEdit: cloneObject(form),
                                                    formModal: true
                                                })
                                            }} />
                                    )}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Page>
        );
    }
}

export default Object.assign(CabinetDetailPage, { ENDPOINT: "/cabinetDetail" })