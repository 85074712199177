import React from 'react';
import BaseComponent from '../../BaseComponent';
import {Bar} from 'react-chartjs-2';
import {Button, Card, CardBody, CardHeader, Col, Label, ListGroup, ListGroupItem, Row} from 'reactstrap';
import {MdRefresh} from 'react-icons/md';
import {getColor} from 'utils/colors';
import DateInput from '../../Widget/DateInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import Global from '../../../utils/Global';
import {parseDate} from 'na-utilities/src/utils/Utilities';
import label from 'na-utilities/src/label/Label'
import Page from "../../Page";
import Endpoint from "na-utilities/src/api/Endpoint";

let report ={
    bar: {
        data: {
            labels: label.MONTHS_LABEL,
            datasets: [
                {
                    label: label.Success,
                    backgroundColor: '#6a82fb',
                    stack: 'Expense',
                    data: [10000, 30000, 50000, 80000, 60000, 20000, 10000, 10000,10000,10000,10000,10000],
                },
                {
                    label: label.Failed,
                    backgroundColor: '#fc5c7d',
                    stack: 'Expense',
                    data: [30000, 80000, 50000, 100000, 60000, 40000, 90000, 20000,20000,20000,20000,20000],
                },
            ],
        },
        options: {
            title: {
                display: true,
                text: label.Transaction,
            },
            tooltips: {
                mode: 'index',
                intersect: true,
            },
            hover: {
                mode: 'index',
            },
            responsive: true,
            legend: {
                display: true,
            },
            scales: {
                xAxes: [
                    {
                        stacked: true,
                        display: true,
                    },
                ],
                yAxes: [
                    {
                        stacked: true,
                        display: true,
                    },
                ],
            },
        },
    }
}

let firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
let firstDayOfYear = new Date(new Date().getFullYear(), 0, 1)

export default class DashboardCategoryAnalytic extends BaseComponent{

    constructor(props) {
        super(props);
        let state = this.state
        state.productCategories = new Array()
        state.startDate = parseDate(firstDayOfYear, "yyyy-mm-dd")
        state.endDate = parseDate(new Date(), "yyyy-mm-dd");
        state.analytic = {}
        state.productCategory = {}
        this.setState(state)

    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchCategoryAnalytic()
    }

    fetchCategoryAnalytic=()=>{
        let fetching = this.state.fetching
        this.setState({
            fetching:true
        }, () => {
            this.get(Endpoint.API.DASHBOARD_SPOT_LOCATION_ANALYTIC, {
                params:{
                    startDate : this.state.startDate,
                    endDate : this.state.endDate,
                    dataId : this.state.productCategory.id,
                }
            }, null, response=>{
                this.setState({fetching:false})
                if(response.code===200){
                    this.setState({
                        analytic:response.data
                    })
                }
            })
        })

    }


    render() {
        const primaryColor = getColor('primary');

        let productCategories = this.state.productCategories
        let analytic = this.state.analytic
        let fetching = this.state.fetching
        let cartData = {
            labels:[],
            datasets:[]
        }

        if(analytic.barInfos){
            analytic.barInfos.map((item, index)=>(
                cartData.labels.push(item.label)
            ))
        }

        if(analytic.datasets){
            analytic.datasets.map((item, index)=>{
                cartData.datasets.push(item)
            })
        }

        return (
            <Card>
                <CardHeader>
                    {label.TransactionPerSpotLocation}
                </CardHeader>
                <ListGroup flush>
                    <ListGroupItem>
                        <Row>
                            <Col md={5}>
                                <Label for="startDate">{label.StartDate}</Label>
                                <DateInput
                                    id="startDate"
                                    maxdate={this.state.endDate}
                                    value={this.state.startDate}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        this.setState({
                                            startDate : value?value:firstDayOfMonth
                                        }, () => {

                                        })
                                    }}
                                    placeholder={label.StartDate}
                                />
                            </Col>
                            <Col md={5}>
                                <Label for="endDate">{label.EndDate}</Label>
                                <DateInput
                                    id="endDate"
                                    mindate={this.state.startDate}
                                    value={this.state.endDate}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        this.setState({
                                            endDate : value?value:parseDate(new Date(), "yyyy-mm-dd")
                                        }, () => {

                                        })
                                    }}
                                    placeholder={label.EndDate}
                                />
                            </Col>
                            {
                                (fetching)?(
                                    <Col md={2}>
                                        <CircularProgress style={{marginTop:'32px'}}  color="secondary" size={24}/>
                                    </Col>
                                ):(
                                    <Col md={2}>
                                        <Button style={{marginTop:'32px'}} className="float-right" onClick={event=>{
                                            this.fetchCategoryAnalytic()
                                        }}><MdRefresh/></Button>
                                    </Col>
                                )
                            }
                        </Row>
                    </ListGroupItem>
                </ListGroup>
                <CardBody>
                    <Bar data={cartData} options={report.bar.options} />
                </CardBody>
            </Card>
        );
    }


}
