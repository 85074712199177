import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import ItemOption from '../Widget/ItemOption';
import Global from '../../utils/Global';
import BaseComponent from '../BaseComponent';
import {statusColor} from 'na-utilities/src/utils/Utilities';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
/*= (message, okCallback, cancelCallback) =>*/
export default class UpdateSmartDeviceStatusModal extends BaseComponent{

    constructor(props){
        super(props);
        let state = this.state
        state.modal= this.props.modal
        state.backdrop= false
        state.smartDevice = this.props.smartDevice
        state.smartDeviceStatus = {}
        state.smartDeviceStatuses = []
        state.newSmartDeviceStatus = {}
        state.okCallback = this.props.okCallback
        state.closeCallback = this.props.closeCallback
        state.note = ""
        state.resiCode = ""
        state.resiCodeMessage=""
        this.setState(state)
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.state.modal
        if(modal!=props.modal){
            this.setState({
                modal:props.modal,
                smartDevice: props.smartDevice,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
                smartDeviceStatus:{},
                smartDeviceStatuses:[],
                newSmartDeviceStatus:{},
                note:"",
                resiCode : "",
                resiCodeMessage : "",
            }, () => {
                if(props.modal && modal!==props.modal){
                    this.fetchSmartDeviceStatus()
                }
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchSmartDeviceStatus()

    }

    fetchSmartDeviceStatus = () =>{
        let smartDevice = this.state.smartDevice?this.state.smartDevice:{}
        let smartDeviceStatus = smartDevice.smartDeviceStatus?smartDevice.smartDeviceStatus:{}
        this.get(Endpoint.API.SMART_DEVICE_STATUSES, null, null, response=>{
            if(response.code===200){
                let smartDeviceStatuses = response.data
                let smartDeviceStatusOptions = []
                smartDeviceStatuses.map((item, index)=>{
                    if(item.id!=smartDeviceStatus.id){
                        smartDeviceStatusOptions.push(item)
                    }
                })
                this.setState({
                    smartDeviceStatuses : smartDeviceStatusOptions
                })
            }
        }, true, true)
    }


    updateSmartDeviceStatus=()=>{
        let smartDevice = this.state.smartDevice?this.state.smartDevice:{}
        let smartDeviceStatus = smartDevice.smartDeviceStatus?smartDevice.smartDeviceStatus:{}
        let newSmartDeviceStatus = this.state.newSmartDeviceStatus?this.state.newSmartDeviceStatus:{}
        let note = this.state.note?this.state.note:""
        // let resiCode = this.state.resiCode?this.state.resiCode:""
        let confirmationMessage = "";
        // if(newSmartDeviceStatus.id===_DELIVERY){
        //     confirmationMessage= "Do you want to update this smartDevice status from = "+smartDeviceStatus.name+" to = "+newSmartDeviceStatus.name+" with resi code is "+resiCode+" ? "
        // }else{
        // }
        confirmationMessage= "Do you want to update this smartDevice status from = "+smartDeviceStatus.name+" to = "+newSmartDeviceStatus.name+" ? "
        this.openConfirmDialog(label.Confirmation, confirmationMessage, ()=>{
            let formData = new FormData();
            formData.append("smartDeviceId", smartDevice.id)
            formData.append("smartDeviceStatusId", newSmartDeviceStatus.id)
            formData.append("note", note)
            this.post(Endpoint.API.SMART_DEVICE_UPDATE_STATUS, formData, null, response=>{
                if(response.code===200){
                    let smartDevice = response.data
                    this.successToast(response.message)
                    this.setState({
                        modal:false
                    }, () => {
                        if(this.state.okCallback){
                            this.state.okCallback(smartDevice.smartDeviceStatus)
                        }
                    })
                }
            }, true, true)
        })
    }

    render(){
        let smartDevice = this.state.smartDevice?this.state.smartDevice:{}
        let smartDeviceStatus = smartDevice.smartDeviceStatus?smartDevice.smartDeviceStatus:{}
        let smartDeviceStatuses = this.state.smartDeviceStatuses?this.state.smartDeviceStatuses:[]
        let newSmartDeviceStatus = this.state.newSmartDeviceStatus?this.state.newSmartDeviceStatus:{}

        return (
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={false}>
                {super.render()}
                <ModalHeader>
                    Update Smart DeviceStatus : {smartDevice&&smartDevice.refCode}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="currentStatus">Current Status</Label>
                                <Input
                                    style={{fontSize:'bold', color:statusColor(smartDeviceStatus.id), borderColor: statusColor(smartDeviceStatus.id)}}
                                    id="currentStatus"
                                    type="text"
                                    name="currentStatus"
                                    value={smartDeviceStatus.name}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.SelectStatus}
                                    fieldForLabel={"label"}
                                    objects={smartDeviceStatuses}
                                    default={newSmartDeviceStatus.id}
                                    callback={(newSmartDeviceStatus)=>{
                                        if(newSmartDeviceStatus!=null){
                                            this.setState({
                                                newSmartDeviceStatus:newSmartDeviceStatus
                                            })
                                        }else{
                                            this.setState({
                                                newSmartDeviceStatus:{}
                                            })
                                        }
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col>
                            <FormGroup>
                            <Label for="note">Note</Label>
                            <Input
                                id="note"
                                type="text"
                                name="note"
                                value={this.state.note}
                                onChange={(e) =>{
                                    let value = e.target.value
                                    this.setState({
                                        note : value?value:null
                                    })
                                }}
                                placeholder={label.EnterNoteRecommended}
                            />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Row>
                        {
                            newSmartDeviceStatus.id&&(
                                <Col>
                                    <Button color="primary" onClick={e=>{
                                        this.updateSmartDeviceStatus()
                                    }}>
                                        Update
                                    </Button>
                                </Col>
                            )
                        }
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                this.setState({
                                    modal:false
                                })
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

UpdateSmartDeviceStatusModal.propTypes = {
    modal:PropTypes.bool,
    smartDevice:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
