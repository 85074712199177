import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS, USER_STATUSES,} from '../utils/Global';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import SearchInput from '../components/SearchInput';
import {allIsEmpty, deleteParam, isEmpty, navigatePage, parseDate, sortirMap,} from "na-utilities/src/utils/Utilities";
import ItemOption from '../components/Widget/ItemOption';
import {IoMdSettings} from 'react-icons/io/index';
import {MdRestore} from 'react-icons/md/index';
import {FaFileExcel} from 'react-icons/fa/index';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import {FiArrowDownCircle, FiArrowUpCircle} from 'react-icons/fi/index';
import DateInput from '../components/Widget/DateInput';
import label, {GENDERS} from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
import CreateUserModal from "../components/modal/CreateUserModal";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class UserPage extends BasePage{

    user = {}
    users = new Array();

    constructor(props) {
        super(props);
        this.state ={
            users : this.users,
            user:this.user,
            modalDeleteConfirm:false,
            ascending:false,
            sortir:'u.created',
            search:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            lastOpenStart:null,
            lastOpenEnd:null,
            gender:{},
            userStatus:{},
            roles:[],
            role:{},
            provinces:[],
            cities:[],
            districts:[],
            villages:[],
            province:{},
            city:{},
            district:{},
            village:{},
            userFilterExpand:false,
            userTransactionExpand:false,
            createUserModal:false,
        }

        this.fetchProvinces((provinces)=>this.setState({provinces:provinces}))
    }


    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
            })
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
        this.fetchUserRoles()
    }


    fetchUserRoles = () =>{
        this.get(Endpoint.API.ROLES, null, null, response=>{
            if(response.code===200){
                this.setState({
                    roles : response.data
                })
            }
        }, true, true)
    }

    resetFilter(){
        this.setState({
            search:"",
            page :1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
            createdStartDate:null,
            createdEndDate:null,
            lastOpenStart:null,
            lastOpenEnd:null,
            gender:{},
            userStatus:{},
            role:{},
            province:{},
            city:{},
            district:{},
            village:{},
        }, () => {
            navigatePage(this.props, 1)
        })
    }

    fetchAll = (ascending, sortir, search, progressing, excelMode) => {
        let params = {
            page:this.state.page-1,
            ascending:ascending,
            sortir:sortir,
            search:search,
            userCreatedStart:this.state.createdStartDate,
            userCreatedEnd:this.state.createdEndDate,
            lastOpenStart:this.state.lastOpenStart,
            lastOpenEnd:this.state.lastOpenEnd,
            gender:this.state.gender.key,
            userStatus:this.state.userStatus.name,
            roleId:this.state.role.id,
            provinceId:this.state.province.id,
            cityId:this.state.city.id,
            districtId:this.state.district.id,
            villageId:this.state.village.id,
            excelMode:excelMode?excelMode:false
        }
        if(excelMode){
            this.asyncDownloadGet(Endpoint.API.USERS, {params:params}, null,true);
            return
        }else{
            this.get(Endpoint.API.USERS, {params:params}, null, res =>{
                if(res.code === 200){
                    this.setState({
                        users : res.data,
                        totalPage: res.totalPage,
                        totalElement: res.totalElement,
                        pageElement: res.pageElement,
                    })
                }
            }, progressing, true);
        }
    }

    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }

    delete = () => {
        this.setState({
            modalDeleteConfirm:false
        }, () => {
            this.get(Endpoint.API.USER_DELETE+"/"+this.state.user.id, null, null, res => {
                if(res.code===200){
                    this.setState({
                        category:null
                    }, () => {
                        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
                    })
                }
            }, true, true);
        })
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false
        })
    }

    refreshUser = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir
        }, () => {
            this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, progressing)
        })
    )


    render() {
        var i = 0 ;
        let {users, user, roles, role, createUserModal} = this.state
        let gender = user.gender?user.gender:{}
        let totalPage =  this.state.totalPage
        let totalElement =  this.state.totalElement
        let pageElement =  this.state.pageElement


        return (

            <Page
                title={label.UserList}
                breadcrumbs={[{ name: label.User, active: true }]}
                className="TablePage"
            >
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message={label.DeleteConfirmationMessage}
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <CreateUserModal
                    show={createUserModal}
                    onClose={()=>{
                        this.setState({createUserModal:false})
                    }}
                    onCreateUser={(user)=>{
                        this.setState({createUserModal:false},() => {
                            this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true)
                        })
                    }}/>
                <Row key={1}>
                    <Col>
                        <Card>
                            <CardHeader style={{cursor:'pointer'}} onClick={event=>{
                                this.setState({
                                    userFilterExpand:!this.state.userFilterExpand
                                })
                            }}>
                                {
                                    (this.state.userFilterExpand)?(<FiArrowUpCircle/>):((<FiArrowDownCircle/>))
                                }
                                &nbsp;{label.AdvanceFilter} ({label.ClickToExpandFilterContent})
                            </CardHeader>
                            <Collapse isOpen={this.state.userFilterExpand}>
                                <CardHeader>
                                    <Row>
                                        <Col md={3}>
                                            <Label for="createdStartDate">{label.RegisteredSince}</Label>
                                            <DateInput
                                                id="createdStartDate"
                                                value={this.state.createdStartDate}
                                                maxdate={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdStartDate : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="createdEndDate">{label.RegisteredUntil}</Label>
                                            <DateInput
                                                id="createdEndDate"
                                                mindate={this.state.createdStartDate}
                                                value={this.state.createdEndDate}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        createdEndDate :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="lastOpenStart">{label.LastOpenStart}</Label>
                                            <DateInput
                                                id="lastOpenStart"
                                                value={this.state.lastOpenStart}
                                                maxdate={this.state.lastOpenEnd}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        lastOpenStart : value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterStartDate}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Label for="lastOpenEnd">{label.LastOpenEnd}</Label>
                                            <DateInput
                                                id="lastOpenEnd"
                                                mindate={this.state.lastOpenStart}
                                                value={this.state.lastOpenEnd}
                                                onChange={(e) =>{
                                                    let value = e.target.value
                                                    this.setState({
                                                        lastOpenEnd :  value?value:null
                                                    })
                                                }}
                                                placeholder={label.EnterEndDate}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.Gender}
                                                    objects={GENDERS}
                                                    default={!isEmpty(this.state.gender)?this.state.gender.id:null}
                                                    callback={(gender)=>{
                                                        user.gender = gender.name
                                                        this.setState({
                                                            gender:gender,
                                                            user:user
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.UserRole}
                                                    objects={roles}
                                                    default={role.id}
                                                    callback={(role)=>{
                                                        role = role
                                                        this.setState({
                                                            role:role,
                                                            user:user
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.SelectUserStatus}
                                                    objects={USER_STATUSES}
                                                    default={!isEmpty(this.state.userStatus)?this.state.userStatus.id:null}
                                                    callback={(userStatus)=>{
                                                        this.setState({
                                                            userStatus:userStatus
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.Province}
                                                    objects={this.state.provinces}
                                                    default={!allIsEmpty(this.state.province)?this.state.province.id:null}
                                                    callback={(province)=>{
                                                        this.setState({
                                                            province:!isEmpty(province.id)?province:{},
                                                            cities:[],
                                                            city:!isEmpty(province.id)?this.state.city:{},
                                                            districts:[],
                                                            district:!isEmpty(province.id)?this.state.district:{},
                                                            villages:[],
                                                            village:!isEmpty(province.id)?this.state.village:{},
                                                        }, () => {
                                                            if(!isEmpty(province.id)){
                                                                this.fetchCities(province.id, cities=>{
                                                                    this.setState({
                                                                        cities:cities
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.City}
                                                    objects={this.state.cities}
                                                    default={!allIsEmpty(this.state.city)?this.state.city.id:null}
                                                    callback={(city)=>{
                                                        this.setState({
                                                            city:!isEmpty(city.id)?city:{},
                                                            districts:[],
                                                            district:!isEmpty(city.id)?this.state.district:{},
                                                            villages:[],
                                                            village:!isEmpty(city.id)?this.state.village:{},
                                                        }, () => {
                                                            if(!isEmpty(city.id)){
                                                                this.fetchDistricts(city.id, districts =>{
                                                                    this.setState({
                                                                        districts:districts
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.District}
                                                    objects={this.state.districts}
                                                    default={!allIsEmpty(this.state.district)?this.state.district.id:null}
                                                    callback={(district)=>{
                                                        this.setState({
                                                            district:!isEmpty(district.id)?district:{},
                                                            villages:[],
                                                            village:!isEmpty(district.id)?this.state.village:{},
                                                        }, () => {
                                                            if(!isEmpty(district.id)){
                                                                this.fetchVillages(district.id, villages=>{
                                                                    this.setState({
                                                                        villages:villages
                                                                    })
                                                                })
                                                            }
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <ItemOption
                                                    title={label.Village}
                                                    objects={this.state.villages}
                                                    default={!allIsEmpty(this.state.village)?this.state.village.id:null}
                                                    callback={(village)=>{
                                                        this.setState({
                                                            village:!isEmpty(village)?village:{},
                                                        })
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalRecord}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'green'}}><strong>{totalElement}</strong></h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {label.TotalPage}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <h4 style={{color:'blue'}}>{totalPage}</h4>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={8}>
                                            <Row>
                                                <Col>
                                                    <SearchInput
                                                        placeholder={label.SearchNameEmailPhoneUserCodeOrOrganization}
                                                        value={this.state.search}
                                                        onChange={e=>{
                                                            this.setState({
                                                                search:e.target.value
                                                            }, () => {
                                                                if(this.state.search===''){
                                                                    deleteParam(this.props, "page")
                                                                }
                                                            })
                                                        }}
                                                        onEnter={e=>{
                                                            if(this.state.search!==''){
                                                                deleteParam(this.props, "page")
                                                            }
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={3}>
                                                    <Button block className={"float-left"} color={"primary"}
                                                            onClick={event => {
                                                                navigatePage(this.props, 1)
                                                            }}><IoMdSettings/>&nbsp;{label.ApplyFilter}</Button>
                                                </Col>
                                                <Col md={3}>
                                                    <Button block className={"float-left"} color={"yellow"}
                                                            onClick={event => {
                                                                this.resetFilter()
                                                            }}><MdRestore/>&nbsp;{label.ResetFilter}</Button>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}></Col>
                                                <Col md={6}>
                                                    <Button
                                                        block
                                                        className={"float-left"}
                                                        color={"green"}
                                                        onClick={event => {
                                                            this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, true, true)
                                                        }}><FaFileExcel/>&nbsp;{label.DownloadExcel}</Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                <Row key={2}>
                    <Col>
                        <Card>
                            <CardHeader>
                                <strong>{label.UserList}</strong>
                                <br/><br/>
                                <Row>
                                    <Col md={3}>
                                        {label.SortBy} :
                                        <UncontrolledButtonDropdown key={1}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    sortirMap(this.state.sortir)
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshUser(this.state.ascending, "u.created", true))}>{label.Created}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUser(this.state.ascending, "u.firstname", true))}>{label.Name}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUser(this.state.ascending, "u.email", true))}>{label.Email}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUser(this.state.ascending, "u.username", true))}>{label.Username}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUser(this.state.ascending, "u.updated", true))}>{label.Updated}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={3}>
                                        Sort Mode :
                                        <UncontrolledButtonDropdown key={2}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    this.state.ascending?label.Ascending:label.Descending
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshUser(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshUser(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={3}>
                                    </Col>
                                    <Col md={3}>
                                        <ButtonGroup className="float-right">
                                            <Button
                                                color="primary"
                                                onClick={e=>{
                                                    this.setState({createUserModal:true})
                                                }}>
                                                {label.AddUser}
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Table responsive hover>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{label.Fullname}</th>
                                            <th>{label.Username}</th>
                                            <th>{label.Email}</th>
                                            <th>{label.Company}</th>
                                            <th>{label.UserStatus}</th>
                                            <th>{label.Role}</th>
                                            <th>{label.LastOpen}</th>
                                            <th>{label.Updated}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            users.map((item, index)=>{
                                                return (
                                                    <tr key={item.id}>
                                                        <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                                        <td><a style={{textDecoration:'underline', color:'blue'}} href={'/userDetail?id='+item.id}>{item.fullname}</a></td>
                                                        <td><a style={{textDecoration:'underline', color:'blue'}} href={'/userDetail?id='+item.id}>{item.username}</a></td>
                                                        <td>{item.email}</td>
                                                        <td>{item.company.fullName}</td>
                                                        <td>{item.userStatus}</td>
                                                        <td>{item.roleInformation}</td>
                                                        <td>{parseDate(item.lastOpenDate, DD_MM_YYYY_HH_MM_SS)}</td>
                                                        <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                            <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
                        </Card>
                    </Col>
                </Row>
            </Page>
        );
    }
}
export default Object.assign(UserPage, {ENDPOINT : "/users"})
