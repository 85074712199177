import canceled from "../../assets/images/canceled.png"
import demand from "../../assets/images/demand.png"
import error from "../../assets/images/error.png"
import expired from "../../assets/images/expired.png"
import paid from "../../assets/images/paid.png"
import refund from "../../assets/images/refund.png"
import rejected from "../../assets/images/rejected.png"
import warningAttention from "../../assets/images/warning-attention.png"
import warningInfo from "../../assets/images/warning-info.png"
import wellDone from "../../assets/images/well-done.png"
import workInProgress from "../../assets/images/work-in-progress.png"
import initiated from "../../assets/images/initiated.png"
import active from "../../assets/images/active.png"
import inactive from "../../assets/images/inactive.png"
import maintenance from "../../assets/images/maintenance.png"
import markerMap from "../../assets/images/marker-map.png"
import imagePlaceholder from "../../assets/images/image-placeholder.png"
import logout from "../../assets/images/logout.png"
import signout from "../../assets/images/signout.png"
import userAvatar from "../../assets/images/user_avatar.png"
import unlock from "../../assets/images/unlock.png"
import smartSupplies from "../../assets/images/smart-supplies.png"
import smartSuppliesIcon from "../../assets/images/smart-supplies-icon.png"
import rfid from "../../assets/images/rfid.png"
import fingerprint from "../../assets/images/fingerprint.png"
import lifeline from "../../assets/images/lifeline.png"
import pullOut from "../../assets/images/pull-out.png"
import pushIn from "../../assets/images/push-in.png"
import collecting from "../../assets/images/collecting.png"
import imageUpload from "../../assets/images/image-upload.png"
import noImage from "../../assets/images/no_image.png"
import radarGif from "../../assets/gif/radar.gif";
import flash from "../../assets/gif/flash.gif";
import radarImage from "../../assets/images/radar.png";
import takeCamera from "../../assets/images/take_camera.png";
import cabinet from "../../assets/images/cabinet.png";
import lines from "../../assets/images/lines.png";
import notAllocated from "../../assets/images/not_allocated.png";
import allocated from "../../assets/images/allocated.png";
import rack from "../../assets/images/rack.png";


export default{
    Icons:{
        canceled:canceled,
        demand:demand,
        error:error,
        expired:expired,
        paid:paid,
        refund:refund,
        rejected:rejected,
        warningAttention:warningAttention,
        warningInfo:warningInfo,
        wellDone:wellDone,
        workInProgress:workInProgress,
        initiated:initiated,
        active:active,
        inactive:inactive,
        maintenance:maintenance,
        markerMap:markerMap,
        imagePlaceholder:imagePlaceholder,
        userAvatar:userAvatar,
        logout:logout,
        signout:signout,
        unlock:unlock,
        smartSupplies:smartSupplies,
        smartSuppliesIcon:smartSuppliesIcon,
        rfid:rfid,
        fingerprint:fingerprint,
        lifeline:lifeline,
        pullOut:pullOut,
        pushIn:pushIn,
        collecting:collecting,
        imageUpload:imageUpload,
        noImage:noImage,
        radar:radarImage,
        takeCamera:takeCamera,
        cabinet:cabinet,
        lines:lines,
        notAllocated:notAllocated,
        allocated:allocated,
        rack:rack,
    },
    Gifs:{
        radar:radarGif,
        flash:flash,
    }

}