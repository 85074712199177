import React from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ValidatorLabel from '../Widget/ValidatorLabel';
import PropTypes from 'prop-types';
import Global, {SUPER_ADMIN_ID, SYSTEM_ADMIN_ID} from '../../utils/Global';
import BaseComponent, {toast} from '../BaseComponent';
import {saveAdminSession} from '../../utils/StorageUtil';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";
import {isResponseOk, isValidEmail, isEmpty, allIsEmpty} from "na-utilities/src/utils/Utilities";
import {isValidNumber} from "libphonenumber-js";
import ItemOption from "../Widget/ItemOption";
import {RoleDto} from "../../model/model";

/*= (message, okCallback, cancelCallback) =>*/

export default class CreateUserModal extends BaseComponent {

    constructor(props){
        super(props);
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()

        this.state = {
            show:false,
            email:"",
            fullname:"",
            mobilePhone:"",
            roles:[],
            role:{},
            companies:[],
            company:{},
            validation,
            validationMessage
        }
    }

    defaultValidation = () =>{
        let validation = {};
        validation.email = true ;
        validation.fullname = true ;
        validation.mobilePhone = true
        validation.role = true
        validation.company = true
        return validation ;
    }
    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.email = "" ;
        validationMessage.fullname = "" ;
        validationMessage.mobilePhone = ""
        validationMessage.role = ""
        validationMessage.company = ""
        return validationMessage ;
    }
    componentDidMount() {
        super.componentDidMount();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps!=this.props){
            let show = this.props.show?this.props.show:false
            this.setState({show},() => {
                if(show && !prevState.show){
                    let validation = this.defaultValidation()
                    let validationMessage = this.defaultValidationMessage()
                    this.setState({
                        show:show,
                        email:"",
                        fullname:"",
                        mobilePhone:"",
                        roles:[],
                        role:{},
                        companies:[],
                        company:{},
                        validation,
                        validationMessage
                    },() => {
                        this.fetchRoles()
                    })
                }
            })
        }
    }
    fetchRoles = () => {
        this.get(Endpoint.API.ROLES, {
            params:{
                groupName:undefined,
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    roles : response.data
                },() => {
                    this.fetchCompanies()
                })
            }
        }, false, true);
    }

    fetchCompanies = () => {
        this.get(Endpoint.API.COMPANIES, {
            params:{
                "sortir":"jc.full_name",
            }
        }, null, response=>{
            if(response.code === 200){
                this.setState({
                    companies : response.data
                })
            }
        }, false, true);
    }

    validateFormSubmit() {
        // await super.validateFormSubmit();
        let {roles, show, email, fullname, mobilePhone, role, companies, company, validation, validationMessage} = this.state
        let valid = true;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()
        if (isEmpty(mobilePhone) || mobilePhone.length <= 2) {
            validation.mobilePhone = false
            validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
            valid = false;
        }
        if (!isValidNumber(mobilePhone, 'ID')) {
            validation.mobilePhone = false
            validationMessage.mobilePhone = label.PleaseEnterCorrectPhoneNumber
            valid = false;
        }
        if (isEmpty(fullname) || fullname.length <= 2) {
            validation.fullname = false
            validationMessage.fullname = label.PleaseEnterFullName
            valid = false;
        }
        if (isEmpty(email) || email.length <= 2) {
            validation.email = false
            validationMessage.email = label.PleaseEnterEmail
            valid = false;
        }

        if (!isValidEmail(email)) {
            validation.email = false
            validationMessage.email = label.PleaseEnterCorrectEmail
            valid = false;
        }

        if (isEmpty(role) || !role.id) {
            validation.role = false
            validationMessage.role = label.PleaseSelectRoleForThisUser
            valid = false;
        }
        if(!(role.id===SYSTEM_ADMIN_ID || role.id === SUPER_ADMIN_ID)){
            if (isEmpty(company) || !company.id) {
                validation.company = false
                validationMessage.company = label.PleaseSelectCompany
                valid = false;
            }
        }
        this.setState({validation, validationMessage}, () => {
            if(valid){
                this.save()
            }
        })
    }

    save(){
        let {roles, show, email, fullname, mobilePhone, role, companies, company, validation, validationMessage} = this.state
        let {onCreateUser, onClose} = this.props
        let formData = new FormData()
        let userInfo = {}
        userInfo.email = email
        userInfo.fullname = fullname
        userInfo.mobilePhone = mobilePhone
        userInfo.roleId = role.id
        if(!(role.id===SYSTEM_ADMIN_ID && role.id === SUPER_ADMIN_ID)){
            userInfo.companyId = company.id
        }

        formData.append("userInfoGson", JSON.stringify(userInfo))
        this.post(Endpoint.API.USER_CREATE_NEW_USER, formData, null, (res)=>{
            if(isResponseOk(res)){
                let userCreation = res.data
                this.successToast(label.SuccessUpdatingData)
                if(onCreateUser){
                    onCreateUser(userCreation)
                }
            }else{
                this.errorToast(res.message)
                if(onClose){
                    onClose()
                }
            }
        }, true, false)
    }

    render(){
        let {companies, company, roles, show, email, fullname, mobilePhone, role, validation, validationMessage} = this.state
        let {onClose} = this.props
        return (
            <Modal
                isOpen={show}
                centered={true}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.CreateUserAccount}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="fullname">{label.Fullname}</Label>
                                <Input
                                    type="text"
                                    name="fullname"
                                    value={fullname}
                                    onChange={(e) =>{
                                        this.setState({
                                            fullname:e.target.value
                                        })
                                    }}
                                    placeholder={label.EnterFullname}
                                />
                                <ValidatorLabel message={validationMessage.fullname}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="email">{label.Email}</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    value={email}
                                    onChange={async (e) =>{
                                        email = e.target.value
                                        validation.email = true
                                        validationMessage.email = ""
                                        this.setState({email, validation, validationMessage})
                                    }}
                                    placeholder={label.EnterEmail}
                                />
                                <ValidatorLabel message={validationMessage.email}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="mobilePhone">{label.MobilePhone}</Label>
                                <Input
                                    type="text"
                                    name="mobilePhone"
                                    value={mobilePhone}
                                    onChange={async (e) =>{
                                        mobilePhone = e.target.value
                                        validation.mobilePhone = true
                                        validationMessage.fullname = ""
                                        this.setState({mobilePhone, validation, validationMessage})
                                    }}
                                    placeholder={label.EnterMobilePhone}
                                />
                                <ValidatorLabel message={validationMessage.mobilePhone}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    title={label.SelectRole}
                                    objects={roles}
                                    default={role?role.id:undefined}
                                    callback={(role)=>{
                                        if(role.groupName != this.state.role.groupName){
                                            company = {}
                                        }
                                        role = role
                                        validation.role = true
                                        validationMessage.role = ""
                                        this.setState({company, role, validation, validationMessage})
                                    }}
                                />
                                <ValidatorLabel message={validationMessage.role}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    {
                        (role.id && role.id!==SYSTEM_ADMIN_ID && role.id!==SUPER_ADMIN_ID)?(
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <ItemOption
                                            title={label.SelectCompany}
                                            objects={companies}
                                            default={company?company.id:undefined}
                                            fieldForLabel={"fullName"}
                                            callback={(company)=>{
                                                company = company
                                                validation.company = true
                                                validationMessage.company = ""
                                                this.setState({company, validation, validationMessage})
                                            }}
                                        />
                                        <ValidatorLabel message={validationMessage.company}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                        ):(null)
                    }
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="warning" onClick={e=>{
                        e.preventDefault()
                        this.setState({
                            modal:false
                        }, () => {
                            if(this.props.onClose!=null){
                                this.props.onClose()
                            }
                        })
                    }}>
                        {label.Cancel}
                    </Button>
                    &nbsp;
                    <Button
                        color="secondary" onClick={e=>{
                        e.preventDefault()
                        this.validateFormSubmit()
                    }}>
                        {label.Save}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

CreateUserModal.propTypes = {
    show:PropTypes.bool.isRequired,
    onClose:PropTypes.func.isRequired,
    onCreateUser:PropTypes.func.isRequired
}
