import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import BaseComponent from "../components/BaseComponent";
import {AuthConsumer} from "../utils/AuthContext";
import label from "na-utilities/src/label/Label";
import WarningLabel from "../components/Widget/WarningLabel";
import {isEmpty, isResponseOk} from "na-utilities/src/utils/Utilities";
import Endpoint from "na-utilities/src/api/Endpoint";
import AuthPage from "./AuthPage";

class ResetPasswordPage extends BaseComponent {

    constructor(props) {
        super(props);
        let validation = this.defaultValidation()
        let validationMessage = this.defaultValidationMessage()
        this.state = {
            password:"",
            confirmPassword:"",
            validation, validationMessage, tokenValid:false
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.validateToken()
    }

    defaultValidation = () =>{
        let validation = {};
        validation.password = true ;
        validation.confirmPassword = true
        return validation ;
    }

    defaultValidationMessage = () =>{
        let validationMessage = {};
        validationMessage.password = "" ;
        validationMessage.confirmPassword = ""
        return validationMessage ;
    }

    validateToken(){
        let {token} = this.props.match.params
        if(!token){
            window.history.pushState(null, null, '/');
            window.location.href = AuthPage.ENDPOINT
            return
        }
        this.get(Endpoint.API.USER_RESET_PASSWORD_VALIDATE, {params:{token:token}}, null, (response)=>{
            if(isResponseOk(response)){
                this.setState({tokenValid:true})
            }else{
                this.errorToast(response.message)
                setTimeout(()=>{
                    window.history.pushState(null, null, '/');
                    window.location.href = AuthPage.ENDPOINT
                }, 3000)
            }
        }, false, false)
    }

    submitResetPassword(){
        let {token} = this.props.match.params
        let {validation, validationMessage, password, confirmPassword } = this.state
        let form = new FormData()
        form.append("token", token);
        form.append("password", password);
        this.post(Endpoint.API.USER_RESET_PASSWORD, form, null, (res)=>{
            if(isResponseOk(res)){
                this.successToast(label.SuccessUpdatingYourPassword)
                this.setState({
                    password:"", confirmPassword:"", rememberMe:false
                },() => {
                    setTimeout(()=>{
                        window.history.pushState(null, null, '/');
                        window.location.href = AuthPage.ENDPOINT
                    }, 3000)
                })
            }
        }, true, true);
    }

    validateFormSubmit() {
        let {validation, validationMessage, password, confirmPassword,tokenValid} = this.state
        let valid = true ;
        validation = this.defaultValidation()
        validationMessage = this.defaultValidationMessage()

        if(isEmpty(password)){
            validation.password = false
            validationMessage.password = label.EnterPassword
            valid = false ;
        }
        if(isEmpty(confirmPassword)){
            validation.confirmPassword = false
            validationMessage.confirmPassword = label.EnterConfirmPassword
            valid = false ;
        }
        if(password && confirmPassword && password !== confirmPassword){
            validation.confirmPassword = false
            validationMessage.confirmPassword = label.ConfirmPasswordAndNewPasswordIsNotSame
            valid = false ;
        }
        this.setState({password, confirmPassword, validation, validationMessage}, () => {
            if(valid){
                this.submitResetPassword()
            }
        })
    }


    render() {
        let {token} = this.props.match.params

        let {message, password, confirmPassword} = this.state
        return (
            <AuthConsumer>
                {(authProps)=>(
                    <Row
                        style={{
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        {super.render()}
                        <Col md={6} lg={4}>
                            <Card>
                                <CardHeader>
                                    <h3 className="m-4">{label.ChangePassword}</h3>
                                </CardHeader>
                                <CardBody>
                                    <div className="m-4">
                                        <p>{label.RequestToChangePasswordRequiredIsExistsToEnterApplication}</p>
                                        <Form>
                                            <br/>
                                            <FormGroup>
                                                <Label for={label.Password}>{label.Password}</Label>
                                                <Input
                                                    type='text'
                                                    placeholder={label.EnterPassword}
                                                    value={password}
                                                    onChange={e=>{
                                                        this.setState({password:e.target.value})}
                                                    }/>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for={label.ConfirmPassword}>{label.ConfirmPassword}</Label>
                                                <Input
                                                    type='password'
                                                    placeholder={label.EnterConfirmPassword}
                                                    value={confirmPassword}
                                                    onChange={e=>{
                                                        this.setState({confirmPassword:e.target.value})}
                                                    }/>
                                            </FormGroup>
                                            {message?(
                                                <>
                                                    <WarningLabel style={{textAlign:'center', fontSize:'14px'}} show={message} message={message}/>
                                                </>
                                            ):undefined}
                                            <br/>
                                            <Button
                                                size="lg"
                                                block
                                                disabled={(!password || !confirmPassword) || (password.length <=2 || confirmPassword.length <= 2)}
                                                color={"secondary"}
                                                onClick={e=>{
                                                    this.validateFormSubmit()
                                                }}>
                                                {label.Submit}
                                            </Button>
                                        </Form>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </AuthConsumer>

        );
    }
}

export default Object.assign(ResetPasswordPage, {ENDPOINT: "/resetPassword"})
