import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {allIsEmpty, cloneObject, isEmpty, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import {MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import ActiveOption from '../components/Widget/ActiveOption';
import ActiveLabel from '../components/Widget/ActiveLabel';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class RegisteredRFIDReaderDevicePage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      rfidReaderTypes : [],
      rfidReaderType:{},
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }
  fetchAll = () => {
    this.get(Endpoint.API.RFID_READER_TYPES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          rfidReaderTypes : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let {rfidReaderTypes} = this.state
    let aspect = 4/4
    return (
        <Page
            title={label.RegisteredLockerDevice}
            breadcrumbs={[{ name: label.RegisteredLockerDevice, active: true }]}
            className="TablePage">
          {super.render()}
          <Row key={1}>
            <Col>
              <Card>
                <CardBody >
                  <Row>
                    <Col>
                      {label.SortBy} :
                      <UncontrolledButtonDropdown key={1}>
                        <DropdownToggle
                            caret
                            color="white"
                            className="text-capitalize m-1">
                          {
                            sortirMap(this.state.sortir)
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "type"))}>{label.Type}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                    <Col>
                      {label.Sortir} :
                      <UncontrolledButtonDropdown key={2}>
                        <DropdownToggle
                            caret
                            color="white"
                            className="text-capitalize m-1">
                          {
                            this.state.ascending?label.Ascending:label.Descending
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive hover>
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>{label.Type}</th>
                          <th>{label.Description}</th>
                          <th>{label.ChannelSize}</th>
                          <th>{label.Status}</th>
                          <th>{label.Updated}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          rfidReaderTypes.map((item, index) =>(
                              <tr key={index}>
                                <th scope="row">{++index}</th>
                                <td>{item.type}</td>
                                <td>{item.description}</td>
                                <td>{item.channelSize}</td>
                                <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.active}/></td>
                                <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                              </tr>
                          ))
                        }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}

export default Object.assign(RegisteredRFIDReaderDevicePage, {ENDPOINT : "/registeredRFIDReaderDevices"})
