import Page from 'components/Page';
import React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {MdDelete, MdEdit} from 'react-icons/md';
import ConfirmDialog from '../components/modal/ConfirmDialog';
import SearchInput from '../components/SearchInput';
import Pagination from '../components/Pagination';
import queryString from 'query-string';
import {deleteParam, isResponseOk, parseDate, sortirMap} from 'na-utilities/src/utils/Utilities';
import ActiveLabel from '../components/Widget/ActiveLabel';
import label from 'na-utilities/src/label/Label'
import ContainerModal from "../components/modal/ContainerModal";
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class ContainerPage extends BasePage{


    constructor(props) {
        super(props);
        this.state ={
            containers : [],
            container:{},
            containerModal:false,
            modalDeleteConfirm:false,
            ascending:false,
            sortir:'created',
            search:"",
            page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
            totalPage:0,
            totalElement:0,
            pageElement:0,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
    }
    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
            })
        }
    }



    fetchAll = (ascending, sortir, search, page, progressing) => {
        this.get(Endpoint.API.CONTAINERS, {
            params:{
                ascending:ascending,
                sortir:sortir,
                search:search,
                page: page-1
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    containers : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        },progressing, true);
    }

    confirmDelete = () =>{
        this.setState({
            modalDeleteConfirm:true
        })
    }

    delete = () => {
        this.setState({
            modalDeleteConfirm:false
        }, () => {
            this.get(Endpoint.API.CONTAINER_DELETE+"/"+this.state.container.id, null, null, res=>{
                if(res.code===200){
                    this.successToast(res.message)
                    this.setState({
                        category:null
                    }, () => {
                        this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
                    })
                }
            }, true, true);
        })
    }

    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false
        })
    }

    refreshContainer = (ascending, sortir, progressing) => (
        this.setState({
            ascending:ascending,
            sortir:sortir
        }, () => {
            this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, progressing)
        })
    )
    deleteContainer(container){
        let params = {
            id : container.id
        }
        this.get(Endpoint.API.CONTAINER_DELETE, {params},null, (response)=>{
            if(isResponseOk(response)){
                this.refreshContainer()
            }
        },true, true);
    }


    render() {
        let {containerModal, container} = this.state

        return (
            <Page
                title={label.Containers}
                breadcrumbs={[{ name: 'container', active: true }]}
                className="TablePage"
            >
                {super.render()}
                <ConfirmDialog
                    showing={this.state.modalDeleteConfirm}
                    title={label.DeleteConfirmationTitle}
                    message="Do you want to delete this container item from your list ?"
                    okCallback={this.delete}
                    cancelCallback={this.closeDialog}/>
                <ContainerModal
                    modal={containerModal}
                    closeCallback={()=>{
                        this.setState({container:undefined, containerModal:false})
                    }}
                    okCallback={()=>{
                        this.setState({container:undefined, containerModal:false}, () => {
                            this.refreshContainer()
                        })
                    }}
                    smartDevice={container?container.smartDevice:{}}
                    smartDeviceOption={true}
                    container={container}/>

                <Row key={1}>
                    <Col>
                        <Card className="mb-6">
                            <CardHeader>{label.Containers}</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col md={3}>
                                        {label.SortBy} :
                                        <UncontrolledButtonDropdown key={1}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    sortirMap(this.state.sortir)
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshContainer(this.state.ascending, "type", true))}>{label.Type}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshContainer(this.state.ascending, "created", true))}>{label.Created}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshContainer(this.state.ascending, "updated", true))}>{label.Updated}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={3}>
                                        {label.Sortir} :
                                        <UncontrolledButtonDropdown key={2}>
                                            <DropdownToggle
                                                caret
                                                color="white"
                                                className="text-capitalize m-1">
                                                {
                                                    this.state.ascending?label.Ascending:label.Descending
                                                }
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={e=>(this.refreshContainer(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                                <DropdownItem onClick={e=>(this.refreshContainer(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </Col>
                                    <Col md={4}>
                                        <SearchInput
                                            placeholder={label.TypeAndEnterToSearch}
                                            value={this.state.search}
                                            onChange={e=>{
                                                this.setState({
                                                    search:e.target.value
                                                }, () => {
                                                    if(this.state.search===''){
                                                        deleteParam(this.props, 'page')
                                                    }
                                                })
                                            }}
                                            onEnter={e=>{
                                                if(this.state.search!==''){
                                                    deleteParam(this.props, 'page')
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col md={2}>
                                        <ButtonGroup className="float-right">
                                            <Button color="primary" onClick={e=>{
                                                this.setState({containerModal:true})
                                            }}>{label.AddContainer}</Button>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Table responsive hover>
                                        <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{label.Code}</th>
                                            <th>{label.Label}</th>
                                            <th>{label.Type}</th>
                                            <th>{label.SmartDevice}</th>
                                            <th>{label.Station}</th>
                                            <th>{label.Status}</th>
                                            <th>{label.Created}</th>
                                            <th>{label.Updated}</th>
                                            <th>{label.Edit}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.containers.map((item, index)=>(
                                                <tr key={index}>
                                                    <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                                    <td>{item.code}</td>
                                                    <td>{item.label}</td>
                                                    <td>{item.containerType.type}</td>
                                                    <td>{item.smartDevice?item.smartDevice.name:""}</td>
                                                    <td>{item.smartDevice&&item.smartDevice.spotLocation?item.smartDevice.spotLocation.name:""}</td>
                                                    <td><ActiveLabel active={item.enabled}/></td>
                                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                                    <td>
                                                        <Button size="sm" color="danger" onClick={e => {
                                                            this.openConfirmDialog(label.DeleteConfirmationTitle, label.DeleteConfirmationMessage,
                                                                ()=>{
                                                                    this.deleteContainer(item)
                                                                }
                                                            )
                                                        }}>
                                                            <MdDelete/>
                                                        </Button>
                                                        &nbsp;
                                                        <Button size="sm" color="primary" onClick={ e => (
                                                            this.setState({containerModal:true, container:item})
                                                        )}>
                                                            <MdEdit/>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </Table>
                                </Row>
                            </CardBody>
                            <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
                        </Card>
                    </Col>
                </Row>
            </Page>
        );
    }

};


export default Object.assign(ContainerPage, {ENDPOINT : "/containers"})
