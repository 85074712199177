import * as React from 'react';
import Page from './../components/Page';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import SearchInput from '../components/SearchInput';
import Pagination from '../components/Pagination';
import * as queryString from 'query-string/index';
import {deleteParam, parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import label from 'na-utilities/src/label/Label'
import Checkbox from "../components/Checkbox";
import {Link} from "react-router-dom";
import FollowUpDetailPage from "./FollowUpDetailPage";
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];

class FollowUpPage extends BasePage{


  constructor(props) {
    super(props);
    let registrations  = new Array();
    this.state ={
      registrations : registrations,
      registration:{},
      modalDeleteConfirm:false,
      ascending:false,
      sortir:'created',
      search:"",
      page :queryString.parse(this.props.query).page?queryString.parse(this.props.query).page:1,
      totalPage:0,
      totalElement:0,
      pageElement:0,
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
  }
    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, true)
            })
        }
    }



    fetchAll = (ascending, sortir, search, page, progressing) => {
        this.get(Endpoint.API.REGISTRATIONS, {
            params:{
                ascending:ascending,
                sortir:sortir,
                search:search,
                followUp:true,
                page: page-1
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    registrations : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        },progressing, true);
    }

  confirmDelete = () =>{
    this.setState({
      modalDeleteConfirm:true
    })
  }


  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  refreshRegistration = (ascending, sortir, progressing) => (
      this.setState({
          ascending:ascending,
          sortir:sortir
      }, () => {
          this.fetchAll(this.state.ascending, this.state.sortir, this.state.search, this.state.page, progressing)
      })
  )

    render() {
        var i = 0 ;

    return (
        <Page
            title={label.FollowUp}
            breadcrumbs={[{ name: label.FollowUp, active: true }]}
            className="TablePage"
        >
            {super.render()}
          <Row key={1}>
            <Col>
              <Card className="mb-6">
                <CardHeader>{label.FollowUpList}</CardHeader>
                <CardBody>
                  <Row>
                        <Col md={4}>
                            {label.SortBy} :
                            <UncontrolledButtonDropdown key={1}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        sortirMap(this.state.sortir)
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshRegistration(this.state.ascending, "created", true))}>{label.Created}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshRegistration(this.state.ascending, "firstname", true))}>Firstname</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshRegistration(this.state.ascending, "email", true))}>{label.Email}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshRegistration(this.state.ascending, "mobilePhone", true))}>{label.MobilePhone}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={4}>
                            {label.Sortir} :
                            <UncontrolledButtonDropdown key={2}>
                                <DropdownToggle
                                    caret
                                    color="white"
                                    className="text-capitalize m-1">
                                    {
                                        this.state.ascending?label.Ascending:label.Descending
                                    }
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={e=>(this.refreshRegistration(true, this.state.sortir, true))}>{label.Ascending}</DropdownItem>
                                    <DropdownItem onClick={e=>(this.refreshRegistration(false, this.state.sortir, true))}>{label.Descending}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </Col>
                        <Col md={4}>
                            <SearchInput
                                placeholder={label.TypeAndEnterToSearch}
                                value={this.state.search}
                                onChange={e=>{
                                    this.setState({
                                        search:e.target.value
                                    }, () => {
                                        if(this.state.search===''){
                                            deleteParam(this.props, 'page')
                                        }
                                    })
                                }}
                                onEnter={e=>{
                                    if(this.state.search!==''){
                                        deleteParam(this.props, 'page')
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                  <Row>
                      <Table hover>
                          <thead>
                          <tr>
                              <th>#</th>
                              <th>{label.Fullname}</th>
                              <th>{label.Email}</th>
                              <th>{label.MobilePhone}</th>
                              <th>{label.CompanyName}</th>
                              <th>{label.Verified}</th>
                              <th>{label.Created}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                              this.state.registrations.map((item, index)=>(
                                  <tr key={index}>
                                      <th scope="row">{((this.state.page-1)* this.state.pageElement)+(++index)}</th>
                                      <td>
                                          <Link to={FollowUpDetailPage.ENDPOINT+"?id="+item.id}>
                                              {item.fullname}
                                          </Link>
                                      </td>
                                      <td>{item.email}</td>
                                      <td>{item.mobilePhone}</td>
                                      <td>{item.companyName}</td>
                                      <td>
                                          <Checkbox checked={item.verified}/>
                                      </td>
                                      <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                  </tr>
                              ))
                          }
                          </tbody>
                      </Table>
                  </Row>
                </CardBody>
                  <Pagination {...this.props} currentPage={parseInt(this.state.page)} pageCount={this.state.totalPage} />
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }

};
export default Object.assign(FollowUpPage, {ENDPOINT : "/followUp"})

