import React from 'react';
import BasePage from './BasePage';
import Page from '../components/Page';
import {DD_MM_YYYY, DD_MM_YYYY_HH_MM_SS, DEFAULT_LOCATION} from '../utils/Global';
import queryString from 'query-string';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import label from 'na-utilities/src/label/Label';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table,} from 'reactstrap';
import {allIsEmpty, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import MyCardImage from "../components/Card/MyCardImage";
import userProfilePicture from "../assets/img/users/user_pp.png";
import {IoMdEye, IoMdClock} from "react-icons/all";
import CompanyDetailPage from "./CompanyDetailPage";
import Endpoint from "na-utilities/src/api/Endpoint";
import ProductSubscribeDetailPage from "./ProductSubscribeDetailPage";
import SmartDeviceDetailPage from "./SmartDeviceDetailPage";
import ExtendProductOrderInquiryModal from "../components/modal/ExtendProductOrderInquiryModal";


class CompanySubscribeListPage extends BasePage{
    successToast(message) {
        super.successToast(message);
    }

    constructor(props) {
        super(props);
        let state = this.state
        state.company = {}
        state.orderItems = []
        state.productSubscribes = []
        state.companyId = queryString.parse(this.props.query).id
        state.ascending = true
        state.sortir = 'created'
        state.extendProductOrderInquiryModal = false
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        let {companyId} = this.state
        this.fetchCompanyDetail(companyId)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            })
        }
    }

    fetchCompanyDetail = (id) => {
        if(id!=null){
            this.get(Endpoint.API.COMPANY, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let company = response.data
                    // let company = company.company
                    if(!company.latitude || !company.longitude){
                        company.latitude = DEFAULT_LOCATION.latitude
                        company.longitude = DEFAULT_LOCATION.longitude
                    }

                    let village = company&&company.village?company.village:null
                    let district = company&&company.village?company.village.district:null
                    let city = company&& company.village?company.village.district.city:null
                    let province = company&&company.village?company.village.district.city.province:null

                    this.setState({
                        company:company,
                        village:village,
                        district:district,
                        city:city,
                        province:province,
                    }, () => {
                        this.fetchProductSubscribes()
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }

    fetchProductSubscribes = () => {
        let {companyId} = this.state
        this.get(Endpoint.API.PRODUCT_SUBSCRIBES, {
            params:{
                companyId:companyId,
            }
        }, null, res=>{
            if(res.code === 200){
                this.setState({
                    productSubscribes : res.data,
                    totalPage: res.totalPage,
                    totalElement: res.totalElement,
                    pageElement: res.pageElement,
                })
            }
        }, true, true);
    }



    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }


    renderProductSubscribeTable = () => {
        let {productSubscribes} = this.state
        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col>
                            {label.SubscriptionList}
                        </Col>
                        <Col className="text-right">
                            <Button
                                size="sm"
                                onClick={() => {
                                    this.setState({extendProductOrderInquiryModal:true})
                                }}>
                                <IoMdClock/>&nbsp;&nbsp;{label.ExtendPeriod}
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Table responsive hover>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>{label.Code}</th>
                            <th>{label.SmartDevice}</th>
                            <th>{label.ProductPackage}</th>
                            <th>{label.ValidFrom}</th>
                            <th>{label.ValidUntil}</th>
                            <th>{label.Status}</th>
                            <th>{label.Created}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            productSubscribes.map((item, index)=>(
                                <tr key={index}>
                                    <th scope="row">{index+1}</th>
                                    <td><a href={ProductSubscribeDetailPage.ENDPOINT+"?id="+(item.id)}>{item.code}</a></td>
                                    <td><a href={SmartDeviceDetailPage.ENDPOINT+"?id="+(item.smartDevice?item.smartDevice.id:undefined)}>{item.smartDevice.deviceId}</a></td>
                                    <td>{item.productPackage.name}</td>
                                    <td>{parseDate(item.startDate, DD_MM_YYYY)}</td>
                                    <td>{parseDate(item.endDate, DD_MM_YYYY)}</td>
                                    <td><span style={{color:item.productSubscribeStatus.color}}>{item.productSubscribeStatus.label}</span></td>
                                    <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        )
    }

    render() {
        let {company, productSubscribes, extendProductOrderInquiryModal} = this.state
        let user = company.creator
        return (
            <>
                <ExtendProductOrderInquiryModal
                    show={extendProductOrderInquiryModal}
                    company={company}
                    productSubscribes={productSubscribes}
                    onClose={() => {
                        this.setState({extendProductOrderInquiryModal:false})
                    }}
                    onCreateOrder={(productOrder) => {
                        this.setState({extendProductOrderInquiryModal:false})
                    }}/>
                <Page
                    title={label.CompanySubscription}
                    breadcrumbs={[{ name: label.CompanySubscription, active: true }]}>
                    {super.render()}
                    <Card>
                        <CardHeader>{label.CompanySubscription}</CardHeader>
                        <CardBody>
                            <Row>
                                <Col md={3}>
                                    <Card>
                                        <CardHeader>{label.Company}</CardHeader>
                                        <CardBody>
                                            <MyCardImage top src={
                                                company.imageUrl!=null?company.imageUrl:userProfilePicture}
                                                         onClick={e=>{
                                                             if(!allIsEmpty(user, user.id)) {
                                                                 this.openLightBoxSingleImage(company.imageUrl)
                                                             }
                                                         }}
                                                         onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                        </CardBody>
                                        <CardBody>
                                            <Row>
                                                <Col md={10}>
                                                    <FormGroup>
                                                        <Label for="fullname">{label.Fullname}</Label>
                                                        <Input name={"fullName"} value={company.fullName} disabled/>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={2}>
                                                    <FormGroup>
                                                        <Label for="fullname">&nbsp;&nbsp;</Label>
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                navigate(this.props, CompanyDetailPage.ENDPOINT+"?id="+user.id)
                                                            }}>
                                                            <IoMdEye/>
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="email">{label.Email}</Label>
                                                        <Input name={"email"} value={company.email} disabled/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="mobilePhone">{label.PhoneNumber}</Label>
                                                        <Input name={"companyPhoneNumber"} value={company.companyPhoneNumber} disabled/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={9}>
                                    <Row>
                                        <Col>
                                            {this.renderProductSubscribeTable()}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Page>
            </>
        );
    }
}
export default Object.assign(CompanySubscribeListPage, {ENDPOINT : "/companySubscribeList"})