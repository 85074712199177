import React from 'react';
import {Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import queryString from "query-string";
import BaseComponent from "../components/BaseComponent";
import {AuthConsumer} from "../utils/AuthContext";
import label from "na-utilities/src/label/Label";
import WarningLabel from "../components/Widget/WarningLabel";
import {isEmpty, isResponseOk, isValidEmail} from "na-utilities/src/utils/Utilities";
import Endpoint from "na-utilities/src/api/Endpoint";
import {PLATFORM} from "../utils/Global";
import AuthPage from "./AuthPage";

class ForgetPasswordPage extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            message : queryString.parse(this.props.query).message,
            email : ""
        }
    }

    submit(){
        let {email, message} = this.state
        let valid = true ;
        if(isEmpty(email)){
            message = label.EnterEmail
            valid = false ;
        }
        if(email && !isValidEmail(email)){
            message = label.InvalidEmailAddress
            valid = false ;
        }
        this.setState({email, message},() => {
            if(valid){
                let form = new FormData()
                form.append("email",email)
                form.append("platform",PLATFORM)
                this.post(Endpoint.API.USER_FORGET_PASSWORD, form, null, res => {
                    if(isResponseOk(res)){
                        this.successToast(res.message)
                    }
                    window.history.pushState(null, null, '/');
                    setTimeout(()=>{
                        window.location.href = AuthPage.ENDPOINT
                    }, 3000)
                }, true, true);

            }
        })
    }


    render() {

        let {message, email} = this.state
        return (
            <AuthConsumer>
                {(authProps)=>(
                    <Row
                        style={{
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        {super.render()}
                        <Col md={6} lg={4}>
                            <Card>
                                <CardHeader>
                                    <h3 className="m-4">{label.ForgetPassword}</h3>
                                </CardHeader>
                                <CardBody>
                                    <div className="m-4">
                                        <p>{label.WeWillSendYourPasswordOnForgetPasswordMessage}</p>
                                        <Form>
                                            <br/>
                                            <FormGroup>
                                                <Label for={label.Email}>{label.Email}</Label>
                                                <Input
                                                    type='email'
                                                    placeholder={label.EnterEmail}
                                                    value={email}
                                                    onChange={e=>{
                                                        this.setState({email:e.target.value})}
                                                    }/>
                                            </FormGroup>
                                            {message?(
                                                <>
                                                    <WarningLabel style={{textAlign:'center', fontSize:'14px'}} show={message} message={message}/>
                                                </>
                                            ):undefined}
                                            <br/>
                                            <Button
                                                size="lg"
                                                block
                                                disabled={!email || email.length<=3 || !isValidEmail(email)}
                                                color={"secondary"}
                                                onClick={e=>{
                                                    this.submit()
                                                }}>
                                                {label.Submit}
                                            </Button>
                                        </Form>

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </AuthConsumer>

        );
    }
}

export default Object.assign(ForgetPasswordPage, {ENDPOINT: "/forgetPassword"})
