import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'reactstrap';
import {parseDate} from 'na-utilities/src/utils/Utilities';
import moment from 'moment';

export default class DateInput extends React.Component{

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({})
        }

    }


    render() {
        let props = this.props
        return (
            <Input {...props}
                id={props.id}
                type="date"
                max={props.maxdate?parseDate(props.maxdate, "yyyy-mm-dd"):""}
                min={props.mindate?parseDate(props.mindate, "yyyy-mm-dd"):""}
                value={props.value?moment(props.value).format("YYYY-MM-DD"):""}
                onChange={(e) =>{
                    if(props.onChange){
                        props.onChange(e)
                    }
                }}
                placeholder={props.placeholder}
            />
        );
    }
}

DateInput.propTypes = {
    id:PropTypes.string,
    maxdate:PropTypes.any,
    mindate:PropTypes.any,
    value:PropTypes.any,
    placeholder:PropTypes.string,
    onChange:PropTypes.func

}
