import * as React from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import BaseComponent from '../BaseComponent';
import * as PropTypes from 'prop-types';
import { CabinetContainer } from '../../model/formbuilder/CabinetContainer.js';
import ItemOption from '../Widget/ItemOption';
import { Option } from '../../model/formbuilder/Option';
import { FormType } from '../../model/formbuilder/FormType';
import Global from '../../utils/Global';
import { getData } from '../../utils/StorageUtil';
import Card from 'reactstrap/es/Card';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import { QueryParam } from '../../model/formbuilder/QueryParam';
import WarningLabel from '../Widget/WarningLabel';
import { MdAdd, MdDelete } from 'react-icons/md';
import label from 'na-utilities/src/label/Label'
import Checkbox from "../Checkbox";

import { cloneDeep } from "lodash"
import NumberOption from "../Widget/NumberOption";


/*= (message, okCallback, cancelCallback) =>*/

const dataTags = new Object();
export default class CabinetContainerModal extends BaseComponent {

    props = {}

    validator = {
        tag: "",
        title: "'",
        placeHolder: "",
        type: "",
        fetchApi: "",
        queryParams: "",
        imageDirectory: "",
        positiveValue: "",
        negativeValue: "",
    }



    mandatoryOptions = [
        { id: 1, name: false },
        { id: 2, name: true }
    ]
    hidePrintOutOptions = [
        { id: 1, name: false },
        { id: 2, name: true }
    ]


    constructor(props) {
        super(props);
        let form = this.props.form ? this.props.form : new CabinetContainer()
        let modeCreate = false;
        let previousTag = "";
        let lanes = this.props.lanes ? this.props.lanes : []
        let lane = (!this.props.lane ? (lanes && lanes.length > 0 ? lanes[0] : null) : this.props.lane)
        if (!form.id) {
            modeCreate = true;
            form.id = new Date().getTime()
        } else {
            modeCreate = false;
            previousTag = form.tag
        }
        if (modeCreate) {
            lanes.forEach((lane1, laneIndex) => {
                if (lane1.id == lane.id) {
                    form.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                }
            })
        }

        this.state = {
            diffLane: false,
            cabinet: this.props.cabinet?this.props.cabinet:{},
            changePosition: false,
            board: this.props.board,
            modal: this.props.showing,
            onAdd: this.props.onAdd,
            close: this.props.close,
            form: form,
            position: cloneDeep(form),
            original: cloneDeep(form),
            lanes: lanes,
            lane: lane,
            previousTag: previousTag,
            mandatoryOption: this.mandatoryOptions[1],
            hidePrintOutOption: this.hidePrintOutOptions[0],
            validator: this.validator,
            cardSelected: this.props.lanes && Object.keys(this.props.lanes).length > 0 ? this.props.lanes[0] : []
        }
        this.buttonClick = this.buttonClick.bind(this)
    }

    componentWillReceiveProps(props, nextContext) {
        if (this.props.showing != props.showing && props.showing === true) {
            let form = props.form ? props.form : new CabinetContainer();
            let modeCreate = false;
            let previousTag = "";
            if (!form.id) {
                modeCreate = true;
                form.id = new Date().getTime()
            } else {
                modeCreate = false;
                previousTag = form.tag
            }
            let lanes = props.lanes ? props.lanes : []
            let lane = (!props.lane ? (lanes && lanes.length > 0 ? lanes[0] : null) : props.lane)
            if (modeCreate) {
                lanes.forEach((lane1, laneIndex) => {
                    if (lane1.id == lane.id) {
                        form.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                    }
                })
            }
            let mandatoryOption = this.mandatoryOptions.find(value => {
                if (value.name === form.mandatory) {
                    return value;
                }
            })
            let hidePrintOutOption = this.hidePrintOutOptions.find(value => {
                if (value.name === form.hidePrintOut) {
                    return value;
                }
            })
            let cabinet = props.cabinet?props.cabinet:{}
            let board = props.board

            let cabinetLockerTypes = cabinet.cabinetLockerTypes?cabinet.cabinetLockerTypes:[]
            let lockerTypes = []
            cabinetLockerTypes.forEach((item, index)=>{
                lockerTypes.push(item.lockerType)
            })
            if(lockerTypes.length>0){
                form.lockerType = lockerTypes[0]
            }
            if(!form.lockerParallelPosition){
                form.lockerParallelPosition = 1
            }
            if(!form.lockerPosition){
                let lockerPositions = this.availableLockerPositions(board, form.lockerType, form.lockerParallelPosition);
                if(lockerPositions.length>0){
                    form.lockerPosition = lockerPositions[0]
                }
            }

            let cabinetRFIDReaderTypes = cabinet.cabinetRFIDReaderTypes?cabinet.cabinetRFIDReaderTypes:[]
            let rfidReaderTypes = []
            cabinetRFIDReaderTypes.forEach((item, index)=>{
                rfidReaderTypes.push(item.rfidReaderType)
            })
            if(rfidReaderTypes.length>0){
                form.rfidReaderType = rfidReaderTypes[0]
            }
            if(!form.rfidReaderParallelPosition){
                form.rfidReaderParallelPosition = 1
            }
            if(!form.rfidReaderPosition){
                let rfidReaderPositions = this.availableRfidReaderPositions(board, form.rfidReaderType, form.rfidReaderParallelPosition);
                if(rfidReaderPositions.length>0){
                    form.rfidReaderPosition = rfidReaderPositions[0]
                }
            }



            this.setState({
                diffLane: false,
                cabinet: cabinet,
                board: board,
                modal: props.showing,
                form: form,
                position: cloneDeep(form),
                original: cloneDeep(form),
                lanes: lanes,
                lane: lane,
                previousTag: previousTag,
                mandatoryOption: mandatoryOption ? mandatoryOption : this.mandatoryOptions[1],
                hidePrintOutOption: hidePrintOutOption ? hidePrintOutOption : this.hidePrintOutOptions[0],
                validator: this.validator,
                close: props.close,
                cardSelected: this.props.lanes && Object.keys(this.props.lanes).length > 0 ? this.props.lanes[0] : []
            })
        }
        else {
            let form = new CabinetContainer()
            this.setState({form})
        }
    }

    buttonClick(e, button) {
        e.preventDefault()
        if (button === label.Ok) {
            if (this.props.okCallback != null) {
                this.props.okCallback()
            }
        } else {
            if (this.props.cancelCallback != null) {
                this.props.cancelCallback()
            }
        }
    }

    close = () => {
        this.setState({
            modal: false,
        }, () => {
            this.props.close()
        })
    }

    onClickOk() {
        let { changePosition, form, position, board, lane,
            mandatoryOption, hidePrintOutOption, validator, diffLane, original } = this.state
        let print = {
            form, lane, mandatoryOption
        }
        if (!form.tag) {
            validator.tag = label.PleaseEnterFormTag
            this.setState({ validator: validator })
            return;
        }
        if (!form.lockerType) {
            validator.lockerType = label.PleaseSelectLockerType
            this.setState({ validator: validator })
            return;
        }
        if (!form.lockerParallelPosition) {
            validator.lockerParallelPosition = label.PleaseSelectParallelPosition
            this.setState({ validator: validator })
            return;
        }
        if (!form.lockerPosition) {
            validator.lockerPosition = label.PleaseSelectLockerPosition
            this.setState({ validator: validator })
            return;
        }

        if (!form.rfidReaderType) {
            validator.rfidReaderType = label.PleaseSelectRFIDReaderType
            this.setState({ validator: validator })
            return;
        }
        if (!form.rfidReaderParallelPosition) {
            validator.rfidReaderParallelPosition = label.PleaseSelectParallelPosition
            this.setState({ validator: validator })
            return;
        }
        if (!form.rfidReaderPosition) {
            validator.rfidReaderPosition = label.PleaseSelectRfidChannelPosition
            this.setState({ validator: validator })
            return;
        }


        if (!form.title) {
            validator.title = label.PleaseEnterAFormTitle
            this.setState({ validator: validator })
            return;
        }

        if (!form.placeHolder) {
            validator.placeHolder = label.PleaseEnterPlaceHolder
            this.setState({ validator: validator })
            return;
        }

        form.mandatory = mandatoryOption.name
        form.hidePrintOut = hidePrintOutOption.name

        if (this.props.onAdd) {
            if (changePosition) {
                form.tag = original.tag
                const originalTag = form.tag;
                const targetTag = position.tag;
                const [originalLane, originalIndex] = form.tag.split(".");
                const [targetLane, targetIndex] = position.tag.split(".");

                const newBoard = board;
                const boardOnLane = newBoard.lanes[Number(originalLane) - 1];
                const targetCard = boardOnLane.cards.findIndex(it => it.tag === targetTag);
                const originalCard = boardOnLane.cards.find(it => it.tag === originalTag);
                boardOnLane.cards.splice(Number(originalIndex) - 1, 1);

                if (!diffLane) {
                    // boardOnLane.cards.splice(Number(targetIndex) - 1, 0, originalCard);
                    form.lane = lane
                    boardOnLane.cards.splice(Number(targetIndex) - 1, 0, form);
                    boardOnLane.cards.forEach((it, index) => {
                        it.id = index + 1;
                        it.tag = `${originalLane}.${index + 1}`
                    })
                } else {
                    const boardOnLaneDiff = newBoard.lanes[Number(targetLane) - 1];
                    // boardOnLaneDiff.cards.splice(Number(targetIndex) - 1, 0, originalCard);
                    form.lane = lane
                    boardOnLaneDiff.cards.splice(Number(targetIndex) - 1, 0, form);
                    boardOnLaneDiff.cards.forEach((it, index) => {
                        it.id = index + 1;
                        it.tag = `${targetLane}.${index + 1}`
                    })
                }
                this.props.onAdd(form, lane, newBoard)
            } else {
                this.props.onAdd(form, lane)
            }


            this.close()
        }

    }

    availableLockerPositions(board, lockerType, parallelPosition){
        let {cabinet, lanes, form} = this.state
        let positions = []
        if(lockerType && board){
            let channelSize = lockerType.channelSize
            for (let i = 0; i < channelSize; i++) {
                let position = i+1;
                let used = false ;
                board.lanes.forEach((lane, indexLane)=>{
                    let cards = lane.cards
                    cards.forEach((card, indexCard)=>{
                        if(card.lockerType && (card.lockerType.id === lockerType.id)){
                            if(card.lockerParallelPosition === parallelPosition){
                                if(card.lockerPosition === position || !card.lockerPosition){
                                    if(!form.lockerPosition || form.lockerPosition !== position){
                                        used = true ;
                                    }
                                }
                            }
                        }
                    })
                })
                if(!used){
                    positions.push(position)
                }
            }
        }
        return positions;
    }

    availableLockerParallelPositions(board, lockerType){
        let {cabinet} = this.state
        let positions = []
        if(cabinet && cabinet.cabinetLockerTypes && cabinet.cabinetLockerTypes.length>0 && lockerType){
            cabinet.cabinetLockerTypes.forEach((item, index)=>{
                if(lockerType && item.lockerType.id === lockerType.id) {
                    let parallelSize = item.parallelSize
                    for (let i = 0; i < parallelSize; i++) {
                        positions.push(i + 1)
                    }
                }
            })
        }else {
            positions = [1]
        }

        return positions;
    }

    availableRfidReaderPositions(board, rfidReaderType, parallelPosition){
        let {cabinet, lanes, form} = this.state
        let positions = []
        if(rfidReaderType && board){
            let channelSize = rfidReaderType.channelSize
            for (let i = 0; i < channelSize; i++) {
                let position = i+1;
                let used = false ;
                board.lanes.forEach((lane, indexLane)=>{
                    let cards = lane.cards
                    cards.forEach((card, indexCard)=>{
                        if(card.rfidReaderType && (card.rfidReaderType.id === rfidReaderType.id)){
                            if(card.rfidReaderParallelPosition === parallelPosition){
                                if(card.rfidReaderPosition === position || !card.rfidReaderPosition){
                                    if(!form.rfidReaderPosition || form.rfidReaderPosition !== position){
                                        used = true ;
                                    }
                                }
                            }
                        }
                    })
                })
                if(!used){
                    positions.push(position)
                }
            }
        }
        return positions;
    }

    availableRfidReaderParallelPositions(board, rfidReaderType){
        let {cabinet} = this.state
        let positions = []
        if(cabinet && cabinet.cabinetRFIDReaderTypes && cabinet.cabinetRFIDReaderTypes.length>0 && rfidReaderType){
            cabinet.cabinetRFIDReaderTypes.forEach((item, index)=>{
                if(rfidReaderType && item.rfidReaderType.id === rfidReaderType.id) {
                    let parallelSize = item.parallelSize
                    for (let i = 0; i < parallelSize; i++) {
                        positions.push(i + 1)
                    }
                }
            })
        }else {
            positions = [1]
        }

        return positions;
    }


    render() {

        let configuration = getData(Global.CONFIGURATION)
        if (!configuration) {
            configuration = {}
        }
        let { form, position, lanes, lane, diffLane, cabinet } = this.state
        let pageOptions = new Array();
        if (!lanes) {
            lanes = new Array()
        }
        if (!lane) {
            lane = {}
        }
        let validator = this.state.validator

        lanes.forEach(value => {
            let pageOption = new Option();
            pageOption.id = value.id
            pageOption.name = value.title
            pageOptions.push(pageOption)
        })

        let board = this.state.board
        if (!board) {
            board = {}
        }
        let cardSelected = []
        if (lane && lanes && Object.keys(lanes).length > 0) {
            lanes.map((value) => {
                if (value && value.cards && Object.keys(value.cards).length > 0 && value.id == lane.id) {
                    cardSelected = value.cards

                    cardSelected.forEach((it, index) => {
                        const [originalLane, originalIndex] = it.tag.split(".");
                        it.tag = `${originalLane}.${index + 1}`
                    })

                    if (diffLane || position.tag ) {
                        let notExist =  !cardSelected.find(it => it.tag === position.tag )
                        if(notExist){
                            cardSelected.push(position)
                        }

                        cardSelected.map((value) => {
                            dataTags[value.tag] = value;
                        })
                    }
                 
                }else{
                    let notExist =  !cardSelected.find(it => it.tag === position.tag )
                    if(notExist){
                        cardSelected.push(position)
                    }

                    cardSelected.map((value) => {
                        dataTags[value.tag] = value;
                    })
                }
            });
        }


        let mandatoryOption = this.state.mandatoryOption
        let hidePrintOutOption = this.state.hidePrintOutOption

        let cabinetLockerTypes = cabinet.cabinetLockerTypes?cabinet.cabinetLockerTypes:[]
        let lockerTypes = []
        cabinetLockerTypes.forEach((item, index)=>{
            lockerTypes.push(item.lockerType)
        })

        let cabinetRFIDReaderTypes = cabinet.cabinetRFIDReaderTypes?cabinet.cabinetRFIDReaderTypes:[]
        let rfidReaderTypes = []
        cabinetRFIDReaderTypes.forEach((item, index)=>{
            rfidReaderTypes.push(item.rfidReaderType)
        })

        return (
            /*xl-lg-sm*/
            <Modal
                style={{ minWidth: '60%' }}
                size="xl"
                centered={true}
                isOpen={this.state.modal}
                backdrop={true}>
                {super.render()}
                <ModalHeader>
                    {label.ContainerInputConfiguration}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">{label.CabinetRack}</Label>
                                <ItemOption
                                    type="text"
                                    name="name"
                                    default={lane.id}
                                    objects={pageOptions}
                                    hideLabel={true}
                                    hideOptionLabel={true}
                                    callback={(e) => {
                                        let { original, form, position } = this.state
                                        let diffLane = false;
                                        lanes.forEach((lane1, laneIndex) => {
                                            if (lane1.id == e.id) {
                                                diffLane = true;
                                                // form.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                                                position.tag = (laneIndex + 1) + "." + (lane1.cards.length + 1)
                                            } else if (form.lane && form.lane.id == e.id) {
                                                form.tag = this.state.previousTag
                                            }
                                        })
                                        this.setState({
                                            diffLane: diffLane,
                                            lane: e,
                                            form: form,
                                            position: position
                                        })
                                    }}
                                    placeholder={label.EnterFormPage}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="name">{label.Tag}</Label>
                                <Input
                                    type="select"
                                    id="input1-group2"
                                    name="input1-group2"
                                    value={position.tag ? position.tag : ""}
                                    onChange={(e) => {
                                        if (position) {
                                            this.setState({
                                                position: dataTags[e.target.value],
                                                board: board,
                                                changePosition: true
                                            })
                                        }
                                    }}

                                >
                                    {cardSelected && Object.keys(cardSelected).length > 0 ?
                                        cardSelected.map((item) => (
                                            <option value={item.tag}>{item.tag}</option>
                                        )) :
                                        (
                                            <option value={""}></option>
                                        )
                                    }
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    hideOptionLabel={true}
                                    title={label.LockerDevice}
                                    fieldForLabel={"name"}
                                    hideLabel={false}
                                    default={form.lockerType}
                                    disable={true}
                                    callback={(lockerType) => {
                                        form.lockerType = lockerType
                                        validator.lockerType = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    objects={lockerTypes}/>
                                <WarningLabel show={validator.lockerType} message={validator.lockerType} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <NumberOption
                                    hideOptionLabel={true}
                                    title={label.LockerDeviceParallelPosition}
                                    hideLabel={false}
                                    default={form.lockerParallelPosition}
                                    callback={(lockerParallelPosition) => {
                                        form.lockerParallelPosition = parseInt(lockerParallelPosition)
                                        validator.lockerParallelPosition = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    numbers={this.availableLockerParallelPositions(board, form.lockerType)}/>
                                <WarningLabel show={validator.lockerParallelPosition} message={validator.lockerParallelPosition} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <NumberOption
                                    hideOptionLabel={true}
                                    title={label.LockerPosition}
                                    hideLabel={false}
                                    default={form.lockerPosition}
                                    callback={(lockerPosition) => {
                                        form.lockerPosition = parseInt(lockerPosition)
                                        validator.lockerPosition = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    numbers={this.availableLockerPositions(board, form.lockerType, form.lockerParallelPosition)}/>
                                <WarningLabel show={validator.lockerPosition} message={validator.lockerPosition} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    hideOptionLabel={true}
                                    title={label.RFIDReaderDevice}
                                    fieldForLabel={"name"}
                                    hideLabel={false}
                                    default={form.rfidReaderType}
                                    disable={true}
                                    callback={(rfidReaderType) => {
                                        form.rfidReaderType = rfidReaderType
                                        validator.rfidReaderType = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    objects={rfidReaderTypes}/>
                                <WarningLabel show={validator.rfidReaderType} message={validator.rfidReaderType} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <NumberOption
                                    hideOptionLabel={true}
                                    title={label.RFIDReaderParallelPosition}
                                    hideLabel={false}
                                    default={form.rfidReaderParallelPosition}
                                    callback={(rfidReaderParallelPosition) => {
                                        form.rfidReaderParallelPosition = parseInt(rfidReaderParallelPosition)
                                        validator.rfidReaderParallelPosition = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    numbers={this.availableRfidReaderParallelPositions(board, form.rfidReaderType)}/>
                                <WarningLabel show={validator.rfidReaderParallelPosition} message={validator.rfidReaderParallelPosition} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <NumberOption
                                    hideOptionLabel={true}
                                    title={label.RFIDChannelPosition}
                                    hideLabel={false}
                                    default={form.rfidReaderPosition}
                                    callback={(rfidReaderPosition) => {
                                        form.rfidReaderPosition = parseInt(rfidReaderPosition)
                                        validator.rfidReaderPosition = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    numbers={this.availableRfidReaderPositions(board, form.rfidReaderType, form.rfidReaderParallelPosition)}/>
                                <WarningLabel show={validator.rfidReaderPosition} message={validator.rfidReaderPosition} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="name">{label.Title}</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={form.title ? form.title : ""}
                                    onChange={(e) => {
                                        form.title = e.target.value
                                        validator.title = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    placeholder={label.EnterFormTitle}
                                />
                                <WarningLabel show={validator.title} message={validator.title} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="name">{label.Placeholder}</Label>
                                <Input
                                    type="textarea"
                                    name="name"
                                    rows={5}
                                    value={form.placeHolder ? form.placeHolder : ""}
                                    onChange={(e) => {
                                        form.placeHolder = e.target.value
                                        validator.placeHolder = ""
                                        this.setState({
                                            form: form,
                                            validator: validator
                                        })
                                    }}
                                    placeholder={label.EnterPlaceholder}
                                />
                                <WarningLabel show={validator.placeHolder} message={validator.placeHolder} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={e => {
                        this.onClickOk()
                    }}>
                        {label.Ok}
                    </Button>{' '}
                    <Button color="secondary" onClick={e => (this.close())}>
                        {label.Cancel}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

CabinetContainerModal.propTypes = {
    showing: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired,
    lanes: PropTypes.array,
    lane: PropTypes.any,
    form: PropTypes.any,
    cabinet:PropTypes.any
}
