import React, {Component} from "react";
import {compose, withProps} from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker, InfoWindow
} from "react-google-maps";
import PropTypes from "prop-types";
import {DEFAULT_LOCATION} from "../../utils/Global";

const containerStyle = {
    width: '100%',
    height: '400px'
};

const center = {
    lat: -3.745,
    lng: -38.523
};

const ref = React.createRef();

const MyMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyB20CGaWmLTJJxGsJGah9yX7XGqwQGHGjI&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `500px` }} />,
        mapElement: <div className={"google-map"} style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    {
        console.log("Lat : "+props.latitude+" "+"Lng : "+props.longitude )
        let {disabled} = props
        return(
            <GoogleMap
                defaultZoom={8}
                defaultCenter={{ lat: props.latitude, lng: props.longitude }}
                center={{ lat: props.latitude, lng: props.longitude }}
                ref={ref}
                onDragEnd={() => {
                    if(!disabled){
                        props.onGestureExecuting(ref)
                    }
                }}
                onZoomChanged={() => {
                    props.onGestureExecuting(ref)
                }}
            >
                {props.isMarkerShown && <Marker position={{ lat: props.latitude, lng: props.longitude }} ></Marker>}
            </GoogleMap>
        )
    }
)

export class MapContainer extends React.Component {

    state = {}
    constructor(props) {
        super(props);
        let state = this.state
        state.selectedPlace = {}
        state.map = {}
        state.latitude = this.props.latitude
        state.longitude = this.props.longitude
        this.setState(state)
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(nextProps!=this.props){
            let state = this.state
            state.latitude = nextProps.latitude
            state.longitude = nextProps.longitude
            this.setState(state)
        }
    }


    componentDidMount() {
        let {latitude, longitude} = this.state
        if(latitude===DEFAULT_LOCATION.latitude && longitude===DEFAULT_LOCATION.longitude){
            this.initLocation(this)
        }
    }

    initLocation() {
        const location = window.navigator && window.navigator.geolocation

        if (location) {
            window.navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                })
            }, (error) => {
                this.setState({ latitude: -3.745, longitude: -38.523 })
            })
        }
    }

    render() {
        let {latitude, longitude, map} = this.state
        let {disabled} = this.props
        return (
            <MyMapComponent
                isMarkerShown
                latitude={latitude}
                longitude={longitude}
                disabled={disabled}
                onGestureExecuting={(ref)=>{
                    let latLng = ref.current.getCenter()
                    latitude = latLng.lat()
                    longitude = latLng.lng()
                    this.setState({latitude, longitude}, () => {
                        this.props.onGestureExecuting(latitude, longitude)
                    })
                }}/>

        );
    }
}

MapContainer.propTypes = {
    onGestureExecuting:PropTypes.func,
    disabled:PropTypes.bool,
    latitude:PropTypes.number,
    longitude:PropTypes.number
}

