import React from 'react';

import {Nav, Navbar, NavItem} from 'reactstrap';
import label from 'na-utilities/src/label/Label'

const Footer = () => {
  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
            2023 <span className="copyleft" style={{display:'inline-block', transform:'rotate(180deg)'}}>&copy;</span> <a href="https://numeralasia.com" target="_blank">{label.AppName}</a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
