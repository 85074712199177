import Page from 'components/Page';
import React from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import {parseDate, sortirMap} from "na-utilities/src/utils/Utilities";
import ActiveLabel from '../components/Widget/ActiveLabel';
import axios from 'axios';
import ImageCropper from '../components/modal/ImageCropper';
import label from 'na-utilities/src/label/Label'
import Image from "../components/Widget/Image";
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class ProductCategoryPage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      productCategories : [],
      productCategory:{},
      modalAddConfirm:false,
      modalDeleteConfirm:false,
      image : undefined,
      modal: false,
      ascending:true,
      sortir:'created',
    }
  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  closeDialog = () =>{
    this.setState({
      modalAddConfirm:false,
      modalDeleteConfirm:false
    })
  }

  fetchAll = () => {
    this.get(Endpoint.API.PRODUCT_CATEGORIES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        this.setState({
          productCategories : response.data
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )


  render() {
    let aspect = 4/4
    let {image} = this.state

    return (
        <Page
            title={label.ProductCategory}
            breadcrumbs={[{ name: label.ProductCategory, active: true }]}
            className="TablePage">
          {super.render()}
          <Row key={1}>
            <Col>
              <Card>
                <CardHeader>{label.ProductCategoryDetail}</CardHeader>
                <CardBody >
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          {label.SortBy} :
                          <UncontrolledButtonDropdown key={1}>
                            <DropdownToggle
                                caret
                                color="white"
                                className="text-capitalize m-1">
                              {
                                sortirMap(this.state.sortir)
                              }
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                              <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                              <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </Col>
                        <Col>
                          {label.Sortir} :
                          <UncontrolledButtonDropdown key={2}>
                            <DropdownToggle
                                caret
                                color="white"
                                className="text-capitalize m-1">
                              {
                                this.state.ascending?label.Ascending:label.Descending
                              }
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                              <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Table responsive hover>
                            <thead>
                            <tr>
                              <th>#</th>
                              <th>{label.Name}</th>
                              <th style={{width:'10%'}}>{label.Image}</th>
                              <th>{label.Status}</th>
                              <th>{label.Updated}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                              this.state.productCategories.map((item, index) =>(
                                  <tr key={index}>
                                    <th scope="row">{++index}</th>
                                    <td>{item.name}</td>
                                    <td>
                                      {
                                        item.icon&&(
                                            <Image src={item.iconUrl} style={{width:"50%"}}/>
                                        )
                                      }
                                    </td>
                                    <td><ActiveLabel trueLabel={label.Active} falseLabel={label.Inactive} active={item.active}/></td>
                                    <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                  </tr>
                              ))
                            }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <ImageCropper src={this.state.image} aspect={aspect} show={this.state.cropperModal} callback={file=>{
            axios({
              url: file,
              method: 'GET',
              responseType: 'blob', // important
            }).then((response) => {
              var fileBlob = response.data ;
              this.setState({
                image:file,
                cropperModal:false,
                imageBlob:fileBlob,
              })
            })
          }} cancelCallback={()=>{
            this.setState({
              cropperModal:false,
              image:this.state.productCategory.imageLink,
              imageBlob:null,
            })
          }}/>

        </Page>
    );
  }
}

export default Object.assign(ProductCategoryPage, {ENDPOINT : "/categories"})
