import React from 'react';
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'reactstrap/es/Input';
import WarningLabel from "../Widget/WarningLabel";
import label from "na-utilities/src/label/Label";
import {isEmpty} from "na-utilities/src/utils/Utilities";
import ItemOption from "../Widget/ItemOption";
import Endpoint from "na-utilities/src/api/Endpoint";
import BaseComponent from "../BaseComponent";
/*= (message, okCallback, cancelCallback) =>*/
export default class CabinetCopyModal extends BaseComponent{

    constructor(props){
        super(props);
        let cabinet = this.props.cabinet?this.props.cabinet:{}
        let formName = cabinet.name
        let documentNumber = cabinet.documentNumber
        this.state = {
            lockerType:{},
            lockerTypes:[],
            rfidReaderType:{},
            rfidReaderTypes:[],
            modal: this.props.modal,
            cabinet: cabinet?cabinet:{},
            formName : formName,
            documentNumber : documentNumber,
            okCallback : this.props.okCallback,
            closeCallback : this.props.closeCallback,
        }
    }

    componentDidMount() {
        this.fetchLockerTypes()
        this.fetchRfidReaderTypes()
    }

    componentWillReceiveProps(props, nextContext) {
        let modal = this.props.modal
        if(!props.modal){
            this.setState({
                modal:props.modal,
                positiveCheck:true,
            })
        }else if(modal!=props.showing){
            let cabinet = props.cabinet
            let formName = cabinet.name
            let documentNumber = cabinet.documentNumber
            this.setState({
                modal:props.modal,
                cabinet: cabinet?cabinet:{},
                formName : formName,
                documentNumber : documentNumber,
                okCallback : props.okCallback,
                closeCallback : props.closeCallback,
            })
        }
    }

    fetchLockerTypes = () => {
        this.get(Endpoint.API.LOCKER_TYPES, {
            params:{
                ascending:true,
                sortir:"name",
            }
        }, null, response=>{
            if(response.code === 200){
                let lockerTypes = response.data
                let lockerType = undefined
                if(lockerTypes.length>=1){
                    lockerType = lockerTypes[0];
                }
                this.setState({
                    lockerTypes, lockerType
                })
            }
        }, true, true);
    }
    fetchRfidReaderTypes = () => {
        this.get(Endpoint.API.RFID_READER_TYPES, {
            params:{
                ascending:true,
                sortir:"name",
            }
        }, null, response=>{
            if(response.code === 200){
                let rfidReaderTypes = response.data
                let rfidReaderType = undefined
                if(rfidReaderTypes.length>=1){
                    rfidReaderType = rfidReaderTypes[0];
                }
                this.setState({
                    rfidReaderTypes, rfidReaderType
                })
            }
        }, true, true);
    }


    validate(){
        let {cabinet, formName, documentNumber,
            rfidReaderTypes, rfidReaderType,
            lockerTypes, lockerType, lockerTypeWarning, formNameWarning, documentNumberWarning} = this.state
        if(isEmpty(rfidReaderType)){
            this.setState({
                rfidReaderTypeWarning:label.PleaseSelectRFIDReaderType
            })
            return;
        }
        if(isEmpty(lockerType)){
            this.setState({
                lockerTypeWarning:label.PleaseSelectLockerType
            })
            return;
        }
        if(isEmpty(formName)){
            this.setState({
                formNameWarning:label.PleaseEnterFormName
            })
            return;
        }
        if(isEmpty(documentNumber)){
            this.setState({
                documentNumberWarning:label.PleaseEnterDocumentNumber
            })
            return;
        }
        this.state.okCallback(cabinet, this.state.formName, documentNumber)


    }
    render(){
        let {cabinet, formName, documentNumber,
            rfidReaderTypes, rfidReaderType,
            lockerTypes, lockerType,
            rfidReaderTypeWarning, lockerTypeWarning, formNameWarning, documentNumberWarning} = this.state

        return (
            <Modal size="lg" centered={true}
                isOpen={this.state.modal}
                backdrop={true}>
                <ModalHeader>
                    {label.CopyToTheNewFormBuilder(cabinet.name)}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    hideOptionLabel={true}
                                    title={label.LockerDevice}
                                    fieldForLabel={"name"}
                                    hideLabel={false}
                                    default={lockerType}
                                    callback={(lockerType) => {
                                        this.setState({lockerType})
                                    }}
                                    disable={true}
                                    objects={lockerTypes}/>
                                <WarningLabel show={lockerTypeWarning} message={lockerTypeWarning}/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <ItemOption
                                    hideOptionLabel={true}
                                    title={label.RFIDReaderDevice}
                                    fieldForLabel={"name"}
                                    hideLabel={false}
                                    default={rfidReaderType}
                                    callback={(rfidReaderType) => {
                                        this.setState({rfidReaderType})
                                    }}
                                    disable={true}
                                    objects={rfidReaderTypes}/>
                                <WarningLabel show={rfidReaderTypeWarning} message={rfidReaderTypeWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="formName">{label.NewFormBuilderName}</Label>
                                <Input
                                    id="formName"
                                    type="text"
                                    name="formName"
                                    value={formName}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        this.setState({
                                            formNameWarning:undefined,
                                            formName : value?value:null
                                        })
                                    }}
                                    placeholder={label.EnterNewFormBuilderName}
                                />
                                <WarningLabel show={formNameWarning} message={formNameWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="documentNumber">{label.NewDocumentNumber}</Label>
                                <Input
                                    id="documentNumber"
                                    type="text"
                                    name="formName"
                                    value={documentNumber}
                                    onChange={(e) =>{
                                        let value = e.target.value
                                        this.setState({
                                            documentNumberWarning:undefined,
                                            documentNumber : value?value:null
                                        })
                                    }}
                                    placeholder={label.EnterNewDocumentNumber}
                                />
                                <WarningLabel show={documentNumberWarning} message={documentNumberWarning}/>
                            </FormGroup>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            <Button color="primary" onClick={e=>{
                                if(this.state.okCallback){
                                    this.validate()
                                }
                            }}>
                                {label.Save}
                            </Button>
                        </Col>
                        <Col>
                            <Button color="secondary" onClick={e=>{
                                e.preventDefault()
                                if(this.state.closeCallback){
                                    this.state.closeCallback()
                                }
                            }}>
                                {label.Cancel}
                            </Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        )
    }
}

CabinetCopyModal.propTypes = {
    modal:PropTypes.bool,
    cabinet:PropTypes.object,
    okCallback:PropTypes.func,
    closeCallback:PropTypes.func,
}
