import Page from 'components/Page';
import React from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import BasePage from './BasePage';
import {DD_MM_YYYY_HH_MM_SS, FORCE_TO_UPDATES} from '../utils/Global';
import {cloneObject, parseDate, sortirMap} from 'na-utilities/src/utils/Utilities';
import {MdDelete, MdEdit, MdFileDownload} from 'react-icons/md';
import {ContentState, EditorState} from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";

const tableTypes = ['', 'bordered', 'striped', 'hover'];


class MiscellaneousFilePage extends BasePage{
  constructor(props) {
    super(props);
    this.state = {
      miscellaneousFiles: [],
      miscellaneousFile: {},
      ascending: true,
      sortir: 'id',
    }

  }

  componentDidMount() {
    super.componentDidMount();
    this.fetchAll()
  }

  fetchAll = () => {
    this.get(Endpoint.API.MISCELLANEOUS_FILES, {
      params:{
        ascending:this.state.ascending,
        sortir:this.state.sortir,
      }
    }, null, response=>{
      if(response.code === 200){
        let miscellaneousFiles = response.data
        this.setState({
          miscellaneousFiles : miscellaneousFiles
        }, () => {
        })
      }
    }, true, true);
  }

  refresh = (ascending, sortir) => (
      this.setState({
        ascending:ascending,
        sortir:sortir
      }, () => {
        this.fetchAll()
      })
  )

  render() {
    let {apkFile, apkFileBlob, forceToUpdate, descriptionEditorState, miscellaneousFile, miscellaneousFiles} = this.state

    return (
        <Page
            title={label.MiscellaneousFile}
            breadcrumbs={[{ name: label.MiscellaneousFile, active: true }]}
            className="TablePage">
          {super.render()}
          <Row key={1}>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>s
                      {label.SortBy} :
                      <UncontrolledButtonDropdown key={1}>
                        <DropdownToggle
                            caret
                            color="white"
                            className="text-capitalize m-1">
                          {
                            sortirMap(this.state.sortir)
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "name"))}>{label.Name}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "created"))}>{label.Created}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(this.state.ascending, "updated"))}>{label.Updated}</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                    <Col>
                      {label.Sortir} :
                      <UncontrolledButtonDropdown key={2}>
                        <DropdownToggle
                            caret
                            color="white"
                            className="text-capitalize m-1">
                          {
                            this.state.ascending?label.Ascending:label.Descending
                          }
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={e=>(this.refresh(true, this.state.sortir))}>{label.Ascending}</DropdownItem>
                          <DropdownItem onClick={e=>(this.refresh(false, this.state.sortir))}>{label.Descending}</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table responsive hover>
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>{label.Title}</th>
                          <th>Version Code</th>
                          <th>{label.Description}</th>
                          <th>{label.Download}</th>
                          <th>{label.Created}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          miscellaneousFiles.map((item, index) =>(
                              <tr key={index}>
                                <th scope="row">{++index}</th>
                                <td>{item.title}</td>
                                <td>{item.versionCode}</td>
                                <td>{item.description}</td>
                                <td><a href={item.completeUrl} target="_blank">{item.fileName}</a></td>
                                <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                              </tr>
                          ))
                        }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Page>
    );
  }
}

export default Object.assign(MiscellaneousFilePage, {ENDPOINT : "/miscellaneousFiles"})
