import React from 'react';
import Page from '../components/Page';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import Label from 'reactstrap/es/Label';
import CardBody from 'reactstrap/es/CardBody';
import FormGroup from 'reactstrap/es/FormGroup';
import Checkbox from '../components/Checkbox';
import queryString from 'query-string';
import CardFooter from 'reactstrap/es/CardFooter';
import Button from 'reactstrap/es/Button';
import BasePage from './BasePage';
import label from 'na-utilities/src/label/Label'
import Endpoint from "na-utilities/src/api/Endpoint";


class RoleMenuDetailPage extends BasePage{

    constructor(props) {
        super(props);
        let state = this.state
        state.menus = []
        state.role = {}
        state.roleId = queryString.parse(this.props.query).roleId
        this.setState(state)
    }

    componentDidMount() {
        if(!this.state.roleId){
            this.props.history.goBack()
        }
        super.componentDidMount();
        this.fetchRoleDetail()
    }

    fetchRoleDetail=()=>{
        this.get(Endpoint.API.ROLE+"/"+this.state.roleId, null, null, response=>{
            if(response.code===200){
                let role = response.data
                this.setState({
                    role:role
                }, () => {
                    this.fetchMenu(role)
                })
            }
        }, true, true)
    }

    fetchMenu = (role) =>{
        this.get(Endpoint.API.MENUS_TREE, {params:{
                moduleId:role.module.id
            }}, null, response=>{
            if(response.code===200){
                this.setState({
                    menus:response.data
                }, () => {
                    this.checkSelecetedMenus()
                })
            }
        }, true, true)
    }


    checkSelecetedMenus=()=>{
        let menus = this.state.menus
        menus.map((item, index)=>{
            item.checked = this.checkSelectedMenu(item.id)
            item.childMenus.map((childItem, childIndex)=>{
                childItem.checked = this.checkSelectedMenu(childItem.id)
            })
        })
        this.setState({
            menus:menus
        })
    }

    checkSelectedMenu=(menuId)=>{
        let check = false ;
        let role = this.state.role
        for (let i = 0; i < role.roleMenus.length; i++) {
            let menu = role.roleMenus[i].menu
            if(menu.id==menuId){
                check = true
                break
            }
        }
        return check
    }


    updateMenu=()=>{
        let menus = this.state.menus
        this.openConfirmDialog(label.Confirmation, "Do you want to update menu for this role ?", ()=>{
            this.post(Endpoint.API.ROLE_UPDATE_MENU+"/"+this.state.role.id, menus, null, response=>{
                if(response.code===200){
                    this.successToast(response.message)
                    this.setState({
                        role:response.data
                    },() => {
                        this.checkSelecetedMenus()
                    })
                }
            }, true, true)
        })
    }


    render() {
        let menus = this.state.menus
        menus.forEach(value => {
            if(value.link=='/'){
                value.checked = true
            }
        })
        return (
            <Page
                title="Menu"
                breadcrumbs={[{ name: 'menu', active: true }]}
            >
                {super.render()}
                <Card>
                    <CardHeader>Menu</CardHeader>
                    <CardBody>
                        <Row>
                            {
                                menus.map((item, index)=>(
                                    <Col key={index} md={3}>
                                        <Card>
                                            <CardHeader>
                                                {++index} - {item.name}
                                            </CardHeader>
                                            <CardBody>
                                                <FormGroup>
                                                    <Row>
                                                        <Col md={9}>
                                                            <Label><b>{label(item.name)}</b></Label>
                                                        </Col>
                                                        <Col md={3}>
                                                            <Checkbox checked={item.checked} onChange={checked =>{
                                                                item.parentMenu&&(
                                                                    item.childMenus.map((childItem, childIndex)=>{
                                                                            childItem.checked=checked
                                                                        }
                                                                    )
                                                                )
                                                                item.checked=checked
                                                                this.setState({
                                                                    menus:menus
                                                                })
                                                            }}/>
                                                        </Col>
                                                    </Row>
                                                    {
                                                        item.parentMenu&&(
                                                            item.childMenus.map((childItem, childIndex)=>(
                                                                    <Row key={childIndex}>
                                                                        <Col md={9}>
                                                                            <Label style={{marginLeft:'10%'}}>{label(childItem.name)}</Label>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <Checkbox checked={childItem.checked} onChange={checked =>{
                                                                                childItem.checked=checked
                                                                                item.checked=checked?checked:item.checked
                                                                                this.setState({
                                                                                    menus:menus
                                                                                })
                                                                            }}/>
                                                                        </Col>
                                                                    </Row>
                                                                )
                                                            )
                                                        )
                                                    }
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col md={6}>
                                <Button onClick={event=>{
                                    this.props.history.goBack()
                                }}>{label.Back}</Button>
                            </Col>
                            <Col md={6}>
                                <Button  className="float-right" color="primary" onClick={event=>{
                                    event.preventDefault()
                                    this.updateMenu()
                                }}>{label.Update}</Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card>
            </Page>
        );
    }
}
export default Object.assign(RoleMenuDetailPage, {ENDPOINT : "/menu"})
