import React from 'react';
import BasePage from './BasePage';
import Page from '../components/Page';
import Global, {DD_MM_YYYY_HH_MM_SS} from '../utils/Global';
import queryString from 'query-string';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import label from 'na-utilities/src/label/Label';
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Table,} from 'reactstrap';
import {allIsEmpty, currencyFormat, navigate, parseDate} from "na-utilities/src/utils/Utilities";
import MyCardImage from "../components/Card/MyCardImage";
import userProfilePicture from "../assets/img/users/user_pp.png";
import {GiGears, IoMdEye, MdSend} from "react-icons/all";
import SockJsClient from "react-stomp";
import UserDetailPage from "./UserDetailPage";
import CompanyDetailPage from "./CompanyDetailPage";
import Img from "react-image";
import UpdateProductOrderStatusModal from "../components/modal/UpdateProductOrderStatusModal";
import Endpoint from "na-utilities/src/api/Endpoint";


class ProductOrderDetailPage extends BasePage{
    successToast(message) {
        super.successToast(message);
    }

    constructor(props) {
        super(props);
        let state = this.state
        state.orderItems = []
        state.paymentProofs = []
        state.productOrder = {}
        state.productOrderState = {}
        state.productOrderStatusHistories = []
        state.productOrderIdQuery = queryString.parse(this.props.query).id
        state.productOrderCategory = {}
        state.productOrderStatusModal = false
        state.ascending = true
        state.sortir = 'created'
        this.setState(state)
    }

    componentDidMount() {
        super.componentDidMount();
        this.fetchProductOrderDetail(this.state.productOrderIdQuery)
    }

    componentWillReceiveProps(props, nextContext) {
        if(props!=this.props){
            this.setState({
                page:queryString.parse(props.query).page?queryString.parse(props.query).page:1
            }, () => {
                // this.fetchAllInventories(this.state.productOrder.id, this.state.ascending, this.state.sortir, true)
            })
        }
    }

    fetchProductOrderDetail = (id) => {
        if(id!=null){
            this.get(Endpoint.API.PRODUCT_ORDER, {
                params :{
                    id : id
                }
            }, null, response=>{
                if(response.code===200){
                    let productOrder = response.data
                    let productOrderState = productOrder.productOrderState?productOrder.productOrderState:{}
                    this.setState({
                        productOrder,productOrderState
                    }, () => {
                        this.fetchProductOrderStatusHistories()
                        this.fetchOrderItems()
                        this.fetchPaymentProofs()
                    })
                }else{
                    this.props.history.goBack();
                }
            }, true, true)
        }
    }


    fetchProductOrderStatusHistories = () =>{
        let productOrder = this.state.productOrder?this.state.productOrder:{}
        this.get(Endpoint.API.PRODUCT_ORDER_STATUS_HISTORIES, {
            params:{
                productOrderId:productOrder.id,
                sortir:"created",
                ascending:false,
            }
        }, null, res =>{
            if(res.code === 200){
                this.setState({
                    productOrderStatusHistories : res.data
                })
            }
        }, true, true);
    }

    fetchOrderItems = () =>{
        let {orderItems, productOrder} = this.state
        this.get(Endpoint.API.ORDER_ITEMS, {
            params:{
                productOrderId:productOrder.id,
                sortir:"created",
                ascending:false,
            }
        }, null, res =>{
            if(res.code === 200){
                orderItems = res.data
                this.setState({
                    orderItems
                })
            }
        }, true, true);
    }

    fetchPaymentProofs = () =>{
        let {paymentProofs, productOrder} = this.state
        this.get(Endpoint.API.PAYMENT_PROOFS, {
            params:{
                productOrderId:productOrder.id,
                sortir:"created",
                ascending:false,
            }
        }, null, res =>{
            if(res.code === 200){
                paymentProofs = res.data
                this.setState({
                    paymentProofs
                })
            }
        }, true, true);
    }


    resendEmail = () =>{
        let {orderItems, productOrder} = this.state
        let form = new FormData()
        form.append("reference", productOrder.reference)
        this.post(Endpoint.API.PRODUCT_ORDER_RESEND_EMAIL, form, null, res =>{
            if(res.code === 200){
                productOrder = res.data
                this.setState({productOrder})
                this.successToast(res.message)
            }
        }, true, true);
    }




    closeDialog = () =>{
        this.setState({
            modalAddConfirm:false,
            modalDeleteConfirm:false,
        })
    }



    render() {
        let {productOrder, productOrderStatusHistories, productOrderState, orderItems, paymentProofs,productOrderStatusModal} = this.state
        let container = productOrder.container?productOrder.container:{}
        let smartDevice = container.smartDevice?container.smartDevice:{}
        let spotLocation = smartDevice.spotLocation?smartDevice.spotLocation:{}
        let user = productOrder.user?productOrder.user:{}
        let company = productOrder.company?productOrder.company:{}
        let companyType = company.companyType?company.companyType:{}
        let industry = company.industry?company.industry:{}
        let subPaymentMedia = productOrder.subPaymentMedia?productOrder.subPaymentMedia:{}
        let paymentMedia = productOrder.paymentMedia?productOrder.paymentMedia:{}
        let productOrderStatus = productOrder.productOrderStatus?productOrder.productOrderStatus:{}
        return (
            <Page
                title={label.ProductOrderDetail}
                breadcrumbs={[{ name: label.ProductOrderDetail, active: true }]}>
                {super.render()}
                <UpdateProductOrderStatusModal
                    modal={productOrderStatusModal}
                    productOrder={productOrder}
                    okCallback={()=>{
                        this.setState({productOrderStatusModal:false},() => {
                            this.fetchProductOrderDetail(this.state.productOrderIdQuery)
                        })
                    }}
                    closeCallback={() => {
                        this.setState({productOrderStatusModal:false})
                    }}
                />
                <Card>
                    <CardHeader>{label.ProductOrderDetail}</CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={3}>
                                <Card>
                                    <CardHeader>{label.Customer}</CardHeader>
                                    <CardBody>
                                        <MyCardImage top src={
                                            user.photoUrl!=null?user.photoUrl:userProfilePicture}
                                                     onClick={e=>{
                                                         if(!allIsEmpty(user, user.id)) {
                                                             this.openLightBoxSingleImage(user.photoUrl)
                                                         }
                                                     }}
                                                     onError={(elm)=>this.defaultImage(elm, userProfilePicture)}/>
                                    </CardBody>
                                    <CardBody>
                                        <Row>
                                            <Col md={10}>
                                                <FormGroup>
                                                    <Label for="fullname">{label.Fullname}</Label>
                                                    <Input name={"fullname"} value={user.fullname} disabled/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="fullname">&nbsp;&nbsp;</Label>
                                                    <Button
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            navigate(this.props, UserDetailPage.ENDPOINT+"?id="+user.id)
                                                        }}>
                                                        <IoMdEye/>
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="email">{label.Email}</Label>
                                                    <Input name={"email"} value={user.email} disabled/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="mobilePhone">{label.MobilePhone}</Label>
                                                    <Input name={"mobilePhone"} value={user.mobilePhone} disabled/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>{label.ProductOrderInfo}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={6}>
                                                        <FormGroup>
                                                            <Label for="reference">{label.Reference}</Label>
                                                            <Input name={"reference"} value={productOrder.reference} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label for="paymentReference">{label.NotificationEmailSent}</Label>
                                                            <Input name={"paymentReference"} value={productOrder.emailSent} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col md={3}>
                                                        <FormGroup>
                                                            <Label>&nbsp;&nbsp;</Label>
                                                            <br/>
                                                            <Button
                                                                block
                                                                onClick={() => {
                                                                    this.resendEmail()
                                                                }}>
                                                                {label.Resend}&nbsp;<MdSend/>
                                                            </Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentMedia">{label.PaymentMedia}</Label>
                                                            <Input name={"paymentMedia"} value={paymentMedia.label} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col>
                                                        {label.Company}
                                                    </Col>
                                                    <Col className={"text-right"}>
                                                        <Button
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                navigate(this.props, CompanyDetailPage.ENDPOINT+"?id="+company.id)
                                                            }}>
                                                            <IoMdEye/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="reference">{label.Name}</Label>
                                                            <Input name={"reference"} value={company.fullName} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentReference">{label.Email}</Label>
                                                            <Input name={"paymentReference"} value={company.email} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentReference">{label.Phone}</Label>
                                                            <Input name={"paymentReference"} value={company.companyPhoneNumber} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="reference">{label.CompanyType}</Label>
                                                            <Input name={"reference"} value={companyType.name} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for="paymentReference">{label.Industry}</Label>
                                                            <Input name={"paymentReference"} value={industry.name} disabled/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col>
                                                        {label.ProductOrderItem}
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody className={"p-0 m-0"}>
                                                <Row className={"p-0 m-0"}>
                                                    <Col className={"p-0 m-0"}>
                                                        <Table hover responsive className={"p-0 m-0"}>
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>{label.Name}</th>
                                                                <th>{label.AllocatedDeviceId}</th>
                                                                <th>{label.Type}</th>
                                                                <th>{label.Amount}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {
                                                                orderItems.map((item, index) => (
                                                                    <tr key={item.id}>
                                                                        <th scope="row">{(++index)}</th>
                                                                        <td>{item.productPackage.name}</td>
                                                                        <td>{item.allocatedDeviceId}</td>
                                                                        <td>{item.type}</td>
                                                                        <td>{currencyFormat(item.productPackage.price, 'Rp')}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            </tbody>
                                                        </Table>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                <Row>
                                                    <Col md={6}>
                                                        {label.Status}
                                                    </Col>
                                                    <Col md={6} className={"text-right"}>
                                                        <Button
                                                            color={"warning"}
                                                            onClick={() => {
                                                                this.setState({productOrderStatusModal:true})
                                                            }}>
                                                            {label.ControlStatus}&nbsp;<GiGears/>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody style={{textAlign:'center'}}>
                                                <span style={{fontSize:'44px', fontWeight:'bold', color:productOrderStatus.color}}>{productOrderStatus.label}</span>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Card>
                                            <CardHeader>
                                                {label.AmountInformation}
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.BuyAmount}</Label>
                                                            <Input disabled value={currencyFormat(productOrder.amountPrice, 'Rp')}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.AdminFee}</Label>
                                                            <Input disabled value={currencyFormat(productOrder.totalAdminFee, 'Rp')}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label>{label.Tax}</Label>
                                                            <Input disabled value={productOrder.taxPercent+"% - "+currencyFormat(productOrder.taxAmount, 'Rp')}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label size={"lg"}>{label.TotalProductOrderAmount}</Label>
                                                            <Input size={"lg"} disabled value={currencyFormat(productOrder.totalProductOrderAmount, 'Rp')}/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardHeader>
                                        {label.ProductOrderStatus}
                                    </CardHeader>
                                    <CardBody className={"p-0 m-0"}>
                                        <Row className={"p-0 m-0"}>
                                            <Col className={"p-0 m-0"}>
                                                <Table hover responsive>
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{label.Name}</th>
                                                        <th>{label.Description}</th>
                                                        <th>{label.Created}</th>
                                                        <th>{label.Updated}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        productOrderStatusHistories.map((item, index) => (
                                                            <tr key={item.id}>
                                                                <th scope="row">{(++index)}</th>
                                                                <td>{item.productOrderStatus.label}</td>
                                                                <td>{item.description}</td>
                                                                <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                                                <td>{parseDate(item.updated, DD_MM_YYYY_HH_MM_SS)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <CardHeader>
                                        {label.PaymentProof}
                                    </CardHeader>
                                    <CardBody className={"p-0 m-0"}>
                                        <Row className={"p-0 m-0"}>
                                            <Col className={"p-0 m-0"}>
                                                <Table hover responsive>
                                                    <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>{label.Name}</th>
                                                        <th>{label.Description}</th>
                                                        <th>{label.Image}</th>
                                                        <th>{label.Created}</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        paymentProofs.map((item, index) => (
                                                            <tr key={item.id}>
                                                                <th scope="row">{(++index)}</th>
                                                                <td>{item.name}</td>
                                                                <td>{item.description}</td>
                                                                <td width="20%">
                                                                    <Img
                                                                        width={80}
                                                                        className="img-thumbnail"
                                                                        src={item.imageLink}
                                                                        onClick={e=>{
                                                                            this.setState({
                                                                                imageLink:item.imageLink,
                                                                                openPreview:true
                                                                            })
                                                                        }}
                                                                    />
                                                                </td>
                                                                <td>{parseDate(item.created, DD_MM_YYYY_HH_MM_SS)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <SockJsClient
                    url={Global.BASE_URL}
                    topics={[Endpoint.API.LISTEN_UPDATE_CHARGING_INFORMATION+"/"+productOrder.id]}
                    autoReconnect={true}
                    debug={Global.DEBUG}
                    heartbeat={5000}
                    onMessage={(chargingInformation) => {
                        // console.log(chargingInformation)
                        if(chargingInformation.productOrderStateDto){
                            let productOrderState = chargingInformation.productOrderStateDto
                            this.setState({productOrderState}, () => {
                                if(productOrderState.finish){
                                    this.fetchProductOrderDetail(productOrder.id)
                                }
                            })
                        }
                    }}
                    onConnectFailure={(object)=>{
                        console.error(object)
                    }}
                    ref={ (client) => { this.clientRef = client }} />

            </Page>
        );
    }
}
export default Object.assign(ProductOrderDetailPage, {ENDPOINT : "/productOrderDetail"})